<template>
  <div class="gg-table member-table">
    <!-- <div class="table-tabs">
      <ul>
        <li v-for="(tab, index) in tableTabs" :key="index" @click="tabIndex = index">
          <router-link :to="'#' + tab.title" class="active-tabs">{{ tab.title }}</router-link>
        </li>
      </ul>
    </div> -->
    <div class="table-top-nav">
      <md-tabs>
        <md-tab md-label="All" @click="tabIndex = 55"></md-tab>
        <!-- <md-tab md-label="Customers" @click="tabIndex = 1"></md-tab>
        <md-tab md-label="Deliveries" @click="tabIndex = 2"></md-tab>
        <md-tab md-label="Sources" @click="tabIndex = 3"></md-tab>
        <md-tab md-label="Outlets" @click="tabIndex = 4"></md-tab>
        <md-tab md-label="Staffs" @click="tabIndex = 6"></md-tab>
        <md-tab md-label="Inactive" @click="tabIndex = 5"></md-tab> -->
      </md-tabs>
      <md-input
        placeholder="Search by name..."
        v-model="search"
        @input="searchOnTable"
      />
    </div>

    <md-table
      v-model="filterTableData.slice(count_start, count_end)"
      md-sort="name"
      md-sort-order="asc"
    >
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item, index }"
        v-if="item.group != null"
      >
        <md-table-cell md-label="SN" md-numeric
          >{{ index + 1 + (current_page - 1) * 10 }}.</md-table-cell
        >
        <md-table-cell md-label="Name" md-sort-by="name">
          <router-link :to="'/member-detail-page/' + item.id">{{}}</router-link>
        </md-table-cell>
        <md-table-cell
          md-label="Leader"
          md-sort-by="leader"
          >{{
        }}</md-table-cell>
        <md-table-cell
          md-label="Aggregator"
          md-sort-by="aggregator"
          >{{
        }}</md-table-cell>
        <md-table-cell
          md-label="Member"
          md-sort-by="member"
          >{{
        }}</md-table-cell>
        <md-table-cell
          md-label="Addressline"
          md-sort-by="address"
          >{{
        }}</md-table-cell>
        <md-table-cell md-label="Member" md-sort-by="status"> </md-table-cell>
        <md-table-cell md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item
                  v-if="profile_obj.admin"
                  @click="openEditDialog(item)"
                  >Edit</md-menu-item
                >
                <md-menu-item
                  :to="{ name: 'member-detail', params: { id: item.id } }"
                  >View Detail</md-menu-item
                >
                <span v-if="profile_obj.admin">
                  <md-menu-item
                    v-if="item.active"
                    @click="openRemoveDialog(item.id)"
                    >Remove</md-menu-item
                  >
                  <md-menu-item v-else @click="openActivateDialog(item.id)"
                    >Activate</md-menu-item
                  >
                </span>
                <md-menu-item @click="showAddressDialog(item)"
                  >Modify Address</md-menu-item
                >
                <md-menu-item
                  v-if="profile_obj.admin && !item.staff && item.group.id == 6"
                  @click="openStaffAccesDialog(item.id)"
                  >Give Full Access</md-menu-item
                >
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div v-if="filterTableData && filterTableData.length">
      <ul class="nav-pagination">
        <li>
          <router-link
            :to="{
              name: current_route,
              query: { page: current_page <= 1 ? 1 : current_page - 1 },
            }"
            >&laquo;</router-link
          >
        </li>

        <li
          v-for="(page, index) in Math.ceil(
            filterTableData.length / page_sequence
          )"
          :class="current_page == page ? 'active' : ''"
          :key="index"
        >
          <router-link :to="{ name: current_route, query: { page: page } }">{{
            page
          }}</router-link>
        </li>

        <li>
          <router-link
            :to="{
              name: current_route,
              query: {
                page:
                  current_page >=
                  Math.ceil(filterTableData.length / page_sequence)
                    ? Math.ceil(filterTableData.length / page_sequence)
                    : current_page + 1,
              },
            }"
            >&raquo;</router-link
          >
        </li>
      </ul>
    </div>

    <div>
      <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span
              class="material-icons error-icon"
              @click="showDialogEdit = false"
              >highlight_off</span
            >
          </md-dialog-actions>
          <form class="main-form" @submit.prevent="updateMember()">
            <h2 class="form-title">Edit Members</h2>

            <label for="name">First Name *</label>
            <input
              type="text"
              class="product"
              v-model="single_user.first_name"
              placeholder="First Name"
            />
            <span class="form-error" v-if="errors.first_name">{{
              errors.first_name
            }}</span>

            <label for="name">Middle Name</label>
            <input
              type="text"
              class="product"
              v-model="single_user.middle_name"
              placeholder="Middle Name"
            />

            <label for="name">Last Name *</label>
            <input
              type="text"
              class="product"
              v-model="single_user.last_name"
              placeholder="Last Name"
            />
            <span class="form-error" v-if="errors.last_name">{{
              errors.last_name
            }}</span>

            <!-- <label for="name">Date of Birth *</label>
            <input type="date" class="product" v-model="dob" placeholder="Date of Birth" />
            <span class="form-error" v-if="errors.dob">{{errors.dob}}</span> -->

            <label for="role">Role *</label>
            <select class="product" v-model="role">
              <option value disabled>Select Role</option>
              <option
                v-for="(role_obj, index) in user_role_obj"
                :key="index"
                v-if="role_obj.id != 1 && role_obj.id != 2"
                :value="role_obj.id"
              >
                {{ role_obj.id == 4 ? "Source" : role_obj.name }}
              </option>
            </select>
            <label for="role">User Description*</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              v-model="user_description"
              placeholder="Enter the user description"
            ></textarea>
            <span class="form-error" v-if="errors.role">{{ errors.role }}</span>
            <span
              style="color: red; font-weight: bold"
              v-if="errors.user_registration_error"
              ><br />{{ errors.user_registration_error }}</span
            >

            <span class="form-success" v-if="success">
              <md-icon class="md-size-2x">check_circle_outline</md-icon>
              {{ success }}
            </span>

            <button type="submit" class="gg-create-btn">Save Changes</button>

            <input type="hidden" v-model="state_change_text" />
          </form>
        </div>
      </md-dialog>
    </div>
    <div>
      <md-dialog :md-active.sync="showDialogAddress" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span
              class="material-icons error-icon"
              @click="showDialogAddress = false"
              >highlight_off</span
            >
          </md-dialog-actions>
          <form class="main-form" @submit.prevent="modifyAddress">
            <h2 class="form-title">Modify Delivery Address</h2>

            <label for="product">State Name *</label>
            <input
              type="text"
              v-model.trim="single_address.state"
              class="product"
              placeholder="Enter State Name"
            />
            <span v-if="errors.state_name" style="color: red">{{
              errors.state_name
            }}</span>

            <label for="product">City Name *</label>
            <input
              type="text"
              v-model.trim="single_address.city"
              class="product"
              placeholder="Enter City Name"
            />
            <span v-if="errors.city_name" style="color: red">{{
              errors.city_name
            }}</span>

            <label for="product">Street Address *</label>
            <input
              type="text"
              v-model.trim="single_address.street_address"
              class="product"
              placeholder="Enter Street Address"
            />
            <span v-if="errors.street_address" style="color: red">{{
              errors.street_address
            }}</span>
            <label for="product">User Description*</label>
            <input
              type="text"
              v-model.trim="single_address.user_description"
              class="product"
              placeholder="Enter Street Address"
            />
            <span v-if="errors.street_address" style="color: red">{{
              errors.street_address
            }}</span>

            <label for="product">Latitude Value *</label>
            <input
              type="text"
              v-model.trim="single_address.latitude"
              class="product"
              placeholder="Enter Latitude Value"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            />
            <span v-if="errors.latitude_value" style="color: red">{{
              errors.latitude_value
            }}</span>

            <label for="product">Longitude Value *</label>
            <input
              type="text"
              v-model.trim="single_address.longitude"
              class="product"
              placeholder="Enter Longitude Value"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            />
            <span v-if="errors.longitude_value" style="color: red">{{
              errors.longitude_value
            }}</span>

            <span class="form-success" v-if="success"
              ><strong>{{ success }}</strong></span
            >
            <button type="submit" class="gg-create-btn">Modify</button>
          </form>
        </div>
      </md-dialog>
    </div>
    <div>
      <md-dialog :md-active.sync="showDialogRemove">
        <div v-if="delete_status == false">
          <div class="modal-alert">
            <div class="icon-div">
              <span class="material-icons remove-icon">highlight_off</span>
            </div>
            <md-dialog-title class="modal-title"
              >Are you sure want to remove this member?</md-dialog-title
            >
            <md-dialog-actions>
              <md-button class="cancel-btn" @click="showDialogRemove = false"
                >Cancel</md-button
              >
              <md-button class="remove-btn" @click="deleteUser"
                >Delete</md-button
              >
            </md-dialog-actions>
          </div>
        </div>
        <div v-else>
          <div class="modal-alert">
            <div class="icon-div">
              <span class="material-icons remove-icon">highlight_off</span>
            </div>
            <md-dialog-title class="modal-title"
              >Member has been Deleted.</md-dialog-title
            >
            <md-dialog-actions>
              <md-button
                class="cancel-btn"
                @click="(showDialogRemove = false), (delete_status = false)"
                >Cancel</md-button
              >
            </md-dialog-actions>
          </div>
        </div>
      </md-dialog>
      <md-dialog :md-active.sync="showDialogActivate">
        <div class="modal-alert">
          <div class="icon-div">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
          </div>
          <md-dialog-title class="modal-title"
            >Are you sure want to activate this member?</md-dialog-title
          >
          <md-dialog-actions>
            <md-button class="cancel-btn" @click="showDialogActivate = false"
              >Cancel</md-button
            >
            <md-button
              type="submmit"
              class="gg-create-btn"
              @click="activateUser"
              >Activate Now</md-button
            >
          </md-dialog-actions>
        </div>
      </md-dialog>
    </div>
    <div>
      <md-dialog :md-active.sync="showStaffAccesDialog">
        <div class="modal-alert">
          <div class="icon-div">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
          </div>
          <md-dialog-title class="modal-title"
            >Are you sure to give full access to this user?</md-dialog-title
          >
          <p class="form-success" v-if="success">
            <strong>{{ success }}</strong>
          </p>
          <md-dialog-actions>
            <md-button class="cancel-btn" @click="showStaffAccesDialog = false"
              >Cancel</md-button
            >
            <md-button
              type="submmit"
              class="gg-create-btn"
              @click="updateUserToStaff"
              >Give Full Access</md-button
            >
          </md-dialog-actions>
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TableMember",
  components: {},
  data() {
    return {
      showDialogEdit: false,
      showDialogRemove: false,
      showDialogActivate: false,
      showDialogAddress: false,
      showStaffAccesDialog: false,
      full_name: "",
      phone_number: "",
      email: "",
      username: "",
      staff_member: [],
      single_user: [],
      errors: [],
      success: "",
      tabIndex: 0,
      activeTab: "",
      tableTabs: [
        {
          id: 0,
          title: "All",
        },
        {
          id: 1,
          title: "Customers",
        },
        {
          id: 3,
          title: "Deliveries",
        },
        {
          id: 4,
          title: "Sources",
        },
        {
          id: 5,
          title: "Outlets",
        },
        {
          id: 6,
          title: "Inactive",
        },
      ],
      dob: "",
      single_address: {
        state: "",
        city: "",
        street_address: "",
        latitude: "",
        longitude: "",
        author: "",
      },
      state_change_text: "",
      currentLocation: JSON.parse(localStorage.getItem("currentLocation")),
      role: null,
      user_description: "",
      delete_status: false,
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      search: "",
    };
  },
  computed: {
    ...mapState([
      "profile_obj",
      "all_user_obj",
      "user_role_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: { page: 1 },
      });

      let table_data = this.all_user_obj;
      let searchString = this.search;
      if (!searchString) {
        return table_data;
      } else {
        searchString = searchString.trim().toLowerCase();
        table_data = table_data.filter((row) => {
          if (item.full_name.toLowerCase().indexOf(searchString) !== -1) {
            return item;
          }
        });
        if (this.tabIndex == 1) {
          table_data = table_data.filter((row) => {
            return (
              (row.group.id == 1 && row.active) ||
              (row.group.id == 2 && row.active)
            );
          });

          if (this.tabIndex == 2) {
            table_data = table_data.filter((row) => {
              return row.group.id == 3 && row.active;
            });
          }
          if (this.tabIndex == 3) {
            table_data = table_data.filter((row) => {
              return row.group.id == 4 && row.active;
            });
          }
          if (this.tabIndex == 4) {
            table_data = table_data.filter((row) => {
              return row.group.id == 5 && row.active;
            });
          }
          if (this.tabIndex == 6) {
            table_data = table_data.filter((row) => {
              return row.group.id == 6 && row.active;
            });
          }
          if (this.tabIndex == 5) {
            table_data = table_data.filter((row) => {
              return !row.active;
            });
          }
        }
        // Return the filtered data
        return table_data;
      }
    },
  },
  created() {
    this.listProfile();
    this.listUser();
    this.listUserRole();

    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0
        ? this.$route.query.page
        : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  mounted() {
    // Pagination
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0
          ? this.$route.query.page
          : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    },
  },
  methods: {
    ...mapActions(["listProfile", "listUser", "listUserRole"]),
    openEditDialog(staffObj) {
      this.showDialogEdit = true;
      this.single_user = staffObj;
      this.role = staffObj.group.id;
      this.dob = staffObj.user_info ? staffObj.user_info.dob : "";
    },
    updateMember() {
      this.errors = [];
      if (this.single_user.first_name == "") {
        this.errors["first_name"] = "First name required.";
      } else if (this.single_user.last_name == "") {
        this.errors["last_name"] = "Last name required.";
      } else if (!this.role || this.role == "") {
        this.errors["role"] = "User Role required.";
      } else {
        let changeUserObj = {
          first_name: this.single_user.first_name,
          middle_name: this.single_user.middle_name,
          last_name: this.single_user.last_name,
          image: null,
          group: this.role,
          dob: this.single_user.user_info
            ? this.single_user.user_info.dob
            : "2000-01-01",
          nationality: 1,
          country: 1,
          city: this.single_user.user_info
            ? this.single_user.user_info.city
            : "Kathmandu",
          state: this.single_user.user_info
            ? this.single_user.user_info.state
            : "Bagmati Pradesh",
          street_address: this.single_user.user_info
            ? this.single_user.user_info.street_address
            : "N/a",
          latitude: this.single_user.user_info
            ? this.single_user.user_info.latitude
            : this.currentLocation
            ? this.currentLocation.latitude
            : "27.700000762939453",
          longitude: this.single_user.user_info
            ? this.single_user.user_info.longitude
            : this.currentLocation
            ? this.currentLocation.longitude
            : "85.33329772949219",
          id: this.single_user.id,
          user_description: this.user_description,
        };

        //console.log(changeUserObj)

        this.$store.dispatch("updateUserDetail", changeUserObj).then(() => {
          if (this.success_message == "success") {
            this.success = "User Detail Updated Successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(JSON.stringify(this.response_message));
          }
        });
      }
    },
    showAddressDialog(userItem) {
      this.showDialogAddress = true;
      this.single_address.author = userItem.id;
      if (userItem.user_info) {
        this.single_address.state = userItem.user_info.state;
        this.single_address.city = userItem.user_info.city;
        this.single_address.street_address = userItem.user_info.street_address;
        this.single_address.latitude = userItem.user_info.latitude;
        this.single_address.longitude = userItem.user_info.longitude;
      } else {
        this.single_address.state = "";
        this.single_address.city = "";
        this.single_address.street_address = "";
        this.single_address.latitude = "";
        this.single_address.longitude = "";
      }
    },
    modifyAddress() {
      this.success = "";
      this.errors = [];
      if (this.single_address.state == "") {
        this.errors["state_name"] = "State name required";
      } else if (this.single_address.city == "") {
        this.errors["city_name"] = "City name required";
      } else if (this.single_address.street_address == "") {
        this.errors["street_address"] = "Street address required";
      } else if (this.single_address.latitude == "") {
        this.errors["latitude_value"] = "Latitude value required";
      } else if (this.single_address.longitude == "") {
        this.errors["longitude_value"] = "Longitude value required";
      } else {
        let formData = {
          state: this.single_address.state,
          city: this.single_address.city,
          street_address: this.single_address.street_address,
          user_description: this.single_address.user_description,
          latitude: this.single_address.latitude,
          longitude: this.single_address.longitude,
          id: this.single_address.author,
        };
        this.$store.dispatch("updateUserIinfo", formData).then(() => {
          if (this.success_message == "success") {
            this.success = "UserInfo Modified Successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
    openRemoveDialog(user_id) {
      this.single_user = this.all_user_obj.find((row) => row.id == user_id);
      this.showDialogRemove = true;
    },
    deleteUser() {
      if (this.single_user && this.single_user.id > 0) {
        this.showDialogRemove = false;
        this.$store
          .dispatch("deleteUser", {
            active: false,
            id: this.single_user.id,
          })
          .then(() => {
            if (this.success_message == "success") {
              this.single_user = [];
              this.success = "";
              this.delete_status = true;
              location.reload();
            } else if (this.error_message == "errormessage") {
              this.single_user = [];
              alert(this.response_message);
            }
          });
      }
    },
    openActivateDialog(user_id) {
      this.single_user = this.all_user_obj.find((row) => row.id == user_id);
      this.showDialogActivate = true;
    },
    activateUser() {
      if (this.single_user && this.single_user.id > 0) {
        this.$store
          .dispatch("activateUser", {
            active: true,
            id: this.single_user.id,
          })
          .then(() => {
            if (this.success_message == "success") {
              this.single_user = [];
              location.reload();
            } else if (this.error_message == "errormessage") {
              this.single_user = [];
              alert(this.response_message);
            }
          });
      }
    },
    openStaffAccesDialog(user_id) {
      this.success = "";
      this.single_user = this.all_user_obj.find((row) => row.id == user_id);
      this.showStaffAccesDialog = true;
    },
    updateUserToStaff() {
      if (this.single_user && this.single_user.id > 0) {
        this.$store
          .dispatch("makeUserToStaff", {
            staff: true,
            id: this.single_user.id,
          })
          .then(() => {
            if (this.success_message == "success") {
              this.single_user = [];
              this.success = "Access Provided Successfully.";
              setTimeout(() => {
                location.reload();
              }, 1200);
            } else if (this.error_message == "errormessage") {
              this.single_user = [];
              alert(this.response_message);
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.main-form .gg-create-btn {
  width: 150px;
}
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
</style>
