<template>
  <div class="Members">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">people_outline</md-icon>
        <h1>Members</h1>
      </md-toolbar>
      <Cards />
      <md-toolbar class="nav section-title width">
        <span class="sub-heading">Table of Members</span>
        <button v-if="profile_obj.admin" class="gg-create-btn" @click="openDialog = !openDialog">+ Create new member</button>
      </md-toolbar>
      <div class="gg-member-table">
        <TableMember />
      </div>
    </div>
    <ModalMember v-if="profile_obj.admin && openDialog" />
  </div>
</template>

<script>
// @ is an alias to /src
import Cards from "@/components/Cards.vue";
import TableMember from "@/components/TableMember.vue";
import ModalMember from "@/components/ModalMember.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Members",
  components: {
    Cards,
    TableMember,
    ModalMember,
  },
   data: () => ({
    openDialog: false,
  }),
  computed:{
    ...mapState(["profile_obj"])
  },
  created(){
    this.listProfile();
  },
  methods: {
    ...mapActions(["listProfile"])
  },
};
</script>
<style lang='scss' scoped>
</style>
