<template>
  <div class="profile-detail-vue">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>
    <div class="profile-head">
      <div class="profile">
        <div class="circle-icon">
          <input
            type="file"
            id="imageUpload"
            ref="userFile"
            accept="image/png, image/jpeg"
            @change="onUserImageChange"
          />
          <label for="imageUpload" type="file" class="circle-1">
            <i class="fas fa-camera fa-2x"></i>
          </label>
          <img
            v-if="
              profile_obj.image ==
              'http://18.138.188.241:1001/media/profile/default.jpg'
            "
            src="../assets/img/avatar.jpg"
            alt="profile preview"
            class="profile-image-preview"
          />
          <img
            v-else
            :src="profile_obj.image"
            alt="profile preview"
            class="profile-image-preview"
          />
        </div>
      </div>
      <div class="profile-button">
        <div class="add-btn">
          <!-- <router-link to="/purchase-page" class="gg-create-btn">Purchase</router-link> -->
        </div>
      </div>
    </div>

    <div class="profile-details">
      <div class="profile-information">
        <table class="profile-detail-table">
          <tr>
            <td class="td1">Name</td>
            <td class="td2">{{ profile_obj.full_name }}</td>
          </tr>
          <tr>
            <td class="td1">Email</td>
            <td class="td2">{{ profile_obj.email }}</td>
          </tr>
          <tr>
            <td class="td1">Contact</td>
            <td class="td2">{{ profile_obj.phone_number }}</td>
          </tr>
          <div v-if="profile_obj.user_info">
            <tr>
              <td class="td1">Date of Birth</td>
              <td class="td2">{{ profile_obj.user_info.dob }} <AD></AD></td>
            </tr>
          </div>
          <tr>
            <td class="td1">Address</td>
            <td class="td2">
              <span
                v-if="
                  profile_obj.user_info && profile_obj.user_info.street_address
                "
                >{{ profile_obj.user_info.street_address }},
              </span>
              <span v-if="profile_obj.user_info && profile_obj.user_info.city"
                >{{ profile_obj.user_info.city }},
              </span>
              <span
                v-if="profile_obj.user_info && profile_obj.user_info.state"
                >{{ profile_obj.user_info.state }}</span
              >
            </td>
          </tr>
          <tr
            v-if="profile_obj.user_info && profile_obj.user_info.company_name"
          >
            <td class="td1">Company Name</td>
            <td class="td2">{{ profile_obj.user_info.company_name }}</td>
          </tr>
          <tr
            v-if="profile_obj.user_info && profile_obj.user_info.vat_pan_number"
          >
            <td class="td1">VAT/PAN Number</td>
            <td class="td2">
              {{ profile_obj.user_info.vat_pan_number }} (<a
                :href="profile_obj.user_info.vat_pan_photo"
                target="_blank"
                >View Copy</a
              >)
            </td>
          </tr>

          <div class="circle-2">
            <i class="fas fa-pen" @click="updateProfileModal"></i>
          </div>
        </table>
        <table class="role" v-if="profile_obj.group">
          <tr>
            <td class="td1">Role</td>
            <td class="td2">
              {{ profile_obj.admin ? "Super Admin" : profile_obj.group.name }}
            </td>
            <td class="td2"></td>
          </tr>
        </table>
      </div>
      <div class="purchase-information">
        <table class="total-purchase">
          <tr>
            <td class="td1">Total Purchase</td>
            <td class="td2">Rs. {{ calculateMyPurchase }}</td>
          </tr>
        </table>

        <table class="total-food-miles">
          <tr>
            <td class="td1">Total Food Miles</td>
            <td class="td2">{{ parseInt(calculateFoodMiles) }} Km</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <md-dialog :md-active.sync="openDialog" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span
              class="material-icons error-icon"
              @click="closeUpdateProfileModal"
              >highlight_off</span
            >
          </md-dialog-actions>
          <form action>
            <div class="main-form">
              <h2 class="form-title">Edit Profile</h2>

              <label for="name">First Name *</label>
              <input
                type="text"
                class="name"
                v-model.trim="form.first_name"
                placeholder="First Name"
              />
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.first_name"
                >{{ errors.first_name }}</span
              >

              <label for="name">Middle Name </label>
              <input
                type="text"
                class="name"
                v-model.trim="form.middle_name"
                placeholder="Middle Name"
              />

              <label for="name">Last Name *</label>
              <input
                type="text"
                class="name"
                v-model.trim="form.last_name"
                placeholder="Last Name"
              />
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.last_name"
                >{{ errors.last_name }}</span
              >

              <label for="company">Company Name</label>
              <input
                type="text"
                class="company"
                v-model.trim="form.company_name"
                placeholder="Company Name"
              />

              <label for="vat_number">VAT/PAN Number</label>
              <input
                type="number"
                class="contact"
                v-model.trim="form.vat_pan_number"
                placeholder="VAT/PAN Number"
              />
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.vat_pan_number"
                >{{ errors.vat_pan_number }}</span
              >

              <label>
                <div>VAT/PAN Copy</div>
                <div class="upload-file-area">
                  <input
                    type="file"
                    ref="file"
                    @change="onChangeFileUpload()"
                    accept="image/*"
                  />
                  <div class="drag-text">
                    <div>
                      <button class="choose-button">Choose File</button>
                    </div>
                    <div class="or">OR</div>
                    <div>Drag and drop your file here</div>
                  </div>
                </div>
              </label>
              <div class="thumbnail">
                <span
                  class="clear-image"
                  v-if="file !== ''"
                  @click="clearImage"
                >
                  <md-icon>cancel </md-icon>
                </span>
                <img :src="image_url" />
              </div>

              <label for="dob">Date of Birth</label>
              <input
                type="date"
                class="dob"
                v-model.trim="form.dob"
                placeholder="Enter DOB Here"
                :min="currentDate - 120 + '-01-01'"
                :max="currentDate + '-' + currentMonth + '-01'"
              />
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.dob"
                >{{ errors.dob }}</span
              >

              <label for="state">State *</label>
              <input
                type="text"
                class="state"
                v-model.trim="form.state"
                placeholder="Enter State Here"
              />
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.state"
                >{{ errors.state }}</span
              >

              <label for="city">City *</label>
              <input
                type="text"
                class="city"
                v-model.trim="form.city"
                placeholder="Enter City Here"
              />
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.city"
                >{{ errors.city }}</span
              >

              <label for="street">Street Address *</label>
              <input
                type="text"
                class="street"
                v-model.trim="form.street_address"
                placeholder="Enter Street Here"
              />
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.street_address"
                >{{ errors.street_address }}</span
              >
              <span style="color: red" v-if="error_message.length > 0"
                >{{ error_message }}<br
              /></span>
              <span style="color: green" v-if="success_message.length > 0"
                >Profile Updated Successfully.<br
              /></span>
              <input
                type="submit"
                value="Save changes"
                class="gg-create-btn"
                @click="updateUserProfile"
              />
            </div>
          </form>
          <input type="hidden" v-model="data_refresh_value" />
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
//import ModalProfileEdit from "@/components/ModalProfileEdit.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  computed: {
    ...mapState([
      "profile_obj",
      "order_obj",
      "delivery_obj",
      "direction_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
    calculateMyPurchase() {
      let table_data = this.order_obj;
      let total = [];

      table_data = table_data.filter((row) => {
        return row.author.id == this.profile_obj.id;
      });

      table_data.forEach((item) => {
        let sum_price =
          parseFloat(item.order_price) +
          parseFloat(item.tax_vat_price) +
          parseFloat(item.delivery_charge);
        total.push(sum_price);
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
    calculateFoodMiles() {
      let table_data = this.delivery_obj;
      let total = [];

      table_data = table_data.filter((row) => {
        return row.status != "hold";
      });

      table_data.forEach((item) => {
        let sum_price =
          parseFloat(item.start_lat) + parseFloat(item.start_long);
        total.push(sum_price);
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
  },

  created() {
    this.listProfile();
    this.listOrder();
    this.listMyDeliveries();
    let directionObj = {
      start_lat: "27.717728723291803",
      start_lng: "85.32784938812257",
      end_lat: "27.73449858986537",
      end_lng: "85.33714056015016",
    };
    this.getDirectionAPI(directionObj);
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  },

  data: () => ({
    isLoading: false,
    menuVisible: false,
    openDialog: false,
    files: [],
    file: "",
    image_url: null,
    currentDate: new Date().getFullYear(),
    currentMonth: new Date().getMonth(),
    userImage: "http://via.placeholder.com/450?text=User%20Image",
    form: {
      first_name: "",
      middle_name: "",
      last_name: "",
      company_name: "",
      vat_pan_number: "",
      dob: "",
      state: "",
      city: "",
      street_address: "",
      // user_description: "",
    },
    userImgFile: {
      file: "",
      fileSize: "",
      fileType: "",
      fileMaxSize: 1048576,
      fileSupportedType: ["image/png", "image/jpeg"],
    },
    vatImgFile: {
      file: "",
      fileSize: "",
      fileType: "",
      fileMaxSize: 1048576,
      fileSupportedType: ["image/png", "image/jpeg", "application/pdf"],
    },
    data_refresh_value: "",
    currentLocation: JSON.parse(localStorage.getItem("currentLocation")),
    errors: [],
  }),
  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions([
      "listProfile",
      "listOrder",
      "listMyDeliveries",
      "getDirectionAPI",
    ]),
    updateProfileModal(product_id) {
      this.form.first_name = this.profile_obj.first_name;
      this.form.middle_name = this.profile_obj.middle_name;
      this.form.last_name = this.profile_obj.last_name;

      this.form.company_name = this.profile_obj.user_info
        ? this.profile_obj.user_info.company_name
        : "";
      this.form.vat_pan_number = this.profile_obj.user_info
        ? this.profile_obj.user_info.vat_pan_number
        : "";
      this.form.dob = this.profile_obj.user_info
        ? this.profile_obj.user_info.dob
        : "";
      this.form.state = this.profile_obj.user_info
        ? this.profile_obj.user_info.state
        : "";
      this.form.city = this.profile_obj.user_info
        ? this.profile_obj.user_info.city
        : "";
      this.form.street_address = this.profile_obj.user_info
        ? this.profile_obj.user_info.street_address
        : "";
      this.form.user_description = this.profile_obj.user_info
        ? this.profile_obj.user_info.user_description
        : "";
      this.openDialog = !this.openDialog;
    },
    closeUpdateProfileModal() {
      this.openDialog = false;
    },
    onUserImageChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.createUserImage(files[0]);
      this.userImgFile.file = this.$refs.userFile.files[0];
      this.userImgFile.fileSize = this.userImgFile.file.size;
      this.userImgFile.fileType = this.userImgFile.file.type;

      this.onProfileImageChange();
    },
    filechange(e) {
      const file = e.target.files[0];
      this.image = URL.createObjectURL(file);
    },
    createUserImage(userFile) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.userImage = e.target.result;
      };
      reader.readAsDataURL(userFile);
    },
    // For Image Upload
    selectFile() {
      // this.$refs.file.click()
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
    onChange(image) {
      // console.log('New picture selected!')
      // if (image) {
      //   console.log('Picture loaded.')
      //   this.image = image;
      // } else {
      //   console.log('FileReader API not supported: use the <form>, Luke!')
      // }
    },
    onProfileImageChange() {
      let formData = new FormData();
      formData.append("first_name", this.profile_obj.first_name);
      formData.append("middle_name", this.profile_obj.middle_name);
      formData.append("last_name", this.profile_obj.last_name);
      formData.append("image", this.userImgFile.file);
      formData.append("group", this.profile_obj.group.id);
      var formatted_date = new Date().toJSON().slice(0, 10);
      formData.append(
        "dob",
        !this.profile_obj.user_info || this.profile_obj.user_info.dob == ""
          ? formatted_date
          : this.profile_obj.user_info.dob
      );
      formData.append("nationality", 1);
      formData.append(
        "company_name",
        !this.profile_obj.user_info ||
          this.profile_obj.user_info.company_name == ""
          ? ""
          : this.profile_obj.user_info.company_name
      );
      formData.append(
        "vat_pan_number",
        !this.profile_obj.user_info ||
          this.profile_obj.user_info.vat_pan_number == ""
          ? ""
          : this.profile_obj.user_info.vat_pan_number
      );
      formData.append("vat_pan_photo", "");
      formData.append("country", 1);
      formData.append(
        "state",
        !this.profile_obj.user_info || this.profile_obj.user_info.state == ""
          ? ""
          : this.profile_obj.user_info.state
      );
      formData.append(
        "city",
        !this.profile_obj.user_info || this.profile_obj.user_info.city == ""
          ? ""
          : this.profile_obj.user_info.city
      );
      formData.append(
        "street_address",
        !this.profile_obj.user_info ||
          this.profile_obj.user_info.street_address == ""
          ? ""
          : this.profile_obj.user_info.street_address
      );
      formData.append(
        "user_description",
        !this.profile_obj.user_info ||
          this.profile_obj.user_info.user_description == ""
          ? ""
          : this.profile_obj.user_info.user_description
      );
      let latValue = this.profile_obj.user_info
        ? this.profile_obj.user_info.latitude
        : this.currentLocation
        ? this.currentLocation.latitude
        : "27.700000762939453";
      let longtValue = this.profile_obj.user_info
        ? this.profile_obj.user_info.longitude
        : this.currentLocation
        ? this.currentLocation.longitude
        : "85.33329772949219";
      formData.append("latitude", latValue);
      formData.append("longitude", longtValue);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      this.$store.dispatch("updateProfile", formData).then(() => {
        if (this.success_message == "success") {
          setTimeout(function () {
            location.reload();
          }, 500);
        } else if (this.error_message == "errormessage") {
          console.log("Error: ", JSON.stringify(this.response_message));
        }
      });
    },
    updateUserProfile(e) {
      e.preventDefault();
      this.errors = [];
      if (!this.form.first_name || this.form.first_name == "") {
        this.errors["first_name"] = "First Name required.";
      } else if (!this.form.last_name || this.form.last_name == "") {
        this.errors["last_name"] = "Last Name required.";
      } else if (
        this.form.vat_pan_number &&
        this.form.vat_pan_number.length >= 10
      ) {
        this.errors["vat_pan_number"] = "VAT/PAN Max 9 digits.";
      } else if (!this.form.dob || this.form.dob == "") {
        this.errors["dob"] = "Date of birth required.";
      } else if (!this.form.state || this.form.state == "") {
        this.errors["state"] = "State required.";
      } else if (!this.form.city || this.form.city == "") {
        this.errors["city"] = "City required.";
      } else if (!this.form.street_address || this.form.street_address == "") {
        this.errors["street_address"] = "Street Address required.";
      } else {
        this.errors = [];
        let formData = new FormData();
        formData.append("first_name", this.form.first_name);
        formData.append(
          "middle_name",
          this.form.middle_name == "null" ? "" : this.form.middle_name
        );
        formData.append("last_name", this.form.last_name);
        formData.append("image", "");
        formData.append("group", this.profile_obj.group.id);
        formData.append("dob", this.form.dob);
        formData.append("nationality", 1);
        formData.append("company_name", this.form.company_name);
        formData.append("vat_pan_number", this.form.vat_pan_number);
        formData.append("vat_pan_photo", "");
        formData.append("country", 1);
        formData.append("state", this.form.state);
        formData.append("city", this.form.city);
        formData.append("street_address", this.form.street_address);
        // formData.append("user_description", this.form.User_description);
        let latValue = this.profile_obj.user_info
          ? this.profile_obj.user_info.latitude
          : this.currentLocation
          ? this.currentLocation.latitude
          : "27.700000762939453";
        let longtValue = this.profile_obj.user_info
          ? this.profile_obj.user_info.longitude
          : this.currentLocation
          ? this.currentLocation.longitude
          : "85.33329772949219";
        formData.append("latitude", latValue);
        formData.append("longitude", longtValue);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        this.$store.dispatch("updateProfile", formData).then(() => {
          if (this.success_message == "success") {
            setTimeout(function () {
              location.reload();
            }, 1500);
          } else if (this.error_message == "errormessage") {
            //
          }
        });
      }
    },
  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
    console.log("FN", this.profile_obj.first_name);
    // this.form.first_name = this.profile_obj.first_name
    // this.form.middle_name = this.profile_obj.middle_name
    // this.form.last_name = this.profile_obj.last_name

    // this.form.company_name = this.profile_obj.user_info ? this.profile_obj.user_info.company_name : ""
    // this.form.vat_pan_number = this.profile_obj.user_info ? this.profile_obj.user_info.vat_pan_number : ""
    // this.form.dob = this.profile_obj.user_info ? this.profile_obj.user_info.dob : ""
    // this.form.state = this.profile_obj.user_info ? this.profile_obj.user_info.state : ""
    // this.form.city = this.profile_obj.user_info ? this.profile_obj.user_info.city : ""
    // this.form.street_address = this.profile_obj.user_info ? this.profile_obj.user_info.street_address : ""
  },
};
</script>

<style type="text/css" scoped="">
.profile-image-preview {
  border-radius: 50%;
}
</style>