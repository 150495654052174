<template>
  <div class="deliveries">
    <div class="contents">
      <md-toolbar class="order-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-shopping-bag"></i>
        </md-icon>
        <h1>Purchase</h1>
      </md-toolbar>
      <md-field class="order-nav1 ">
        <span class="content">Purchase History</span>
        <h4 class="label-opt">3 orders placed in</h4>
        <!-- <div class="md-layout-item">
          <md-field id="selector-1">
            <md-select v-model="day" name="day" id="day">
              <md-option value="today">Today</md-option>
              <md-option value="tomorrow">Tomorrow</md-option>
              <md-option value="yesterday">Yesterday</md-option>
            </md-select>
          </md-field>
        </div> -->
        <div class="md-layout-item">
            <md-field class="select-options">
              <md-select v-model="day" name="day">
                <!-- <md-option value="all" class="table-filter-option">All Day</md-option> -->
                <md-option class="table-filter-option" value="today">Today</md-option>
                <md-option class="table-filter-option" value="tomorrow">Tomorrow</md-option>
                <md-option class="table-filter-option" value="yesterday">Yesterday</md-option>
              </md-select>
            </md-field>
          </div>
      </md-field>
      <PaginationPurchase />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DeliveriesCard from "@/components/DeliveriesCard.vue";
import PaginationPurchase from "@/components/PaginationPurchase.vue";
// import TaskAssign from "@/components/TaskAssign.vue";

export default {
  name: "Deliveries",
  components: {
    DeliveriesCard,
    PaginationPurchase,
    // TaskAssign,
  },
  data: () => ({
    day: "today",
    openDialog: false
  })
};
</script>
<style lang='scss' scoped>
</style>
