<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <div class="modal-alert">
        <div class="icon-div">
          <span class="material-icons remove-icon">highlight_off</span>
        </div>
        <md-dialog-title class="modal-title">Are you sure want to remove this item</md-dialog-title>
        <md-dialog-actions>
          <md-button class="cancel-btn" @click="showDialog = false">Cancel</md-button>
          <md-button class="remove-btn" @click="showDialog = false">Delete</md-button>
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    showDialog: true
  })
};
</script>

<style lang="scss">
</style>