<template>
  <div class="products">
    <div class="contents">
      <span v-if="errors.unit_add_error" style="color: red; font-weight: bold;">{{ errors.unit_add_error }}<br/></span>
      <h2>Add Unit</h2>
      <aside class="product-unit-form">
        <form class="create-post" @submit.prevent="addUnitForm()">
          <label for="uname">Add New Unit *</label>
          <input type="text" id="uname" v-model="unit_name" placeholder="Enter here" />
          <span
            class="form-error"
            v-if="errors.unit_name"
          >{{ errors.unit_name}}</span>
          <button class="post-btn">ADD</button>
          <span class="form-success" v-if="success">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{success}}
          </span>
        </form>
        <div class="order-detail-table-cost unit">
          <h3>Available Units</h3>
          <ul>
            <li v-for="(product_unit_obj,index) in product_unit.slice(count_start,count_end)" :key="index">
              <!-- <li> -->
              {{product_unit_obj.name}}
              <span>
                <button class="gg-create-btn" @click="openEditDialog(product_unit_obj.id)">Edit</button>
                <button class="gg-create-btn delete-button" @click="openDeleteDialog(product_unit_obj.id)">Delete</button>
              </span>
              <!-- </li> -->
            </li>
          </ul>
          <div v-if="product_unit && product_unit.length">
            <ul class="nav-pagination">
              <li><router-link :to="{name: current_route, query:{page: current_page <= 1 ? 1 : current_page-1}}">&laquo;</router-link></li>

              <li v-for="(page, index) in Math.ceil(product_unit.length/page_sequence)" :class="current_page == page ? 'active' : ''" :key="index">
                <router-link :to="{name: current_route, query:{page: page}}">{{ page }}</router-link>
              </li>
              
              <li><router-link :to="{name: current_route, query:{page: current_page >= Math.ceil(product_unit.length/page_sequence) ? Math.ceil(product_unit.length/page_sequence) : current_page+1}}">&raquo;</router-link></li>
            </ul>
          </div>
        </div>
      </aside>
    </div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <h2 class="form-title">Edit Unit</h2>
        <form class="main-form" @submit.prevent="editUnit">
          <label for="name">Unit</label>
          <input type="text" class="product" v-model="edit_unit" placeholder />
          <span class="form-error" v-if="errors.emptyUnit">{{errors.emptyUnit}}</span>
          <button type="submit" class="gg-create-btn">Save</button>
          <span class="form-success" v-if="editSuccess">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{editSuccess}}
          </span>
        </form>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="showDialogDelete" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialogDelete = false">highlight_off</span>
        </md-dialog-actions>
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons error-icon">close</span>
          </div>
          <md-dialog-title class="modal-title">Are you sure to delete this unit?</md-dialog-title>
          <div class="buttons">
            <button type="submit" class="gg-create-btn delete-button" @click="deleteUnitNow">Delete</button>
            <button type="submit" class="gg-create-btn" @click="showDialogDelete=false">Cancel</button>
          </div>
        </div>
        <!-- <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons error-icon">close</span>
          </div>
          <md-dialog-title class="modal-title">Product Deleted</md-dialog-title>
        </div>-->
      </div>
    </md-dialog>
    <input type="hidden" v-model="state_update_text">
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductUnitForm from "@/components/ProductUnitForm.vue";

export default {
  components: {
    ProductUnitForm,
  },
  computed: {
    ...mapState([
      "product_unit",
      "success_message",
      "error_message",
      "response_message",
    ]),
  },
  created() {
    this.listUnits();
    // Pagination
    this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
    this.count_start = (this.current_page-1)*this.page_sequence
    this.count_end = this.current_page*this.page_sequence
  },
  watch: {
    '$route.query.page'(){
      this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
      this.count_start = (this.current_page-1)*this.page_sequence
      this.count_end = this.current_page*this.page_sequence
    }
  },
  data() {
    return {
      unit_name: "",
      edit_unit: "",
      single_unit: [],
      errors: [],
      inputDisabled: true,
      showDialog: false,
      showDialogDelete: false,
      success: "",
      editSuccess: "",
      state_update_text: "",
      deleted: false,
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
    }
  },
  methods: {
    ...mapActions(["listUnits"]),

    addUnitForm() {
      this.errors = [];
      if (this.unit_name == "") {
        this.errors["unit_name"] = "Unit is required.";
      } else {
        let formData = new FormData();
        formData.append("name", this.unit_name);
        
        // for (var pair of formData.entries()) {
        //  console.log(pair[0]+ ', ' + pair[1]); 
        // }

        this.$store.dispatch("createUnit", formData).then(() => {
          if (this.success_message == "success") {
            this.unit_name = "";
            this.success = "Unit created successfully";
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            this.state_update_text = "Unit Name already exists."
            this.errors['unit_add_error'] = "Unit Name already exists."
          }
        });
      }
    },
    openEditDialog(unit_id){
      this.single_unit = this.product_unit.find(
        (row) => row.id == unit_id
      );
      this.edit_unit = this.single_unit.name
      this.showDialog = true
    },
    editUnit() {
      this.errors = [];
      this.editSuccess = "";
      if (this.edit_unit == "") {
        this.errors["emptyUnit"] = "Unit is required.";
      } else {
        var formatted_date = new Date().toISOString().split('T')[0];
        this.$store.dispatch("updateUnit", {
          'name': this.edit_unit,
          'status': true,
          'updated_at': formatted_date,
          'id': this.single_unit.id,
        })
        .then(() => {
          if (this.success_message == "success") {
            this.editSuccess = "Unit changed successfully";
            setTimeout(function() {
              location.reload();
            }, 500);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
    openDeleteDialog(unit_id){
        this.single_unit = this.product_unit.find(
          (row) => row.id == unit_id
        );
        this.showDialogDelete = true
    },
    deleteUnitNow() {
      if (this.single_unit && this.single_unit.id > 0) {
        this.showDialogDelete = false
        this.$store.dispatch("deleteUnit", this.single_unit.id)
        .then(() => {
          if (this.success_message == "success") {
            this.single_unit = []
            alert("Unit Deleted Successfully.");
            location.reload();
          } else if (this.error_message == "errormessage") {
            this.single_unit = []
            alert(this.response_message);
          }
        });
      }
    }
  },
};
</script>

<style scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination{list-style: none; float: left; width: 100%; padding: 10px;}
.nav-pagination li{float: left; margin: 0 5px;}
.nav-pagination li.active{}
.nav-pagination li a{padding: 3px 8px; background: #e6e6e6; border: 1px #3b844a solid; border-radius: 6px; color: #3b844a; text-decoration: none; font-size: 14px; transition-duration: 1s;}
.nav-pagination li a:hover{ background: #3b844a; color: #ffffff; transition-duration: 1s;}
.nav-pagination li.active a{background: #3b844a; color: #ffffff;}
</style>
