const axios = require("axios");
// Old Server IP:- 18.139.218.40:1001
// Demo Server IP:- 18.138.188.241:1001

export default {
  forgetPassword({ state, commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = null;
    return axios
      .post("users/forgetpassword", user_obj)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  resetPassword({ state, commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = null;
    return axios
      .post("users/resetpassword", user_obj)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listOrder({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("orders", {}).then(response => {
      commit("setOrder", response.data);
    });
  },

  listSingleOrder({ commit }, order_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("orders/" + order_id).then(response => {
      commit("setOrder", response.data);
    });
  },

  updateOrder({ state, commit }, order_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("orders/" + order_obj.id, order_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.order_obj.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateOrderProduct({ commit }, order_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("updateorderproduct/" + order_obj.order_id + "/" + order_obj.product_id, order_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  modifyDeliveryAddress({ state, commit }, form_data) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("deliveryaddress/" + form_data.id, form_data)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateUserIinfo({ state, commit }, form_data) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("userinfo/" + form_data.id, form_data)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateUserDetail({ state, commit }, form_data) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("adminupdateuser/" + form_data.id, form_data)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response);
        }
      });
  },

  changeOrderStatus({ state, commit }, order_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("orderstatusadmin/" + order_obj.id, order_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  cancelOrderByUser({ state, commit }, cancel_order_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("ordercancel", cancel_order_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  makeUserToStaff({ state, commit }, form_data) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("makestaff/" + form_data.id, form_data)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response);
        }
      });
  },

  deleteOrder({ commit }, order_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("orders/" + order_id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  dashBoardCount({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("dashboardcount", {}).then(response => {
      commit("setDashBoardCount", response.data);
    });
  },

  deliveryDashCount({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("countdelivery", {}).then(response => {
      commit("setDashBoardCount", response.data);
    });
  },

  outletDashCount({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("countoutlet", {}).then(response => {
      commit("setDashBoardCount", response.data);
    });
  },

  sourceDashCount({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("countoutlet", {}).then(response => {
      commit("setDashBoardCount", response.data);
    });
  },

  customerDashCount({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("countcustomer", {}).then(response => {
      commit("setDashBoardCount", response.data);
    });
  },

  listProfile({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("users/profile", {}).then(response => {
      commit("setProfile", response.data);
    });
  },

  updateProfile({ state, commit }, profile_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("users/update", profile_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data);
        }
      });
  },

  deleteUser({ commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("deleteuser/" + user_obj.id, user_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  activateUser({ commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("activateuser/" + user_obj.id, user_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  changeProfilePassword({ state, commit }, password_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("users/changepassword", password_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  doughnutVisualization({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("doughnutvisualization", {}).then(response => {
      commit("setdoughnutVisualization", response.data);
    });
  },

  listVisualization({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("linevisualization", {}).then(response => {
      commit("setlistVisualization", response.data);
    });
  },

  listUnits({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("units", {}).then(response => {
      commit("setUnitList", response.data);
    });
  },

  listMultipleUnits({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("productunit", {}).then(response => {
      commit("setMultipleUnits", response.data);
    });
  },

  listBrands({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("brands", {}).then(response => {
      commit("setBrandList", response.data);
    });
  },

  listSites({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("site", {}).then(response => {
      commit("setSiteList", response.data);
    });
  },

  listCrops({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("croptype", {}).then(response => {
      commit("setCropList", response.data);
    });
  },

  listCountries({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("country", {}).then(response => {
      commit("setCountryList", response.data);
    });
  },

  listInventries({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("inventry", {}).then(response => {
      commit("setInventryList", response.data);
    });
  },

  createInventory({ state, commit }, product_inventry) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("inventry", product_inventry)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  createUnit({ state, commit }, unit_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("units", unit_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateUnit({ state, commit }, unit_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("units/" + unit_obj.id, unit_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.product_unit.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteUnit({ commit }, unit_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("units/" + unit_id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  createProductUnit({ state, commit }, unit_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("productunit", unit_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteProductUnit({ commit }, productUnit_Id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("productunit/" + productUnit_Id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  createBrand({ state, commit }, brand_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("brands", brand_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  createSite({ state, commit }, site_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("site", site_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  createCrop({ state, commit }, crop_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("croptype", crop_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateBrand({ state, commit }, brand_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("brands/" + brand_obj.id, brand_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.product_brand.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateSite({ state, commit }, site_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("site/" + site_obj.id, site_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.product_site.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateCrop({ state, commit }, crop_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("croptype/" + crop_obj.id, crop_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.product_crop.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteBrand({ commit }, brand_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("brands/" + brand_id)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  deleteSite({ commit }, site_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("site/" + site_id)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  deleteCrop({ commit }, crop_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("croptype/" + crop_id)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  listCategories({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("categories", {}).then(response => {
      commit("setProductCategories", response.data);
    });
  },

  createCategory({ state, commit }, category_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("categories", category_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
        commit("setProductCategories", response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateCategory({ state, commit }, category_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("categories/" + category_obj.id, category_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.product_category.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteCategory({ commit }, category_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("categories/" + category_id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  listProducts({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("products", {}).then(response => {
      commit("setProductList", response.data);
    });
  },

  listProductsNoUser({ commit }) {
    axios.defaults.headers.common["authorization"] = null;
    return axios.get("products", {}).then(response => {
      commit("setNewProductList", response.data);
    });
  },

  getTaskDetail({ commit }, taskId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("tasks/" + taskId, {}).then(response => {
      commit("setTask", response.data);
    });
  },

  createProduct({ state, commit }, product_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("products", product_obj, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateProduct({ state, commit }, product_obj_data) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("products/" + product_obj_data.get("id"), product_obj_data)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.product_obj.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteProduct({ commit }, product_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("products/" + product_id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  listBanner({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("banner", {}).then(response => {
      commit("setBannerList", response.data);
    });
  },

  createBanner({ state, commit }, banner_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("banner", banner_obj, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteBanner({ state, commit }, banner_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("banner/" + banner_id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  updateBanner({ state, commit }, banner_obj_data) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("banner/" + banner_obj_data.get("id"), banner_obj_data)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.banner_obj.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listPartner({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("partner", {}).then(response => {
      commit("setPartnerList", response.data);
    });
  },

  createPartner({ state, commit }, partner_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("partner", partner_obj, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deletePartner({ state, commit }, partner_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("partner/" + partner_id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  updatePartner({ state, commit }, partner_obj_data) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("partner/" + partner_obj_data.get("id"), partner_obj_data)
      .then(response => {
        commit("setSuccessMessage", "success");
        state.partner_obj.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listUserRole({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("groups", {}).then(response => {
      commit("setUserRoleList", response.data);
    });
  },

  listUser({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("users").then(response => {
      commit("setUser", response.data);
    });
  },

  listStaffUser({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("staffuserlist").then(response => {
      commit("setUser", response.data);
    });
  },

  createUser({ state, commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("users", user_obj)

      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateUser({ state, commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("users" + user_obj.id, user_obj)

      .then(response => {
        commit("setSuccessMessage", "success");
        state.user_obj.push(response.data);
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listProductRequests({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("productrequests", {}).then(response => {
      commit("setProductRequests", response.data);
    });
  },

  createProductRequest({ state, commit }, productrequest_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("productrequests", productrequest_obj, {
        headers: { "Content-Type": "multipart/form-data" }
      })

      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateProductRequest({ state, commit }, request_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("productrequests/" + request_obj.id, request_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateProductRequestStatus({ state, commit }, request_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("productrequeststatus/" + request_obj.id, request_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeProductRequest({ commit }, requestId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("productrequests/" + requestId)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  assigneTask({ state, commit }, crm_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("crms", crm_obj, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listTask({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("tasks", {}).then(response => {
      commit("setTask", response.data);
    });
  },

  listSingleTask({ commit }, taskId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("tasks/" + taskId, {}).then(response => {
      commit("setTask", response.data);
    });
  },

  createNewTask({ state, commit }, task_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("tasks", task_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateNewTask({ state, commit }, task_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("tasks/" + task_obj.id, task_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteNewTask({ commit }, task_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("tasks/" + task_id)
      .then(response => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  updateTaskStatus({ state, commit }, task_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("taskstatus/" + task_obj.id, task_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  getDirectionAPI({ commit }, latLangObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://api.baato.io/api/v1/directions?key=bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu&points[]=" +
          latLangObj.start_lat +
          "," +
          latLangObj.start_lng +
          "&points[]=" +
          latLangObj.end_lat +
          "," +
          latLangObj.end_lng +
          "&mode=bike",
        {}
      )
      .then(response => {
        commit("setDirectionResponse", response.data);
      });
  },

  listDeliveries({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("delivery", {}).then(response => {
      commit("setDeliveries", response.data);
    });
  },

  listMyDeliveries({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("mydelivery", {}).then(response => {
      commit("setDeliveries", response.data);
    });
  },

  createNewDelivry({ state, commit }, delivery_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("delivery", delivery_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateNewDelivery({ state, commit }, delivery_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("delivery/" + delivery_obj.id, delivery_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateDeliveryStatus({ state, commit }, delivery_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("deliverystatus/" + delivery_obj.id, delivery_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listNotification({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("notifications", {}).then(response => {
      commit("setNotification", response.data);
    });
  },

  createNewNotification({ state, commit }, notification_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("notifications", notification_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listComments({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("comment", {}).then(response => {
      commit("setComment", response.data);
    });
  },

  createComment({ state, commit }, comment_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("comment", comment_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateComment({ state, commit }, comment_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("comment/" + comment_obj.id, comment_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeComment({ commit }, commentId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("comment/" + commentId)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  searchProducts({ commit }, search_query) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("product/?search=" + search_query, {}).then(response => {
      commit("setProductList", response.data);
    });
  },

  getProductBatch({ commit }, search_query) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("batch", {}).then(response => {
      commit("setProductBatch", response.data);
    });
  },

  postProductBatch({ state, commit }, batch_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("batch", batch_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateProductBatch({ state, commit }, batch_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("batchupdate/" + batch_obj.get("batch_id"), batch_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  dispatchProductBatch({ state, commit }, batch_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("dispatchbatch/" + batch_obj.get("batch_id"), batch_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  receiveProductBatch({ state, commit }, batch_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put("receivebatch/" + batch_obj.get("batch_id"), batch_obj)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeBatchRequest({ commit }, batchId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("batchupdate/" + batchId)
      .then(response => {
        commit("setSuccessMessage", "success");
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  getSourceBatch({ commit }, search_query) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("sourcebatch", {}).then(response => {
      commit("setProductBatch", response.data);
    });
  }
};
