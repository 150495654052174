<template>
	<div class="inventry">
	<div class="contents">
		<md-toolbar class="nav page-title">
			<md-icon class="navbar-icon1">
			<i class="fas fa-layer-group"></i>
			</md-icon>
			<h1 class="content">Search Products</h1>
		</md-toolbar>
		<p>{{ product_obj.length }} products found in '<strong><em>{{ this.query }}</em></strong>'</p>

		<div class="nav section-title">
		<div class="card">

			<!--  Product Item -->
			<div class="product-items" v-if="product_obj && product_obj.length" v-for="(product, index) in product_obj" :key="index">
				<div class="product-card">
					<div class="product-image" id="product-details">
						<img :src="product.image" />
					</div>
					<div class="description-card">
						<a class="product-description" href="#">
							<h1>{{ product.name }}</h1>
						</a>
						<div class="product-quantity">
							<p>
							<span><strong>Price:</strong> Rs. {{product.price_out}}/-</span><br/>
							<span><strong>SKU: </strong>{{product.sku}}</span>
							</p>
							<span>Total Quantity: {{product.total_quantity}} {{product.unit.name}}</span>
							<span>Product Status: {{product.product_status}}</span>
						</div>
					</div>
				</div>
			</div>

		</div>
		</div>

	</div>
	</div>
</template>

<script type="text/javascript">
import { mapState, mapActions } from "vuex";
export default {
	name: 'searchProduct',
	data() {
		return {
			query: '',
		}
	},
	created(){
		this.query = this.$route.query.query
		this.searchProducts(this.query);
	},
	computed: {
    	...mapState(["product_obj", "success_message", "error_message", "response_message"]),
	},
	methods: {
    	...mapActions(["searchProducts"]),
    }
}
</script>