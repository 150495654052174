export default {
  setToken(state, token) {
    state.token = token;
  },

  setSuccessMessage(state, success_message) {
    state.success_message = success_message;
  },

  setErrorMessage(state, error_message) {
    state.error_message = error_message;
  },

  setMessage(state, response_message) {
    state.response_message = response_message;
  },

  setProfile(state, profile_obj) {
    state.profile_obj = profile_obj;
  },

  setOrder(state, order_obj) {
    state.order_obj = order_obj;
  },

  setDashBoardCount(state, dashboard_count) {
    state.dashboard_count = dashboard_count;
  },

  setdoughnutVisualization(state, doughnut_visualization) {
    state.doughnut_visualization = doughnut_visualization;
  },

  setlistVisualization(state, list_visualization) {
    state.list_visualization = list_visualization;
  },

  setDeliveryBoardCount(state, delivery_dash_count) {
    state.delivery_dash_count = delivery_dash_count;
  },

  setOutletBoardCount(state, outlet_dash_count) {
    state.outlet_dash_count = outlet_dash_count;
  },

  setUserBoardCount(state, user_dash_count) {
    state.user_dash_count = user_dash_count;
  },

  setProductCategories(state, product_category) {
    state.product_category = product_category;
  },

  setProductList(state, product_obj) {
    state.product_obj = product_obj;
  },

  setBannerList(state, banner_obj) {
    state.banner_obj = banner_obj;
  },

  setPartnerList(state, partner_obj) {
    state.partner_obj = partner_obj;
  },

  setNewProductList(state, product_new_obj) {
    state.product_new_obj = product_new_obj;
  },

  setUnitList(state, product_unit) {
    state.product_unit = product_unit;
  },

  setBrandList(state, product_brand) {
    state.product_brand = product_brand;
  },

  setSiteList(state, product_site) {
    state.product_site = product_site;
  },

  setCropList(state, product_crop) {
    state.product_crop = product_crop;
  },

  setCountryList(state, country_obj) {
    state.country_obj = country_obj;
  },

  setInventryList(state, product_inventry) {
    state.product_inventry = product_inventry;
  },

  setUserRoleList(state, user_role_obj) {
    state.user_role_obj = user_role_obj;
  },

  setUser(state, all_user_obj) {
    state.all_user_obj = all_user_obj;
  },

  setProductRequests(state, product_request) {
    state.product_request = product_request;
  },

  setTask(state, task_obj) {
    state.task_obj = task_obj;
  },

  setDeliveries(state, delivery_obj) {
    state.delivery_obj = delivery_obj;
  },

  setNotification(state, notification_obj) {
    state.notification_obj = notification_obj;
  },

  setComment(state, comment_obj) {
    state.comment_obj = comment_obj;
  },

  setDirectionResponse(state, direction_obj) {
    state.direction_obj = direction_obj;
  },

  setMultipleUnits(state, multiple_unit_obj) {
    state.multiple_unit_obj = multiple_unit_obj;
  },

  setProductBatch(state, batch_obj) {
    state.batch_obj = batch_obj;
  }
};
