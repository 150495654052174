<template>
  <div class="gg-table member-table" v-if="product_category.length>0">
    <md-table v-model="product_category.slice(count_start,count_end)" md-sort="name" md-sort-order="asc">
      <md-table-row slot="md-table-row" slot-scope="{ item , index}">
        <md-table-cell md-label="SN" md-numeric>{{ (index+1)+((current_page-1)*10) }}.</md-table-cell>
        <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Items Added" md-sort-by="total">{{ item.product_category.length }}</md-table-cell>
        <!-- <md-table-cell md-label='CategoryId' md-sort-by='category'>{{ item.category }}</md-table-cell> -->
        <md-table-cell md-label="Status" md-sort-by="status">{{ item.category_status }}</md-table-cell>
        <!-- <md-table-cell md-label='Items Added' md-sort-by='add'>{{ item.add }}</md-table-cell> -->
        <md-table-cell md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item @click="singleCategory(item.id)">Edit</md-menu-item>
                <md-menu-item @click="removesingleCategory(item.id)">Remove</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <!-- <DropdownBtn /> -->
        </md-table-cell>
      </md-table-row>
    </md-table>
    
    <div v-if="product_category && product_category.length">
      <ul class="nav-pagination">
        <li><router-link :to="{name: current_route, query:{page: current_page <= 1 ? 1 : current_page-1}}">&laquo;</router-link></li>

        <li v-for="(page, index) in Math.ceil(product_category.length/page_sequence)" :class="current_page == page ? 'active' : ''" :key="index">
          <router-link :to="{name: current_route, query:{page: page}}">{{ page }}</router-link>
        </li>
        
        <li><router-link :to="{name: current_route, query:{page: current_page >= Math.ceil(product_category.length/page_sequence) ? Math.ceil(product_category.length/page_sequence) : current_page+1}}">&raquo;</router-link></li>
      </ul>
    </div>
    <div>
      <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
        <div class="modal-form">
          <!-- <product-edit-form /> -->
          <md-dialog-actions class="close-icon">
            <span class="material-icons error-icon" @click="showDialogEdit = false">highlight_off</span>
          </md-dialog-actions>
          <div class="main-form">
            <h2 class="form-title">Edit Category</h2>
            <span style="color:red" v-if="error_message.length>0">Category Value Required..<br/></span>
            <span style="color:green" v-if="success_message.length>0">Category Updated Successfully.<br/></span>
            <label for="product">Category Name *</label>
            <input
              type="text"
              class="product"
              name="product"
              v-model.trim="form.catName"
              placeholder="Category Name"
              required
            />
            <label for="product">नाम नेपालीमा *</label>
            <input
              type="text"
              class="product"
              name="product"
              v-model.trim="form.catNapaliName"
              placeholder="नाम नेपालीमा"
              required
            />
            <label for="availability">Status*</label>
            <select class="availability" v-model.trim="form.catStatus">
              <option value="Available">Available</option>
              <option value="Not Available">Not Available</option>
            </select>

            <input
              type="submit"
              value="Save Changes"
              class="gg-create-btn"
              @click="categoryUpdated"
            />
          </div>
        </div>
      </md-dialog>
    </div>
    <div>
      <md-dialog :md-active.sync="showDialogRemove">
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons remove-icon">highlight_off</span>
          </div>
          <md-dialog-title class="modal-title">Are you sure want to remove this item</md-dialog-title>
          <md-dialog-actions>
            <md-button class="cancel-btn" @click="showDialogRemove = false">Cancel</md-button>
            <md-button class="remove-btn" @click="deleteCategory">Delete</md-button>
          </md-dialog-actions>
        </div>
      </md-dialog>
    </div>
    <!-- <ModalProductEdit v-if="openDialog1" />
    <ModalRemove v-if="openDialogRemove1" />-->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import ModalProductEdit from "./ModalProductEdit.vue";
// import ModalRemove from "./ModalRemove.vue";

export default {
  components: {
    // ModalRemove,
    // ModalProductEdit
  },
  computed: {
    ...mapState([
      "product_category",
      "success_message",
      "error_message",
      "response_message",
    ]),
  },
  watch: {
    '$route.query.page'(){
      this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
      this.count_start = (this.current_page-1)*this.page_sequence
      this.count_end = this.current_page*this.page_sequence
    }
  },
  created() {
    this.listCategories();
    // Pagination
    this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
    this.count_start = (this.current_page-1)*this.page_sequence
    this.count_end = this.current_page*this.page_sequence
  },
  data(){
    return{
      showDialog: false,
      showDialogEdit: false,
      showDialogRemove: false,
      form: {
        catName: '',
        catNapaliName: '',
        catStatus: '',
      },
      single_category: [],
      remove_single_category: [],
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
    }
  },
  methods: {
    ...mapActions(["listCategories"]),

    singleCategory(category_id) {
      this.showDialogEdit = !this.showDialogEdit;
      this.single_category = this.product_category.find(
        (evt) => evt.id == category_id
      );
      this.form.catName = this.single_category.name
      this.form.catNapaliName = this.single_category.nepali_name
      this.form.catStatus = this.single_category.category_status
    },

    removesingleCategory(category_id) {
      this.showDialogRemove = !this.showDialogRemove;
      this.remove_single_category = this.product_category.find(
        (evt) => evt.id == category_id
      );
    },

    categoryUpdated(e) {
      e.preventDefault();
      this.$store
        .dispatch("updateCategory", {
          id: this.single_category.id,
          name: this.form.catName,
          nepali_name: this.form.catNapaliName,
          category_status: this.form.catStatus,
        })
        .then(() => {
          if (this.success_message == "success") {
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
    },

    deleteCategory() {
      this.showDialogRemove = false;
      this.$store
        .dispatch("deleteCategory", this.remove_single_category.id)
        .then(() => {
          if (this.success_message == "success") {
            location.reload();
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
            // this.showDismissibleAlert1 = true
            // this.$router.replace({name:"banks"});
          }
        });
    },
  }
};
</script>
<style scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination{list-style: none; float: left; width: 100%; padding: 10px;}
.nav-pagination li{float: left; margin: 0 5px;}
.nav-pagination li a{padding: 3px 8px; background: #e6e6e6; border: 1px #3b844a solid; border-radius: 6px; color: #3b844a; text-decoration: none; font-size: 14px; transition-duration: 1s;}
.nav-pagination li a:hover{ background: #3b844a; color: #ffffff; transition-duration: 1s;}
.nav-pagination li.active a{background: #3b844a; color: #ffffff;}
</style>
<style lang='scss'>
</style>
