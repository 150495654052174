<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <div class="main-form">
          <form method="POST" class="main-form" @submit.prevent="sendNotificationForm">
            <h2 class="form-title">Send Notification</h2>

            <label for="role">Role *</label>
            <select name="" id="" v-model="user_type">
              <option value="customer">Customer</option>
              <option value="delivery">Delivery</option>
              <option value="outlet">Outlets</option>
              <option value="source">Source</option>
            </select>
            <span class="form-error" v-if="errors.user_type" >{{ errors.user_type}}</span>
            
            <label for="fname">Send Notification To *</label>
            <select name="" id="" v-model="notification_to">
              <option v-for="(member_obj, index) in new_user_list" :key="index" :value="member_obj.id">{{ member_obj.full_name }}</option>
            </select>
            <span class="form-error" v-if="errors.notification_to" >{{ errors.notification_to}}</span>

            <label for="fname">Notification Title *</label>
            <input type="text" v-model.trim="title" placeholder="Notification Ttile" />
            <span class="form-error" v-if="errors.title" >{{ errors.title}}</span>

            <label for="fname">Notification Message *</label>
            <textarea name="notification" id="notificationMessage" v-model.trim="description" cols="30" rows="10" placeholder="Notification Message"></textarea>
            <span class="form-error" v-if="errors.description" >{{ errors.description}}</span>

            <span class="errors form-error" style="color: red;" v-if="errors.submit_error">{{ errors.submit_error }}</span>
            <span class="success form-success" style="color: green;" v-if="success">Notification Submitted Successfuly.</span>

            <input type="submit" value="Send" class="gg-create-btn" />
          </form>
        </div>
      </div>
    </md-dialog>
    <input type="hidden" v-model="state_change_value">
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: 'ModalSendNotification',
  components: {
  },
  data(){
    return{
      showDialog: true,
      user_type: null,
      notification_to: null,
      title: "",
      description: "",
      new_user_list: [],
      errors: [],
      success: false,
      state_change_value: '',
    }
  },
  computed: {
    ...mapState([
      "all_user_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
    filterMemberData(){
      let table_data = this.all_user_obj;

      return table_data;
    }
  },
  created() {
    this.listUser();
  },
   methods: {
    ...mapActions(["listUser"]),
    sendNotificationForm(event){
      this.errors = []
      if (!this.user_type) {
        this.errors['user_type'] = "User type required."
      }else if(!this.notification_to){
        this.errors['notification_to'] = "Notification to required."
      }else if(this.title == ""){
        this.errors['title'] = "Notification title required."
      }else if(this.description == ""){
        this.errors['description'] = "Notification message required."
      }else{
        
        let formData = new FormData();
        formData.append("notification_to", this.notification_to);
        formData.append("title", this.title);
        formData.append("description", this.description);

        for (var pair of formData.entries()) {
         console.log(pair[0]+ ', ' + pair[1]); 
        }

        this.$store.dispatch("createNewNotification", formData).then(() => {
          if (this.success_message == "success") {
            this.state_change_value = Date.now()
            this.success = true
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            this.state_change_value = Date.now()
            this.errors['submit_error'] = this.response_message;
          }
        });

      }
    },
  },
  watch: {
    user_type: function (val) {
      if (this.user_type && this.user_type == 'customer') {
        this.new_user_list = this.all_user_obj.filter(
          (evt) => evt.group.id == 6 || evt.group.id == 7
        );
      }
      else if (this.user_type && this.user_type == 'delivery') {
        this.new_user_list = this.all_user_obj.filter(
          (evt) => evt.group.id == 3
        );
      }
      else if (this.user_type && this.user_type == 'source') {
        this.new_user_list = this.all_user_obj.filter(
          (evt) => evt.group.id == 4
        );
      }
      else if (this.user_type && this.user_type == 'outlet') {
        this.new_user_list = this.all_user_obj.filter(
          (evt) => evt.group.id == 5
        );
      }
      else{
        this.new_user_list = []
      }

    },
  },
};
</script>

<style lang="scss">
</style>