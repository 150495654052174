<template>
  <div class="orderdetail">
    
    <div class="contents">
      <md-toolbar class="order-nav">
        <div class="content-title-navigation">
          <md-icon class="navbar-icon1">local_mall</md-icon>
          <span class="content1">Tasks</span>
          <md-icon class="navbar-icon2">navigate_next</md-icon>
          <span class="content2">Details</span>
        </div>
        
      </md-toolbar>
      
    <!-- contet here -->
    <div class="order-detail-vue">
        
    <!-- task table-->
    <table class="order-detail-table" v-if="task_obj && task_obj.id">
        <tr>
            <td class="td1" colspan="2">Task Detail:</td>
        </tr>
        <tr>
            <td class="td1">Assigned By</td>
            <td class="td2">
                <span>{{ task_obj.assigned_by.full_name }} , {{ task_obj.assigned_by.phone_number }}, {{ task_obj.assigned_by.email }}</span>
            </td>
        </tr>
        <tr>
            <td class="td1">Assigned To</td>
            <td class="td2">
                <span>{{ task_obj.assigned_to.full_name }} , {{ task_obj.assigned_to.phone_number }}, {{ task_obj.assigned_to.email }}</span>
            </td>
        </tr>
        <tr>
            <td class="td1">Task  Type</td>
            <td class="td2">
                <span>{{ task_obj.task_type }} </span>
            </td>
        </tr>
        <tr>
            <td class="td1">Task Message</td>
            <td class="td2">
                <span>{{ task_obj.message }} </span>
            </td>
        </tr>
        <tr>
            <td class="td1">Reply Message</td>
            <td class="td2">
                <span>{{ task_obj.reply_message }} </span>
            </td>
        </tr>
        <tr>
            <td class="td1">Due Date</td>
            <td class="td2">
                <span>{{ task_obj.due_date }} </span>
            </td>
        </tr>
        <tr>
            <td class="td1">Added Date</td>
            <td class="td2">
                <span>{{ task_obj.created_at }} </span>
            </td>
        </tr>
        <tr>
            <td class="td1">Task Status</td>
            <td class="td2">
                <span>{{ task_obj.status }} </span>
            </td>
        </tr>
    </table>

    <!-- order detail -->
    <table class="order-detail-table-cost">
        <tr>
            <td class="td1" colspan="2">Order Detail:</td>
        </tr>
        <tr>
            <td class="td1">Order ID</td>
            <td class="td2">
                <span>{{ task_obj.order.id }}</span>
            </td>
        </tr>
        <tr>
            <td class="td1">Delivery To</td>
            <td class="td2">
                <span>{{ task_obj.order.delivery_address.first_name }} {{ task_obj.order.delivery_address.middle_name }} {{ task_obj.order.delivery_address.last_name }}, {{ task_obj.order.delivery_address.phone }}, {{ task_obj.order.delivery_address.email }}</span>
            </td>
        </tr>
        <tr>
            <td class="td1">Delivery Address</td>
            <td class="td2">
                <span>{{ task_obj.order.delivery_address.street_address }}, {{ task_obj.order.delivery_address.city }} {{ task_obj.order.delivery_address.state }}</span>
            </td>
        </tr>
        <tr>
            <td class="td1" colspan="2">Product Items:</td>
        </tr>
        <tr>
        <td colspan="2">
          <table width="100%" border="1" cellspacing="0"  cellpadding="5">
            <thead>
              <tr>
                <td>Image</td>
                <td>Product</td>
                <td>Quantity</td>
                <td>Rate</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody v-if="task_obj.order.order_product && task_obj.order.order_product.length > 0">
              <tr v-for="(product_item, index) in task_obj.order.order_product">
                <td><a :href="product_item.product.image" target="_blank">
                    <img :src="product_item.product.image" width="" height="" alt="Green Growth" style="width: 50px;" />
                  </a>
                </td>
                <td>
                  <span>{{ product_item.product.name }}</span><Br/>
                </td>
                <td>
                  <span>{{ product_item.quantity }} {{ product_item.unit.name }}</span>
                  <span v-if="profile_obj.admin==true || profile_obj.group.name=='Staff User'"><br/><a href="#" @click="openChangeQtyModel(product_item)">Change Quantity</a></span>
                </td>
                <td>Rs. {{ product_item.price }}</td>
                <td>Rs. {{ product_item.quantity*product_item.price }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr><td colspan="4"><p style="text-align: center;">Sorry! No product found.</p></td></tr>
            </tbody>
          </table>
        </td>
      </tr>
    </table>

    </div>
      
    </div>
    <input type="hidden" v-model="state_change_text">
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState, mapActions } from "vuex";
import axios from 'axios'
export default {
  name: "OrderDetail",
  components: {
      //
  },
  data(){
      return{
          task_id: this.$route.params.id,
          task_obj: [],
      }
  },
  computed: {
    ...mapState(["profile_obj", "product_new_obj", "success_message", "error_message", "response_message"])
  },
  created() {
    this.listProfile();
    this.listProductsNoUser();
  },
  methods: {
      ...mapActions(["listProfile", "listProductsNoUser"]),
  },
  mounted() {

    // this.$store.dispatch("getTaskDetail", this.task_id)
    // .then(() => {
    //   if (this.success_message == "success") {
    //     this.state_change_text = "Task Listed"
    //   }
    // });
    axios.defaults.headers.common['authorization'] = 'JWT ' + localStorage.getItem('token')
    axios.get('tasks/'+this.task_id, {})
    .then(response => {
        if(response.status == 200){
            this.task_obj = response.data
        }else{
            this.$router.replace({
                name: 'deliveries'
            })
        }
    }).catch(error => {
        console.log(error)
    })

  },
};
</script>
<style lang="scss" scoped>
</style>
