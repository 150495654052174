<template>
  <div class='analytics'>
    <ChartLine />
    <ChartDoughnut />
  </div>
</template>

<script>
// @ is an alias to /src
import ChartLine from '@/components/ChartLine.vue';
import ChartDoughnut from '@/components/ChartDoughnut.vue';

export default {
  name: 'Analytics',
  components: {
    ChartLine,
    ChartDoughnut,
  },
};
</script>

<style lang='scss' scoped>
.analytics {
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 400px;
  grid-gap: 3rem;
}
</style>
