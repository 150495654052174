<template>
  <div class='card'>
    <div class='card-body'>
      <div class='btn-group btn-group-toggle'>
        <label
          v-for='(item, index) in btn'
          :key='index'
          :class='{ active: item.value == radio }'
          class='btn btn-success'
        >
          <input v-model='radio' :name='dataLabel' :value='item.value' type='radio' />
          {{ item.label }}
        </label>
      </div>
    </div>

    <div class='card-img-bottom'>
      <chartjs-line
        :backgroundcolor='bgColor'
        :beginzero='beginZero'
        :bind='true'
        :bordercolor='borderColor'
        :data='data[radio]'
        :datalabel='dataLabel'
        :labels='labels[radio]'
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["list_visualization"])
  },

  watch: {
    list_visualization: function(){
      if(this.list_visualization){
        this.data = this.list_visualization;
      }
    },
  },

  created() {
    this.listVisualization();
  },

  data() {
    return {
      bgColor: 'blue',
      beginZero: true,
      borderColor: 'blue',
      btn: [
        { label: 'This week', value: 'week' },
        { label: 'Year', value: 'year' },
      ],
      data:{},
      // data: {
      //   week: [5, 10, 14, 15, 6, 13, 7],
      //   month: [13, 10, 15, 18, 23, 13, 25],
      // },
      dataLabel: 'Order Product',
      labels: {
        week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        year: ['Jan', 'Feb', 'March', 'April', 'May', 'Jun', 'July'],
      },
      radio: 'week',
    };
  },
  methods: {
    ...mapActions(["listVisualization"]),
  }
};
</script>
<style lang="scss" scoped>
  .card {
    padding: 20px;
    height: max-content;
  }
</style>
