<template>
  <div class="products">
    <div class="contents">
      <md-toolbar class="product-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-carrot"></i>
        </md-icon>
        <h1>Product Batch Request</h1>
      </md-toolbar>
      <div class="add-btn">
        <button
          class="gg-create-btn product-request-btn"
          @click="openProductRequest = !openProductRequest"
        >
          + Batch Request
        </button>
      </div>
      <!-- <div class="add-btn">
        <button class="gg-create-btn product-request-btn" @click="openImageRequest = !openImageRequest" >+ Add Partner Image</button>
      </div> -->
      <!-- <h2>Add Unit</h2>
      <ProductUnitForm /> -->
      <!-- <md-toolbar class="product-nav section-title table-title-button"> -->
      <h2>Table of Product Batch</h2>
      <!-- <div class="table-edit-buttons">
          <button data-v-3cec2962 class="gg-create-btn table-edit" @click="disabled=!disabled">Edit</button>
          <button data-v-3cec2962 class="gg-create-btn table-edit">Save Changes</button>
      </div>-->
      <!-- </md-toolbar> -->
      <ProductBatchTable />
    </div>
    <ModalProductRequest v-if="openProductRequest" />
  </div>
</template>

<script>
// @ is an alias to /src
import ProductBatchTable from "@/components/ProductBatchTable.vue";
import ModalProductRequest from "@/components/ModalProductRequest.vue";
// import PartnerImageRequest from "@/components/PartnerImageRequest.vue";

export default {
  name: "ProductBatch",
  components: {
    ProductBatchTable,
    ModalProductRequest
    // PartnerImageRequest
  },
  data() {
    return {
      openProductRequest: false
      // openImageRequest: false,
    };
  }
};
</script>
<style lang="scss" scoped>
.product-request-btn {
  margin: 0px 10px;
}
</style>
