<template>
  <div class="main-form">
    <h2 class="form-title">Add New Products</h2>
    <label for="product">Product Name in English*</label>
    <input
      type="text"
      class="product"
      v-model="product_name"
      placeholder="Product Name in English"
    />
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.product_name"
      >{{ errors.product_name }}</span
    >

    <label for="product">नेपालीमा प्रोडक्टको  नाम *</label>
    <input
      type="text"
      class="product"
      v-model="nepali_name"
      placeholder="नेपालीमा प्रोडक्टको  नाम"
    />
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.nepali_name"
      >{{ errors.nepali_name }}</span
    >

    <label for="category">Category *</label>
    <select class="category" v-model="category_obj">
      <option value disabled>Select Category</option>
      <option
        v-for="(category_obj, index) in filterCategories"
        :key="index"
        :value="category_obj.id"
      >
        {{ category_obj.name }}
      </option>
    </select>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.category_obj"
      >{{ errors.category_obj }}</span
    >

    <label for="product">SKU (Stock Keeping Unit)</label>
    <input
      type="text"
      class="product"
      v-model.trim="sku"
      placeholder="Stock Keeping Unit"
      @focusout="validateSKU"
    />
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.sku"
      >{{ errors.sku }}</span
    >
    <span v-if="validsku == 'no'" class="text-danger float-right" style="color: red">SKU is invalid.</span>
    <span v-if="validsku == 'yes'" class="text-success float-right" style="color: green">SKU is Valid.</span>

    <label for="quantity">Unit *</label>
    <select v-model="unit_obj">
      <option value disabled>Select Unit</option>
      <option
        v-for="(all_unit_obj, index) in product_unit"
        :key="index"
        :value="all_unit_obj.id"
      >
        {{ all_unit_obj.name }}
      </option>
    </select>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.unit_obj"
      >{{ errors.unit_obj }}</span
    >

    <label for="price">Product Unit Price *</label>
    <input type="number" v-model="price_out" placeholder="200" />
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.price_out"
      >{{ errors.price_out }}</span
    >
    <label>
      <div>Image</div>
      <div class="upload-file-area">
        <input
          type="file"
          ref="file"
          @change="onChangeFileUpload()"
          accept="image/jpeg, image/png"
        />
        <div class="drag-text">
          <div>
            <button class="choose-button">Choose File</button>
          </div>
          <div class="or">OR</div>
          <div>Drag and drop your file here</div>
        </div>
      </div>
    </label>
    <div class="thumbnail">
      <span class="clear-image" v-if="file !== ''" @click="clearImage">
        <md-icon>cancel</md-icon>
      </span>
      <img :src="image_url" />
    </div>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.file"
      >{{ errors.file }}</span
    >

    <label for="brands">Product Brand *</label>
    <select class="brands" v-model="brand">
      <option
        v-for="(brand, index) in product_brand"
        :key="index"
        :value="brand.id"
      >
        {{ brand.name }}
      </option>
    </select>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.brand"
      >{{ errors.brand }}</span
    >

    <label for="price">Product Description in English</label>
    <textarea
      v-model="description"
      placeholder="Product Description in English"
      style="width: 100%; height: 100px"
    ></textarea>

    <label for="nepali_description">नेपालीमा प्रोडक्टको विवरण</label>
    <textarea
      v-model="nepali_description"
      id="nepali_description"
      placeholder="नेपालीमा प्रोडक्टको विवरण"
      style="width: 100%; height: 100px"
    ></textarea>

    <label for="exclusives">
      <input
        style="width: 18px;height: 18px;float: left;"
        type="checkbox"
        v-model="is_exclusive"
        id="exclusives"
      /> &nbsp; <span style="margin-top: 4px;float: left;margin-left: 10px;">Product is Exclusive?</span>
      </label>

    <label for="availability">Availability *</label>
    <select class="availability" v-model="availability_obj">
      <option value disabled>Select Availability</option>
      <option value="Available">In stock</option>
      <option value="Not Available">Out of stock</option>
    </select>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.availability_obj"
      >{{ errors.availability_obj }}</span
    >

    <span style="color: red" v-if="error_message.length > 0"
      >Product Name Already Exist.<br
    /></span>
    <span style="color: green" v-if="success_message.length > 0"
      >Product Added Successfully.<br
    /></span>

    <input
      type="submit"
      @click="addProduct"
      value="Add Product"
      class="gg-create-btn"
      v-if="!isLoading"
    />
    <img v-else src="../assets/img/loader.gif" width="64" height="64" alt="Greengrowth Logo" /> 

  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapMutations, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {
    Loading
  },
  computed: {
    ...mapState([
      "product_category",
      "product_unit",
      "product_brand",
      "success_message",
      "error_message",
      "response_message",
    ]),
    filterCategories() {
      let allCategories = this.product_category;

      allCategories = allCategories.filter((row) => {
        return row.category_status == "Available";
      });

      return allCategories;
    },
  },

  created() {
    this.listCategories();
    this.listUnits();
    this.listBrands();
  },

  data() {
    return {
      isLoading: false,
      category_obj: "",
      unit_obj: "",
      availability_obj: "",
      product_name: "",
      nepali_name: "",
      sku: "",
      quantity: 1,
      price_in: "",
      price_out: "",
      image: "",
      errors: [],
      validsku: '',
      files: [],
      file: "",
      brand: null,
      description: "",
      nepali_description: "",
      return_policy: "",
      image_url: null,
      is_exclusive: false,
    };
  },

  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions(["listCategories", "listUnits", "listBrands"]),
    validateSKU(){
      
      if(this.sku.length > 0){
        this.errors["sku"] = "";
          this.isLoading = true;
          axios.defaults.headers.common['authorization'] = 'JWT ' + localStorage.getItem("token")
          axios.post('skuvalidate', { sku: this.sku })
          .then((response) => {
            this.isLoading = false;
            if(response.status == 200){
              this.validsku = "yes"
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.validsku = "no"
          })
      }else{
        this.validsku = "no"
      }
    },
    addProduct() {
      this.errors = [];
      if (this.product_name == "") {
        this.errors["product_name"] = "Product Name required.";
      } else if (this.nepali_name == "") {
        this.errors["nepali_name"] = "Product Nepali name required.";
      } else if (this.category_obj == "") {
        this.errors["category_obj"] = "Category required.";
      } else if (this.sku == "") {
        this.errors["sku"] = "SKU required.";
      } else if (this.validsku == "no") {
        this.errors["sku"] = "";
        this.validsku = "no"
      } else if (this.unit_obj == "") {
        this.errors["unit_obj"] = "Unit required.";
      } else if (this.price_out == "" || this.price_out <= 0) {
        this.errors["price_out"] = "Price Out required.";
      } else if (this.file == "") {
        this.errors["file"] = "Image required.";
      } else if (this.brand == ""  || this.brand == null) {
        this.errors["brand"] = "Brand required.";
      } else if (this.availability_obj == "") {
        this.errors["availability_obj"] = "Availability required.";
      } else {
        if(this.validsku == "yes"){
          this.isLoading = true
        
          let formData = new FormData();
          formData.append("name", this.product_name);
          formData.append("nepali_name", this.nepali_name);
          formData.append("category", this.category_obj);
          formData.append("unit", this.unit_obj);
          formData.append("quantity", this.quantity);
          formData.append("price_in", 0);
          formData.append("price_out", this.price_out);
          formData.append("image", this.file);
          formData.append("sku", this.sku);
          formData.append("description", this.description);
          formData.append("nepali_description", this.nepali_description);
          formData.append("return_policy", "n/a");
          formData.append("brand", this.brand);
          formData.append("exclusives", this.is_exclusive ? 'active' : 'inactive');
          formData.append("product_status", this.availability_obj);

          // for (var pair of formData.entries()) {
          //   console.log(pair[0]+ ', ' + pair[1]);
          // }

          this.$store.dispatch("createProduct", formData).then(() => {
            if (this.success_message == "success") {
              this.image = "";
              this.product_name = "";
              this.category_obj = "";
              this.unit_obj = "";
              this.sku = "";
              this.price_in = "";
              this.price_out = "";
              this.brand = 1;
              this.description = "";
              this.availability_obj = "";
              
              setTimeout(function () {
                setTimeout(() => {
                  location.reload();
                }, 100);
                this.isLoading = false
              }, 1000);
            } else if (this.error_message == "errormessage") {
              // console.log(this.response_message)
              // this.setErrorMessage("");
              // this.setMessage("");
            }
          });
        }else{
          // error
          this.validsku = "no"
        }
        
      }
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
  },
};
</script>

<style lang="scss" >
.main-form {
  .upload-file-area {
    height: 120px;
    border: 1px dashed #4ba069;
    text-align: center;
    position: relative;
    .choose-button {
      background: #4ba069;
      padding: 10px 40px;
      color: #fff;
      border: none;
      margin-bottom: 20px;
    }
    .or {
      color: grey;
    }
    .drag-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;
    }
    input[type="file"] {
      border: none;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
</style>
