<template>
  <div class="home">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">home</md-icon>
        <h1>Banner</h1>
      </md-toolbar>
      <div class="add-btn">
        <button
          class="gg-create-btn"
          @click="openDialogBanner = !openDialogBanner"
        >
          + Add Banner Image
        </button>
      </div>
      <div class="banner-section">
        <h1 class="content">Banner List</h1>
        <!-- <BannerTable /> -->
        <div>
          <div v-if="profile_obj.admin || profile_obj.staff">
            <div class="nav section-title">
              <div class="row">
                <div v-for="(banner, index) in banner_obj" :key="index">
                  <div class="product-card">
                    <div class="product-image">
                      <img :src="banner.image" />
                    </div>
                    <div class="description-card">
                      <h2>{{ banner.title }}</h2>
                      <a :href="banner.link">
                        link:
                        {{ banner.link }}
                      </a>
                      <h4>
                        Status: {{ banner.active ? "Active" : "Not Active" }}
                      </h4>
                      <div class="button">
                        <!-- <input
                          type="submit"
                          value="Add Banner Image"
                          class="gg-create-btn"
                          @click="addBanner(banner.id)"
                        /> -->
                        <input
                          type="submit"
                          value="Delete the Banner Image"
                          class="gg-create-btn"
                          @click="deleteSingleBanner(banner.id)"
                        />
                        <input
                          type="submit"
                          value="Edit the Banner Image"
                          class="gg-create-btn"
                          @click="editBanner(banner.id)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div else></div>
        <!-- <img src="" alt="" /> -->
      </div>
    </div>
    <ModalBanner v-if="openDialogBanner" />
    <div>
      <md-dialog :md-active.sync="showDialogRemove">
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons remove-icon">highlight_off</span>
          </div>
          <md-dialog-title class="modal-title"
            >Are you sure want to remove this item ?
          </md-dialog-title>
          <md-dialog-actions>
            <md-button class="cancel-btn" @click="showDialogRemove = false"
              >Cancel</md-button
            >
            <md-button class="remove-btn" @click="deleteBannerForm"
              >Delete</md-button
            >
          </md-dialog-actions>
        </div>
      </md-dialog>
    </div>
    <div>
      <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span
              class="material-icons error-icon"
              @click="showDialogEdit = false"
              >highlight_off</span
            >
          </md-dialog-actions>
          <form
            class="main-form"
            @submit.prevent="updateBanner(single_banner.id)"
          >
            <h2 class="form-title">Edit Products</h2>
            <label for="product">Banner Name</label>
            <input
              type="text"
              class="product"
              v-model="single_banner.title"
              placeholder="Enter Banner Name"
            />
            <span
              class="text-danger float-right"
              style="color: red"
              v-if="errors.banner_title"
              >{{ errors.banner_title }}</span
            >
            <label>
              <div>Choose Banner Image</div>
              <div class="upload-file-area">
                <input
                  type="file"
                  ref="file"
                  @change="onChangeFileUpload()"
                  accept="image/jpeg, image/png"
                />
                <div class="drag-text">
                  <div>
                    <button class="choose-button">Choose File</button>
                  </div>
                  <div class="or">OR</div>
                  <div>Drag and drop your file here</div>
                </div>
              </div>
            </label>
            <div class="thumbnail">
              <span
                class="clear-image"
                v-if="image_url !== ''"
                @click="clearImage"
              >
                <md-icon>cancel</md-icon>
              </span>
              <img :src="image_url" />
            </div>
            <span
              class="text-danger float-right"
              style="color: red"
              v-if="errors.file"
              >{{ errors.file }}</span
            >

            <label for="product">Banner Link</label>
            <input
              type="text"
              class="product"
              v-model="single_banner.link"
              placeholder="Enter Banner URL link"
            />
            <span
              class="text-danger float-right"
              style="color: red"
              v-if="errors.banner_link"
              >{{ errors.banner_link }}</span
            >
            <label for="active">
              <input
                style="width: 18px; height: 18px; float: left"
                type="checkbox"
                v-model="single_banner.active"
                id="active"
              />
              &nbsp;
              <span style="margin-top: 4px; float: left; margin-left: 10px"
                >Active ?</span
              >
            </label>
            <span style="color: red" v-if="error_message.length > 0"
              >Invalid Submission.<br
            /></span>
            <span style="color: green" v-if="success_message.length > 0"
              >Banner Added Successfully.<br
            /></span>

            <input
              type="submit"
              @click="updateBanner"
              value="Save"
              class="gg-create-btn"
              v-if="!isLoading"
            />
            <img
              v-else
              src="../assets/img/loader.gif"
              width="64"
              height="64"
              alt="Greengrowth Logo"
            />
          </form>
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// @ is an alias to /src
import ModalBanner from "@/components/ModalBanner.vue";
// import BannerTable from "@/components/BannerTable.vue";

export default {
  name: "Banner",
  components: {
    ModalBanner,
    // BannerTable,
  },
  computed: {
    ...mapState([
      "profile_obj",
      "success_message",
      "error_message",
      "response_message",
      "banner_obj",
    ]),
  },

  created() {
    this.listProfile();
    this.listBanner();
  },
  data() {
    return {
      openDialogBanner: false,
      showDialogRemove: false,
      showDialogEdit: false,
      single_banner: [],
      image_url: null,
      image: "",
      errors: [],
      files: [],
      file: "",
      file_name: "",
      is_active: "",
      // single_is_active: false,
      isLoading: false,

      // is_active: "",
      // banner_single: [],
    };
  },
  methods: {
    ...mapActions(["listProfile", "listBanner"]),

    editBanner(banner_id) {
      this.single_banner = this.banner_obj.find((evt) => evt.id == banner_id);
      // console.log(banner_id);
      this.image_url = this.single_banner.image;
      this.is_active = this.single_banner.active == "active" ? true : false;
      this.showDialogEdit = !this.showDialogEdit;
    },

    updateBanner() {
      this.errors = [];
      if (this.single_banner.title == "") {
        this.errors["banner_title"] = "Banner Title is required";
      } else if (this.single_banner.link == "") {
        this.errors["banner_link"] = "Banner Link is required";
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("title", this.single_banner.title);
        formData.append("image", this.file ? this.file : "");
        formData.append("link", this.single_banner.link);
        formData.append(
          "active",
          this.single_banner.active == true ? true : false
        );
        formData.append("id", this.single_banner.id);
        console.log(this.single_banner.id);
        this.$store.dispatch("updateBanner", formData).then(() => {
          this.success = "Profile updated successfully.";
          this.isLoading = false;
          window.location.reload(100);
          if (this.error_message == "errormessage") {
            window.location.reload(100);
            alert(JSON.stringify(this.response_message));
          } else {
            return 0;
          }
        });
      }
    },

    deleteSingleBanner(banner_id) {
      this.showDialogRemove = !this.showDialogRemove;
      this.single_banner = this.banner_obj.find((evt) => evt.id == banner_id);
      // console.log( this.single_banner);
    },

    deleteBannerForm() {
      this.showDialogRemove = false;
      this.$store.dispatch("deleteBanner", this.single_banner.id).then(() => {
        // alert("Are you sure want to delete it ?")
        window.location.reload(100);
        if (this.success_message == "success") {
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.banner-section {
  .row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
  .description-card {
    margin-top: 10px;
    display: grid;
    grid-gap: 15px;
    h2 {
      margin: 0px;
      font-size: 20px;
    }
    h4 {
      margin: 0px;
      font-size: 20px;
    }
    a {
      cursor: pointer;
      color: green;
      font-size: 18px;
      text-decoration: none;
      &:hover {
        color: rgb(10, 68, 10);
      }
    }
    .button {
      display: flex;
      flex-wrap: wrap;
      input {
        margin: 5px 10px;
        width: 100%;
      }
    }
  }
}
</style>
