<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false"
            >highlight_off</span
          >
        </md-dialog-actions>
        <div>
          <form
            class="main-form"
            method="post"
            onsubmit="setTimeout(function(){window.location.reload();},10);"
          >
            <h2 class="form-title">Add New Banner</h2>
            <label for="product">Banner Name</label>
            <input
              type="text"
              class="product"
              v-model="banner_title"
              placeholder="Enter Banner Name"
            />
            <span
              class="text-danger float-right"
              style="color: red"
              v-if="errors.banner_title"
              >{{ errors.banner_title }}</span
            >
            <label>
              <div>Choose Banner Image</div>
              <div class="upload-file-area">
                <input
                  type="file"
                  ref="file"
                  @change="onChangeFileUpload()"
                  accept="image/jpeg, image/png"
                />
                <div class="drag-text">
                  <div>
                    <button class="choose-button">Choose File</button>
                  </div>
                  <div class="or">OR</div>
                  <div>Drag and drop your file here</div>
                </div>
              </div>
            </label>
            <div class="thumbnail">
              <span class="clear-image" v-if="file !== ''" @click="clearImage">
                <md-icon>cancel</md-icon>
              </span>
              <img :src="image_url" />
            </div>
            <span
              class="text-danger float-right"
              style="color: red"
              v-if="errors.file"
              >{{ errors.file }}</span
            >

            <label for="product">Banner Link</label>
            <input
              type="text"
              class="product"
              v-model="banner_link"
              placeholder="Enter Banner URL link"
            />
            <span
              class="text-danger float-right"
              style="color: red"
              v-if="errors.banner_link"
              >{{ errors.banner_link }}</span
            >
            <label for="active">
              <input
                style="width: 18px; height: 18px; float: left"
                type="checkbox"
                v-model="is_active"
                id="active"
              />
              &nbsp;
              <span style="margin-top: 4px; float: left; margin-left: 10px"
                >Active ?</span
              >
            </label>
            <span style="color: red" v-if="error_message.length > 0"
              >Invalid Submission.<br
            /></span>
            <span style="color: green" v-if="success_message.length > 0"
              >Banner Added Successfully.<br
            /></span>

            <input
              type="submit"
              @click="addBanner"
              value="Add Banner"
              class="gg-create-btn"
              v-if="!isLoading"
            />
            <img
              v-else
              src="../assets/img/loader.gif"
              width="64"
              height="64"
              alt="Greengrowth Logo"
            />
          </form>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
// import axios from 'axios'
import { mapState } from "vuex";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    // Loading,
  },
  // name: "DialogCustom",
  computed: {
    ...mapState(["success_message", "error_message", "response_message"]),
  },
  data: () => ({
    showDialog: true,
    isLoading: false,
    banner_title: "",
    banner_name: "",
    banner_link: "",
    files: [],
    file: "",
    errors: [],
    image_url: null,
    is_active: false,
    image: "",
  }),
  methods: {
    addBanner(e) {
      e.preventDefault();
      this.errors = [];
      if (this.banner_title == "") {
        this.errors["banner_title"] = "Banner Title is required.";
      } else if (this.file == "") {
        this.errors["file"] = "Image required.";
      } else if (this.banner_link == "") {
        this.errors["banner_link"] = "Link url required.";
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("title", this.banner_title);
        formData.append("image", this.file);
        formData.append("link", this.banner_link);
        formData.append("active", this.is_active ? "true" : "false");

        this.$store.dispatch("createBanner", formData).then(() => {
          // alert("Banner have been added successfully");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
          // if ((this, success_message == "success")) {
          //   this.banner_title = "";
          //   this.file = "";
          //   this.banner_link = "";
          //   this.banner_active = "";

          //   // setTimeout(function () {
          //   //   setTimeout(() => {
          //   //     windows.location.reload();
          //   //   }, 100);
          //   //   this.isLoading = false;
          //   // }, 1000);
          // } else {
          //   this.isLoading = true;
          // }
        });
      }
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
  },
};
</script>

<style lang="scss">
// .md-dialog /deep/.md-dialog-container {
//   max-width: 768px;
// }
</style>