<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form modal-get-route">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <h2 class="form-title"> Your Route </h2>

        <div class="route-image">
          <img src="../assets/img/route.jpg" alt=""/>
        </div>
        <div class="route-description">
          <h2>
            Route Description
          </h2>
          <p>
            To catchup the route to Green Growth. From Banepa, go straight to the west (towards Kathmandu). While you reach to Maitighar, head towards Green Growth.
          </p>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import Baato from "@klltech/baato-js-client";
// import TaskAssignForm from "./TaskAssignForm.vue";

export default {
  components: {
    // TaskAssignForm
  },

  // mounted: function() {
  //   var map = new mapboxgl.Map({
  //       container: "map",
  //       style:
  //         "https://api-staging.baato.io/api/v1/styles/breeze?key=bpk.whkzK2b4MgeI9s7l-Y9izy11aE6j5sQzd-GrkYzMpkKS", // Baato stylesheet location
  //       center: [85.31853583740946, 27.701739466949107], // starting position [lng, lat]
  //       zoom: 14, // starting zoom
  //     });
  //
  //     map.on('load', function() {
  //
  //       new Baato.Routing({
  //         key: "bpk.whkzK2b4MgeI9s7l-Y9izy11aE6j5sQzd-GrkYzMpkKS",
  //       })
  //       .addPoints([
  //         "27.695007971417382,85.30786383056528",
  //         "27.706710946667044,85.33378469848509",
  //       ]) // points for which routing is to be done
  //         // .addPoints([
  //         //   "27.695007971417382,85.30786383056528",
  //         //   "27.706710946667044,85.33378469848509",
  //         // ]) // points for which routing is to be done
  //         .setVehicle("foot") // means of transporation
  //         .getBest()
  //         .setBaseUrl(`https://api-staging.baato.io/api`) // Baato base URL
  //         .doRequest()
  //         .then((response) => {
  //           response.forEach((item) => {
  //             map.addSource("route", {
  //               type: "geojson",
  //               data: {
  //                 type: "Feature",
  //                 properties: {},
  //                 geometry: {
  //                   type: "LineString",
  //                   coordinates: item.geojson.coordinates,
  //                 },
  //               },
  //             });
  //             map.addLayer({
  //               id: "route",
  //               type: "line",
  //               source: "route",
  //               layout: {
  //                 "line-join": "round",
  //                 "line-cap": "round",
  //               },
  //               paint: {
  //                 "line-color": "#008148",
  //                 "line-width": 8,
  //               },
  //             });
  //           });
  //         });
  //     })
  //
  // },


  // name: "DialogCustom",
  data: () => ({
    showDialog: true
  })
};
</script>

<style lang="scss">
</style>
