<template>
  <form class="main-form" @submit.prevent="editOrder">
    <h2 class="form-title">Edit Order</h2>

    <label for="fname">Name</label>
    <input type="text" class="fname" placeholder="Ravi Ojha" disabled/>

    <label for="contact">contact</label>
    <input type="number" id="contact" name="contact" placeholder="980144444444" disabled/>

    <label for="productId">Product ID</label>
    <input type="text" class="productId" placeholder="06" disabled/>

    <label for="Product">Product</label>
    <input type="text" id="product" name="product" placeholder="Wheat flour" disabled/>

    <label for="quantity">Quantity</label>
    <input type="text" id="quantity" name="quantity" placeholder="Enter quantity here" />

    <label for="price">Price</label>
    <input type="text" id="price" name="price" placeholder="Enter price here" />

    <label for="location">Location</label>
    <input type="text" id="location" name="location" placeholder="Enter location here" disabled/>

    <label for="status">Status </label>
    <select class="status" >
      <option value="true">Delivered</option>
      <option value="false">Pending </option>
      <option value="false">Rejected </option>
    </select>

    <button type="submit" class="gg-create-btn">Save</button>
    <span class="form-success" v-if="success">
      <md-icon class="md-size-2x">check_circle_outline</md-icon>
      {{success}}
    </span>
  </form>
</template>

<script>
export default {
  data() {
    return {
      quantity: "",
      price: "",
      location: "",
      status: "",
      errors: [],
      success: "",
    }
  },
  methods: {
    editOrder() {
      this.errors = [];
      this.success = "";
      if (this.quantity == "") {
        this.errors["quantity"] = "Quantity is required and should be 1 or greater.";
      } else if (this.quantity < 1) {
        this.errors["quantity"] = "Quantity should be 1 or greater.";
      } else {
        this.success = "Form submitted successfully.";
      }
    }
  }
};
</script>

<style>
</style>