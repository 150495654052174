<template>
  <div class="notification">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-bell"></i>
        </md-icon>
        <h1>Notifications</h1>
      </md-toolbar>
      <div class="add-btn" v-if="profile_obj.admin==true">
        <button class="gg-create-btn" @click="openNotificationDialog = !openNotificationDialog"> Create Notification </button>
      </div>
      <!-- <input type="checkbox" @click="selectAll" v-model="allSelected"> -->
      <!-- <NotificationCard />
      <NotificationCard />
      <NotificationCard />
      <NotificationCard /> -->

      <div class="notification-card"   v-for="(all_notification_obj,index) in reversedNotificationList"
        :key="index">
        <md-card md-with-hover class="card-content">
          <md-ripple>
            <md-card-content class="card-para">
              {{all_notification_obj.description}}
            </md-card-content>
            <div class="notification-details">
              <span>
                {{all_notification_obj.created_at | formatDate}}
                <!-- 20:00 PM, July 3 -->
              </span>
            </div>
          </md-ripple>
        </md-card>
      </div>

    </div>
    <ModalSendNotification v-if="openNotificationDialog" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// @ is an alias to /src
import NotificationCard from "@/components/NotificationCard.vue";
import ModalSendNotification from "@/components/ModalSendNotification.vue";

export default {
  components: {
    NotificationCard,
    ModalSendNotification,
  },

  computed: {
    ...mapState(["notification_obj", "profile_obj"]),
    reversedNotificationList(){
      return this.notification_obj;
    },
  },

  created() {
    this.listNotification();
    this.listProfile();
  },

  data() {
    return {
      openNotificationDialog: false,
    }
  },

  methods: {
    ...mapActions(["listNotification", "listProfile"]),
}
};
</script>
<style scoped>
.md-ripple{
  text-transform: inherit;
}
</style>
