<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form">
      <md-dialog-actions class="close-icon">
         <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
      </md-dialog-actions>
        <DispatchProductForm />
      </div>
    </md-dialog>
  </div>
</template>

<script>
import DispatchProductForm from "./DispatchProductForm.vue";

export default {
  components: {
   DispatchProductForm
  },
  // name: "DialogCustom",
  data: () => ({
    showDialog: true,
  })
};
</script>

<style lang="scss">
// .md-dialog /deep/.md-dialog-container {
//   max-width: 768px;
// }
</style>