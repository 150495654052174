<template>
  <div class="dropdown-menu">
    <md-menu md-size="big" class="navbar-user">
      <md-button class="dropdown-icon" md-menu-trigger>
        <div class="avatar-circle">
          <img v-if="profile_obj.image == apiBaseUrl+'media/profile/default.jpg'" src="../assets/img/avatar.jpg" alt="avatar" class="fixed1" />
          <img v-else :src="profile_obj.image" alt="avatar" class="fixed1" />
        </div>
        <span class="nametitle">{{profile_obj.full_name}}</span>
        <i class="fas fa-caret-down"></i>
      </md-button>
      <md-menu-content class="gg-dropdown-menu">
        <md-menu-item>
          <router-link to="/profile-page">My Profile</router-link>
        </md-menu-item>
        <md-menu-item>
          <a :href="viewStoreUrl">View Store</a>
        </md-menu-item>
        <md-menu-item>
          <router-link to="/change-password">Change Password</router-link>
        </md-menu-item>
        <md-menu-item @click="Logout()">Logout</md-menu-item>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  computed: {
    ...mapState(["profile_obj"])
  },

  created() {
    this.listProfile();
  },
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      viewStoreUrl: process.env.VUE_APP_USER_PANEL,
      apiBaseUrl: process.env.VUE_APP_API_BASE_IP,
    };
  },
  methods: {
    ...mapActions(["listProfile"]),

    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    startArrowKeys() {
      if (this.isVisible) {
        this.$refs.dropdown.children[0].children[0].focus();
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex -= 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex += 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus();
    },
    Logout() {
      this.$router.push({ name: "logout" });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
