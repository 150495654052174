<template>
  <div class="outlets">
    <div class="contents">
      <md-toolbar class="outlet-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-carrot"></i>
        </md-icon>
        <h1>Outlets</h1>
      </md-toolbar>
      <h2>Requests</h2>
      
      <TableRequest />
      
      <h2>All Outlets</h2>
      
      <TableOutlet />
    </div>
    <ModalOutlets v-if="openDialog" />
  </div>
</template>

<script>
// @ is an alias to /src
import TableOutlet from "@/components/TableOutlet.vue";
import TableRequest from "@/components/TableRequest.vue";
import ModalOutlets from "@/components/ModalOutlets.vue";

export default {
  name: "Outlets",
  components: {
    TableOutlet,
    TableRequest,
    ModalOutlets
  },
  data: () => ({
    openDialog: false
  })
};
</script>
<style lang='scss' scoped>
</style>
