<template>
  <div class="gg-table product-tabtable">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>
    <div class="search-box">
      <md-field md-clearable class="searchs">
        <md-icon><i class="fas fa-search"></i></md-icon>
        <md-input placeholder="Search to edit the product" v-model="search" />
      </md-field>
    </div>
    <div class="table-top-nav">
      <md-tabs>
        <md-tab id="tab-home" md-label="All" @click="tabIndex = 0"></md-tab>
        <md-tab id="tab-pages" md-label="Active" @click="tabIndex = 1"></md-tab>
        <md-tab id="tab-posts" md-label="Inactive" @click="tabIndex = 2"></md-tab>
        <!-- <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" /> -->
      </md-tabs>

      <md-field class="table-category">
        <md-select
          v-if="product_category && product_category.length > 0"
          v-model="category"
          name="category"
          id="category"
          placeholder="All Category"
        >
          <md-option class="table-filter-option" selected>All Category</md-option>
          <md-option
            class="table-filter-option"
            v-for="(cats, index) in product_category"
            :key="index"
            :value="cats.id"
            >{{ cats.name }}</md-option
          >
        </md-select>
      </md-field>
    </div>
    <!-- {{filterTableData}} -->
    <div class="product-table">
      <md-table
        v-model="filterTableData.slice(count_start, count_end)"
        md-sort="name"
        md-sort-order="asc"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S.N" md-sort-by="sn"
            >{{ index + 1 + (current_page - 1) * 10 }}.</md-table-cell
          >
          <md-table-cell md-label="Name" md-sort-by="name" class="table-name">
            {{ item.name }}
          </md-table-cell>
          <md-table-cell md-label="Price" md-sort-by="price">{{ item.price_out }}</md-table-cell>
          <md-table-cell md-label="Qty" md-sort-by="qty"
            >{{ item.total_quantity }} {{ item.unit.name }}</md-table-cell
          >
          <md-table-cell md-label="Add/Edit Unit" md-sort-by="addEditUnit"
            ><button class="gg-create-btn add-edit-button" @click="openDialogEditUnit(item.id)">
              Add/Edit Unit
            </button></md-table-cell
          >
          <md-table-cell md-label="Category" md-sort-by="category">{{
            item.category.name
          }}</md-table-cell>
          <md-table-cell
            md-label="Availability"
            md-sort-by="availability"
            class="in-stock-indication"
            v-if="item.product_status == 'Available'"
            >In Stock</md-table-cell
          >
          <md-table-cell
            md-label="Availability"
            md-sort-by="availability"
            class="not-in-stock-indication"
            v-else
            >Not In Stock</md-table-cell
          >
          <md-table-cell md-label="Added on " md-sort-by="added">{{
            item.created_at
          }}</md-table-cell>
          <md-table-cell md-label="Status" md-sort-by="status">{{
            item.status ? "Publish" : "Unpublish"
          }}</md-table-cell>
          <md-table-cell md-label="Action" md-sort-by="action">
            <div class="gg-dropdown-btn">
              <md-menu md-size="big">
                <md-button class="dropdown-icon" md-menu-trigger>
                  <i class="fas fa-caret-down"></i>
                </md-button>
                <md-menu-content class="gg-dropdown-menu">
                  <md-menu-item @click="editProduct(item.id)">Edit</md-menu-item>
                  <md-menu-item @click="removesingleProduct(item.id)">Remove</md-menu-item>
                </md-menu-content>
              </md-menu>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="filterTableData && filterTableData.length">
        <ul class="nav-pagination">
          <li>
            <router-link
              :to="{
                name: current_route,
                query: { page: current_page <= 1 ? 1 : current_page - 1 }
              }"
              >&laquo;</router-link
            >
          </li>

          <li
            v-for="(page, index) in Math.ceil(filterTableData.length / page_sequence)"
            :class="current_page == page ? 'active' : ''"
            :key="index"
          >
            <router-link :to="{ name: current_route, query: { page: page } }">{{
              page
            }}</router-link>
          </li>

          <li>
            <router-link
              :to="{
                name: current_route,
                query: {
                  page:
                    current_page >= Math.ceil(filterTableData.length / page_sequence)
                      ? Math.ceil(filterTableData.length / page_sequence)
                      : current_page + 1
                }
              }"
              >&raquo;</router-link
            >
          </li>
        </ul>
      </div>
      <div>
        <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDialogEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="updateProduct(single_product.id)">
              <h2 class="form-title">Edit Products</h2>

              <label for="product">Product Name in English *</label>
              <input
                type="text"
                class="product"
                v-model="single_product.name"
                placeholder="Product Name in English"
                required
              />
              <span class="form-error" v-if="errors.product_name">{{ errors.product_name }}</span>

              <label for="product">नेपालीमा प्रोडक्टको नाम *</label>
              <input
                type="text"
                class="product"
                v-model="single_product.nepali_name"
                placeholder="नेपालीमा प्रोडक्टको  नाम"
                required
              />
              <span class="form-error" v-if="errors.nepali_name">{{ errors.nepali_name }}</span>

              <label for="category">Category</label>
              <select class="category" v-model="single_category">
                <option
                  v-for="(category_obj, index) in product_category"
                  :key="index"
                  :value="category_obj.id"
                >
                  {{ category_obj.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.category_obj">{{ errors.category_obj }}</span>

              <label for="product">SKU (Stock Keeping Unit)</label>
              <input
                type="text"
                class="product"
                v-model.trim="sku"
                placeholder="Stock Keeping Unit"
                @focusout="validateSKU"
              />
              <span class="text-danger float-right" style="color: red" v-if="errors.sku">{{
                errors.sku
              }}</span>
              <span v-if="validsku == 'no'" class="text-danger float-right" style="color: red"
                >SKU is invalid.</span
              >
              <span v-if="validsku == 'yes'" class="text-success float-right" style="color: green"
                >SKU is Valid.</span
              >

              <label for="category">Unit</label>
              <select class="category" v-model="single_unit">
                <option v-for="(unit_obj, index) in product_unit" :key="index" :value="unit_obj.id">
                  {{ unit_obj.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.unit_obj">{{ errors.unit_obj }}</span>

              <label for="price">Product Unit Price *</label>
              <input
                type="text"
                v-model="single_product.price_out"
                name="price"
                placeholder="200"
                required
              />
              <span class="form-error" v-if="errors.price_out">{{ errors.price_out }}</span>

              <label>
                <div>Upload Product's Image</div>
                <div class="upload-file-area">
                  <input
                    type="file"
                    ref="file"
                    @change="onChangeFileUpload()"
                    accept="image/jpeg, image/png"
                  />
                  <div class="drag-text">
                    <div>
                      <button class="choose-button">Choose File</button>
                    </div>
                    <div class="or">OR</div>
                    <div>Drag and drop your file here</div>
                  </div>
                </div>
              </label>
              <div class="thumbnail">
                <span class="clear-image" v-if="image_url !== ''" @click="clearImage">
                  <md-icon>cancel</md-icon>
                </span>
                <img :src="image_url" />
              </div>
              <span class="text-danger float-right" style="color: red" v-if="errors.file">{{
                errors.file
              }}</span>

              <label for="brands">Product Brand *</label>
              <select class="brands" v-model="single_brand">
                <option v-for="(brand, index) in product_brand" :key="index" :value="brand.id">
                  {{ brand.name }}
                </option>
              </select>
              <span class="text-danger float-right" style="color: red" v-if="errors.brand">{{
                errors.brand
              }}</span>

              <label for="price">Product Description in English</label>
              <textarea
                v-model="single_product.description"
                placeholder="Product Description in English"
                style="width: 100%; height: 100px"
              ></textarea>
              <span class="form-error" v-if="errors.description">{{ errors.description }}</span>

              <label for="price">नेपालीमा प्रोडक्टको विवरण</label>
              <textarea
                v-model="single_product.nepali_description"
                placeholder="नेपालीमा प्रोडक्टको विवरण"
                style="width: 100%; height: 100px"
              ></textarea>
              <span class="form-error" v-if="errors.nepali_description">{{
                errors.nepali_description
              }}</span>

              <label for="price">Product Return Policy</label>
              <textarea
                v-model="single_product.return_policy"
                placeholder="Product Return Policy"
                style="width: 100%; height: 75px"
              ></textarea>
              <span class="form-error" v-if="errors.return_policy">{{ errors.return_policy }}</span>

              <label for="exclusives">
                <input
                  style="width: 18px; height: 18px; float: left"
                  type="checkbox"
                  v-model="is_exclusive"
                  id="exclusives"
                />
                &nbsp;
                <span style="margin-top: 4px; float: left; margin-left: 10px"
                  >Product is Exclusive?</span
                >
              </label>

              <label for="availability">Availability *</label>
              <select class="availability" v-model="single_status">
                <option value="Available">In stock</option>
                <option value="Not Available">Out of stock</option>
              </select>
              <span class="form-error" v-if="errors.availability_obj">{{
                errors.availability_obj
              }}</span>

              <span style="color: red" v-if="error_message.length > 0"
                >Please clear the errors.<br
              /></span>
              <span class="form-success" v-if="success">{{ success }}</span>

              <button v-if="!isLoading" type="submit" class="gg-create-btn">
                Save
              </button>
              <img
                v-else
                src="../assets/img/loader.gif"
                width="64"
                height="64"
                alt="Greengrowth Logo"
              />
            </form>
          </div>
        </md-dialog>
      </div>
      <div class="add-edit-unit-modal">
        <md-dialog :md-active.sync="showDialogEditUnit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDialogEditUnit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="addProductUnit">
              <h2 class="form-title">Add Units on product '{{ single_product.name }}'</h2>
              <label for="unit">Unit</label>
              <select class="unit" id="unit" v-model="single_product_unit">
                <option v-for="(unit_obj, index) in product_unit" :key="index" :value="unit_obj.id">
                  {{ unit_obj.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.single_product_unit">{{
                errors.single_product_unit
              }}</span>

              <label for="price">Product Unit Price *</label>
              <input
                type="text"
                v-model="single_product_price"
                name="price"
                placeholder="200"
                required
              />
              <span class="form-error" v-if="errors.single_product_price">{{
                errors.single_product_price
              }}</span>

              <span class="form-success" v-if="success">{{ success }}</span>

              <button type="submit" class="gg-create-btn">Save</button>
            </form>

            <div
              v-if="single_product.product_unit && single_product.product_unit.length > 0"
              class="product-unit-form product-unit-form-edit"
            >
              <form class="order-detail-table-cost unit" @submit.prevent="">
                <h3>List units of '{{ single_product.name }}'</h3>
                <ul>
                  <li
                    v-for="(product_unit_obj, index) in single_product.product_unit"
                    :key="index"
                    class="edit-unit-list"
                  >
                    <span>{{ product_unit_obj.unit.name }}</span>
                    <span>Rs. {{ product_unit_obj.price }}</span>
                    <span>
                      <button
                        class="gg-create-btn delete-button"
                        @click="openUnitRemoveDialog(product_unit_obj)"
                      >
                        Remove
                      </button>
                    </span>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </md-dialog>
      </div>
      <md-dialog :md-active.sync="showUnitRemoveDialog">
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons remove-icon">highlight_off</span>
          </div>
          <md-dialog-title class="modal-title"
            >Are you sure want to remove this product unit?</md-dialog-title
          >
          <md-dialog-actions>
            <md-button class="cancel-btn" @click="showUnitRemoveDialog = false">Cancel</md-button>
            <md-button class="remove-btn" @click="deleteProductUnit">Delete</md-button>
          </md-dialog-actions>
          <span class="form-success" v-if="success">{{ success }}</span>
        </div>
      </md-dialog>
      <div>
        <md-dialog :md-active.sync="showDialogRemove">
          <div class="modal-alert">
            <div class="icon-div">
              <span class="material-icons remove-icon">highlight_off</span>
            </div>
            <md-dialog-title class="modal-title"
              >Are you sure want to remove this item</md-dialog-title
            >
            <md-dialog-actions>
              <md-button class="cancel-btn" @click="showDialogRemove = false">Cancel</md-button>
              <md-button class="remove-btn" @click="deleteProductForm">Delete</md-button>
            </md-dialog-actions>
          </div>
        </md-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
const toLower = text => {
  return text.toString().toLowerCase();
};
const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.product_name).includes(toLower(term)));
  }
  return items;
};
export default {
  name: "ProductTabTable",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      Members: [],
      search: "",
      searched: [],
      single_product: [],
      single_category: "",
      single_brand: null,
      single_unit: "",
      single_status: "",
      showDialog: true,
      openDialogRequest: false,
      showDialogEdit: false,
      showDialogEditUnit: false,
      showDialogEditUnitPrice: false,
      showDialogRemove: false,
      showUnitRemoveDialog: false,
      category_obj: "",
      unit_obj: "",
      availability_obj: "",
      product_name: "",
      quantity: "",
      price_in: "",
      price_out: "",
      image: "",
      errors: [],
      validsku: "",
      files: [],
      file: "",
      file_name: "",
      image_url: null,
      image: "",
      single_product_unit: "",
      single_product_price: "",
      selected_product_unit: [],
      success: "",
      category: null,
      sku: "",
      is_exclusive: false,
      tabIndex: 0,
      tabIsActive: false,
      activeTab: "",
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      boolean: true,
      search: ""
    };
  },
  computed: {
    ...mapState([
      "product_obj",
      "product_category",
      "product_unit",
      "product_brand",
      "success_message",
      "error_message",
      "response_message"
    ]),
    // filterTableData() {
    //   this.$router.replace({
    //     name: this.current_route,
    //     query: { page: 1 },
    //   });
    //   let table_data = this.product_obj;

    //   if (this.tabIndex == 1) {
    //     table_data = table_data.filter((row) => {
    //       return row.product_status == "Available";
    //     });
    //   }
    //   if (this.tabIndex == 2) {
    //     table_data = table_data.filter((row) => {
    //       return row.product_status == "Not Available";
    //     });
    //   }
    //   if (this.category) {
    //     table_data = table_data.filter((row) => {
    //       return row.category.id == this.category;
    //     });
    //   }
    //   return table_data;
    // },
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: { page: 1 }
      });

      let table_data = this.product_obj;
      let searchString = this.search;
      if (!searchString) {
        if (this.tabIndex == 1) {
          table_data = table_data.filter(row => {
            return row.product_status == "Available";
          });
        }
        if (this.tabIndex == 2) {
          table_data = table_data.filter(row => {
            return row.product_status == "Not Available";
          });
        }
        if (this.category) {
          table_data = table_data.filter(row => {
            return row.category.id == this.category;
          });
        }
        return table_data;
      } else {
        searchString = searchString.trim().toLowerCase();
        table_data = table_data.filter(row => {
          if (row.name.toLowerCase().indexOf(searchString) !== -1) {
            return row;
          }
        });
        // Return the filtered data
        return table_data;
      }
    }
  },
  created() {
    this.listProducts();
    this.listCategories();
    this.listUnits();
    this.listBrands();
    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
    // search
    this.searched = this.product_name;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    }
  },
  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions(["listProducts", "listCategories", "listUnits", "listBrands"]),
    validateSKU() {
      if (this.sku == this.single_product.sku) {
        this.validsku = "yes";
      } else if (this.sku.length > 0) {
        this.errors["sku"] = "";
        this.isLoading = true;
        axios.defaults.headers.common["authorization"] = "JWT " + localStorage.getItem("token");
        axios
          .post("skuvalidate", { sku: this.sku })
          .then(response => {
            this.isLoading = false;
            if (response.status == 200) {
              this.validsku = "yes";
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.validsku = "no";
          });
      } else {
        this.validsku = "no";
      }
    },
    editProduct(product_id) {
      this.single_product = this.product_obj.find(evt => evt.id == product_id);
      this.single_category = this.single_product.category.id;
      this.sku = this.single_product.sku;
      this.single_unit = this.single_product.unit.id;
      this.single_brand = this.single_product.brand.id;
      this.single_status = this.single_product.product_status;
      this.image_url = this.single_product.image;
      this.is_exclusive = this.single_product.exclusives == "active" ? true : false;
      this.showDialogEdit = !this.showDialogEdit;
    },

    removesingleProduct(product_id) {
      this.showDialogRemove = !this.showDialogRemove;
      this.single_product = this.product_obj.find(evt => evt.id == product_id);
    },

    updateProduct(product_id) {
      this.errors = [];
      if (this.single_product.name == "") {
        this.errors["product_name"] = "Product Name required.";
      } else if (this.single_product.nepali_name == "") {
        this.errors["nepali_name"] = "Product Nepali Name required.";
      } else if (this.single_product.price_out == "") {
        this.errors["price_out"] = "Price Out required.";
      } else if (this.single_product.sku == "") {
        this.errors["sku"] = "SKU required.";
      } else if (this.validsku == "no") {
        this.errors["sku"] = "";
        this.validsku = "no";
      } else if (this.single_brand == null || this.single_brand == "") {
        this.errors["brand"] = "Brand required.";
      } else if (this.single_product.description == "") {
        this.errors["description"] = "Description required.";
      } else if (this.single_product.nepali_description == "") {
        this.errors["nepali_description"] = "Nepali Description required.";
      } else if (this.single_product.return_policy == "") {
        this.errors["return_policy"] = "Return Policy required.";
      } else if (this.single_product.availability_obj == "") {
        this.errors["availability_obj"] = "Availability required.";
      } else {
        if (this.single_product.sku == this.sku || this.validsku == "yes") {
          this.isLoading = true;
          let formData = new FormData();
          formData.append("name", this.single_product.name);
          formData.append("nepali_name", this.single_product.nepali_name);
          formData.append("category", this.single_category);
          formData.append("unit", this.single_unit);
          formData.append("quantity", 1);
          formData.append("price_in", this.single_product.price_in);
          formData.append("price_out", this.single_product.price_out);
          formData.append("image", this.file ? this.file : "");
          formData.append("sku", this.sku);
          formData.append("description", this.single_product.description);
          formData.append("nepali_description", this.single_product.nepali_description);
          formData.append("return_policy", this.single_product.return_policy);
          formData.append("brand", this.single_brand);
          formData.append("exclusives", this.is_exclusive ? "active" : "inactive");
          formData.append("product_status", this.single_status);
          formData.append("id", product_id);

          for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
          }

          this.$store.dispatch("updateProduct", formData).then(() => {
            if (this.success_message == "success") {
              this.success = "Product Updated Successfully.";
              this.isLoading = false;
              setTimeout(function() {
                setTimeout(() => {
                  location.reload();
                }, 100);
                // this.showDialogEdit = false;
                this.isLoading = true;
              }, 1000);
            } else if (this.error_message == "errormessage") {
              alert(JSON.stringify(this.response_message));
            }
          });
        } else {
          // error
          this.validsku = "no";
        }
      }
    },
    deleteProductForm() {
      this.showDialogRemove = false;
      this.$store.dispatch("deleteProduct", this.single_product.id).then(() => {
        if (this.success_message == "success") {
          location.reload();
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    },
    // For Image Upload
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
    openDialogEditUnit(product_id) {
      this.single_product = this.product_obj.find(evt => evt.id == product_id);
      this.showDialogEditUnit = true;
    },
    addProductUnit() {
      this.errors = [];
      if (this.single_product_unit == "") {
        this.errors["single_product_unit"] = "Product unit value required.";
      } else if (this.single_product_price == "") {
        this.errors["single_product_price"] = "Product unit price required.";
      } else {
        //
        let formData = new FormData();
        formData.append("unit", this.single_product_unit);
        formData.append("product", this.single_product.id);
        formData.append("price", this.single_product_price);

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.$store.dispatch("createProductUnit", formData).then(() => {
          if (this.success_message == "success") {
            this.success = "Product Unit Added Successfully.";
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(JSON.stringify(this.response_message));
          }
        });
      }
    },
    openUnitRemoveDialog(product_unit_obj) {
      this.errors = [];
      var productId = product_unit_obj.product;
      this.single_product = this.product_obj.find(evt => evt.id == productId);
      if (this.single_product.unit.id == product_unit_obj.unit.id) {
        alert(
          "Sorry! you can't remove the main unit value. Please change the product unit on update, then only you can remove this item?"
        );
      } else {
        this.selected_product_unit = product_unit_obj;
        this.showUnitRemoveDialog = true;
      }
    },
    deleteProductUnit() {
      this.$store.dispatch("deleteProductUnit", this.selected_product_unit.id).then(() => {
        if (this.success_message == "success") {
          this.success = "Product Unit Deleted Successfully.";
          this.showDialogEditUnit = false;
          this.showUnitRemoveDialog = false;
          setTimeout(() => {
            location.reload();
          }, 500);
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    },
    searchOnTable() {
      this.searched = (this.product_name, this.search);
    }
  }
};
</script>

<style lang="scss" scoped>
/** *** Pagination Menu Styling *** **/

.search-box {
  display: flex;
  justify-content:flex-end;
}

.searchs{
  border: 1px solid rgb(156, 154, 154);
  padding: 10px 10px;
  width: 300px;
}
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
// .nav-pagination li.active {
// }
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
.gg-create-btn.add-edit-button {
  font-size: 13px;
  padding: 5px 10px;
}

.product-unit-form-edit {
  grid-template-columns: auto;
}

.custom-switch {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    margin: 0 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.search {
  display: grid;
  justify-content: flex-end;
  input {
    border-radius: 5px;
    border: 1px solid #ccc !important;
    padding: 15px 50px;
    font-size: 14px;
    outline: none;
  }
}

@media (max-width: 600px) {
  .product-unit-form-edit .order-detail-table-cost.unit ul li {
    display: block;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span {
    margin-bottom: 10px;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span button {
    margin: 0;
    margin-right: 20px;
  }
}
</style>
