<template>
  <aside class="category-form">
    <span style="color:red" v-if="errors.name">{{ errors.name}}</span>
    <span style="color:red" v-if="error_message.length>0">Category Name Already Exist.<br/></span>
    <span style="color:green" v-if="success_message.length>0">Category Added Successfully.<br/></span>
    <form class="create-post">
      
        <label for="name">Category Name in English *</label>
        <input type="text" id="name" v-model="name" value="Vegetables" placeholder="Name"/>
      
        <label for="nepali_name">नाम नेपालीमा *</label>
        <input type="text" id="nepali_name" v-model="nepali_name" value="Vegetables" placeholder="नाम नेपालीमा"/>
      
      <button class="post-btn" @click="addCategories">ADD</button>
    </form>
  </aside>
</template>
<script>
import { mapState} from "vuex";
export default {

  computed: {
    ...mapState(["success_message","error_message","response_message"])
  },

  data() {
    return {
      name: "",
      nepali_name: "",
      errors: [],
    };
  },


  methods: {
    addCategories(e){
      e.preventDefault();
      this.errors = []
      if (this.name == "") {
        this.errors["name"] = "Category Name required.";
      }else if (this.nepali_name == "") {
        this.errors["name"] = "Nepali Name required.";
      }else{
        this.$store.dispatch("createCategory", {
          'name':this.name,
          'nepali_name': this.nepali_name
        }).then(() => {
          if(this.success_message=='success'){
            setTimeout(function() {
              location.reload();
            }, 1000);
          }else if(this.error_message=="errormessage"){
            //alert(this.response_message)
          }
        });
      }

    }
  }

}


</script>

<style lang="scss" scoped>

</style>
