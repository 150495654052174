<template>
  <div class="deliveries-card">
    <md-table md-card id="card">
      <md-table-row>
        <md-table-cell class="tablecontent">Aanadi Rice</md-table-cell>
        <md-table-cell class="tablecontent">gg-101</md-table-cell>
        <md-table-cell class="tablecontent">2 KG</md-table-cell>
        <md-table-cell class="tablecontent">Rs. 500</md-table-cell>
        <md-table-cell class="tablecontent">Today</md-table-cell>
        <md-table-cell class="tablecontent">Muraliprasad Hriprasad Sharma</md-table-cell>
        <md-table-cell class="tablecontent">9807677430</md-table-cell>
        <md-table-cell class="tablecontent">Pulchowk, Lalitpur</md-table-cell>
        <md-table-cell class="tablecontent" id="card-select">
          <md-field>
            <md-select v-model="day" name="day" id="day">
              <md-option value="today">Pending</md-option>
              <md-option value="tomorrow">Delivered</md-option>
            </md-select>
          </md-field>
        </md-table-cell>
        <md-table-cell class="tablecontent" md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item >Get Route</md-menu-item>
                <md-menu-item>Save Location</md-menu-item>
                <md-menu-item class="add-comment" @click="openDialog = !openDialog">Add comment</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-table md-card id="card">
      <md-table-row>
        <md-table-cell class="tablecontent">Aanadi Rice</md-table-cell>
        <md-table-cell class="tablecontent">gg-101</md-table-cell>
        <md-table-cell class="tablecontent">2 KG</md-table-cell>
        <md-table-cell class="tablecontent">Rs. 500</md-table-cell>
        <md-table-cell class="tablecontent">Today</md-table-cell>
        <md-table-cell class="tablecontent">Muraliprasad Hriprasad Sharma</md-table-cell>
        <md-table-cell class="tablecontent">9807677430</md-table-cell>
        <md-table-cell class="tablecontent">Pulchowk, Lalitpur</md-table-cell>
        <md-table-cell class="tablecontent" id="card-select">
          <md-field>
            <md-select v-model="yesterday" name="yesterday" id="yesterday">
              <md-option value="today">Pending</md-option>
              <md-option value="yesterday">Delivered</md-option>
            </md-select>
          </md-field>
        </md-table-cell>
        <md-table-cell class="tablecontent" md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item>Get Route</md-menu-item>
                <md-menu-item>Save Location</md-menu-item>
                <md-menu-item class="add-comment" @click="openDialog = !openDialog">Add comment</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-table md-card id="card">
      <md-table-row>
        <md-table-cell class="tablecontent">Aanadi Rice</md-table-cell>
        <md-table-cell class="tablecontent">gg-101</md-table-cell>
        <md-table-cell class="tablecontent">2 KG</md-table-cell>
        <md-table-cell class="tablecontent">Rs. 500</md-table-cell>
        <md-table-cell class="tablecontent">Today</md-table-cell>
        <md-table-cell class="tablecontent">Muraliprasad Hriprasad Sharma</md-table-cell>
        <md-table-cell class="tablecontent">9807677430</md-table-cell>
        <md-table-cell class="tablecontent">Pulchowk, Lalitpur</md-table-cell>
        <md-table-cell class="tablecontent" id="card-select">
          <md-field>
            <md-select v-model="delivered" name="delivered" id="delivered">
              <md-option value="today">Pending</md-option>
              <md-option value="tomorrow">Delivered</md-option>
            </md-select>
          </md-field>
        </md-table-cell>
        <md-table-cell class="tablecontent" md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item>Get Route</md-menu-item>
                <md-menu-item>Save Location</md-menu-item>
                <md-menu-item class="add-comment" @click="openDialog = !openDialog">Add comment</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <ModalComment v-if="openDialog" />
  </div>
</template>

<script>
import ModalComment from "@/components/ModalComment.vue";

export default {
  components: {
    ModalComment
  },
  data: () => ({
    day: "today",
    tomorrow: "tomorrow",
    yesterday: "yesterday",
    openDialog: false
  })
};
</script>

<style lang='scss' >
// td.md-table-cell {
//   border: none;
// }
// #card {
//   margin: 2rem 0;
//   background-color: #fff;
//   border-radius: 10px;
//   padding: 0.5rem;
//   width: 100%;
//   // overflow: scroll;
// }
// .md-table-cell-container {
//   padding: 6px;
//   line-height: 21px;
//   border-top: none;
//   border-top: none;
// }
// tbody .md-table-row td {
//   border-top: none;
// }
// .md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default{
//   background-color: #fff;
//   width: 100px;
// }
// #card-select{
// max-width: 100px;
// }
</style>
