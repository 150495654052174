<template>
  <div class="gg-table product-tabtable">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>
    <div class="table-top-nav">
      <md-tabs>
        <md-tab id="tab-home" md-label="All" @click="tabIndex = 0"></md-tab>
        <md-tab id="tab-pages" md-label="Requested" @click="tabIndex = 1"></md-tab>
        <md-tab id="tab-posts" md-label="Dispatched" @click="tabIndex = 2"></md-tab>
        <md-tab id="tab-blogs" md-label="Received" @click="tabIndex = 3"></md-tab>
      </md-tabs>
      <md-field class="table-category">
        <md-select
          v-if="product_category && product_category.length > 0"
          v-model="category"
          name="category"
          id="category"
          placeholder="All Category"
        >
          <md-option class="table-filter-option" selected>All Category</md-option>
          <md-option
            class="table-filter-option"
            v-for="(cats, index) in product_category"
            :key="index"
            :value="cats.id"
            >{{ cats.name }}</md-option
          >
        </md-select>
      </md-field>
    </div>

    <div class="product-table">
      <md-table
        v-model="filterTableData.slice(count_start, count_end)"
        md-sort="name"
        md-sort-order="asc"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S.N" md-sort-by="sn"
            >{{ index + 1 + (current_page - 1) * 10 }}.</md-table-cell
          >
          <md-table-cell md-label="Product Name" md-sort-by="name" class="table-name">
            {{ item.product.name }}<br />({{ item.product.id }})
          </md-table-cell>
          <md-table-cell md-label="Source Name" md-sort-by="name" class="table-name">
            {{ item.source.full_name }}
          </md-table-cell>
          <md-table-cell class="avatar" md-label="Partner Details" md-sort-by="name">
            <!-- {{ item.partner.name }}<br /><img :src="item.partner.logo" alt=""> -->
            <div class="partner-details" v-if="item.partner !== null">
              <div class="" v-for="(partner, index) in item.partner" :key="index">
                <a :href="partner.link" target="_blank">
                  <div class="flex">
                    <div class="partner-name">
                      {{ partner.name.toUpperCase() }}
                    </div>
                    <div class="partner-logo">
                      <img :src="mediaRootUrl + partner.logo" alt="" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </md-table-cell>
          <md-table-cell md-label="Outlet Details" md-sort-by="name" class="table-name">
            <!-- {{ item.partner.name }}<br /><img :src="item.partner.logo" alt=""> -->
            <div v-if="item.outlet !== null">
              <div class="partner-name">
                {{ item.outlet.full_name }}
              </div>
            </div>
          </md-table-cell>
          <md-table-cell md-label="Quantity" md-sort-by="qty"
            >{{ item.order_quantity }} {{ item.unit.name }}</md-table-cell
          >

          <md-table-cell md-label="Date " md-sort-by="added">{{ item.created_at }}</md-table-cell>

          <md-table-cell md-label="Status" md-sort-by="status">{{ item.status }}</md-table-cell>

          <md-table-cell md-label="Print QR" md-sort-by="qr-code">
            <h3>{{ item.id }}</h3>
            <button class="gg-create-btn" @click="printQRCode(item.qrcode, item.id)">
              PRINT QR
            </button>
          </md-table-cell>
          <md-table-cell md-label="Action" md-sort-by="action">
            <div class="gg-dropdown-btn">
              <md-menu md-size="big">
                <md-button class="dropdown-icon" md-menu-trigger>
                  <i class="fas fa-caret-down"></i>
                </md-button>
                <md-menu-content class="gg-dropdown-menu">
                  <router-link :to="{ name: 'BatchDetail', params: { id: item.id } }">
                    <md-menu-item>Detail Batch</md-menu-item>
                  </router-link>
                  <md-menu-item v-if="item.status == 'Pending'" @click="editBatch(item.id)"
                    >Update Batch</md-menu-item
                  >
                  <md-menu-item v-if="item.status === 'Pending'" @click="dispatchBatch(item.id)"
                    >Dispatch Batch</md-menu-item
                  >
                  <md-menu-item v-if="item.status === 'Dispatched'" @click="receiveBatch(item.id)"
                    >Receive Batch</md-menu-item
                  >
                  <md-menu-item v-if="item.status == 'Pending'" @click="removesingleBatch(item.id)"
                    >Remove Batch</md-menu-item
                  >
                </md-menu-content>
              </md-menu>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="filterTableData && filterTableData.length">
        <ul class="nav-pagination">
          <li>
            <router-link
              :to="{
                name: current_route,
                query: { page: current_page <= 1 ? 1 : current_page - 1 }
              }"
              >&laquo;</router-link
            >
          </li>

          <li
            v-for="(page, index) in Math.ceil(filterTableData.length / page_sequence)"
            :class="current_page == page ? 'active' : ''"
            :key="index"
          >
            <router-link :to="{ name: current_route, query: { page: page } }">{{
              page
            }}</router-link>
          </li>

          <li>
            <router-link
              :to="{
                name: current_route,
                query: {
                  page:
                    current_page >= Math.ceil(filterTableData.length / page_sequence)
                      ? Math.ceil(filterTableData.length / page_sequence)
                      : current_page + 1
                }
              }"
              >&raquo;</router-link
            >
          </li>
        </ul>
      </div>
      <div>
        <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDialogEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="updateBatch(single_batch.id)">
              <h2 class="form-title">Update Batch Product</h2>

              <label for="source_name">Source Name *</label>
              <select id="source_name" v-model="batch_source_name">
                <option value disabled>Select Source Name</option>
                <option v-for="(user, index) in filterSourceUser" :key="index" :value="user.id">
                  {{ user.full_name }}
                </option>
              </select>
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.batch_source_name"
                >{{ errors.batch_source_name }}</span
              >

              <label for="product_name">Product Name *</label>
              <select id="product_name" v-model="batch_product_name">
                <option value disabled>Select Product Name</option>
                <option v-for="(product, index) in product_obj" :key="index" :value="product.id">
                  {{ product.name }} ({{ product.id }})
                </option>
              </select>
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.batch_product_name"
                >{{ errors.batch_product_name }}</span
              >
              <label for="outlet_name">Outlet Name *</label>
              <select id="outlet_name" v-model="batch_source_outlet">
                <option value disabled>Select Source Name</option>
                <option v-for="(user, index) in filterOutletUser" :key="index" :value="user.id">
                  {{ user.full_name }}
                </option>
              </select>
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.batch_source_outlet"
                >{{ errors.batch_source_outlet }}</span
              >

              <label for="partner_name">Partner Name</label>
              <select id="partner_name" multiple v-model="batch_partner_name">
                <option value disabled>Select Partner Name</option>
                <option v-for="(user, index) in filterPartnerUser" :key="index" :value="user.id">
                  {{ user.name }}
                </option>
              </select>
              <label for="product_quantity">Request Quantity *</label>
              <md-field md-clearable>
                <md-input id="product_quantity" v-model="batch_quantity"></md-input>
              </md-field>
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.batch_quantity"
                >{{ errors.batch_quantity }}</span
              >

              <label for="product_unit">Product Unit *</label>
              <select class="product_unit" v-model="batch_product_unit">
                <option value disabled>Select Product Unit</option>
                <option v-for="(unit, index) in filterProductUnit" :key="index" :value="unit.id">
                  {{ unit.name }}
                </option>
              </select>
              <span
                class="text-danger float-right"
                style="color: red"
                v-if="errors.batch_product_unit"
                >{{ errors.batch_product_unit }}</span
              >

              <span style="color: red" v-if="error_message.length > 0"
                >{{ response_message }}<br
              /></span>
              <span style="color: green" v-if="success_message.length > 0"
                >Batch Request Updated Successfully.<br
              /></span>

              <button v-if="!isLoading" type="submit" class="gg-create-btn">
                Update Batch
              </button>
              <img
                v-else
                src="../assets/img/loader.gif"
                width="64"
                height="64"
                alt="Greengrowth Logo"
              />
            </form>
          </div>
        </md-dialog>
      </div>

      <div>
        <md-dialog :md-active.sync="showDispatchDialogEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDispatchDialogEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="updateProductBatch(single_batch.id)">
              <h2 class="form-title">Dispatch Batch</h2>

              <label for="product">Dispatched Product Quantity *</label>
              <input
                type="number"
                class="product"
                v-model="single_batch.order_quantity"
                placeholder="Dispatched Product Quantity"
                required
              />
              <span class="form-error" v-if="errors.order_quantity">{{
                errors.order_quantity
              }}</span>

              <label for="price"> Dispatch Product Unit *</label>
              <select class="price" v-model="single_unit">
                <option selected disabled>Select Unit</option>
                <option v-for="(unit, index) in product_unit" :key="index" :value="unit.id">
                  {{ unit.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.single_unit">{{ errors.single_unit }}</span>

              <label for="date">Dispatch Date</label>
              <input type="date" v-model="single_batch.dispatched_at" />
              <span class="form-error" v-if="errors.dispatched_at">{{ errors.dispatched_at }}</span>

              <label for="status"> Product Production Time (in days)</label>
              <input type="number" minlength="1" step="0.01" v-model="product_production_time" />
              <span class="form-error" v-if="errors.product_production_time">{{
                errors.product_production_time
              }}</span>

              <label for="status">Dispatch Messagae</label>
              <textarea rows="4" v-model="dispatched_remarks"></textarea>
              <span class="form-error" v-if="errors.dispatched_remarks">{{
                errors.dispatched_remarks
              }}</span>

              <span v-if="success" style="color: green;">Product Dispatched Successfully</span>
              <button v-if="!isLoading" type="submit" class="gg-create-btn">
                Save
              </button>
              <img
                v-else
                src="../assets/img/loader.gif"
                width="64"
                height="64"
                alt="Greengrowth Logo"
              />
            </form>
          </div>
        </md-dialog>
      </div>

      <div>
        <md-dialog :md-active.sync="showReceiveDialogEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showReceiveDialogEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="receiveProductBatch(single_batch.id)">
              <h2 class="form-title">Dispatch Batch</h2>

              <label for="product">Receive Product Quantity *</label>
              <input
                type="number"
                class="product"
                v-model="single_batch.dispatched_quantity"
                placeholder="Receive Product Quantity"
                required
              />
              <span class="form-error" v-if="errors.dispatched_quantity">{{
                errors.dispatched_quantity
              }}</span>

              <label for="price"> Receive Product Unit *</label>
              <select class="price" v-model="single_unit">
                <option selected disabled>Select Unit</option>
                <option v-for="(unit, index) in product_unit" :key="index" :value="unit.id">
                  {{ unit.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.single_unit">{{ errors.single_unit }}</span>

              <label for="date">Receive Date</label>
              <input type="date" v-model="single_batch.received_at" />
              <span class="form-error" v-if="errors.received_at">{{ errors.received_at }}</span>

              <label for="status">Receive Messagae</label>
              <textarea rows="4" v-model="received_remarks"></textarea>
              <span class="form-error" v-if="errors.received_remarks">{{
                errors.received_remarks
              }}</span>

              <span v-if="success" style="color: green;">Product Receive Successfully</span>
              <button v-if="!isLoading" type="submit" class="gg-create-btn">
                Save
              </button>
              <img
                v-else
                src="../assets/img/loader.gif"
                width="64"
                height="64"
                alt="Greengrowth Logo"
              />
            </form>
          </div>
        </md-dialog>
      </div>

      <div>
        <md-dialog :md-active.sync="showDialogRemove">
          <div class="modal-alert">
            <div class="icon-div">
              <span class="material-icons remove-icon">highlight_off</span>
            </div>
            <md-dialog-title class="modal-title"
              >Are you sure want to remove this item</md-dialog-title
            >
            <md-dialog-actions>
              <md-button class="cancel-btn" @click="showDialogRemove = false">Cancel</md-button>
              <md-button class="" @click="deleteBatchForm">Delete</md-button>
            </md-dialog-actions>
          </div>
        </md-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import printJS from "print-js";

export default {
  name: "ProductTabTable",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      Members: [],
      newbatchdata: "",
      single_batch: [],
      batch_source_name: "",
      batch_source_outlet: "",
      batch_product_name: "",
      batch_partner_name: [],
      batch_product_unit: "",
      batch_quantity: 0,
      single_category: "",
      single_brand: null,
      single_unit: "",
      single_status: "",
      showDialog: true,
      openDialogRequest: false,
      showDialogEdit: false,
      showDialogEditUnit: false,
      showDialogEditUnitPrice: false,
      showDispatchDialogEdit: false,
      showReceiveDialogEdit: false,
      showDialogRemove: false,
      showUnitRemoveDialog: false,
      product_production_time: "",
      dispatched_remarks: "",
      received_remarks: "",
      category_obj: "",
      unit_obj: "",
      availability_obj: "",
      product_name: "",
      quantity: "",
      price_in: "",
      price_out: "",
      image: "",
      errors: [],
      validsku: "",
      files: [],
      file: "",
      file_name: "",
      image_url: null,
      single_batch_unit: "",
      single_batch_price: "",
      selected_product_unit: [],
      success: "",
      category: null,
      sku: "",
      is_exclusive: false,
      tabIndex: 0,
      tabIsActive: false,
      activeTab: "",
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      boolean: true,
      mediaRootUrl: process.env.VUE_APP_USER_PANEL
    };
  },
  computed: {
    ...mapState([
      "product_obj",
      "batch_obj",
      "partner_obj",
      "product_category",
      "product_unit",
      "product_brand",
      "all_user_obj",
      "success_message",
      "error_message",
      "response_message"
    ]),
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: { page: 1 }
      });
      let tableData = this.batch_obj;
      if (this.tabIndex === 1) {
        tableData = tableData.filter(row => {
          return row.status === "Pending";
        });
      }
      if (this.tabIndex === 2) {
        tableData = tableData.filter(row => {
          return row.status === "Dispatched";
        });
      }
      if (this.tabIndex === 3) {
        tableData = tableData.filter(row => {
          return row.status === "Received";
        });
      }
      if (this.category) {
        tableData = tableData.filter(row => {
          return row.category.id === this.category;
        });
      }
      return tableData;
    },
    filterSourceUser() {
      let allusers = this.all_user_obj;
      allusers = allusers.filter(row => {
        return row.group.name === "Farmer";
      });

      return allusers;
    },
    filterOutletUser() {
      let allusers = this.all_user_obj;
      allusers = allusers.filter(row => {
        return row.group.name === "Outlets";
      });

      return allusers;
    },
    filterPartnerUser() {
      let allPartner = this.partner_obj;
      allPartner = allPartner.filter(row => {
        return row.name;
      });

      return allPartner;
    },
    filterProductUnit() {
      let allunits = this.product_unit;
      allunits = allunits.filter(row => {
        return row.status;
      });

      return allunits;
    },
    singleBatch() {
      let newbatchdata = this.batch_obj;
      if (newbatchdata) {
        newbatchdata = newbatchdata.filter(row => {
          return row.id === this.batch_id;
        });
        this.newbatchdata = newbatchdata;
      }
      return newbatchdata[0];
    }
  },
  created() {
    this.getProductBatch();
    this.listProducts();
    this.listCategories();
    this.listUnits();
    this.listBrands();
    this.listUser();
    this.listPartner();
    // Pagination
    // eslint-disable-next-line max-len
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    }
  },
  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions([
      "getProductBatch",
      "listProducts",
      "listCategories",
      "listUnits",
      "listBrands",
      "listUser",
      "listPartner"
    ]),
    printQRCode(qrcodeurl, qucodeid) {
      printJS({
        printable: this.mediaRootUrl + qrcodeurl,
        type: "image",
        header: "Green Growth Batch QR Code: " + qucodeid
      });
    },
    editBatch(batch_id) {
      // alert(batch_id)
      this.single_batch = this.batch_obj.find(evt => evt.id === batch_id);
      this.batch_source_name = this.single_batch.source.id;
      this.batch_source_outlet = this.single_batch.outlet.id;
      this.batch_product_name = this.single_batch.product.id;
      this.single_batch.partner.forEach(element => {
        this.batch_partner_name.push(element.id);
      });
      this.batch_product_unit = this.single_batch.unit.id;
      this.batch_quantity = this.single_batch.order_quantity;
      this.showDialogEdit = !this.showDialogEdit;
    },
    dispatchBatch(batch_id) {
      this.single_batch = this.batch_obj.find(evt => evt.id === batch_id);
      this.single_unit = this.single_batch.unit.id;
      this.showDispatchDialogEdit = !this.showDispatchDialogEdit;
    },
    receiveBatch(batch_id) {
      this.single_batch = this.batch_obj.find(evt => evt.id === batch_id);
      this.single_unit = this.single_batch.unit.id;
      this.showReceiveDialogEdit = !this.showReceiveDialogEdit;
    },
    updateProductBatch(batchId) {
      this.errors = [];
      if (this.single_batch.order_quantity === "") {
        this.errors.order_quantity = "Batch Product Quantity Required.";
      } else if (!this.single_unit || this.single_unit === "") {
        this.errors.single_unit = "Batch Product Unit Required";
      } else if (this.single_batch.dispatched_at === "") {
        this.errors.dispatched_at = "Dispatched Date Required.";
      } else if (this.product_production_time === "") {
        this.errors.product_production_time = "Product Production Time Required.";
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("dispatched_quantity", this.single_batch.order_quantity);
        formData.append("unit", this.single_unit);
        formData.append("dispatched_at", this.single_batch.dispatched_at);
        formData.append("product_production_time", this.product_production_time);
        formData.append("dispatched_remarks", this.dispatched_remarks);
        formData.append("batch_id", batchId);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }

        this.$store.dispatch("dispatchProductBatch", formData).then(() => {
          if (this.success_message === "success") {
            this.success = "Product Updated Successfully.";
            this.isLoading = false;
            setTimeout(() => {
              setTimeout(() => {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
              }, 100);
              this.isLoading = false;
            }, 1000);
          } else if (this.error_message === "errormessage") {
            // eslint-disable-next-line no-alert
            alert(JSON.stringify(this.response_message));
          }
        });
      } // else
    },
    receiveProductBatch(batchId) {
      this.errors = [];
      if (this.single_batch.dispatched_quantity === "") {
        this.errors.dispatched_quantity = "Batch Product Quantity Required.";
      } else if (!this.single_unit || this.single_unit === "") {
        this.errors.single_unit = "Batch Product Unit Required";
      } else if (this.single_batch.dispatched_at === "") {
        this.errors.dispatched_at = "Dispatched Date Required.";
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("received_quantity", this.single_batch.dispatched_quantity);
        formData.append("unit", this.single_unit);
        formData.append("received_at", this.single_batch.received_at);
        formData.append("received_remarks", this.received_remarks);
        formData.append("batch_id", batchId);

        this.$store.dispatch("receiveProductBatch", formData).then(() => {
          if (this.success_message === "success") {
            this.success = "Product Receive Successfully.";
            this.isLoading = false;
            setTimeout(() => {
              setTimeout(() => {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
              }, 100);
              this.isLoading = false;
            }, 1000);
          } else if (this.error_message === "errormessage") {
            // eslint-disable-next-line no-alert
            alert(JSON.stringify(this.response_message));
          }
        });
      } // else
    },

    removesingleBatch(batch_id) {
      this.showDialogRemove = !this.showDialogRemove;
      this.single_batch = this.batch_obj.find(evt => evt.id === batch_id);
    },

    updateBatch(batch_id) {
      this.errors = [];
      this.errors = [];
      if (this.batch_source_name == "") {
        this.errors["batch_source_name"] = "Source Name required.";
      } else if (this.batch_product_name == "") {
        this.errors["batch_product_name"] = "Product Name required.";
      } else if (this.batch_source_outlet == "") {
        this.errors["batch_source_outlet"] = "Source Outlet required.";
      } else if (this.batch_quantity < 1) {
        this.errors["batch_quantity"] = "Batch Quantity required.";
      } else if (this.batch_product_unit == "") {
        this.errors["batch_product_unit"] = "Product Unit required.";
      } else {
        this.isLoading = true;
        // console.log(this.batch_partner_name);
        let partner_id = [];
        this.batch_partner_name.forEach(element => {
          partner_id.push(element);
        });
        console.log(partner_id);
        let formData = new FormData();
        formData.append("source", this.batch_source_name);
        formData.append("product", this.batch_product_name);
        partner_id.forEach(element => {
          formData.append("partner", element);
        });
        // formData.append("partner", this.batch_partner_name);
        formData.append("outlet", this.batch_source_outlet);
        formData.append("unit", this.batch_product_unit);
        formData.append("order_quantity", this.batch_quantity);
        formData.append("status", this.single_batch.status);
        formData.append("batch_id", batch_id);

        this.$store.dispatch("updateProductBatch", formData, batch_id).then(() => {
          if (this.success_message == "success") {
            this.success = "Batch Updated Successfully.";
            this.isLoading = false;
            setTimeout(() => {
              setTimeout(() => {
                location.reload();
              }, 100);
              this.isLoading = false;
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(JSON.stringify(this.response_message));
          }
        });
      }
    },
    deleteBatchForm() {
      this.showDialogRemove = false;
      this.$store.dispatch("removeBatchRequest", this.single_batch.id).then(() => {
        if (this.success_message == "success") {
          location.reload();
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/** *** Pagination Menu Styling *** **/
.flex{
  display: flex;
  // justify-content: center;
  align-items: center;
}
.partner-details {
  display: grid;
  grid-gap: 10px;
  margin: 15px 0px;
  justify-content: center;
}

a {
  &:hover {
    text-decoration: none;
  }
}
.partner-logo {
  margin-top: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0px 10px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;

    &:hover {
      transform: scale(1.1);
    }
    // object-fit: cover;
  }
}
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
.gg-create-btn.add-edit-button {
  font-size: 13px;
  padding: 5px 10px;
}

.product-unit-form-edit {
  grid-template-columns: auto;
}

.custom-switch {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    margin: 0 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@media (max-width: 600px) {
  .product-unit-form-edit .order-detail-table-cost.unit ul li {
    display: block;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span {
    margin-bottom: 10px;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span button {
    margin: 0;
    margin-right: 20px;
  }
}
</style>
