<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <form class="modal-form" @submit.prevent="addOutlet">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <div class="main-form">
          <h2 class="form-title">Add New Outlet</h2>

          <label for="name">First Name *</label>
          <input type="text" class="product" v-model="firs_name" placeholder="First Name" />
          <span class="form-error" v-if="errors.firs_name">{{errors.firs_name}}</span>

          <label for="name">Middle Name</label>
          <input type="text" class="product" v-model="middle_name" placeholder="Middle Name" />

          <label for="name">Last Name *</label>
          <input type="text" class="product" v-model="  last_name" placeholder="Last Name" />
          <span class="form-error" v-if="errors.last_name">{{errors.last_name}}</span>

          <label for="email">Username *</label>
          <input type="text" v-model="username" placeholder="Username" />
          <span class="form-error" v-if="errors.username">{{errors.username}}</span>

          <label for="name">Address *</label>
          <input type="text" v-model="address" class="product" placeholder="Enter address here" />
          <span class="form-error" v-if="errors.address">{{errors.address}}</span>

          <label for="email">Email *</label>
          <input type="email" v-model="email" placeholder="Email" />
          <span class="form-error" v-if="errors.email">{{errors.email}}</span>

          <label for="name">Phone Number *</label>
          <input type="number" class="product" v-model="phone_number" placeholder="Phone Number" />
          <span class="form-error" v-if="errors.phone_number">{{errors.phone_number}}</span>

          <button type="submit" class="gg-create-btn">Add Outlet</button>
          <span class="form-success" v-if="success">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{success}}
          </span>
        </div>
      </form>
    </md-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {},
  computed: {
    ...mapState([
      "user_role_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
  },

  created() {
    this.listUserRole();
  },

  data() {
    return {
      firs_name: "",
      middle_name: "",
      last_name: "",
      username: "",
      address: "",
      email: "",
      phone_number: "",
      password: "",
      confirm_password: "",
      errors: [],
      showDialog: true,
      success: "",
    };
  },

  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions(["listUserRole"]),

    addOutlet() {
      this.errors = [];
      this.success = "";
      if (this.firs_name == "") {
        this.errors["firs_name"] = "First Name is required.";
        // this.$router.replace({name:"banks"});
      } else if (this.last_name == "") {
        this.errors["last_name"] = "Last Name is required.";
      } else if (this.username == "") {
        this.errors["username"] = "Username is required.";
      } else if (this.address == "") {
        this.errors["address"] = "Address is required.";
      } else if (this.email == "") {
        this.errors["email"] = "Email is required.";
      } else if (this.phone_number == "") {
        this.errors["phone_number"] = "Phone Number is required.";
      } else if (this.role == "") {
        this.errors["role"] = "Role is required.";
      } else {
        this.success = "Form submitted successfully.";
        let formData = new FormData();
        formData.append("first_name", this.firs_name);
        formData.append("last_name", this.last_name);
        formData.append("middle_name", this.middle_name);
        formData.append("username", this.username);
        formData.append("email", this.email);
        formData.append("phone_number", this.phone_number);
        formData.append("group", this.role);
        formData.append("password", this.password);
        formData.append("confirm_password", this.confirm_password);
        this.$store.dispatch("createUser", formData).then(() => {
          if (this.success_message == "success") {
            this.image = "";
            this.product_name = "";
            this.category_obj = "";
            this.unit_obj = "";
            this.quantity = "";
            this.price_in = "";
            this.price_out = "";
            this.availability_obj = "";
            // this.setSuccessMessage("");
          } else if (this.error_message == "errormessage") {
          }
        });
      }
    },

    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
  },
};
</script>

<style lang="scss">
</style>