<template>
  <div class="gg-table purchase-table">
    <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-fixed-header>
      <md-table-toolbar class="purchase-search">
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>

      <md-table-empty-state
        md-label="No users found"
        :md-description="`No user found for this '${search}' query. Try a different search term or create a new user.`"
      >
        <md-button class="md-primary md-raised" @click="newUser">Create New User</md-button>
      </md-table-empty-state>

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="SN " md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>
        <md-table-cell md-label="Product" md-sort-by="name">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Product ID" md-sort-by="productid">{{ item.productid }}</md-table-cell>
        <md-table-cell md-label="Category" md-sort-by="category">{{ item.category }}</md-table-cell>
        <md-table-cell md-label="Quantity" md-sort-by="quantity">{{ item.quantity }}</md-table-cell>
        <md-table-cell md-label="Price" md-sort-by="price">{{ item.price }}</md-table-cell>
        <md-table-cell md-label="Date" md-sort-by="date">{{ item.date }}</md-table-cell>
        <md-table-cell md-label="Contact of Branch" md-sort-by="contact">{{ item.contact }}</md-table-cell>
        <md-table-cell md-label="Address of Branch" md-sort-by="address">{{ item.address }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
const toLower = text => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)));
  }
  return items;
};

export default {
  name: "TableSearch",
  data: () => ({
    search: null,
    searched: [],
    users: [
      {
        id: 1,
        name: "Carrot",
        productid: "gg-101",
        category: "Vegetable",
        quantity: "2 K.G",
        price: "Rs 250",
        date: "3 mar ,2020",
        contact: "9807677430",
        address: "pulchowk,lalitpur"
      },
      {
        id: 2,
        name: "Cucumber",
        productid: "gg-102",
        category: "Vegetable",
        quantity: "2 K.G",
        price: "Rs 250",
        date: "3 mar ,2020",
        contact: "9807677430",
        address: "pulchowk,lalitpur"
      },
      {
        id: 3,
        name: "Potato",
        productid: "gg-103",
        category: "Vegetable",
        quantity: "2 K.G",
        price: "Rs 250",
        date: "3 mar ,2020",
        contact: "9807677430",
        address: "pulchowk,lalitpur"
      }
    ]
  }),
  methods: {
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = searchByName(this.users, this.search);
    }
  },
  created() {
    this.searched = this.users;
  }
};
</script>

<style lang="scss" scoped>
.purchase-table {
  margin-top: 0rem;
}
.md-field {
  max-width: 300px;
}
</style>