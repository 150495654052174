<template>
  <div class="member-table">
    <loading 
      :active.sync="isLoading" 
      :can-cancel="true">
    </loading>
    <md-table v-model="all_user_obj" md-sort="name" md-sort-order="asc">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item, index }"
        v-if="item.group!=null && item.group.id==1"
      >
        <md-table-cell md-label="S.N" md-numeric>{{ index + '.' }}</md-table-cell>
        <md-table-cell md-label="Name" md-sort-by="name">
          <router-link to="/member-detail-page">{{ item.full_name }}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Contact" md-sort-by="contact">{{ item.phone_number }}</md-table-cell>
        <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
        <md-table-cell md-label="Username" md-sort-by="address">{{ item.username }}</md-table-cell>
        <md-table-cell
          md-label="Status"
          md-sort-by="status"
          :class="'status-' + item.status"
        >{{ item.active }}</md-table-cell>
        <md-table-cell md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item @click="clickEditDialog(item)">Edit</md-menu-item>
                <md-menu-item @click="openDialogRemove = !openDialogRemove">Remove</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <!-- <DropdownBtn /> -->
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div>
      <md-dialog :md-active.sync="openDialog" v-if="openDialog" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span class="material-icons error-icon" @click="openDialog = false">highlight_off</span>
          </md-dialog-actions>
          <div class="main-form">
            <h2 class="form-title">Edit Profile</h2>
            
            <label for="name">First Name </label>
            <input type="text" class="name" v-model="single_form.first_name"  placeholder="First Name" />
            <span class="form-error" v-if="errors.first_name">{{ errors.first_name }}</span>

            <label for="name">Middle Name </label>
            <input type="text" class="name" v-model="single_form.middle_name" placeholder="Middle Name" />

            <label for="name">Last Name </label>
            <input type="text" class="name" v-model="single_form.last_name" placeholder="Last Name" />
            <span class="form-error" v-if="errors.last_name">{{ errors.last_name }}</span>

            <label for="dob">DOB</label>
            <input type="date" class="dob" v-model="single_form.dob" placeholder="Enter DOB Here" />
            <span class="form-error" v-if="errors.dob">{{ errors.dob }}</span>

            <label for="city">State</label>
            <input type="text" class="city" v-model="single_form.state" placeholder="Enter State Here" />
            <span class="form-error" v-if="errors.state">{{ errors.state }}</span>
            
            <label for="city">City</label>
            <input type="text" class="city" v-model="single_form.city" placeholder="Enter City Here" />
            <span class="form-error" v-if="errors.city">{{ errors.city }}</span>

            <label for="street">Street</label>
            <input type="text" class="street" v-model="single_form.street_address" placeholder="Enter Street Here" />
            <span class="form-error" v-if="errors.street_address">{{ errors.street_address }}</span>

            <button v-if="!isLoading" type="submit" class="gg-create-btn" @click="updateMember">Save changes</button>
            <img v-else src="../assets/img/loader.gif" width="64" height="64" alt="Greengrowth Logo" /> 
          </div>
        </div>
      </md-dialog>
    </div>

    <ModalRemove v-if="openDialogRemove" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalRemove from "./ModalRemove.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "UserTable",
  components: {
    ModalRemove
  },
  computed: {
    ...mapState(["all_user_obj", "success_message", "error_message", "response_message",])
  },

  created() {
    this.listUser();
  },
  data(){
    return{
      isLoading: false,
      openDialog: false,
      openDialogRemove: false,
      single_user: '',
      single_form: {
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        state: '',
        city: '',
        street_address: ''
      },
      currentLocation: JSON.parse(localStorage.getItem("currentLocation")),
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      boolean: true,
      success: "",
      errors: [],
    }
  },
  methods: {
    ...mapActions(["listUser"]),
    clickEditDialog(userObj){
      this.openDialog = true
      this.single_user = userObj

      this.single_form.first_name = userObj.first_name
      this.single_form.middle_name = userObj.middle_name
      this.single_form.last_name = userObj.last_name
      this.single_form.dob = (userObj && userObj.user_info) ? userObj.user_info.dob : ''
      this.single_form.state = (userObj && userObj.user_info) ? userObj.user_info.state : ''
      this.single_form.city = (userObj && userObj.user_info) ? userObj.user_info.city : ''
      this.single_form.street_address = (userObj && userObj.user_info) ? userObj.user_info.street_address : ''
    },
    updateMember(){
      this.errors = []
      
      if(this.single_form.first_name == ""){
        this.errors['first_name'] = "First name required."
      }else if(this.single_form.last_name == ""){
        this.errors['last_name'] = "Last name required."
      }else if(this.single_form.dob == ""){
        this.errors['dob'] = "Date of Birth (DOB) required."
      }else if(this.single_form.state == ""){
        this.errors['state'] = "State required."
      }else if(this.single_form.city == ""){
        this.errors['city'] = "City required."
      }else if(this.single_form.street_address == ""){
        this.errors['street_address'] = "Street Address required."
      }else{
        this.isLoading = true
        
        let changeUserObj = {
          "first_name": this.single_form.first_name,
          "middle_name": this.single_form.middle_name,
          "last_name": this.single_form.last_name,
          "image": null,
          "group": this.single_user.group.id,
          "dob": this.single_form ? this.single_form.dob : "2000-01-01",
          "nationality": 1,
          "country": 1,
          "city": this.single_form ? this.single_form.city : "Kathmandu",
          "state": this.single_form ? this.single_form.state : "Bagmati Pradesh",
          "street_address": this.single_form ? this.single_form.street_address : "N/a",
          "latitude": this.single_user.user_info ? this.single_user.user_info.latitude : this.currentLocation ? this.currentLocation.latitude  : "27.700000762939453",
          "longitude": this.single_user.user_info ? this.single_user.user_info.longitude : this.currentLocation ? this.currentLocation.longitude : "85.33329772949219",
          "id": this.single_user.id
        }

        console.log(changeUserObj)
        
        this.$store.dispatch("updateUserDetail", changeUserObj).then(() => {
          if (this.success_message == "success") {
            this.success = "User Detail Updated Successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(JSON.stringify(this.response_message));
          }
        });

      }
    },
  }
};
</script>
<style lang='scss'>
// This is temporarily managed as the class depends on the data it receives
.status-Active {
  color: #4ba069;
}
.status-Inactive {
  color: #ff8993;
}
</style>
