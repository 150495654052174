export default {
  title: "** GreenGrowth **",
  token: window.localStorage.getItem("token"),
  order_obj: [],
  dashboard_count: [],
  delivery_dash_count: [],
  outlet_dash_count: [],
  user_dash_count: [],
  profile_obj: [],
  doughnut_visualization: [],
  product_category: [],
  list_visualization: [],
  success_message: [],
  error_message: [],
  response_message: [],
  product_obj: [],
  product_new_obj: [],
  product_unit: [],
  product_brand: [],
  product_site: [],
  product_crop: [],
  product_inventry: [],
  banner_obj: [],
  partner_obj: [],
  country_obj: [],
  user_role_obj: [],
  all_user_obj: [],
  product_request: [],
  task_obj: [],
  delivery_obj: [],
  notification_obj: [],
  product_rating_obj: [],
  comment_obj: [],
  direction_obj: [],
  multiple_unit_obj: [],
  batch_obj: []
};
