<template>
  <div class="gg-table request-table">

    <div class="table-top-nav">
      <md-tabs>
        <md-tab md-label="All" @click="tabIndex = 0"></md-tab>
        <md-tab md-label="Pending" @click="tabIndex = 1"></md-tab>
        <md-tab md-label="Approved" @click="tabIndex = 2"></md-tab>
        <md-tab md-label="Rejected" @click="tabIndex = 3"></md-tab>
      </md-tabs>
    </div>
    <md-table v-model="filterTableData.slice(count_start,count_end)" md-sort="name" md-sort-order="asc">
      <md-table-row slot="md-table-row" slot-scope="{ item, index }">
        <md-table-cell md-label="S.N " md-sort-by="id" md-numeric>{{ (index+1)+((current_page-1)*10) }}.</md-table-cell>
        <md-table-cell md-label="Product" md-sort-by="name">{{ item.product.name }}<br/>({{ item.product.category.name }})</md-table-cell>
        <md-table-cell md-label="Quantity" md-sort-by="quantity">{{ item.quantity }}</md-table-cell>
        <md-table-cell md-label="Price" md-sort-by="price">Rs.{{ item.product.price_out }}</md-table-cell>
        <md-table-cell md-label="Author" md-sort-by="outlet">{{ item.request_by.full_name }}</md-table-cell>
        <md-table-cell md-label="Request to" md-sort-by="outlet">{{ item.request_to.full_name }}</md-table-cell>
        <md-table-cell md-label="Status" md-sort-by="status">
          <md-button v-if="item.request_status=='Requested'" class="md-raised">Pending</md-button>
          <md-button v-else-if="item.request_status=='Accepted'" class="md-raised">Approved</md-button>
          <md-button v-else-if="item.request_status=='Rejected'" class="md-raised">Rejected</md-button>
        </md-table-cell>
        <md-table-cell md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item @click="openUpdateDailog(item)" v-if="profile_obj.admin || item.request_to.id == profile_obj.id">Update</md-menu-item>
                <md-menu-item :to="{name: 'request-detail', params: {id: item.id}}">View Detail</md-menu-item>
                <md-menu-item v-if="item.request_status=='Requested' && (profile_obj.admin || profile_obj.staff|| item.request_by.id == profile_obj.id)" @click="openRemoveDialog(item.id)">Remove</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
    
    <div v-if="filterTableData && filterTableData.length">
      <ul class="nav-pagination">
        <li><router-link :to="{name: current_route, query:{page: current_page <= 1 ? 1 : current_page-1}}">&laquo;</router-link></li>

        <li v-for="(page, index) in Math.ceil(filterTableData.length/page_sequence)" :class="current_page == page ? 'active' : ''" :key="index">
          <router-link :to="{name: current_route, query:{page: page}}">{{ page }}</router-link>
        </li>
        
        <li><router-link :to="{name: current_route, query:{page: current_page >= Math.ceil(filterTableData.length/page_sequence) ? Math.ceil(filterTableData.length/page_sequence) : current_page+1}}">&raquo;</router-link></li>
      </ul>
    </div>

    <div>
      <md-dialog :md-active.sync="showUpdateDialog" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span
              class="material-icons error-icon"
              @click="showUpdateDialog = false"
              >highlight_off</span
            >
          </md-dialog-actions>
          <form class="main-form" @submit.prevent="updateRequst">
            <h2 class="form-title">Update Request Status</h2>

            <label for="product">Request Status *</label>
            <select v-model="request_status">
              <option value="">-- Select Status --</option>
              <option v-for="(value, key) in requestStatus" :key="key" :value="value">{{ value }}</option>
            </select>
            <span v-if="errors.request_status" style="color: red">{{
              errors.request_status
            }}</span>

            <span class="form-success" v-if="success"
              ><strong>Request Status Changed Successfully.</strong></span
            >
            <button type="submit" class="gg-create-btn">Modify</button>
          </form>
        </div>
      </md-dialog>
    </div>

    <div>
      <md-dialog :md-active.sync="showRemoveDialog" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span
              class="material-icons error-icon"
              @click="showRemoveDialog = false"
              >highlight_off</span
            >
          </md-dialog-actions>
            <h2 class="form-title">Remove Request</h2>

            <p>Are you sure to remove this request?</p>

            <span class="form-success" v-if="success"><strong>Request Remove Successfully.</strong></span>
            
            <md-dialog-actions>
              <md-button class="cancel-btn" @click="showRemoveDialog = false"
                >Cancel</md-button>
              <md-button class="remove-btn" @click="removeRequest(request_id)"
                >Remove</md-button>
            </md-dialog-actions>

        </div>
      </md-dialog>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: "TableRequest",

  computed: {
    ...mapState(["profile_obj", "product_request", "success_message", "error_message", "response_message",]),
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: {page: 1}
      })
      let table_data = this.product_request;
      if (this.tabIndex == 1) {
        table_data = table_data.filter((row) => {
          return row.request_status == "Requested";
        });
      }
      if (this.tabIndex == 2) {
        table_data = table_data.filter((row) => {
          return row.request_status == "Accepted";
        });
      }
      if (this.tabIndex == 3) {
        table_data = table_data.filter((row) => {
          return row.request_status == "Rejected";
        });
      }
      return table_data;
    },
  },
  created() {
    // this.searched = this.users;
    this.listProductRequests();
    this.listProfile();
    // Pagination
    this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
    this.count_start = (this.current_page-1)*this.page_sequence
    this.count_end = this.current_page*this.page_sequence
  },
  watch: {
    '$route.query.page'(){
      this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
      this.count_start = (this.current_page-1)*this.page_sequence
      this.count_end = this.current_page*this.page_sequence
    }
  },
  data(){
    return{
      search: null,
      tabIndex: 0,
      activeTab: "",
      tableTabs: [
        {
          title: "Pending",
        },
        {
          title: "Approved",
        },
        {
          title: "Rejected",
        },
      ],
      request_id: "",
      request_status: null,
      requestStatus: ['Accepted','Rejected'],
      showUpdateDialog: false,
      showRemoveDialog: false,
      single_request: [],
      success: false,
      errors: [],
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
    }
  },
  methods: {
    ...mapActions(["listProductRequests", "listProfile"]),
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.product_request = searchByName(this.product_request, this.search);
    },
    openUpdateDailog(requestObj) {
      this.showUpdateDialog = true
      this.single_request = requestObj
    },
    updateRequst(){
      this.errors = []
      if (!this.request_status) {
        this.errors['request_status'] = "Status Required."
      }else{
        
        let requestObj = {
          "request_status": this.request_status,
          "id": this.single_request.id
        }
        console.log("RO", requestObj)

        this.$store.dispatch("updateProductRequestStatus", requestObj).then(() => {
          if (this.success_message == "success") {
            this.success = true
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            this.errors['submit_error'] = this.response_message;
          }
        });

      }
    },
    openRemoveDialog(requestId) {
      this.request_id = requestId;
      this.showRemoveDialog = true;
    },
    removeRequest(requestId){
      this.$store.dispatch("removeProductRequest", requestId)
      .then(() => {
        if (this.success_message == "success") {
          this.success = true;
          setTimeout(function() {
            location.reload();
          }, 1000);
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    },
  },
};
</script>

<style type="text/css" scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination{list-style: none; float: left; width: 100%; padding: 10px;}
.nav-pagination li{float: left; margin: 0 5px;}
.nav-pagination li.active{}
.nav-pagination li a{padding: 3px 8px; background: #e6e6e6; border: 1px #3b844a solid; border-radius: 6px; color: #3b844a; text-decoration: none; font-size: 14px; transition-duration: 1s;}
.nav-pagination li a:hover{ background: #3b844a; color: #ffffff; transition-duration: 1s;}
.nav-pagination li.active a{background: #3b844a; color: #ffffff;}
</style>

<style lang="scss" scoped>
.md-field {
  max-width: 300px;
}
</style>
