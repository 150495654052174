<template>
  <div class="home">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">home</md-icon>
        <h1>Dashboard</h1>
      </md-toolbar>
      <Cards />
      <h2>Analytics</h2>
      <ChartAnalytics v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'" />
      <div v-if="profile_obj.group.name == 'Delivery User'">
        <Deliveries />
        <TableDeliveries />
      </div>
      <div v-else>
        <TableOrder />
      </div>
    </div>
      <!-- <FoodMiles /> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
// @ is an alias to /src
import Cards from "@/components/Cards.vue";
import ChartAnalytics from "@/components/ChartAnalytics.vue";
import TableOrder from "@/components/TableOrder.vue";
import Deliveries from "./Deliveries.vue";
// import FoodMiles from "@/components/FoodMiles.vue";

export default {
  name: "Home",
  components: {
    Cards,
    TableOrder,
    Deliveries,
    ChartAnalytics,
    // FoodMiles,
  },
  data(){
    return {
      currentLocation: JSON.parse(localStorage.getItem('currentLocation'))
    }
  },
  computed: {
    ...mapState(["token", "profile_obj"])
  },
  created() {
    this.listProfile();
  },
  methods: {
    ...mapActions([
      "listProfile"
    ]),
  },
  mounted: function() {
    var token = window.localStorage.getItem("token");
    if (token == undefined || token == "") {
      this.$router.replace("/logout");
    }
    //Get IP and Get Lat, Long 
    fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
      if (ip) {
        if (!localStorage.getItem('currentIPAddress') || localStorage.getItem('currentIPAddress') != ip) {
          localStorage.setItem('currentIPAddress', ip);
          fetch('http://api.ipstack.com/'+ip+'?access_key=31be659c99f14d9dccb7582b379382d1&format=1')
          .then((response) => response.json())
          .then((data) => {
            this.currentLocation = data
            localStorage.setItem('currentLocation', JSON.stringify(data))
          })
        }else{
        this.currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
        }
      }
    });
  },


};
</script>
<style lang='scss'>
</style>
