<template>
  <div class="contents">
    <h2>Food Miles</h2>
    <p><strong>Order ID:</strong> {{ order_obj.id }}</p>
    <p><strong>Order By:</strong> {{ order_obj.author.full_name }}</p>
    <p><strong>Product ID:</strong> {{ single_product.id }}</p>
    <p><strong>Product Name:</strong> {{ single_product.name }}</p>
    <aside class="map-section">
      <div id="map"></div>
    </aside>
  </div>
</template>

<script type="text/javascript">
import Baato from "@klltech/baato-js-client";
import mapboxgl from "mapbox-gl";
import { mapState, mapActions } from "vuex";
export default {
  name: "food-miles",
  components: {
    //
  },
  computed: {
    ...mapState(["all_user_obj", "userinfo_obj", "order_obj", "product_obj"])
  },
  data() {
    return {
      profile_data: [],
      order_id: "",
      product_id: "",
      order_data: [],
      single_product: [],
      start_latitude: "27.689152",
      start_longitude: "85.337372",
      end_latitude: "27.706327",
      end_longitude: "85.325004",
      startLatLongString: "",
      endLatLongString: "",
      map: ""
    };
  },
  created() {
    this.listUser();
    this.listProducts();
    this.order_id = this.$route.params.oid;
    this.product_id = this.$route.params.pid;
  },
  watch: {
    all_user_obj: function() {
      this.profile_data = this.all_user_obj.find(evt => evt.id == this.$route.params.oid);
    },
    product_obj: function() {
      // console.log(this.start_latitude, this.start_longitude, "hello");
      console.log(this.single_product.author.user_info.latitude, "latitude");
      this.single_product = this.product_obj.find(evt => evt.id == this.product_id);
      this.start_latitude = this.single_product.author.user_info.latitude;
      this.start_longitude = this.single_product.author.user_info.longitude;
    }
  },
  mounted: function() {
    // console.log(this.product_obj, "product obje");
    let authorId = "";
    this.$store.dispatch("listSingleOrder", this.order_id).then(() => {
      if (this.success_message == "success") {
        this.state_change_text = "Order Listed";
      }
    });
    let startLatLongString = this.start_latitude + "," + this.start_longitude;
    let endLatLongString = this.end_latitude + "," + this.end_longitude;
    console.log("Start", startLatLongString);
    console.log("End", endLatLongString);
    // For map
    var map = new mapboxgl.Map({
      container: "map",
      style:
        "https://api.baato.io/api/v1/styles/breeze?key=bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu",
      center: [85.3230575, 27.695612],
      zoom: 13
    });
    map.on("load", function() {
      new Baato.Routing({
        key: "bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu"
      })
        .addPoints([startLatLongString, endLatLongString])
        .setVehicle("bike")
        .getBest()
        .setBaseUrl(`https://api.baato.io/api`)
        .doRequest()
        .then(response => {
          response.forEach(item => {
            map.addSource("route", {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: item.geojson.coordinates
                }
              }
            });
            map.addLayer({
              id: "route",
              type: "line",
              source: "route",
              layout: {
                "line-join": "round",
                "line-cap": "round"
              },
              paint: {
                "line-color": "#008148",
                "line-width": 8
              }
            });
          });
        });
    });
  },
  methods: {
    ...mapActions(["listUser", "listProducts"]),
    getProductById(product_id) {
      let thisProduct = this.product_obj;
      thisProduct = thisProduct.find(evt => evt.id == product_id);
      return thisProduct;
    }
  }
};
</script>

<style type="text/css" scoped>
#map {
  height: 350px !important;
}
</style>
