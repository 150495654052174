<template>
  <form class="main-form" @submit.prevent="addSource">
    <h2 class="form-title">Add New Source</h2>

    <label for="name">Name of Source*</label>
    <input type="text" class="product" v-model="name" placeholder="First Name" />
    <span class="form-error" v-if="errors.name">{{errors.name}}</span>

    <label for="vat">PAN/VAT Number *</label>
    <input
      name="vat"
      type="number"
      v-model="vat_pan_number"
      class="product"
      placeholder="Enter VAT number"
    />
    <span class="form-error" v-if="errors.vat_pan_number">{{errors.vat_pan_number}}</span>

    <label for="name">Address *</label>
    <input type="text" class="product" placeholder="Enter your address" v-model="address" />
    <span class="form-error" v-if="errors.address">{{errors.address}}</span>

    <label for="email">Contact Email *</label>
    <input type="email" v-model="email" placeholder="Email" />
    <span class="form-error" v-if="errors.email">{{errors.email}}</span>

    <label for="name">Contact Number *</label>
    <input type="number" class="product" v-model="phone_number" placeholder="Phone Number" />
    <span class="form-error" v-if="errors.phone_number">{{errors.phone_number}}</span>

    <button type="submit" class="gg-create-btn">Add Source</button>
    <span class="form-success" v-if="success">
      <md-icon class="md-size-2x">check_circle_outline</md-icon>
      {{success}}
    </span>
  </form>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState([
      "user_role_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
  },

  created() {
    this.listUserRole();
  },

  data() {
    return {
      name: "",
      vat_pan_number: "",
      address: "",
      email: "",
      phone_number: "",
      errors: [],
      success: "",
    };
  },

  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions(["listUserRole"]),

    addSource() {
      this.errors = [];
      this.success = '';
      if (this.name == "") {
        this.errors["name"] = "Name of source required.";
      } else if (this.vat_pan_number == "") {
        this.errors["vat_pan_number"] = "VAT/PAN number required.";
      } else if (this.vat_pan_number.length !== 9 ) {
        this.errors["vat_pan_number"] = "VAT/PAN number should be of 9 digits"
      } else if (this.address == "") {
        this.errors["address"] = "Address is required.";
      } else if (this.email == "") {
        this.errors["email"] = "Email is required.";
      } else if (this.phone_number == "") {
        this.errors["phone_number"] = "Phone Number required.";
      } else {
        this.success = "Form submitted successfully.";
      }
    },
  },
};
</script>

<style lang="scss" >
</style>
