<template>
  <div class="container">
    <div class="form-modal">
    <div class="login-form">
      <h1>Green Growth User Validation</h1>

        <div class='loader'>
            <div class='loader--dot'></div>
            <div class='loader--dot'></div>
            <div class='loader--dot'></div>
            <div class='loader--dot'></div>
            <div class='loader--dot'></div>
            <div class='loader--dot'></div>
            <div class='loader--text'></div>
        </div>

        <p>Please wait we are processing.</p>
    </div>
    </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const axios = require("axios");

export default {
  name: "ValidateUser",
    data() {
        return {
            token: '',
            status: this.$route.query.status,
            date: this.$route.query.date,
            page: this.$route.query.page,
            errors: [],
        };
    },
  mounted() {
    console.log("Starting");
    if(this.$route.query.value && this.$route.query.value.length >= 64){

        if(window.localStorage.getItem("token") === null || window.localStorage.getItem("token") == ""){
            window.localStorage.setItem("token", "");
            //
            var formData = { token: this.$route.query.value };
            console.log("TkN: ", this.$route.query.value)
            axios
            .post("token/verify", formData)
            .then((response) => {
                if(response.status == 200){
                    window.localStorage.setItem("token", response.data.token);
                    this.setToken(response.data.token);
                    axios.defaults.headers.common["authorization"] =
                        "JWT " + response.data.token;
                    var redirectPage = this.page == "" ? 'homepage' : this.page
                    this.$router.push({
                        name: redirectPage
                    });
                }else{
                    window.history.back()
                }
            })
            .catch((error) => {
                window.history.back()
            });
            
            
        }else{
            window.localStorage.removeItem("token");
            window.localStorage.setItem("token", "");
            setTimeout(() => {
                location.reload();
            }, 100); 
        }

    }else{
        window.history.back()
    } // else
    
  },
  
  computed: {
    ...mapState([
      "success_message",
      "error_message",
      "response_message",
    ]),
  },
  methods: {
    ...mapMutations(["setToken"]),
  },
};
</script>

<style scoped>

.container {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
}
.form-modal .login-form h1{
    margin-top: -12px;
    line-height: 32px;
    text-align: center;
}
.loader:before{
    background-color: rgb(255 255 255 / 70%);
}
.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #8cc759;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}
.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
</style>
