<template>
  <form class="main-form" @submit.prevent="addMember">
    <h2 class="form-title">Add New Member</h2>

    <label for="name">First Name *</label>
    <input type="text" class="product" v-model="firs_name" placeholder="First Name" />
    <span class="form-error" v-if="errors.firs_name">{{errors.firs_name}}</span>

    <label for="name">Middle Name</label>
    <input type="text" class="product" v-model="middle_name" placeholder="Middle Name" />

    <label for="name">Last Name *</label>
    <input type="text" class="product" v-model="last_name" placeholder="Last Name" />
    <span class="form-error" v-if="errors.last_name">{{errors.last_name}}</span>

    <label for="email">Username *</label>
    <input type="text" class="product" v-model="username" placeholder="Username" />
    <span class="form-error" v-if="errors.username">{{errors.username}}</span>

    <label for="email">Email *</label>
    <input type="email" class="product" v-model="email" placeholder="Email" />
    <span class="form-error" v-if="errors.email">{{errors.email}}</span>

    <label for="name">Phone Number *</label>
    <input type="number" class="product" v-model="phone_number" placeholder="Phone Number" />
    <span class="form-error" v-if="errors.phone_number">{{errors.phone_number}}</span>

    <label for="name">Password *</label>
    <input type="password" class="product" v-model="password" placeholder="Password" />
    <span class="form-error" v-if="errors.password">{{errors.password}}</span>

    <label for="name">Confirm Password *</label>
    <input type="password" class="product" v-model="confirm_password" placeholder="Confirm Password" />
    <span class="form-error" v-if="errors.confirm_password">{{errors.confirm_password}}</span>
    <span class="form-error" v-if="errors.password_not_match">{{errors.password_not_match}}</span>
    <span class="form-error" v-if="errors.password_length">{{errors.password_length}}</span>

    <label for="role">Role *</label>
    <select class="product" v-model="role">
      <option value disabled>Select Role</option>
      <option v-for="(role_obj,index) in user_role_obj" :key="index" v-if="role_obj.id != 1 && role_obj.id != 2" :value="role_obj.id">{{ role_obj.id == 4 ? 'Source' : role_obj.name}}</option>
    </select>
    <span class="form-error" v-if="errors.role">{{errors.role}}</span>

    <span style="color: red; font-weight: bold;" v-if="errors.user_registration_error"><br/>{{ errors.user_registration_error }}</span>

    <button type="submit" class="gg-create-btn">Add Member</button>
    <span class="form-success" v-if="success">
      <md-icon class="md-size-2x">check_circle_outline</md-icon>
      {{success}}
    </span>

    <input type="hidden" v-model="state_change_text">
  </form>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
  },
  computed: {
    ...mapState(["user_role_obj", "success_message", "error_message", "response_message"])
  },

  created() {
    this.listUserRole();
  },

  data() {
    return {
      firs_name:'',
      middle_name:'',
      last_name:'',
      username:'',
      email:'',
      phone_number:'',
      password:'',
      confirm_password:'',
      errors: [],
      role: '',
      valid_password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$/,
      valid_username: /^\S+\w{6,32}\S{1,}$/,
      state_change_text: "",
      success: '',
    };
  },

  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions(["listUserRole"]),

    addMember(){
      this.errors = [];
      this.success = '';
      if(this.firs_name==''){
        this.errors['firs_name']="First Name is required."
      } else if(this.last_name==''){
        this.errors['last_name']="Last Name is required."
      } else if(this.username==''){
        this.errors['username']="Username is required."
      } else if(!this.valid_username.test(this.username)){
        this.errors['username']="Username minimum 6 - 32 character with no special characters."
      } else if(this.email==''){
        this.errors['email']="Email is required."
      } else if(this.phone_number==''){
        this.errors['phone_number']="Phone Number is required."
      } else if(this.password==''){
        this.errors['password']="Password is required."
      } else if(!this.valid_password.test(this.password)) {
        this.errors["password"] = "Password Must contain Capital and Small Letter, Number and Special Character.";
      } else if(this.confirm_password==''){
        this.errors['confirm_password']="Confirm Password is required."
      } else if(this.password != this.confirm_password){
        this.errors['password_not_match']="Confirm Password not match."
      } else if(this.role==''){
        this.errors['role']="Role is required."
      } else{
        let formData = new FormData();
        formData.append('first_name', this.firs_name);
        formData.append('last_name', this.last_name);
        formData.append('middle_name', this.middle_name);
        formData.append('username', this.username);
        formData.append('email', this.email);
        formData.append('phone_number', this.phone_number);
        formData.append('group', this.role);
        formData.append('password', this.password);
        formData.append('confirm_password', this.confirm_password);
        formData.append('active', true);

         for (var pair of formData.entries()) {
           console.log(pair[0]+ ', ' + pair[1]); 
         }

        this.$store.dispatch("createUser", formData).then(() => {
          if(this.success_message=='success'){
            this.success = "User Registered Successfully.";
            setTimeout(function() {
              location.reload()
            }, 1000);
          }else if(this.error_message=="errormessage"){
            this.state_change_text = "SorrySorry"
            this.errors['user_registration_error'] = this.response_message
          }
        })
      }//else
    },
  }
};
</script>
<style type="text/css" scoped>
/* .main-form select.product{ background-color: #E7F4F8; } */
</style>