<template>

	<div id="app">
		
	</div>

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: "Logout",
  created() {
    this.listProfile();
  },
  mounted: function() {
		window.localStorage.setItem("token","");
		this.setToken('');
		let logoutUrl = process.env.VUE_APP_USER_PANEL+"logout"
		window.location.replace(logoutUrl);
		// if(this.profile_obj.group.name == 'Web User' || 
		// 	this.profile_obj.group.name == 'Mobile User'){
		// 		let logoutUrl = process.env.VUE_APP_USER_PANEL+"logout"
		// 		window.location.replace(logoutUrl);
		// }else{
		// 	this.$router.replace({
		// 		name: 'login'
		// 	});
		// }
	},
	computed: {
		...mapState(["profile_obj"])
	},
	methods:{
		...mapActions(["listProfile"]),
		...mapMutations(['setToken'])
	}
};
</script>
