<template>
  <!-- <div class="login-form login-form--hidden"> -->
  <div class="form-modal">
    <div class="login-form">
      <h1>Welcome to Green Growth</h1>
      <a :href="mainStoreUrl"><i class="fas fa-cart-plus"></i><h2>View Store </h2></a>
      <div class="login-block">
        <div class="login-contents" v-if="openLogin">
          <span style="color: red" v-if="errors.auth">{{ errors.auth }}</span>
          <span></span>
          <div class="form-heading">
            <h2 class="heading">Login</h2>
          </div>

          <div class="form">
            <form class="custom-form">
              <div class="login-username">
                <input
                  type="email"
                  id="login-username"
                  v-model="email"
                  placeholder="Email"
                  @keyup.enter="processLogin"
                  required
                />
                <label for="login-username">Email</label>
                <span style="color: red" v-if="errors.email_required">{{
                  errors.email_required
                }}</span>
              </div>

              <div class="login-password">
                <input
                  :type="password_type"
                  id="login-password"
                  placeholder="Password"
                  v-model="password"
                  @keyup.enter="processLogin"
                />
                <label for="login-password">Password</label>
                <i
                  :class="'fas ' + password_eye + ' move_to_up'"
                  @click="showHidePassword()"
                ></i>
                <span style="color: red" v-if="errors.password_required">{{
                  errors.password_required
                }}</span>
              </div>
            </form>
          </div>
          <div class="submit-area">
            <button class="submit" @click="processLogin">
              <span>Log In</span>
            </button>
            <button
              class="button-link-type"
              @click="(openPasswordReset = true), (openLogin = false)"
            >
              <span>Forgot your password?</span>
            </button>
          </div>
        </div>

        <div class="forgot-password-contents" v-if="openPasswordReset">
          <div v-if="errors.reset_password_error">
            <p style="color: red; font-weight: bold">
              {{ errors.reset_password_error }}
            </p>
          </div>

          <div class="form-heading">
            <h1 class="heading">Forget Password</h1>
          </div>

          <div class="form">
            <form class="custom-form">
              <label>Enter your email to reset password</label>
              <input
                type="email"
                v-model.trim="reset_email"
                placeholder="Your email address"
                autofocus="autofocus"
              />
              <span style="color: red" v-if="errors.reset_email">{{
                errors.reset_email
              }}</span>
            </form>
          </div>
          <div class="submit-area">
            <button
              class="submit"
              @click="(openLogin = true), (openPasswordReset = false)"
            >
              <span>Back</span>
            </button>
            <button class="submit" @click="sendResetEmailForm">
              <span>Submit</span>
            </button>
          </div>
        </div>

        <div class="reset-code" v-if="openReset">
          <div class="form-heading">
            <h2 class="heading">Set A New Password</h2>
          </div>

          <div v-if="errors.change_password_error">
            <p style="color: red; font-weight: bold">
              {{ errors.change_password_error }}
            </p>
          </div>

          <div class="form">
            <form class="custom-form">
              <p style="color: green">
                We sent the reset password <strong>code</strong> to your email,
                please check and use it.
              </p>
              <div class="new-password">
                <input
                  type="text"
                  id="rest-code"
                  v-model.trim="reset_code"
                  placeholder="Enter Code"
                  minlength="6"
                  maxlength="6"
                />
                <label for="rest-code">Code</label>
                <span style="color: red" v-if="errors.reset_code">{{
                  errors.reset_code
                }}</span>
              </div>

              <div class="new-password site-login-password">
                <input
                  :type="new_password_type"
                  id="new-password"
                  v-model.trim="reset_password"
                  placeholder="Enter New Password"
                />
                <label for="new-password">New Password</label>
                <i
                  :class="'fas ' + new_password_eye + ' move_to_up'"
                  @click="showHideNewPassword"
                ></i>
                <span style="color: red" v-if="errors.reset_password">{{
                  errors.reset_password
                }}</span>
              </div>

              <div class="confirm-password">
                <input
                  :type="conf_password_type"
                  id="confirm-password"
                  placeholder="Confirm Password"
                  v-model.trim="reset_cofirm_password"
                />
                <label for="confirm-password">Confirm Password</label>
                <i
                  :class="'fas ' + conf_password_eye + ' move_to_up'" class="site-login-password"
                  @click="showHideConfPassword"
                ></i>
                <span style="color: red" v-if="errors.reset_cofirm_password">{{
                  errors.reset_cofirm_password
                }}</span>
              </div>
            </form>
          </div>
          <div class="submit-area">
            <button
              class="submit"
              @click="(openReset = false), (openPasswordReset = true)"
            >
              <span>Back</span>
            </button>
            <button class="submit" @click="changePasswordNow">
              <span>Submit</span>
            </button>
          </div>
        </div>

        <!-- <div class="confirm-password-contents" v-if="openConfirm">
          <span style="color:red" v-if="errors.auth">{{errors.auth}}</span>
          <span></span>
          <div class="form-heading">
            <h2 class="heading">Set A New Password</h2>
          </div>

          <div class="form">
            <form class="custom-form">
              <div class="new-password">
                <input type="password" id="new-password" placeholder="Enter New Password" />
                <label for="new-password">New Password</label>
                <span style="color:red" v-if="errors.password_required">{{errors.password_required}}</span>
              </div>

              <div class="confirm-password">
                <input type="password" id="confirm-password" placeholder="Confirm Password" />
                <label for="confirm-password">Confirm Password</label>
                <span style="color:red" v-if="errors.password_required">{{errors.password_required}}</span>
              </div>
            </form>
          </div>
          <div class="submit-area">
            <button class="submit">
              <span>Confirm</span>
            </button>
            <button class="submit" @click="openConfirm = false, openReset = true">
              <span>Back</span>
            </button>
          </div>
        </div> -->
      </div>
      <router-link to="/register" class="registration-link"
        >Don't have an account? Register</router-link
      >
    </div>
    <input type="hidden" v-model.trim="not_required_data" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const axios = require("axios");

export default {
  name: "SiteLogin",

  mounted() {
    var token = window.localStorage.getItem("token");
    if (token != undefined && token != "") {
      this.$router.push("/dashboard");
    }
  },
  data() {
    return {
      email: "",
      password: "",
      openPasswordReset: false,
      openConfirm: false,
      openLogin: true,
      openReset: false,
      reset_email: "",
      reset_code: "",
      reset_password: "",
      reset_cofirm_password: "",
      not_required_data: "",
      emailRegx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      passwordRegx: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
      password_reset_success: false,
      errors: [],
      password_eye: "fa-eye-slash",
      password_type: "password",
      new_password_eye: "fa-eye-slash",
      new_password_type: "password",
      conf_password_eye: "fa-eye-slash",
      conf_password_type: "password",
      mainStoreUrl: process.env.VUE_APP_USER_PANEL,
    };
  },
  computed: {
    ...mapState(["success_message", "error_message", "response_message"]),
  },
  methods: {
    ...mapMutations(["setToken"]),

    processLogin() {
      if (this.email == "") {
        this.errors["email_required"] = "Email required.";
      } else if (this.password == "") {
        this.errors["password_required"] = "Password required.";
      } else {
        var formData = { email: this.email, password: this.password };
        axios
          .post("token/obtain", formData)
          .then((response) => {
            window.localStorage.setItem("token", response.data.token);
            this.setToken(response.data.token);
            axios.defaults.headers.common["authorization"] =
              "JWT " + response.data.token;
            this.$router.push("/dashboard");
            //this.$router.replace("/dashboard");
            // this.$router.replace("/");
          })
          .catch((error) => {
            if (error) {
              this.not_required_data = "Invalid email or password.";
              this.errors["auth"] = "Invalid email or password.";
              // this.showAuthSnackbar = true
              // this.showSpinner = false
            }
            this.errored = true;
          });
      }
    },
    sendResetEmailForm() {
      this.success_message = "";
      this.password_reset_success = false;
      this.errors = [];
      if (this.reset_email == "") {
        this.errors["reset_email"] = "Email required.";
      } else if (!this.emailRegx.test(this.reset_email)) {
        this.errors["reset_email"] = "Wrong email format.";
      } else {
        let formData = new FormData();
        formData.append("email", this.reset_email);

        this.$store.dispatch("forgetPassword", formData).then(() => {
          if (this.success_message == "success") {
            this.openReset = true;
            this.openPasswordReset = false;
          } else if (this.error_message == "errormessage") {
            this.errors["reset_password_error"] = this.response_message;
            this.not_required_data = this.response_message;
          }
        });
      }
    },
    changePasswordNow() {
      this.success_message = "";
      this.password_reset_success = false;
      this.errors = [];
      if (this.reset_code == "") {
        this.errors["reset_code"] = "Code required.";
      } else if (this.reset_code.length != 6) {
        this.errors["reset_code"] = "Code length must have 6 characters.";
      } else if (this.reset_password == "") {
        this.errors["reset_password"] = "Password required.";
      } else if (!this.passwordRegx.test(this.reset_password)) {
        this.errors["reset_password"] =
          "Password must contain 6 characters with a capital & small letter, number & special character.";
      } else if (this.reset_cofirm_password == "") {
        this.errors["reset_cofirm_password"] = "Confirm Password required.";
      } else if (this.reset_cofirm_password != this.reset_password) {
        this.errors["reset_cofirm_password"] = "Confirm Password Not Matched.";
      } else {
        let formData = new FormData();
        formData.append("token", this.reset_code);
        formData.append("password", this.reset_password);
        formData.append("confirm_password", this.reset_cofirm_password);

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.$store.dispatch("resetPassword", formData).then(() => {
          if (this.success_message == "success") {
            this.password_reset_success = true;
            this.openLogin = true;
            this.openReset = false;
            this.not_required_data = "Reset password success.";
          } else if (this.error_message == "errormessage") {
            this.errors["change_password_error"] = this.response_message;
            this.not_required_data = this.response_message;
          }
        });
      }
    },
    showHidePassword() {
      if (this.password_type == "password") {
        this.password_eye = "fa-eye";
        this.password_type = "text";
      } else {
        this.password_eye = "fa-eye-slash";
        this.password_type = "password";
      }
    },
    showHideNewPassword() {
      if (this.new_password_type == "password") {
        this.new_password_eye = "fa-eye";
        this.new_password_type = "text";
      } else {
        this.new_password_eye = "fa-eye-slash";
        this.new_password_type = "password";
      }
    },
    showHideConfPassword() {
      if (this.conf_password_type == "password") {
        this.conf_password_eye = "fa-eye";
        this.conf_password_type = "text";
      } else {
        this.conf_password_eye = "fa-eye-slash";
        this.conf_password_type = "password";
      }
    },
  },
};
</script>

<style lang='scss'>
.login-password {
  i {
    position: relative;
    top: -40px;
    cursor: pointer;
    left: 320px;
  }
}
.site-login-password{
  i{
    position: relative;
    left: 320px !important;
    top: -39px  !important;
  }
}

.confirm-password{
    i{
    position: relative;
    left: 320px !important;
    top: -38px;
  }
}
</style>
