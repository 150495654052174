<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <form class="main-form" @submit.prevent="addComment">
          <h2 class="form-title">Add a comment</h2>
          <label for="order">Select Order *</label>
          <select id="order" v-model="order">
            <option v-for="(order, index) in order_obj" :value="order.id" v-if="order.order_status == 'delivered'">{{ order.id }} (Rs.{{ order.order_price }})</option>
          </select>
          <span class="form-error" v-if="errors.order">
            {{errors.order}}
          </span>

          <label for="products" v-if="productlist_obj && productlist_obj.length > 0">Select Product *</label>
          <select id="products" v-model="product" v-if="productlist_obj && productlist_obj.length > 0">
            <option v-for="(product, index) in productlist_obj" :value="product.product">{{ getProductById(product.product.id).name }} (Rs. {{ product.price }})</option>
          </select>
          <span class="form-error" v-if="errors.product">
            {{errors.product}}
          </span>

          <label for="comment">Comment *</label>
          <textarea
            class="comment"
            v-model="comment"
            placeholder="Comment here ..." 
          >
          </textarea>
          <span class="form-error" v-if="errors.comment">
            {{errors.comment}}
          </span>

          <button type="submit" class="gg-create-btn">Add</button>
          <span class="form-success" v-if="success">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{success}}
          </span>
        </form>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  // name: "DialogCustom",
  data(){
    return {
      showDialog: true,
      order: "",
      product: "",
      comment: "",
      productlist_obj: [],
      single_order_obj: [],
      errors: [],
      success: "",
    }
  },
  created(){
    this.listOrder();
    this.listProductsNoUser();
    this.listMyDeliveries();
    this.listProfile();
  },
  computed: {
    ...mapState(["order_obj", "profile_obj", "product_new_obj", "delivery_obj", "success_message", "error_message", "response_message",]),
  },
  methods: {
    ...mapActions(["listOrder", "listProductsNoUser", "listMyDeliveries", "listProfile"]),
    getProductById(product_id){
      let thisProduct = this.product_new_obj
      thisProduct = thisProduct.find(
        (evt) => evt.id == product_id
      )
      return thisProduct
    },
    addComment() {
      this.errors = [];
      this.success = "";
      if(this.order == ""){
        this.errors["order"] = "Order required."
      } else if(this.product == ""){
        this.errors["product"] = "Product required."
      } else if (this.comment == "") {
        this.errors["comment"] = "Comment required."
      } else if (this.comment.length <= 10) {
        this.errors["comment"] = "Comment at least 10 characters."
      } else {
        console.log("This Order: ", this.order)
        console.log("Task Obj: ", JSON.stringify(this.delivery_obj))

        let thisDelivery = this.delivery_obj
          thisDelivery = thisDelivery.find(
          (evt) => evt.task.order.id == this.order
        )

        let formData = new FormData();
        formData.append("product", this.product.id);
        formData.append("order", this.order);
        formData.append("delivery", thisDelivery.id);
        formData.append("comment", this.comment);

        for (var pair of formData.entries()) {
         console.log(pair[0]+ ', ' + pair[1]); 
        }

        this.$store.dispatch("createComment", formData).then(() => {
          if (this.success_message == "success") {
            this.success = "Comment added successfully."
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });

      }
    }
  },
  watch: {
    order: function (val) {
      if (val != "") {
        this.single_order_obj = this.order_obj.find(
          (evt) => evt.id == val
        );
        this.productlist_obj = this.single_order_obj.order_product
      }
    },
  },
};
</script>

<style lang="scss">
</style>