<template>
  <div class="deliveries">
    <div class="contents">
      <md-toolbar class="order-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-shopping-cart"></i>
        </md-icon>
        <h1>Deliveries</h1>
      </md-toolbar>
      <div class="add-btn">
        <!-- <button class="gg-create-btn" @click="openDialogAddTask = !openDialogAddTask">+ Add Tasks</button> -->
      </div>
      <aside class="map-section">
        <div id="map"></div>
        <!-- <iframe :src="map_url" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
        <!-- <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4996.801672278038!2d85.31357356094769!3d27.67797620854085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19cbbeac9357%3A0x8f35a679609cb5b9!2sPulchowk%2C%20Lalitpur%2044600!5e0!3m2!1sen!2snp!4v1593154017337!5m2!1sen!2snp"
          frameborder="0"
          style="border:0;"
          allowfullscreen
          aria-hidden="false"
          tabindex="0"
        ></iframe>-->
      </aside>
      <!-- <TaskAssign /> -->
      <h2>Assigned Tasks</h2>
      <div class="deliveries-card assigned-tasks">

        <md-table
          md-card
          id="card"
          v-for="(taskall_obj, index) in task_obj.slice(-10000)"
          :key="index" v-if="taskall_obj.status == 'pending' && (profile_obj && profile_obj.id == taskall_obj.assigned_to.id)">
          <md-table-row>
            <md-table-cell class="tablecontent">
              <span>Delivery the order #{{ taskall_obj.order.order_code }}</span
              ><br />
              <span>Order ID: {{ taskall_obj.order.id }}</span>
            </md-table-cell>
            <md-table-cell class="tablecontent">
              <span>Order Total</span><br />
              <span>Rs. {{ taskall_obj.order.order_price+taskall_obj.order.delivery_charge+taskall_obj.order.tax_vat_price }}/-</span>
            </md-table-cell>
            <md-table-cell class="tablecontent">
              <span>Delivery Due Date</span><br />
              <span>{{ taskall_obj.due_date }}</span>
            </md-table-cell>
            <md-table-cell class="tablecontent">
              <span>{{ taskall_obj.order.author.full_name }}</span
              ><br />
              <span>{{ taskall_obj.order.author.phone_number }}</span>
            </md-table-cell>
            <md-table-cell class="tablecontent">
              <span v-if="taskall_obj.order.delivery_address.street_address"
                >{{ taskall_obj.order.delivery_address.street_address }},
              </span>
              <span v-if="taskall_obj.order.delivery_address.city"
                >{{ taskall_obj.order.delivery_address.city }},
              </span>
              <span v-if="taskall_obj.order.delivery_address.state">{{
                taskall_obj.order.delivery_address.state
              }}</span>
            </md-table-cell>
            <md-table-cell class="tablecontent">
              <span>Task Status</span><br />
              <span>{{ taskall_obj.status }}</span>
            </md-table-cell>

            <md-table-cell
              class="tablecontent last-column"
              md-label="Action"
              md-sort-by="action"
            >
              <div class="gg-dropdown-btn">
                <md-menu md-size="big">
                  <md-button class="dropdown-icon" md-menu-trigger>
                    <i class="fas fa-caret-down"></i>
                  </md-button>
                  <md-menu-content class="gg-dropdown-menu">
                    <md-menu-item
                      v-if="taskall_obj.status == 'pending'"
                      @click="openAcceptBox(taskall_obj)"
                      >Accept Task</md-menu-item
                    >
                    <!-- <md-menu-item @click="openDialogGetRoute = !openDialogGetRoute">Get Route</md-menu-item> -->
                    <md-menu-item
                      :to="{
                        name: 'deliveries-map',
                        params: {
                          taskid: taskall_obj.id
                        },
                      }"
                      >Get Route</md-menu-item
                    >
                    <md-menu-item
                      :to="{
                        name: 'task-detail',
                        params: {
                          id: taskall_obj.id
                        },
                      }"
                      >Task Detail</md-menu-item
                    >
                    <md-menu-item>Save Location</md-menu-item>
                    <md-menu-item
                      v-if="taskall_obj.status == 'pending'"
                      class="add-comment"
                      @click="opencancelTask(taskall_obj)"
                      >Cancel Task</md-menu-item
                    > </md-menu-content
                  >.
                </md-menu>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <ModalComment v-if="openDialogComment" />
      </div>
      <!-- <DeliveriesCard /> -->
      <TableDeliveries />
    </div>
    <!-- <ModalTaskAdd v-if="openDialogAddTask" /> -->
    <ModalGetRoute v-if="openDialogGetRoute" />
    <md-dialog :md-active.sync="openAcceptDialogBox">
      <div class="modal-alert">
        <md-dialog-actions class="close-icon">
          <span
            class="material-icons error-icon"
            @click="openAcceptDialogBox = false"
            >highlight_off</span
          >
        </md-dialog-actions>
        <md-dialog-title class="modal-title md-layout"
          >Are you sure you want to accept this task?</md-dialog-title
        >
        <md-dialog-content>
          After accepting this, it
          automatically goes to the Delivery Process.
        </md-dialog-content>
        <span class="form-success" v-if="success"
          ><br /><strong>{{ success }}</strong></span
        >
        <md-dialog-actions>
          <md-button class="cancel-btn" @click="openAcceptDialogBox = false"
            >Cancel</md-button
          >
          <md-button class="remove-btn" @click="submitAcceptTask"
            >Accept</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
    <ModalGetRoute v-if="openDialogGetRoute" />
    <md-dialog :md-active.sync="openCancelDialogBox">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span
            class="material-icons error-icon"
            @click="openCancelDialogBox = false"
            >highlight_off</span
          >
        </md-dialog-actions>
        <form class="main-form" @submit.prevent="submitCancelTask">
          <h2 class="form-title">Cancel Task</h2>
          <h3>After canceling this task, you are not able to re-accept it.</h3>
          <label for="reply_message">Reason for Cancel *</label>
          <textarea
            class="reply_message"
            v-model.trim="reply_message"
            placeholder="Reason for Cancel the task"
          ></textarea>
          <span class="form-error" v-if="errors.reply_message">
            {{ errors.reply_message }}
          </span>

          <button type="submit" class="gg-create-btn">Submit</button>
          <span class="form-success" v-if="success">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{ success }}
          </span>
        </form>
      </div>
    </md-dialog>
    <!-- <ModalAcceptTask :single_task="single_task" v-if="openAcceptDialogBox" /> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// @ is an alias to /src
import TableDeliveries from "@/components/TableDeliveries.vue";
import ModalTaskAdd from "@/components/ModalTaskAdd.vue";
import ModalComment from "@/components/ModalComment.vue";
import ModalGetRoute from "@/components/ModalGetRoute.vue";
//import ModalAcceptTask from "@/components/ModalAcceptTask.vue";
// import TaskAssign from "@/components/TaskAssign.vue";

import Baato from "@klltech/baato-js-client";
import mapboxgl from "mapbox-gl";

export default {
  name: "Deliveries",
  components: {
    TableDeliveries,
    ModalTaskAdd,
    ModalComment,
    ModalGetRoute,
    //ModalAcceptTask,
    // mapboxgl
    // TaskAssign,
  },

  computed: {
    ...mapState([
      "task_obj",
      "profile_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
  },

  created() {
    this.listTask();
    this.listProfile();
  },

  mounted: function () {
    var markers = [];
    var map = new mapboxgl.Map({
      container: "map",
      style:
        "https://api.baato.io/api/v1/styles/breeze?key=bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu", // Baato stylesheet location
      center: [85.324, 27.7172], // starting position [lng, lat]
      zoom: 10, // starting zoom
    });

    map.on("load", (e) => {
      // Single Item
      new Baato.Reverse()
        .setBaseUrl(`https://api.baato.io/api`) // Baato base URL
        .setCoordinates([27.707182, 85.320479]) // [lng, lat] for reverse geocoding
        .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu") // Baato access token
        .setRadius(0.2)
        .doRequest()
        .then((res) => {
          var marker = new mapboxgl.Marker()
            .setLngLat([res.data[0].centroid.lon, res.data[0].centroid.lat]) // adding marker for the response [lon, lat]
            .addTo(map);
          markers.push(marker);

          function clearMarkers() {
            markers.forEach((marker) => marker.remove());
            markers = [];
          }

          document.getElementById("response").innerHTML =
            "<b>" +
            res.data[0].name +
            "</b>" +
            "<br/><br/>" +
            "<b>" +
            "Address: " +
            "</b>" +
            res.data[0].address;
        });
      // Single Item
      new Baato.Reverse()
        .setBaseUrl(`https://api.baato.io/api`) // Baato base URL
        .setCoordinates([27.705947, 85.322367]) // [lng, lat] for reverse geocoding
        .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu") // Baato access token
        .setRadius(0.2)
        .doRequest()
        .then((res) => {
          var marker = new mapboxgl.Marker()
            .setLngLat([res.data[0].centroid.lon, res.data[0].centroid.lat]) // adding marker for the response [lon, lat]
            .addTo(map);
          markers.push(marker);

          function clearMarkers() {
            markers.forEach((marker) => marker.remove());
            markers = [];
          }

          document.getElementById("response").innerHTML =
            "<b>" +
            res.data[0].name +
            "</b>" +
            "<br/><br/>" +
            "<b>" +
            "Address: " +
            "</b>" +
            res.data[0].address;
        });
      // Single Item
      new Baato.Reverse()
        .setBaseUrl(`https://api.baato.io/api`) // Baato base URL
        .setCoordinates([27.70513, 85.317045]) // [lng, lat] for reverse geocoding
        .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu") // Baato access token
        .setRadius(0.2)
        .doRequest()
        .then((res) => {
          var marker = new mapboxgl.Marker()
            .setLngLat([res.data[0].centroid.lon, res.data[0].centroid.lat]) // adding marker for the response [lon, lat]
            .addTo(map);
          markers.push(marker);

          function clearMarkers() {
            markers.forEach((marker) => marker.remove());
            markers = [];
          }

          document.getElementById("response").innerHTML =
            "<b>" +
            res.data[0].name +
            "</b>" +
            "<br/><br/>" +
            "<b>" +
            "Address: " +
            "</b>" +
            res.data[0].address;
        });
    });
  },
  data: () => ({
    openDialogComment: false,
    openDialogAddTask: false,
    openDialogGetRoute: false,
    openAcceptDialogBox: false,
    openCancelDialogBox: false,
    success: "",
    single_task: [],
    reply_message: "",
    currentLocation: JSON.parse(localStorage.getItem("currentLocation")),
    errors: [],
  }),

  methods: {
    ...mapActions(["listTask", "listProfile"]),
    openAcceptBox(taskObject) {
      this.single_task = this.task_obj.find((evt) => evt.id == taskObject.id);
      this.openAcceptDialogBox = true;
    },
    opencancelTask(taskObject) {
      this.single_task = this.task_obj.find((evt) => evt.id == taskObject.id);
      this.openCancelDialogBox = true;
    },
    submitAcceptTask() {
      this.$store
        .dispatch("updateTaskStatus", {
          status: "accepted",
          id: this.single_task.id,
        })
        .then(() => {
          if (this.success_message == "success") {
            this.success =
              "Task Accepted Successfully.<br/>Pleaase wait we are creating new delivery.";
            let formData = new FormData();
            formData.append("task", this.single_task.id);
            formData.append(
              "start_lat",
              this.currentLocation
                ? this.currentLocation.latitude
                : "27.700000762939453"
            );
            formData.append(
              "start_long",
              this.currentLocation
                ? this.currentLocation.longitude
                : "85.33329772949219"
            );
            formData.append(
              "end_lat",
              this.single_task.order.delivery_address.latitude
            );
            formData.append(
              "end_long",
              this.single_task.order.delivery_address.longitude
            );
            formData.append("ordering", 1);
            this.success_message = "";

            // for (var pair of formData.entries()) {
            //   console.log(pair[0]+ ', ' + pair[1]);
            // }

            this.$store.dispatch("createNewDelivry", formData).then(() => {
              if (this.success_message == "success") {
                this.success = "New Delivery Created Successfully.";
                setTimeout(function () {
                  location.reload();
                }, 700);
              } else if (this.error_message == "errormessage") {
                alert(this.response_message);
              }
            });

          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
    },
    submitCancelTask() {
      this.errors = [];
      if (this.reply_message == "") {
        this.errors["reply_message"] = "Cancel Reason Required";
      } else if (this.reply_message.length <= 20) {
        this.errors["reply_message"] =
          "Cancel Reason Must be minimum 20 characters.";
      } else {
        this.errors = [];
        this.$store
          .dispatch("updateNewTask", {
            assigned_to: this.single_task.assigned_to.id,
            task_type: "order",
            order: this.single_task.order.id,
            message: this.single_task.message,
            reply_message: this.reply_message,
            status: "rejected",
            due_date: this.single_task.due_date,
            id: this.single_task.id,
          })
          .then(() => {
            if (this.success_message == "success") {
              this.success = "Task Canceled Successfully.";
              setTimeout(function () {
                location.reload();
              }, 1000);
            } else if (this.error_message == "errormessage") {
              alert(this.response_message);
            }
          });
      }
    },
  },
};
</script>
<style lang='scss'>
.assigned-tasks {
  .md-table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px 200px;
    align-items: center;
  }
  .tablecontent {
    height: max-content;
  }
  .gg-dropdown-btn {
    justify-content: flex-end;
    align-items: center;
    .md-menu {
      display: flex;
    }
  }
}
.mapboxgl-canvas-container {
  overflow: hidden;
  height: inherit;
}
</style>
