<template>
  <div class="Members">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1"><i class="fas fa-comments"></i></md-icon>
        <h1>Comments</h1>
      </md-toolbar>
      
      <md-toolbar class="nav section-title width" v-if="!profile_obj.admin && (profile_obj.group.name=='Web User' || profile_obj.group.name=='Mobile User')">
        <span class="sub-heading">Table of Comments</span>
        <button class="gg-create-btn" @click="openDialog = !openDialog">Add new comment</button>
      </md-toolbar>
      
      <div class="gg-member-table">
        <CommentTable />
      </div>
    </div>
    <div v-if="!profile_obj.admin && (profile_obj.group.name=='Web User' || profile_obj.group.name=='Mobile User')">
      <ModalComment v-if="openDialog" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CommentTable from "@/components/CommentTable.vue";
import ModalComment from "@/components/ModalComment.vue";

export default {
  name: "Members",
  components: {
    CommentTable,
    ModalComment,
  },
   data: () => ({
    day: "today",
    openDialog: false,
  }),
   created(){
    this.listProfile();
  },
  computed: {
    ...mapState(["profile_obj", "success_message", "error_message", "response_message"]),
  },
  methods: {
    ...mapActions(["listProfile"]),
  }
};
</script>
<style lang='scss' scoped>
</style>
