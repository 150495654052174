<template>
  <div class="orders">
    <div class="contents">
      <md-toolbar class="order-nav page-title">
        <md-icon class="navbar-icon1">local_mall</md-icon>
        <h1>Orders</h1>
      </md-toolbar>
      <Cards />
      <!-- <md-toolbar class="order-nav section-title table-title-button"> -->
        <h2>List of Orders</h2>
        <!-- <div class="table-edit-buttons">
          <button data-v-3cec2962 class="gg-create-btn table-edit" @click="disabled=!disabled">Edit</button>
          <button data-v-3cec2962 class="gg-create-btn table-edit">Save Changes</button>
        </div> -->
      <!-- </md-toolbar> -->
      <TableOrder />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Cards from "@/components/Cards.vue";
import TableOrder from "@/components/TableOrder.vue";

export default {
  name: "Orders",
  components: {
    Cards,
    TableOrder
    // Sidebar,
  },
  data() {
    return {
      // 
    };
  }
};
</script>
<style lang='scss' scoped>
</style>
