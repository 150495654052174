<template>
  <div class="categories-cards">
    <md-card class="md-accent" md-with-hover v-for="(category_obj,index) in product_category"
    :key="index">
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">{{category_obj.name}}</div>
          <div class="md-subhead">{{category_obj.products.length}} items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card>
    <!-- <md-card class="md-accent" md-with-hover>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Rice/Millet</div>
          <div class="md-subhead">20 items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
    <!-- <md-card class="md-accent" md-with-hover>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Beans and Lentils</div>
          <div class="md-subhead">9 items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
    <!-- <md-card class="md-accent" md-with-hover>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Grocery</div>
          <div class="md-subhead">15 items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
    <!-- <md-card class="md-accent" md-with-hover>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Himalayan Bounty .</div>
          <div class="md-subhead">6 items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
    <!-- <md-card class="md-accent" md-with-hover>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Flowes Pot Bouquet</div>
          <div class="md-subhead">3 items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
    <!-- <md-card class="md-accent" md-with-hover>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Vegetables</div>
          <div class="md-subhead">25 items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
    <!-- <md-card class="md-accent" md-with-hover>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Fruits</div>
          <div class="md-subhead">16 items</div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {
  },
  computed: {
    ...mapState(["product_category",])
  },

  created() {
    this.listCategories();
  },
  data: () => ({
  }),

  methods: {
    ...mapActions(["listCategories"]),
  }
};
</script>

<style lang="scss" scoped>
// .md-card {
//   width: 20%;
//   margin: 20px;
//   background-color: #fff;
//   display: inline-block;
//   vertical-align: top;
// }
// .md-title {
//   font-weight: 700;
//   line-height: 60px;
// }
// .md-card.md-accent.md-theme-default.md-with-hover {
//   border-radius: 10px;
// }
// .md-card-header {
//   color: #707070;
//   border-radius: 10px;
//   margin: 0;
// }

// .md-card .md-subhead {
//   font-size: 36px;
//   font-weight: 700;
//   line-height: 60px;
// }
// .md-toolbar.card-icon.md-theme-default.md-elevation-4 {
//   padding: 0;
//   display: flex;
//   justify-content: flex-start;
//   box-shadow: none;
// }
// .categories-cards {
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   grid-gap: 2rem;
//   @media only screen and (max-width: 992px) {
//     grid-template-columns: repeat(3, 1fr);
//   }
//   @media only screen and (max-width: 768px) {
//     grid-template-columns: repeat(2, 1fr);
//   }
//   @media only screen and (max-width: 576px) {
//     grid-template-columns: repeat(1, 1fr);
//   }
//   .md-card.md-accent.md-theme-default.md-with-hover {
//     width: 100%;
//   }
// }
</style>
