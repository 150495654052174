<template>
  <div class="categories">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">widgets</md-icon>
        <h1>Categories</h1>
      </md-toolbar>
      <!-- <md-toolbar class="nav section-title"> -->
        <h2>Add New Category</h2>
      <!-- </md-toolbar> -->
      <CategoriesForm />
      <CategoriesTabsTable />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CategoriesTabsTable from '@/components/CategoriesTabsTable.vue';
import CategoriesForm from '@/components/CategoriesForm.vue';

export default {
  name: 'Categories',
  components: {
    CategoriesTabsTable,
    CategoriesForm,
  },
};
</script>
<style lang='scss' scoped>

</style>
