import HomePage from "../views/Home.vue";
import About from "../views/About.vue";
import Orders from "../views/Orders.vue";
import Deliveries from "../views/Deliveries.vue";
import DeliveriesMap from "../views/DeliveriesMap.vue";
import Comments from "../views/Comments.vue";
import OrderDetail from "../views/OrderDetail.vue";
import TaskDetail from "../views/TaskDetail.vue";
import FoodMiles from "../views/FoodMiles.vue";
import Members from "../views/Members.vue";
import CooperativePage from "../views/CooperativePage.vue";
import MemberDetail from "../views/MemberDetail.vue";
import Categories from "../views/Categories.vue";
import Product from "../views/Product.vue";
import ProductBatch from "../views/ProductBatch.vue";
import RecieveProductBatch from "../views/RecieveProductBatch.vue";
import DispatchProduct from "../views/DispatchProduct.vue";
import BatchDetail from "../views/BatchDetail.vue";
import DispatchDetail from "../views/DispatchDetail.vue";
// import TrackOrder from '../views/TrackOrder.vue';
// import TrackProduct from '../views/TrackProduct.vue';
import Sources from "../views/Sources.vue";
import Customers from "../views/Customers.vue";
import Outlets from "../views/Outlets.vue";
import SiteLogin from "../views/SiteLogin.vue";
import ValidateUser from "../views/ValidateUser.vue";
import SiteRegistration from "../views/SiteRegistration.vue";
import PageNotFound from "../views/404-page.vue";
import Logout from "../components/Logout.vue";
import ChangePassword from "../views/ChangePassword.vue";
import Notification from "../views/Notification.vue";
import Profile from "../views/Profile.vue";
import ProfilePurchase from "../views/Profile-Purchase.vue";
import Inventry from "../views/Inventry.vue";
import Requests from "../views/Requests.vue";
import RequestDetail from "../views/RequestDetail.vue";
import SourceProduct from "../views/Source-Product.vue";
import NotFoundPage from "../views/404-page.vue";
import UnitPage from "../views/UnitPage.vue";
import BrandPage from "../views/BrandPage.vue";
import SitePage from "../views/SitePage.vue";
import CropPage from "../views/CropPage.vue";
import PlotPage from "../views/PlotPage.vue";
import SearchProduct from "../views/SearchProduct.vue";
import BannerPage from "../views/BannerPage.vue";
import PartnerPage from "../views/PartnerPage.vue";

export default [
  { path: "/dashboard", name: "homepage", component: HomePage },
  { path: "/about-page", name: "about", component: About },
  { path: "/order-page", name: "orders", component: Orders },
  { path: "/deliveries-page", name: "deliveries", component: Deliveries },
  { path: "/deliveries-map-page/:taskid", name: "deliveries-map", component: DeliveriesMap },
  { path: "/comments-page", name: "Comments", component: Comments },
  { path: "/members-page", name: "Members", component: Members },
  { path: "/cooperative-page", name: "CooperativePage", component: CooperativePage },
  { path: "/order-detail-page/:id", name: "order-detail", component: OrderDetail },
  { path: "/task-detail-page/:id", name: "task-detail", component: TaskDetail },
  { path: "/food-miles/:oid/:pid", name: "food-miles", component: FoodMiles },
  { path: "/member-detail-page/:id", name: "member-detail", component: MemberDetail },
  { path: "/categories-page", name: "categories", component: Categories },
  { path: "/product-page", name: "product", component: Product },
  { path: "/product-batch", name: "product-batch", component: ProductBatch },
  { path: "/recieve-product-batch", name: "recieve-product-batch", component: RecieveProductBatch },
  { path: "/dispatch-product", name: "dispatch-product", component: DispatchProduct },
  { path: "/batch-detail/:id", name: "BatchDetail", component: BatchDetail },
  { path: "/dispatch-detail/:id", name: "DispatchDetail", component: DispatchDetail },
  // { path: '/track-order', name: 'track-order', component: TrackOrder},
  // { path: '/track-product', name: 'track-product', component: TrackProduct},
  { path: "/source-page", name: "sources", component: Sources },
  { path: "/customers-page", name: "customers", component: Customers },
  { path: "/outlets-page", name: "outlets", component: Outlets },
  { path: "/", name: "login", component: SiteLogin },
  { path: "/validate-user", name: "validateUser", component: ValidateUser },
  { path: "/register", name: "registration", component: SiteRegistration },
  { path: "/page-not-found", name: "pagenotfound", component: PageNotFound },
  { path: "/logout", name: "logout", component: Logout },
  { path: "/change-password", name: "changePassword", component: ChangePassword },
  { path: "/notification-page", name: "notification", component: Notification },
  { path: "/profile-page", name: "profile", component: Profile },
  { path: "/purchase-page", name: "profilepurchase", component: ProfilePurchase },
  { path: "/request-page", name: "request", component: Requests },
  { path: "/request-page/:id", name: "request-detail", component: RequestDetail },
  { path: "/inventry-page", name: "inventry", component: Inventry },
  { path: "/source-product-page", name: "source-product", component: SourceProduct },
  { path: "/unit-page", name: "unitPage", component: UnitPage },
  { path: "/brand-page", name: "brandPage", component: BrandPage },
  { path: "/site-page", name: "sitePage", component: SitePage },
  { path: "/crop-page", name: "cropPage", component: CropPage },
  { path: "/plot-page", name: "plotPage", component: PlotPage },
  { path: "/search-product", name: "searchProduct", component: SearchProduct },
  { path: "/banner-page", name: "bannerpage", component: BannerPage },
  { path: "/partner-page", name: "partnerpage", component: PartnerPage },
  { path: "*", name: "404notfound", component: NotFoundPage }
];
