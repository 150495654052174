<template>
  <div class="pages" id="paginationComponent">
    <pagination-control :settings="settings" />
    <pagination-page :settings="settings">
      <div slot="page" slot-scope="item">
        <!-- <div>{{item.originalEvent.data}}</div> -->
        <components :is="item.originalEvent.component"></components>
      </div>
    </pagination-page>
  </div>
</template>

<script>
import { PaginationControl, PaginationPage } from "vue-smart-pagination";
// import SourceTabTable from "./SourceTabTable.vue";
// import TabsTable from './TabsTable.vue';
import PurchaseTable from './PurchaseTable.vue';

export default {
  name: "Pagination",
  components: {
    PaginationPage,
    PaginationControl,
    PurchaseTable,
    // TabsTable,
  },
  data() {
    return {
      arrayData: [
        {
          data: "Page № 1",
          component: "PurchaseTable"
        },
        {
          data: "page 2",
          component: "PurchaseTable"
        },
        {
          data: "page 3",
          component: "PurchaseTable"
        },
        {
          data: "page 4",
          component: "PurchaseTable"
        },
        {
          data: "page 5",
          component: "PurchaseTable"
        },
        {
          data: "page 6",
          component: "PurchaseTable"
        },
        {
          data: "page 7",
          component: "PurchaseTable"
        },
        {
          data: "page 8",
          component: "PurchaseTable"
        },
        {
          data: "page 9",
          component: "PurchaseTable"
        },
        {
          data: "page 10",
          component: "PurchaseTable"
        }
      ]
    };
  },
  computed: {
    settings() {
      return {
        arrayData: this.arrayData
      };
    }
  }
};
</script>
<style lang='scss' >
</style>
