<template>
	<div>
		<div v-if="profile_obj.admin || profile_obj.group.name != 'Web User'">
			<form method="POST" @submit.prevent="submitSearchRequest">
				<input type="text" name="query" v-model.trim="query" placeholder="Search Product" class="input-search-item" :class="query_error ? 'query_error' : ''">
				<button class="global-search" type="submit"><i class="material-icons" id="search-icon">search</i></button>
			</form>
			<input type="hidden" v-model="state_change_text">
		</div>
	</div>
</template>

<script type="text/javascript">
import { mapState, mapActions } from "vuex";
export default{
	name: 'searchComponentNew',
	data() {
		return {
			query: '',
			query_error: false,
			state_change_text: '',
		}
	},
	created(){
		this.query = this.$route.query.query
		this.searchProducts(this.query);
		this.listProfile();
	},
	computed: {
    	...mapState(["product_obj", "success_message", "error_message", "response_message", "profile_obj"]),
	},
	methods: {
    	...mapActions(["searchProducts", "listProfile"]),
    	submitSearchRequest(){
    		if (!this.query) {
    			this.query_error = true
    		}else{
    			this.query_error = false
    			if (this.$route.name == 'searchProduct') {
    				this.$router.push({
	    				name: 'searchProduct',
	    				query: {'query': this.query}
	    			})
    				location.reload()
				}else{
					this.$router.push({
	    				name: 'searchProduct',
	    				query: {'query': this.query}
	    			})
				}
    		} //else
    	},
	},
	watch: {
		'$route.name'() {
			if (this.$route.name != 'searchProduct') {
				this.query = ''
			}
	    },
	}
}
</script>

<style type="text/css" lang="scss" scoped>
.input-search-item{
	float: left;
	height: 32px;
	padding: 5px;
	border: none;
	border-bottom: 1px solid grey;
	font-size: 20px;
	&:focus {
		border-bottom: 1px solid #4ba069;
		outline: 0;
	}
}
.global-search {
	border: none;
	background: none;
	margin-left: -30px;
	margin-top: 4px;
}
@media (max-width: 476px) {
	.input-search-item {
		max-width: 90%;
	}
}
.query_error{
	background: #ffe8e8;
	border: 1px #ffbdb9 solid;
	color: #3c0d0d;
}
#search-icon{
	color: #8a8787;
}
</style>