<template>
  <div class="products">
    <div class="contents">
      <md-toolbar class="product-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-layer-group"></i>
        </md-icon>
        <h1>Products</h1>
      </md-toolbar>
      <div class="add-btn">
        <!-- <button class="gg-create-btn md-spacing-1"  @click="openDialogSites = !openDialogSites">+ Add Sites</button> -->
        <button class="gg-create-btn" @click="openDialogProduct = !openDialogProduct">+ Add Product</button>
      </div>
      <!-- <h2>Add Unit</h2>
      <ProductUnitForm /> -->
      <!-- <md-toolbar class="product-nav section-title table-title-button"> -->
      <h2>Product List</h2>
      <!-- <div class="table-edit-buttons">
          <button data-v-3cec2962 class="gg-create-btn table-edit" @click="disabled=!disabled">Edit</button>
          <button data-v-3cec2962 class="gg-create-btn table-edit">Save Changes</button>
      </div>-->
      <!-- </md-toolbar> -->
      <ProductTabTable />
    </div>
    <ModalProduct v-if="openDialogProduct" />
    <ModalSites v-if="openDialogSites" />
  </div>
</template>

<script>
// @ is an alias to /src
import ProductTabTable from "@/components/ProductTabTable.vue";
import ModalProduct from "@/components/ModalProduct.vue";
import ModalSites from "@/components/AddSitesModal.vue";

export default {
  name: "Product",
  components: {
    ProductTabTable,
    ModalProduct,
    ModalSites
  },
  data() {
    return {
      openDialogProduct: false,
      openDialogSites: false,
    };
  },
};
</script>
<style lang='scss' scoped>
.product-request-btn{
  margin: 0px 10px;
}
</style>
