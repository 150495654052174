<template>
  <div class="products">
    <div class="contents">
      <span v-if="errors.crop_add_error" style="color: red; font-weight: bold;"
        >{{ errors.crop_add_error }}<br
      /></span>
      <h2>Add Crop Type</h2>
      <aside class="product-unit-form">
        <form class="create-post" @submit.prevent="addCrop()">
          <label for="uname">Add New Crop *</label>
          <input type="text" id="uname" v-model="crop_name" placeholder="Enter here" />
          <span class="form-error" v-if="errors.crop_name">{{ errors.crop_name }}</span>
          <button class="post-btn">ADD</button>
          <span class="form-success" v-if="success">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{ success }}
          </span>
        </form>
        <div class="order-detail-table-cost unit">
          <h3>Available Crop Names</h3>
          <ul>
            <li
              v-for="(crop_name, index) in product_crop.slice(count_start, count_end)"
              :key="index"
            >
              <!-- <li> -->
              {{ crop_name.name }}
              <span>
                <button class="gg-create-btn" @click="openEditDialog(crop_name.id)">Edit</button>
                <button class="gg-create-btn delete-button" @click="openDeleteDialog(crop_name.id)">
                  Delete
                </button>
              </span>
              <!-- </li> -->
            </li>
          </ul>

          <div v-if="product_crop && product_crop.length">
            <ul class="nav-pagination">
              <li>
                <router-link
                  :to="{
                    name: current_route,
                    query: { page: current_page <= 1 ? 1 : current_page - 1 }
                  }"
                  >&laquo;</router-link
                >
              </li>

              <li
                v-for="(page, index) in Math.ceil(product_crop.length / page_sequence)"
                :class="current_page == page ? 'active' : ''"
                :key="index"
              >
                <router-link :to="{ name: current_route, query: { page: page } }">{{
                  page
                }}</router-link>
              </li>

              <li>
                <router-link
                  :to="{
                    name: current_route,
                    query: {
                      page:
                        current_page >= Math.ceil(product_crop.length / page_sequence)
                          ? Math.ceil(product_crop.length / page_sequence)
                          : current_page + 1
                    }
                  }"
                  >&raquo;</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <h2 class="form-title">Edit Crop</h2>
        <form class="main-form" @submit.prevent="editCrop">
          <label for="name">Crop</label>
          <input type="text" class="product" v-model="edit_crop" placeholder />
          <span class="form-error" v-if="errors.edit_crop">{{ errors.crop_brand }}</span>
          <button type="submit" class="gg-create-btn">Save</button>
          <span class="form-success" v-if="editSuccess">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{ editSuccess }}
          </span>
        </form>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="showDialogDelete" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialogDelete = false"
            >highlight_off</span
          >
        </md-dialog-actions>
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons error-icon">close</span>
          </div>
          <md-dialog-title class="modal-title">Are you sure to delete this crop?</md-dialog-title>
          <div class="buttons">
            <button type="submit" class="gg-create-btn delete-button" @click="deleteCrop">
              Delete
            </button>
            <button type="submit" class="gg-create-btn" @click="showDialogDelete = false">
              Cancel
            </button>
          </div>
        </div>
        <!-- <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons error-icon">close</span>
          </div>
          <md-dialog-title class="modal-title">Product Deleted</md-dialog-title>
        </div>-->
      </div>
    </md-dialog>
    <input type="hidden" v-model="state_update_text" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "cropage",
  computed: {
    ...mapState(["product_crop", "success_message", "error_message", "response_message"])
  },
  created() {
    this.listCrops();
    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    }
  },
  data() {
    return {
      crop_name: "",
      edit_crop: "",
      emptycrop: "",
      single_crop: [],
      errors: [],
      inputDisabled: true,
      showDialog: false,
      showDialogDelete: false,
      success: "",
      editSuccess: "",
      state_update_text: "",
      deleted: false,
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10
    };
  },
  methods: {
    ...mapActions(["listCrops"]),
    addCrop() {
      this.errors = [];
      if (this.crop_name == "") {
        this.errors["crop_name"] = "Crop Name is required.";
      } else {
        let formData = new FormData();
        formData.append("name", this.crop_name);

        // for (var pair of formData.entries()) {
        //  console.log(pair[0]+ ', ' + pair[1]);
        // }

        this.$store.dispatch("createCrop", formData).then(() => {
          if (this.success_message == "success") {
            this.crop_name = "";
            this.success = "Crop name created successfully";
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            this.state_update_text = "Unit Name already exists.";
            this.errors["crop_add_error"] = "Crop Name already exists.";
          }
        });
      }
    },
    openEditDialog(crop_id) {
      this.single_crop = this.product_crop.find(row => row.id == crop_id);
      this.edit_crop = this.single_crop.name;
      this.showDialog = true;
    },
    editCrop() {
      this.errors = [];
      this.editSuccess = "";
      if (this.edit_crop == "") {
        this.errors["edit_crop"] = "Crop is required.";
      } else {
        this.$store
          .dispatch("updateCrop", {
            name: this.edit_crop,
            id: this.single_crop.id
          })
          .then(() => {
            if (this.success_message == "success") {
              this.editSuccess = "Crop name updated successfully";
              setTimeout(function() {
                location.reload();
              }, 500);
            } else if (this.error_message == "errormessage") {
              alert(this.response_message);
            }
          });
      }
    },
    openDeleteDialog(crop_id) {
      this.single_crop = this.product_crop.find(row => row.id == crop_id);
      this.showDialogDelete = true;
    },
    deleteCrop() {
      if (this.single_crop && this.single_crop.id > 0) {
        this.showDialogDelete = false;
        this.$store.dispatch("deleteCrop", this.single_crop.id).then(() => {
          if (this.success_message == "success") {
            this.single_crop = [];
            location.reload();
          } else if (this.error_message == "errormessage") {
            this.single_crop = [];
            alert(this.response_message);
          }
        });
      }
    }
  }
};
</script>

<style scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
</style>
