<template>
  <div class="order-detail-vue">
    <table class="order-detail-table">
      <tr>
        <td class="td1" colspan="2">Order Detail:</td>
      </tr>
      <tr>
        <td class="td1">Order ID</td>
        <td class="td2">{{ order_obj.id }} ({{ order_obj.order_code }})</td>
      </tr>
      <tr>
        <td class="td1">Order Price Detail</td>
        <td class="td2">
          <table>
            <tr><td>Cart Price:</td><td>Rs. {{ order_obj.order_price.toFixed(2) }}</td></tr>
            <tr><td>TAX/VAT Price:</td><td>Rs. {{ order_obj.tax_vat_price.toFixed(2) }}</td></tr>
            <tr><td>Delivery Charge:</td><td>Rs. {{ order_obj.delivery_charge.toFixed(2) }}</td></tr>
          </table>
        </td>
      </tr>
      <tr class="table-total-price">
        <td class="td1">Order Total Price</td>
        <td class="td2"><span style="font-size: 18px;">Rs. {{ (order_obj.order_price+order_obj.tax_vat_price+order_obj.delivery_charge).toFixed(2) }}/-</span></td>
      </tr>
      <tr>
        <td class="td1">Payment Type</td>
        <td class="td2">Cash On Delivery</td>
      </tr>
      <tr>
        <td class="td1">Order Status</td>
        <td class="td2">{{ order_obj.order_status }}</td>
      </tr>
      <tr>
        <td class="td1">Payment Status</td>
        <td class="td2">{{ order_obj.payment_status }}</td>
      </tr>
      
    </table>
    
    <!-- New Table -->
    <table class="order-detail-table-cost">
      <tr>
        <td class="td1" colspan="2">Order By Detail:</td>
      </tr>
      <tr>
        <td class="td1">Order By</td>
        <td class="td2">
          <span>{{ order_obj.author.full_name }} ({{ order_obj.author.phone_number }})</span><br/>
          <span>{{ order_obj.author.email }}</span>
        </td>
      </tr>
      <tr>
        <td class="td1">Received By</td>
        <td class="td2">
          <span v-if="order_obj.delivery_address.first_name">{{ order_obj.delivery_address.first_name }} {{ order_obj.delivery_address.middle_name }} {{ order_obj.delivery_address.last_name }} ({{ order_obj.delivery_address.phone }})</span><br/>
          <span v-if="order_obj.delivery_address.email">{{ order_obj.delivery_address.email }}</span>
        </td>
      </tr>
      <tr>
        <td class="td1">Delivery Address</td>
        <td class="td2">
          <span v-if="order_obj.delivery_address.street_address">{{ order_obj.delivery_address.street_address }}, </span>
          <span v-if="order_obj.delivery_address.city">{{ order_obj.delivery_address.city }}, </span>

          <span v-if="order_obj.delivery_address.state">{{ order_obj.delivery_address.state }}</span>
        </td>
      </tr>
      <tr>
        <td class="td1" colspan="2">Product Items:</td>
      </tr>
      <tr>
        <td colspan="2">
          <table width="100%" border="1" cellspacing="0"  cellpadding="5">
            <thead>
              <tr>
                <td>Image</td>
                <td>Product</td>
                <td>Quantity</td>
                <td>Rate</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody v-if="order_obj.order_product && order_obj.order_product.length > 0">
              <tr v-for="(product_item, index) in order_obj.order_product">
                <td><a :href="product_item.product.image" target="_blank">
                    <img :src="product_item.product.image" width="" height="" alt="Green Growth" style="width: 50px;" />
                  </a>
                </td>
                <td>
                  <span>{{ product_item.product.name }}</span><Br/>
                  <router-link :to="{name: 'food-miles', params:{oid: order_id, pid: product_item.product.id}}">Food Miles</router-link> &raquo;
                </td>
                <td>
                  <span>{{ product_item.quantity }} {{ product_item.unit.name }}</span>
                  <span v-if="profile_obj.admin==true || profile_obj.group.name=='Staff User'"><br/><a href="#" @click="openChangeQtyModel(product_item)">Change Quantity</a></span>
                </td>
                <td>Rs. {{ product_item.price.toFixed(2) }}</td>
                <td>Rs. {{ (product_item.quantity*product_item.price).toFixed(2) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr><td colspan="4"><p style="text-align: center;">Sorry! No product found.</p></td></tr>
            </tbody>
          </table>
        </td>
      </tr>
      
    </table>

    <!-- Change Quantity Model Box -->
    <div v-if="profile_obj.admin==true || profile_obj.group.name=='Staff User'">
      <md-dialog v-if="single_product && single_product.id" :md-active.sync="showChangeQtyModel" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span
              class="material-icons error-icon"
              @click="showChangeQtyModel = false"
              >highlight_off</span
            >
          </md-dialog-actions>
          <form class="main-form" @submit.prevent="updateQuantity">
            <h2 class="form-title">Update Order Quantity</h2>
            <p>Product: {{ single_product.product.name }}</p>
            <p>Current Quantity: {{ single_product.quantity }} {{ single_product.unit.name }}</p>
            <label for="product">New Quantity *</label>
            <input
              type="number"
              min="0.01"
              pattern="[0-9]+([\.,][0-9]+)?"
              step="0.01"
              accept="float"
              v-model.trim="new_quantity"
              class="product"
              placeholder="Enter New Quantity"
            />
            <span v-if="errors.product_quantity" style="color: red">{{
              errors.product_quantity
            }}</span>

            <span class="form-success" v-if="success"><strong>{{ success }}</strong></span>
            <button type="submit" class="gg-create-btn">Update Now</button>
          </form>
        </div>
      </md-dialog>
    </div>

    <div>
      
    </div>
    
    <input type="hidden" v-model="state_change_text">
  </div>
</template>

<script>
import Baato from "@klltech/baato-js-client";
import mapboxgl from "mapbox-gl";

import { mapState, mapActions } from "vuex";

export default {
  name: 'OrderDetailCards',
  components: {
    //
  },
  computed: {
    ...mapState(["profile_obj", "all_user_obj", "order_obj", "product_obj", "product_new_obj", "success_message", "error_message", "response_message"])
  },
  created() {
    this.listProfile();
    this.listUser();
    this.listProducts();
    this.listProductsNoUser();
  },

  watch: {
    all_user_obj: function(){
      this.profile_data = this.all_user_obj.find(evt => evt.id == this.$route.params.id);
    },
  },
  data() {
    return {
      profile_data: [],
      order_id: this.$route.params.id,
      order_data: [],
      single_product:[],
      new_quantity: 0,
      showChangeQtyModel: false,
      state_change_text: "",
      errors: [],
      success: '',
    };
  },
  methods: {
    ...mapActions(["listProfile", "listUser", "listProducts", "listProductsNoUser"]),
    getProductById(product_id){
      let thisProduct = [];
      if (this.profile_obj.admin) {
        thisProduct = this.product_obj
      }else{
        thisProduct = this.product_new_obj
      }
      thisProduct = thisProduct.find(
        (evt) => evt.id == product_id
      )
      return thisProduct
    },
    openChangeQtyModel(product_obj){
      this.single_product = product_obj
      this.new_quantity = this.single_product.quantity
      this.showChangeQtyModel = true
    },
    updateQuantity(){
      this.errors = []
      if(this.new_quantity == '' || parseFloat(this.new_quantity) <= 0){
        this.errors['product_quantity'] = "Quantity must be a greater than 0."
      }else{
        let currentOrderPrice = this.order_obj.order_price
        let thisProductCurrentTotalPrice = parseFloat(this.single_product.quantity)*parseFloat(this.single_product.price)
        let remainingOrderPrice = parseFloat(currentOrderPrice) - parseFloat(thisProductCurrentTotalPrice)
        let changedProductTotalPrice = parseFloat(this.new_quantity)*parseFloat(this.single_product.price)
        let changedOrderTotalPrice = remainingOrderPrice+changedProductTotalPrice
        let quantityObj = {
          'order_price': changedOrderTotalPrice,
          'quantity' : this.new_quantity,
          'order_id' : this.single_product.order,
          'product_id' : this.single_product.id
        }
        this.$store.dispatch("updateOrderProduct", quantityObj).then(() => {
          if (this.success_message == "success") {
            this.success = "Quantiry updated Successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
  },
  mounted() {

    this.$store.dispatch("listSingleOrder", this.order_id)
    .then(() => {
      if (this.success_message == "success") {
        this.state_change_text = "Order Listed"
      }
    });

  },
};
</script>