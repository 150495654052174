<template>
  <div class="source-table">
    <md-table v-model="filterTableData.slice(count_start,count_end)" md-sort="name" md-sort-order="asc">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item, index }"
        v-if="item.group != null"
      >
        <md-table-cell md-label="SN" md-numeric>{{ (index+1)+((current_page-1)*10) }}.</md-table-cell>
        <md-table-cell md-label="Name" md-sort-by="name">
          <router-link :to="'/member-detail-page/' + item.id">{{
            item.full_name
          }}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Contact" md-sort-by="contact">{{
          item.phone_number
        }}</md-table-cell>
        <md-table-cell md-label="Email" md-sort-by="email">{{
          item.email
        }}</md-table-cell>
        <md-table-cell md-label="Username" md-sort-by="address">{{
          item.username
        }}</md-table-cell>
        <md-table-cell md-label="Status" md-sort-by="status">{{
          item.active ? "Active" : "Inactive"
        }}</md-table-cell>
        <md-table-cell md-label="Action" md-sort-by="action">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item :to="{name: 'member-detail', params: {id: item.id}}">View Detail</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
   
    <div v-if="filterTableData && filterTableData.length">
      <ul class="nav-pagination">
        <li><router-link :to="{name: current_route, query:{page: current_page <= 1 ? 1 : current_page-1}}">&laquo;</router-link></li>

        <li v-for="(page, index) in Math.ceil(filterTableData.length/page_sequence)" :class="current_page == page ? 'active' : ''" :key="index">
          <router-link :to="{name: current_route, query:{page: page}}">{{ page }}</router-link>
        </li>
        
        <li><router-link :to="{name: current_route, query:{page: current_page >= Math.ceil(filterTableData.length/page_sequence) ? Math.ceil(filterTableData.length/page_sequence) : current_page+1}}">&raquo;</router-link></li>
      </ul>
    </div>
    
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {
    //
  },
  data(){
    return {
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10, 
    }
  },
  computed: {
    ...mapState([
      "profile_obj",
      "all_user_obj",
      "user_role_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
    filterTableData() {
      
      this.$router.replace({
        name: this.current_route,
        query: {page: 1}
      })

      let table_data = this.all_user_obj;
      table_data = table_data.filter((row) => {
        return row.group.id == 5
      });
      
      return table_data;
    },
  },
  created() {
    this.listProfile();
    this.listUser();
    this.listUserRole();

    // Pagination
    this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
    this.count_start = (this.current_page-1)*this.page_sequence
    this.count_end = this.current_page*this.page_sequence
  },
  mounted() {
    // Pagination
  },
  watch: {
    '$route.query.page'(){
      this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
      this.count_start = (this.current_page-1)*this.page_sequence
      this.count_end = this.current_page*this.page_sequence
    }
  },
  methods: {
    ...mapActions(["listProfile", "listUser", "listUserRole"]),
  },
};
</script>
<style scoped>
.nav-pagination{list-style: none; float: left; width: 100%; padding: 10px;}
.nav-pagination li{float: left; margin: 0 5px;}
.nav-pagination li.active{}
.nav-pagination li a{padding: 3px 8px; background: #e6e6e6; border: 1px #3b844a solid; border-radius: 6px; color: #3b844a; text-decoration: none; font-size: 14px; transition-duration: 1s;}
.nav-pagination li a:hover{ background: #3b844a; color: #ffffff; transition-duration: 1s;}
.nav-pagination li.active a{background: #3b844a; color: #ffffff;}
</style>