import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMaterial from 'vue-material';
import { PaginationControl, PaginationPage } from 'vue-smart-pagination';
import VModal from 'vue-js-modal';
import VueFuse from 'vue-fuse';
import ToggleButton from 'vue-js-toggle-button';
import VueAgile from 'vue-agile'
import axios from 'axios'

import moment from 'moment';


import { createStore } from './store'

import 'vue-material/dist/vue-material.min.css';
import App from './App.vue';
import Routes from './router';

import './assets/scss/app.scss';

require('chart.js');
require('hchs-vue-charts');

Vue.use(VueAgile)
Vue.use(window.VueCharts);
Vue.component('PaginationControl', PaginationControl);
Vue.component('PaginationPage', PaginationPage);

Vue.use(VueMaterial);
Vue.use(VueRouter);
Vue.use(VModal);
Vue.use(VueFuse);
Vue.use(ToggleButton);

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MMM Do YY')
    }
});

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL

// Vue.filter('formatDate', function(value) {
//   if (value) {
//     return moment(String(value)).fromNow();
//   }
// });


// Vue.loadScript("https://api.mapbox.com/mapbox-gl-js/v1.11.0/mapbox-gl.js");
// Vue.loadScript("https://sgp1.digitaloceanspaces.com/baatocdn/baato-0.0.1.js");

const router = new VueRouter({
  routes: Routes,
  mode: 'history',
});

const store = createStore();

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
