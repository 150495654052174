<template>
  <div class="dashboard-cards">
    <md-card class="md-accent" md-with-hover id="card1">
      <md-card-header >
        <md-card-header-text>
          <div class="md-title1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">Today's Order</span>
            <!-- <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">{{dashboard_count.today_order}}</span> -->
            <span v-else-if="profile_obj.group.name == 'Delivery User'">Today's Delivery</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">Total Products</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">Total Products</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">Total Orders</span>
          </div>
          <div class="md-subhead1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">{{dashboard_count.today_order}}</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">{{dashboard_count.today_delivery}}</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">{{dashboard_count.total_product}}</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">{{dashboard_count.total_product}}</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">{{dashboard_count.total_order}}</span>
          </div>
        </md-card-header-text>
      </md-card-header>
    </md-card>

    <md-card class="md-accent" md-with-hover id="card2">
      <md-card-header >
        <md-card-header-text>
          <div class="md-title1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">Today's Delivered</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">Today's Delivered</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">Products in Stock</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">Products in Stock</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">Total Expenses</span>
          </div>
          <div class="md-subhead1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">{{dashboard_count.today_delivered}}</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">{{dashboard_count.today_delivered}}</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">{{dashboard_count.in_stock}}</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">{{dashboard_count.in_stock}}</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">Rs. {{dashboard_count.total_expense}}</span>
          </div>
        </md-card-header-text>
      </md-card-header>
    </md-card>

    <md-card class="md-accent" md-with-hover id="card3">
      <md-card-header >
        <md-card-header-text>
          <div class="md-title1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">Today's Confirmed Orders</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">Cancel Orders</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">Products Out of Stocks</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">Products Out of Stocks</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">Total Delivered Orders</span>
          </div>
          <div class="md-subhead1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">{{dashboard_count.today_order_accpted}}</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">{{dashboard_count.today_canceled}}</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">{{dashboard_count.out_of_stock}}</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">{{dashboard_count.out_of_stock}}</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">{{dashboard_count.total_delivered}}</span>
          </div>
        </md-card-header-text>
      </md-card-header>
    </md-card>

    <md-card class="md-accent" md-with-hover id="card4">
      <md-card-header >
        <md-card-header-text id="card-text">
          <div class="md-title1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">Today's Rejected Order</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">Delivery In Hold</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">Total Uses Category</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">Total Uses Category</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">Rejected Orders</span>
          </div>
          <div class="md-subhead1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">{{dashboard_count.today_order_rejected}}</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">{{dashboard_count.delivery_in_hold}}</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">{{dashboard_count.total_category}}</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">{{dashboard_count.total_category}}</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">{{dashboard_count.total_rejected}}</span>
          </div>
        </md-card-header-text>
      </md-card-header>
    </md-card>
     <!-- <md-card class="md-accent" md-with-hover id="card5">
      <md-card-header >
        <md-card-header-text id="card-text">
          <div class="md-title1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">Total Order Recieved</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">Delivery In Hold</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">Total Uses Category</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">Total Uses Category</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">Rejected Orders</span>
          </div>
          <div class="md-subhead1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">{{dashboard_count.today_order_rejected}}</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">{{dashboard_count.delivery_in_hold}}</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">{{dashboard_count.total_category}}</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">{{dashboard_count.total_category}}</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">{{dashboard_count.total_rejected}}</span>
          </div>
        </md-card-header-text>
      </md-card-header>
    </md-card>
     <md-card class="md-accent" md-with-hover id="card6">
      <md-card-header >
        <md-card-header-text id="card-text">
          <div class="md-title1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">Total Confirm Order</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">Delivery In Hold</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">Total Uses Category</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">Total Uses Category</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">Rejected Orders</span>
          </div>
          <div class="md-subhead1">
            <span v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">{{dashboard_count.today_order_rejected}}</span>
            <span v-else-if="profile_obj.group.name == 'Delivery User'">{{dashboard_count.delivery_in_hold}}</span>
            <span v-else-if="profile_obj.group.name == 'Outlets'">{{dashboard_count.total_category}}</span>
            <span v-else-if="profile_obj.group.name == 'Farmer'">{{dashboard_count.total_category}}</span>
            <span v-else-if="profile_obj.group.name == 'Mobile User' || profile_obj.group.name == 'Web User'">{{dashboard_count.total_rejected}}</span>
          </div>
        </md-card-header-text>
      </md-card-header>
    </md-card> -->
    
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'Cards',

    computed: {
      ...mapState(["profile_obj", "dashboard_count"])
    },

    created() {
      this.listProfile();
      if(this.profile_obj.admin || this.profile_obj.group.name == "Staff User"){
        this.dashBoardCount()
      }else if(this.profile_obj.group.name == "Delivery User"){
        this.deliveryDashCount()
      }else if(this.profile_obj.group.name == "Outlets"){
        this.outletDashCount()
      }else if(this.profile_obj.group.name == "Farmer"){
        this.sourceDashCount()
      }else if(this.profile_obj.group.name == "Web User" || this.profile_obj.group.name == "Mobile User"){
        this.customerDashCount()
      }else{
        this.deliveryDashCount()
      }
    },
    methods: {
      ...mapActions(["listProfile", "dashBoardCount", "deliveryDashCount", "outletDashCount", "sourceDashCount", "customerDashCount"]),
    }
};
</script>

<style lang="scss" >

</style>
