<template>
  <div class="gg-table product-tabtable">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>
    <div class="table-top-nav">
      <md-tabs>
        <md-tab id="tab-home" md-label="All" @click="tabIndex = 0"></md-tab>
        <md-tab id="tab-pages" md-label="Active" @click="tabIndex = 1"></md-tab>
        <md-tab
          id="tab-posts"
          md-label="Inactive"
          @click="tabIndex = 2"
        ></md-tab>
      </md-tabs>
      <md-field class="table-category">
        <md-select
          v-if="product_category && product_category.length > 0"
          v-model="category"
          name="category"
          id="category"
          placeholder="All Category"
        >
          <md-option class="table-filter-option" selected
            >All Category</md-option
          >
          <md-option
            class="table-filter-option"
            v-for="(cats, index) in product_category"
            :key="index"
            :value="cats.id"
            >{{ cats.name }}</md-option
          >
        </md-select>
      </md-field>
    </div>

    <div class="product-table">
      <md-table
        v-model="filterTableData.slice(count_start, count_end)"
        md-sort="name"
        md-sort-order="asc"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S.N" md-sort-by="sn"
            >{{ index + 1 + (current_page - 1) * 10 }}.</md-table-cell
          >
          <md-table-cell
            md-label="QR Code/ Batch No"
            md-sort-by="name"
            class="table-name"
          >
            {{  }}
          </md-table-cell>
          <md-table-cell
            md-label="Product Name"
            md-sort-by="name"
            class="table-name"
          >
            {{  }}
          </md-table-cell>
              <md-table-cell md-label="Receive Date " md-sort-by="added">{{
            item.created_at
          }}</md-table-cell>
          <md-table-cell md-label="Receive Quantity" md-sort-by="qty"
            >{{ item.total_quantity }} {{ item.unit.name }}</md-table-cell
          >
          <!-- <md-table-cell md-label="Add/Edit Unit" md-sort-by="addEditUnit"
            ><button class="gg-create-btn add-edit-button" @click="openDialogEditUnit(item.id)">
              Add/Edit Unit
            </button></md-table-cell
          > -->
          <md-table-cell md-label="Price" md-sort-by="price">{{
            item.price_out
          }}</md-table-cell>

      
          <md-table-cell md-label="Status" md-sort-by="status">{{
            
          }}</md-table-cell>

          <md-table-cell md-label="Action" md-sort-by="action">
            <div class="gg-dropdown-btn">
              <md-menu md-size="big">
                <md-button class="dropdown-icon" md-menu-trigger>
                  <i class="fas fa-caret-down"></i>
                </md-button>
                <md-menu-content class="gg-dropdown-menu">
                  <md-menu-item @click="detailbatch(item.id)"
                    >Detail Batch</md-menu-item
                  >
                  <md-menu-item @click="editProduct(item.id)"
                    >Update Batch</md-menu-item
                  >
                </md-menu-content>
              </md-menu>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="filterTableData && filterTableData.length">
        <ul class="nav-pagination">
          <li>
            <router-link
              :to="{
                name: current_route,
                query: { page: current_page <= 1 ? 1 : current_page - 1 },
              }"
              >&laquo;</router-link
            >
          </li>

          <li
            v-for="(page, index) in Math.ceil(
              filterTableData.length / page_sequence
            )"
            :class="current_page == page ? 'active' : ''"
            :key="index"
          >
            <router-link :to="{ name: current_route, query: { page: page } }">{{
              page
            }}</router-link>
          </li>

          <li>
            <router-link
              :to="{
                name: current_route,
                query: {
                  page:
                    current_page >=
                    Math.ceil(filterTableData.length / page_sequence)
                      ? Math.ceil(filterTableData.length / page_sequence)
                      : current_page + 1,
                },
              }"
              >&raquo;</router-link
            >
          </li>
        </ul>
      </div>
      <div>
        <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span
                class="material-icons error-icon"
                @click="showDialogEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form
              class="main-form"
              @submit.prevent="updateProduct(single_product.id)"
            >
              <h2 class="form-title">Edit Products</h2>

              <label for="product">Source Name *</label>
              <input
                type="text"
                class="product"
                v-model="single_product.name"
                placeholder="Source"
                required
              />
              <span class="form-error" v-if="errors.product_name">{{
                errors.product_name
              }}</span>

              <label for="product">Product Name*</label>
              <input
                type="text"
                class="product"
                v-model="single_product.nepali_name"
                placeholder="Product Name"
                required
              />
              <span class="form-error" v-if="errors.nepali_name">{{
                errors.nepali_name
              }}</span>

              <label for="quantity"> Quantity</label>
              <select class="quantity" v-model="single_category">
                <option
                  v-for="(category_obj, index) in product_category"
                  :key="index"
                  :value="category_obj.id"
                >
                  {{ category_obj.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.category_obj">{{
                errors.category_obj
              }}</span>

              <label for="price"> Price</label>
              <select class="price" v-model="single_category">
                <option
                  v-for="(category_obj, index) in product_category"
                  :key="index"
                  :value="category_obj.id"
                >
                  {{ category_obj.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.category_obj">{{
                errors.category_obj
              }}</span>

              <label for="date"> Date</label>
              <input type="date" />
              <span class="form-error" v-if="errors.category_obj">{{
                errors.category_obj
              }}</span>

              <label for="status"> Status</label>
              <input type="status" />
              <span class="form-error" v-if="errors.category_obj">{{
                errors.category_obj
              }}</span>

              <label for="status">QR Code</label>
              <input type="file" />
              <span class="form-error" v-if="errors.category_obj">{{
                errors.category_obj
              }}</span>

              <button v-if="!isLoading" type="submit" class="gg-create-btn">
                Save
              </button>
              <img
                v-else
                src="../assets/img/loader.gif"
                width="64"
                height="64"
                alt="Greengrowth Logo"
              />
            </form>
          </div>
        </md-dialog>
      </div>
      <div class="add-edit-unit-modal">
        <md-dialog :md-active.sync="showDialogEditUnit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span
                class="material-icons error-icon"
                @click="showDialogEditUnit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="addProductUnit">
              <h2 class="form-title">
                Add Units on product '{{ single_product.name }}'
              </h2>
              <label for="unit">Unit</label>
              <select class="unit" id="unit" v-model="single_product_unit">
                <option
                  v-for="(unit_obj, index) in product_unit"
                  :key="index"
                  :value="unit_obj.id"
                >
                  {{ unit_obj.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.single_product_unit">{{
                errors.single_product_unit
              }}</span>

              <label for="price">Product Unit Price *</label>
              <input
                type="text"
                v-model="single_product_price"
                name="price"
                placeholder="200"
                required
              />
              <span class="form-error" v-if="errors.single_product_price">{{
                errors.single_product_price
              }}</span>

              <span class="form-success" v-if="success">{{ success }}</span>

              <button type="submit" class="gg-create-btn">Save</button>
            </form>

            <div
              v-if="
                single_product.product_unit &&
                single_product.product_unit.length > 0
              "
              class="product-unit-form product-unit-form-edit"
            >
              <form class="order-detail-table-cost unit" @submit.prevent="">
                <h3>List units of '{{ single_product.name }}'</h3>
                <ul>
                  <li
                    v-for="(
                      product_unit_obj, index
                    ) in single_product.product_unit"
                    :key="index"
                    class="edit-unit-list"
                  >
                    <span>{{ product_unit_obj.unit.name }}</span>
                    <span>Rs. {{ product_unit_obj.price }}</span>
                    <span>
                      <button
                        class="gg-create-btn delete-button"
                        @click="openUnitRemoveDialog(product_unit_obj)"
                      >
                        Remove
                      </button>
                    </span>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </md-dialog>
      </div>
      <md-dialog :md-active.sync="showUnitRemoveDialog">
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons remove-icon">highlight_off</span>
          </div>
          <md-dialog-title class="modal-title"
            >Are you sure want to remove this product unit?</md-dialog-title
          >
          <md-dialog-actions>
            <md-button class="cancel-btn" @click="showUnitRemoveDialog = false"
              >Cancel</md-button
            >
            <md-button class="remove-btn" @click="deleteProductUnit"
              >Delete</md-button
            >
          </md-dialog-actions>
          <span class="form-success" v-if="success">{{ success }}</span>
        </div>
      </md-dialog>
      <div>
        <md-dialog :md-active.sync="showDialogRemove">
          <div class="modal-alert">
            <div class="icon-div">
              <span class="material-icons remove-icon">highlight_off</span>
            </div>
            <md-dialog-title class="modal-title"
              >Are you sure want to remove this item</md-dialog-title
            >
            <md-dialog-actions>
              <md-button class="cancel-btn" @click="showDialogRemove = false"
                >Cancel</md-button
              >
              <md-button class="remove-btn" @click="deleteProductForm"
                >Delete</md-button
              >
            </md-dialog-actions>
          </div>
        </md-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "ProductTabTable",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      Members: [],
      single_product: [],
      single_category: "",
      single_brand: null,
      single_unit: "",
      single_status: "",
      showDialog: true,
      openDialogRequest: false,
      showDialogEdit: false,
      showDialogEditUnit: false,
      showDialogEditUnitPrice: false,
      showDialogRemove: false,
      showUnitRemoveDialog: false,
      category_obj: "",
      unit_obj: "",
      availability_obj: "",
      product_name: "",
      quantity: "",
      price_in: "",
      price_out: "",
      image: "",
      errors: [],
      validsku: "",
      files: [],
      file: "",
      file_name: "",
      image_url: null,
      image: "",
      single_product_unit: "",
      single_product_price: "",
      selected_product_unit: [],
      success: "",
      category: null,
      sku: "",
      is_exclusive: false,
      tabIndex: 0,
      tabIsActive: false,
      activeTab: "",
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      boolean: true,
    };
  },
  computed: {
    ...mapState([
      "product_obj",
      "product_category",
      "product_unit",
      "product_brand",
      "success_message",
      "error_message",
      "response_message",
    ]),
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: { page: 1 },
      });
      let table_data = this.product_obj;
      if (this.tabIndex == 1) {
        table_data = table_data.filter((row) => {
          return row.product_status == "Available";
        });
      }
      if (this.tabIndex == 2) {
        table_data = table_data.filter((row) => {
          return row.product_status == "Not Available";
        });
      }
      if (this.category) {
        table_data = table_data.filter((row) => {
          return row.category.id == this.category;
        });
      }
      return table_data;
    },
  },
  created() {
    this.listProducts();
    this.listCategories();
    this.listUnits();
    this.listBrands();
    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0
        ? this.$route.query.page
        : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0
          ? this.$route.query.page
          : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    },
  },
  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions([
      "listProducts",
      "listCategories",
      "listUnits",
      "listBrands",
    ]),
    validateSKU() {
      if (this.sku == this.single_product.sku) {
        this.validsku = "yes";
      } else if (this.sku.length > 0) {
        this.errors["sku"] = "";
        this.isLoading = true;
        axios.defaults.headers.common["authorization"] =
          "JWT " + localStorage.getItem("token");
        axios
          .post("skuvalidate", { sku: this.sku })
          .then((response) => {
            this.isLoading = false;
            if (response.status == 200) {
              this.validsku = "yes";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.validsku = "no";
          });
      } else {
        this.validsku = "no";
      }
    },
    editProduct(product_id) {
      this.single_product = this.product_obj.find(
        (evt) => evt.id == product_id
      );
      this.single_category = this.single_product.category.id;
      this.sku = this.single_product.sku;
      this.single_unit = this.single_product.unit.id;
      this.single_brand = this.single_product.brand.id;
      this.single_status = this.single_product.product_status;
      this.image_url = this.single_product.image;
      this.is_exclusive =
        this.single_product.exclusives == "active" ? true : false;
      this.showDialogEdit = !this.showDialogEdit;
    },

    removesingleProduct(product_id) {
      this.showDialogRemove = !this.showDialogRemove;
      this.single_product = this.product_obj.find(
        (evt) => evt.id == product_id
      );
    },

    updateProduct(product_id) {
      this.errors = [];
      if (this.single_product.name == "") {
        this.errors["product_name"] = "Product Name required.";
      } else if (this.single_product.nepali_name == "") {
        this.errors["nepali_name"] = "Product Nepali Name required.";
      } else if (this.single_product.price_out == "") {
        this.errors["price_out"] = "Price Out required.";
      } else if (this.single_product.sku == "") {
        this.errors["sku"] = "SKU required.";
      } else if (this.validsku == "no") {
        this.errors["sku"] = "";
        this.validsku = "no";
      } else if (this.single_brand == null || this.single_brand == "") {
        this.errors["brand"] = "Brand required.";
      } else if (this.single_product.description == "") {
        this.errors["description"] = "Description required.";
      } else if (this.single_product.nepali_description == "") {
        this.errors["nepali_description"] = "Nepali Description required.";
      } else if (this.single_product.return_policy == "") {
        this.errors["return_policy"] = "Return Policy required.";
      } else if (this.single_product.availability_obj == "") {
        this.errors["availability_obj"] = "Availability required.";
      } else {
        if (this.single_product.sku == this.sku || this.validsku == "yes") {
          this.isLoading = true;
          let formData = new FormData();
          formData.append("name", this.single_product.name);
          formData.append("nepali_name", this.single_product.nepali_name);
          formData.append("category", this.single_category);
          formData.append("unit", this.single_unit);
          formData.append("quantity", 1);
          formData.append("price_in", this.single_product.price_in);
          formData.append("price_out", this.single_product.price_out);
          formData.append("image", this.file ? this.file : "");
          formData.append("sku", this.sku);
          formData.append("description", this.single_product.description);
          formData.append(
            "nepali_description",
            this.single_product.nepali_description
          );
          formData.append("return_policy", this.single_product.return_policy);
          formData.append("brand", this.single_brand);
          formData.append(
            "exclusives",
            this.is_exclusive ? "active" : "inactive"
          );
          formData.append("product_status", this.single_status);
          formData.append("id", product_id);

          for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
          }

          this.$store.dispatch("updateProduct", formData).then(() => {
            if (this.success_message == "success") {
              this.success = "Product Updated Successfully.";
              this.isLoading = false;
              setTimeout(function () {
                setTimeout(() => {
                  location.reload();
                }, 100);
                this.isLoading = false;
              }, 1000);
            } else if (this.error_message == "errormessage") {
              alert(JSON.stringify(this.response_message));
            }
          });
        } else {
          // error
          this.validsku = "no";
        }
      }
    },
    deleteProductForm() {
      this.showDialogRemove = false;
      this.$store.dispatch("deleteProduct", this.single_product.id).then(() => {
        if (this.success_message == "success") {
          location.reload();
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    },
    // For Image Upload
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
    openDialogEditUnit(product_id) {
      this.single_product = this.product_obj.find(
        (evt) => evt.id == product_id
      );
      this.showDialogEditUnit = true;
    },
    addProductUnit() {
      this.errors = [];
      if (this.single_product_unit == "") {
        this.errors["single_product_unit"] = "Product unit value required.";
      } else if (this.single_product_price == "") {
        this.errors["single_product_price"] = "Product unit price required.";
      } else {
        //
        let formData = new FormData();
        formData.append("unit", this.single_product_unit);
        formData.append("product", this.single_product.id);
        formData.append("price", this.single_product_price);

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.$store.dispatch("createProductUnit", formData).then(() => {
          if (this.success_message == "success") {
            this.success = "Product Unit Added Successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(JSON.stringify(this.response_message));
          }
        });
      }
    },
    openUnitRemoveDialog(product_unit_obj) {
      this.errors = [];
      var productId = product_unit_obj.product;
      this.single_product = this.product_obj.find((evt) => evt.id == productId);
      if (this.single_product.unit.id == product_unit_obj.unit.id) {
        alert(
          "Sorry! you can't remove the main unit value. Please change the product unit on update, then only you can remove this item?"
        );
      } else {
        this.selected_product_unit = product_unit_obj;
        this.showUnitRemoveDialog = true;
      }
    },
    deleteProductUnit() {
      this.$store
        .dispatch("deleteProductUnit", this.selected_product_unit.id)
        .then(() => {
          if (this.success_message == "success") {
            this.success = "Product Unit Deleted Successfully.";
            this.showDialogEditUnit = false;
            this.showUnitRemoveDialog = false;
            setTimeout(() => {
              location.reload();
            }, 500);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
.gg-create-btn.add-edit-button {
  font-size: 13px;
  padding: 5px 10px;
}

.product-unit-form-edit {
  grid-template-columns: auto;
}

.custom-switch {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    margin: 0 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@media (max-width: 600px) {
  .product-unit-form-edit .order-detail-table-cost.unit ul li {
    display: block;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span {
    margin-bottom: 10px;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span button {
    margin: 0;
    margin-right: 20px;
  }
}
</style>
