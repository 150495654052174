<template>
  <div class="main-form">
    <h2 class="form-title">Receive Product Form</h2>
    <label for="product">Batch ID*</label>
    <select v-model="batch_id" aria-placeholder="dd">
      <option value disabled>Select Batch</option>
      <option
        v-for="(all_unit_obj, index) in product_unit"
        :key="index"
        :value="all_unit_obj.id"
      >
        {{ all_unit_obj.name }}
      </option>
    </select>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.product_name"
      >{{ errors.product_name }}</span
    >

    <label for="category">Recieve Quantity*</label>
    <md-field md-clearable>
      <md-input v-model="initial"></md-input>
    </md-field>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.category_obj"
      >{{ errors.category_obj }}</span
    >

    <label for="category">Recieve Date*</label>
    <input type="date" />
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.category_obj"
      >{{ errors.category_obj }}</span
    >

    <label for="quantity">Status *</label>
    <select v-model="status_obj">
      <option value disabled>Pending</option>
      <option
        v-for="(all_unit_obj, index) in product_unit"
        :key="index"
        :value="all_unit_obj.id"
      >
        {{ all_unit_obj.name }}
      </option>
    </select>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.unit_obj"
      >{{ errors.unit_obj }}</span
    >

    <label for="price">Remarks*</label>
    <textarea name="" id="" cols="30" rows="10"></textarea>
    <span
      class="text-danger float-right"
      style="color: red"
      v-if="errors.price_out"
      >{{ errors.price_out }}</span
    >
    <span style="color: red" v-if="error_message.length > 0"
      >Product Name Already Exist.<br
    /></span>
    <span style="color: green" v-if="success_message.length > 0"
      >Product Added Successfully.<br
    /></span>

    <input
      type="submit"
      @click="addProduct"
      value="Add"
      class="gg-create-btn"
      v-if="!isLoading"
    />
    <img
      v-else
      src="../assets/img/loader.gif"
      width="64"
      height="64"
      alt="Greengrowth Logo"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  computed: {
    ...mapState([
      "product_category",
      "product_unit",
      "product_brand",
      "success_message",
      "error_message",
      "response_message",
    ]),
    filterCategories() {
      let allCategories = this.product_category;

      allCategories = allCategories.filter((row) => {
        return row.category_status == "Available";
      });

      return allCategories;
    },
  },

  created() {
    this.listCategories();
    this.listUnits();
    this.listBrands();
  },

  data() {
    return {
      isLoading: false,
      category_source_name: "",
      category_product_name: "",
      dispatch_last_date: "",
      auto_generated_batch_id: "",
      unit_obj: "",
      availability_obj: "",
      product_name: "",
      nepali_name: "",
      sku: "",
      quantity: 1,
      price_in: "",
      price_out: "",
      image: "",
      errors: [],
      validsku: "",
      files: [],
      file: "",
      brand: null,
      description: "",
      nepali_description: "",
      return_policy: "",
      image_url: null,
      is_exclusive: false,
      initial:"200"
    };
  },

  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions(["listCategories", "listUnits", "listBrands"]),
    validateSKU() {
      if (this.sku.length > 0) {
        this.errors["sku"] = "";
        this.isLoading = true;
        axios.defaults.headers.common["authorization"] =
          "JWT " + localStorage.getItem("token");
        axios
          .post("skuvalidate", { sku: this.sku })
          .then((response) => {
            this.isLoading = false;
            if (response.status == 200) {
              this.validsku = "yes";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.validsku = "no";
          });
      } else {
        this.validsku = "no";
      }
    },
    addProduct() {
      this.errors = [];
      if (this.product_name == "") {
        this.errors["product_name"] = "Product Name required.";
      } else if (this.nepali_name == "") {
        this.errors["nepali_name"] = "Product Nepali name required.";
      } else if (this.category_obj == "") {
        this.errors["category_obj"] = "Category required.";
      } else if (this.sku == "") {
        this.errors["sku"] = "SKU required.";
      } else if (this.validsku == "no") {
        this.errors["sku"] = "";
        this.validsku = "no";
      } else if (this.unit_obj == "") {
        this.errors["unit_obj"] = "Unit required.";
      } else if (this.price_out == "" || this.price_out <= 0) {
        this.errors["price_out"] = "Price Out required.";
      } else if (this.file == "") {
        this.errors["file"] = "Image required.";
      } else if (this.brand == "" || this.brand == null) {
        this.errors["brand"] = "Brand required.";
      } else if (this.availability_obj == "") {
        this.errors["availability_obj"] = "Availability required.";
      } else {
        if (this.validsku == "yes") {
          this.isLoading = true;

          let formData = new FormData();
          formData.append("name", this.product_name);
          formData.append("nepali_name", this.nepali_name);
          formData.append("category", this.category_obj);
          formData.append("unit", this.unit_obj);
          formData.append("quantity", this.quantity);
          formData.append("price_in", 0);
          formData.append("price_out", this.price_out);
          formData.append("image", this.file);
          formData.append("sku", this.sku);
          formData.append("description", this.description);
          formData.append("nepali_description", this.nepali_description);
          formData.append("return_policy", "n/a");
          formData.append("brand", this.brand);
          formData.append(
            "exclusives",
            this.is_exclusive ? "active" : "inactive"
          );
          formData.append("product_status", this.availability_obj);

          // for (var pair of formData.entries()) {
          //   console.log(pair[0]+ ', ' + pair[1]);
          // }

          this.$store.dispatch("createProduct", formData).then(() => {
            if (this.success_message == "success") {
              this.image = "";
              this.product_name = "";
              this.category_obj = "";
              this.unit_obj = "";
              this.sku = "";
              this.price_in = "";
              this.price_out = "";
              this.brand = 1;
              this.description = "";
              this.availability_obj = "";

              setTimeout(function () {
                setTimeout(() => {
                  location.reload();
                }, 100);
                this.isLoading = false;
              }, 1000);
            } else if (this.error_message == "errormessage") {
              // console.log(this.response_message)
              // this.setErrorMessage("");
              // this.setMessage("");
            }
          });
        } else {
          // error
          this.validsku = "no";
        }
      }
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
  },
};
</script>

<style lang="scss" >
.main-form {
  .upload-file-area {
    height: 120px;
    border: 1px dashed #4ba069;
    text-align: center;
    position: relative;
    .choose-button {
      background: #4ba069;
      padding: 10px 40px;
      color: #fff;
      border: none;
      margin-bottom: 20px;
    }
    .or {
      color: grey;
    }
    .drag-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;
    }
    input[type="file"] {
      border: none;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
</style>
