<template>
  <div class="gg-table product-tabtable">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>
    <div class="table-top-nav">
      <md-tabs>
        <md-tab id="tab-home" md-label="All" @click="tabIndex = 0"></md-tab>
        <md-tab id="tab-pages" md-label="New" @click="tabIndex = 1"></md-tab>
        <md-tab id="tab-posts" md-label="Dispatched" @click="tabIndex = 2"></md-tab>
      </md-tabs>
    </div>
    
    <div class="product-table">
      <md-table v-model="filterTableData.slice(count_start, count_end)" md-sort="name" md-sort-order="asc" >
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S.N" md-sort-by="sn">
            {{ index + 1 + (current_page - 1) * 10 }}.
          </md-table-cell>
          <md-table-cell
            md-label="Product Name"
            md-sort-by="name"
            class="table-name"
          >
            {{ item.product.name }}<br/>({{item.product.id}})
          </md-table-cell>
          <md-table-cell md-label="Quantity" md-sort-by="qty"
            >{{ item.order_quantity }} {{ item.unit.name }}</md-table-cell>
          <md-table-cell md-label="Date " md-sort-by="added">{{ item.created_at }}</md-table-cell>
          <md-table-cell md-label="Status" md-sort-by="status">{{ item.status }}</md-table-cell>
          <md-table-cell
            md-label="Print QR"
            md-sort-by="qr-code"
            >
              <h3>{{item.id}}</h3>
              <button class="gg-create-btn" @click="printQRCode(item.qrcode, item.id)">PRINT QR</button>

          </md-table-cell>
          <md-table-cell md-label="Action" md-sort-by="action">
            <div class="gg-dropdown-btn">
              <md-menu md-size="big">
                <md-button class="dropdown-icon" md-menu-trigger>
                  <i class="fas fa-caret-down"></i>
                </md-button>
                <md-menu-content class="gg-dropdown-menu">
                  <router-link :to="{name: 'DispatchDetail', params:{id: item.id}}">
                  <md-menu-item
                    >Detail Batch</md-menu-item
                  >
                  </router-link>
                  <md-menu-item v-if="item.status==='Pending'" @click="dispatchBatch(item.id)"
                    >Dispatch Batch</md-menu-item
                  >
                </md-menu-content>
              </md-menu>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="filterTableData && filterTableData.length">
        <ul class="nav-pagination">
          <li>
            <router-link
              :to="{
                name: current_route,
                query: { page: current_page <= 1 ? 1 : current_page - 1 },
              }"
              >&laquo;</router-link
            >
          </li>

          <li
            v-for="(page, index) in Math.ceil(
              filterTableData.length / page_sequence
            )"
            :class="current_page == page ? 'active' : ''"
            :key="index"
          >
            <router-link :to="{ name: current_route, query: { page: page } }">{{
              page
            }}</router-link>
          </li>

          <li>
            <router-link
              :to="{
                name: current_route,
                query: {
                  page:
                    current_page >=
                    Math.ceil(filterTableData.length / page_sequence)
                      ? Math.ceil(filterTableData.length / page_sequence)
                      : current_page + 1,
                },
              }"
              >&raquo;</router-link
            >
          </li>
        </ul>
      </div>
      <div>
        <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span
                class="material-icons error-icon"
                @click="showDialogEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form
              class="main-form"
              @submit.prevent="updateProductBatch(single_batch.id)"
            >
              <h2 class="form-title">Dispatch Batch</h2>

              <label for="product">Dispatched Product Quantity *</label>
              <input
                type="number"
                class="product"
                v-model="single_batch.order_quantity"
                placeholder="Dispatched Product Quantity"
                required
              />
              <span class="form-error" v-if="errors.order_quantity">{{
                errors.order_quantity
              }}</span>

              <label for="price"> Dispatch Product Unit *</label>
              <select class="price" v-model="single_unit">
                <option selected disabled>Select Unit</option>
                <option
                  v-for="(unit, index) in product_unit"
                  :key="index"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.single_unit">{{
                errors.single_unit
              }}</span>

              <label for="date">Dispatch Date</label>
              <input type="date" v-model="single_batch.dispatched_at"/>
              <span class="form-error" v-if="errors.dispatched_at">{{
                errors.dispatched_at
              }}</span>

              <label for="status"> Product Production Time (in days)</label>
              <input type="number" minlength="1" step="0.01" v-model="product_production_time"/>
              <span class="form-error" v-if="errors.product_production_time">{{
                errors.product_production_time
              }}</span>

              <label for="status">Dispatch Messagae</label>
              <textarea rows="4" v-model="dispatched_remarks"></textarea>
              <span class="form-error" v-if="errors.dispatched_remarks">{{
                errors.dispatched_remarks
              }}</span>

              <span v-if="success" style="color: green;">Product Dispatched Successfully</span>
              <button v-if="!isLoading" type="submit" class="gg-create-btn">
                Save
              </button>
              <img
                v-else
                src="../assets/img/loader.gif"
                width="64"
                height="64"
                alt="Greengrowth Logo"
              />
            </form>
          </div>
        </md-dialog>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapState, mapMutations, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import printJS from 'print-js';

export default {
  name: 'ProductTabTable',
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      Members: [],
      single_batch: [],
      single_category: '',
      single_brand: null,
      single_unit: null,
      single_status: '',
      showDialog: true,
      openDialogRequest: false,
      showDialogEdit: false,
      showDialogEditUnit: false,
      showDialogEditUnitPrice: false,
      showDialogRemove: false,
      showUnitRemoveDialog: false,
      product_production_time: '',
      dispatched_remarks: '',
      category_obj: '',
      unit_obj: '',
      availability_obj: '',
      product_name: '',
      quantity: '',
      price_in: '',
      price_out: '',
      image: '',
      errors: [],
      validsku: '',
      files: [],
      file: '',
      file_name: '',
      image_url: null,
      single_product_unit: '',
      single_product_price: '',
      selected_product_unit: [],
      success: '',
      category: null,
      sku: '',
      is_exclusive: false,
      tabIndex: 0,
      tabIsActive: false,
      activeTab: '',
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      boolean: true,
      mediaRootUrl: process.env.VUE_APP_API_BASE_IP,
    };
  },
  computed: {
    ...mapState([
      'product_obj',
      'batch_obj',
      'product_category',
      'product_unit',
      'product_brand',
      'success_message',
      'error_message',
      'response_message',
    ]),
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: { page: 1 },
      });
      let table_data = this.batch_obj;
      if (this.tabIndex === 1) {
        table_data = table_data.filter((row) => {
          return row.status === 'Pending';
        });
      }
      if (this.tabIndex === 2) {
        table_data = table_data.filter((row) => {
          return row.status === 'Dispatched' || row.status === 'Received';
        });
      }
      if (this.category) {
        table_data = table_data.filter((row) => {
          return row.category.id === this.category;
        });
      }
      return table_data;
    },
  },
  created() {
    this.getSourceBatch();
    this.listProducts();
    this.listCategories();
    this.listUnits();
    this.listBrands();
    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0
        ? this.$route.query.page
        : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0
          ? this.$route.query.page
          : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    },
  },
  methods: {
    ...mapMutations(["setSuccessMessage", "setErrorMessage", "setMessage"]),
    ...mapActions([
      "getSourceBatch",
      "listProducts",
      "listCategories",
      "listUnits",
      "listBrands",
    ]),
    printQRCode(qrcodeurl, qucodeid) {
      printJS({ printable: this.mediaRootUrl + qrcodeurl, type: 'image', header: 'Green Growth Batch QR Code: ' + qucodeid });
    },
    dispatchBatch(batch_id) {
      this.single_batch = this.batch_obj.find(
        (evt) => evt.id == batch_id
      );
      // this.single_category = this.single_batch.category.id;
      // this.sku = this.single_batch.sku;
      this.single_unit = this.single_batch.unit.id;
      // this.single_brand = this.single_batch.brand.id;
      // this.single_status = this.single_batch.product_status;
      // this.image_url = this.single_batch.image;
      // this.is_exclusive =
      //   this.single_batch.exclusives == "active" ? true : false;
      this.showDialogEdit = !this.showDialogEdit;
    },

    updateProductBatch(batchId) {
      this.errors = [];
      if (this.single_batch.order_quantity === '') {
        this.errors.order_quantity = 'Batch Product Quantity Required.';
      } else if (!this.single_unit || this.single_unit === '') {
        this.errors.single_unit = 'Batch Product Unit Required';
      } else if (this.single_batch.dispatched_at === '') {
        this.errors.dispatched_at = 'Dispatched Date Required.';
      } else if (this.product_production_time === '') {
        this.errors.product_production_time = 'Product Production Time Required.';
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append('dispatched_quantity', this.single_batch.order_quantity);
        formData.append('unit', this.single_unit);
        formData.append('dispatched_at', this.single_batch.dispatched_at);
        formData.append('product_production_time', this.product_production_time);
        formData.append('dispatched_remarks', this.dispatched_remarks);
        formData.append('batch_id', batchId);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }

        this.$store.dispatch('dispatchProductBatch', formData).then(() => {
          if (this.success_message === 'success') {
            this.success = 'Product Updated Successfully.';
            this.isLoading = false;
            setTimeout(() => {
              setTimeout(() => {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
              }, 100);
              this.isLoading = false;
            }, 1000);
          } else if (this.error_message === 'errormessage') {
            // eslint-disable-next-line no-alert
            alert(JSON.stringify(this.response_message));
          }
        });
      } // else
    },
  },
};
</script>

<style lang="scss" scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
.gg-create-btn.add-edit-button {
  font-size: 13px;
  padding: 5px 10px;
}

.product-unit-form-edit {
  grid-template-columns: auto;
}

.custom-switch {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    margin: 0 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@media (max-width: 600px) {
  .product-unit-form-edit .order-detail-table-cost.unit ul li {
    display: block;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span {
    margin-bottom: 10px;
  }
  .product-unit-form-edit .order-detail-table-cost.unit ul li span button {
    margin: 0;
    margin-right: 20px;
  }
}
</style>