<template>
  <div class="categories">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">widgets</md-icon>
        <h1>Change Profile Password</h1>
      </md-toolbar>

      <aside class="category-form">
        <form class="create-post">
          <span
            style="
              color: red;
              font-size: 17px;
              font-weight: bold;
              padding-bottom: 10px;
            "
            v-if="error_message.length > 0"
            >Old Password Not Matched.<br
          /></span>
          <span
            style="
              color: green;
              font-size: 17px;
              font-weight: bold;
              padding-bottom: 10px;
            "
            v-if="success_message.length > 0"
            >Password Updated Successfully.<br
          /></span>

          <label for="old_password">Old Password *</label>
          <div class="password-icon">
            <input
              :type="old_password_type"
              v-model.trim="form.old_password"
              id="old_password"
              placeholder="Old Password"
            />
            <i
              :class="'fas ' + old_password_eye + ' move_to_up'"
              @click="showHideOldPassword"
            ></i>
          </div>
          <span
            class="text-danger float-right"
            style="color: red"
            v-if="errors.old_password"
            >{{ errors.old_password }}</span
          >

          <label for="password">New Password *</label>
          <div class="password-icon">
            <input
              :type="password_type"
              v-model.trim="form.new_password"
              id="password"
              placeholder="New Password"
            />
            <i
              :class="'fas ' + password_eye + ' move_to_up'"
              @click="showHidePassword"
            ></i>
          </div>
          <span
            class="text-danger float-right"
            style="color: red"
            v-if="errors.new_password"
            >{{ errors.new_password }}</span
          >

          <label for="password">Confirm Password *</label>
          <div class="password-icon">
            <input
              :type="conf_password_type"
              v-model.trim="form.confirm_password"
              id="password"
              placeholder="Confirm Password"
            />
            <i
              :class="'fas ' + conf_password_eye + ' move_to_up'"
              @click="showHideConfPassword"
            ></i>
          </div>

          <span
            class="text-danger float-right"
            style="color: red"
            v-if="errors.confirm_password"
            >{{ errors.confirm_password }}</span
          >

          <button class="post-btn" @click="changePassword">SUBMIT</button>
        </form>
      </aside>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex";
export default {
  name: "changePassword",
  components: {},
  computed: {
    ...mapState(["success_message", "error_message", "response_message"]),
  },
  data() {
    return {
      form: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      old_password_eye: "fa-eye-slash",
      old_password_type: "password",
      password_eye: "fa-eye-slash",
      password_type: "password",
      conf_password_eye: "fa-eye-slash",
      conf_password_type: "password",
      valid_password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
      errors: [],
    };
  },
  methods: {
    changePassword(e) {
      e.preventDefault();
      this.errors = [];
      if (this.form.old_password == "") {
        this.errors["old_password"] = "Old Password required.";
      } else if (this.form.new_password == "") {
        this.errors["new_password"] = "Password required.";
      } else if (!this.valid_password.test(this.form.new_password)) {
        this.errors["new_password"] =
          "Password Must contain Capital and Small Letter, Number and Special Character.";
      } else if (this.form.confirm_password == "") {
        this.errors["confirm_password"] = "Confirm Password required.";
      } else if (this.form.new_password != this.form.confirm_password) {
        this.errors["confirm_password"] = "Confirm Password not match.";
      } else {
        //changeProfilePassword
        let formData = new FormData();
        formData.append("old_password", this.form.old_password);
        formData.append("new_password", this.form.new_password);
        formData.append("confirm_password", this.form.confirm_password);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0]+ ', ' + pair[1]);
        // }

        this.$store.dispatch("changeProfilePassword", formData).then(() => {
          if (this.success_message == "success") {
            this.form.old_password = "";
            this.form.new_password = "";
            this.form.confirm_password = "";
            setTimeout(() => {
              this.$router.push({
                name: "homepage",
              });
            }, 1000);
          } else if (this.error_message == "errormessage") {
          }
        });
      }
    },
    showHidePassword() {
      if (this.password_type == "password") {
        this.password_eye = "fa-eye";
        this.password_type = "text";
      } else {
        this.password_eye = "fa-eye-slash";
        this.password_type = "password";
      }
    },
    showHideConfPassword() {
      if (this.conf_password_type == "password") {
        this.conf_password_eye = "fa-eye";
        this.conf_password_type = "text";
      } else {
        this.conf_password_eye = "fa-eye-slash";
        this.conf_password_type = "password";
      }
    },
    showHideOldPassword() {
      if (this.old_password_type == "password") {
        this.old_password_eye = "fa-eye";
        this.old_password_type = "text";
      } else {
        this.old_password_eye = "fa-eye-slash";
        this.old_password_type = "password";
      }
    },
  },
};
</script>
<style scoped>
.move_to_up {
  z-index: 9999;
}
</style>