<template>
  <div class="products">
    <div class="contents">
      <md-toolbar class="product-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-carrot"></i>
        </md-icon>
        <h1>Dispatch Product</h1>
      </md-toolbar>
      <!-- <div class="add-btn">
        <button class="gg-create-btn product-request-btn" @click="dispatchProductform = !dispatchProductform" >+ Dispatch Product</button>
      </div> -->
      <!-- <h2>Add Unit</h2>
      <ProductUnitForm /> -->
      <!-- <md-toolbar class="product-nav section-title table-title-button"> -->
      <h2>Table of Dispatched Batch Products</h2>
      <!-- <div class="table-edit-buttons">
          <button data-v-3cec2962 class="gg-create-btn table-edit" @click="disabled=!disabled">Edit</button>
          <button data-v-3cec2962 class="gg-create-btn table-edit">Save Changes</button>
      </div>-->
      <!-- </md-toolbar> -->
      <DispatchProductTable/>
    </div>
    <ModalDispatch v-if="dispatchProductform" />
  </div>
</template>

<script>
// @ is an alias to /src
import DispatchProductTable from "@/components/DispatchProductTable.vue";
import ModalDispatch from "@/components/ModalDispatch.vue";

export default {
  name: 'Product',
  components: {
    DispatchProductTable,
    ModalDispatch,
  },
  data() {
    return {
      dispatchProductform: false,
    };
  },
};
</script>
<style lang='scss' scoped>
.product-request-btn{
  margin: 0px 10px;
}
</style>
