<template>
    <div class="orderdetail">
        <div class="contents">
        <md-toolbar class="order-nav">
            <div class="content-title-navigation">
            <md-icon class="navbar-icon1">local_mall</md-icon>
            <span class="content1">Batch</span>
            <md-icon class="navbar-icon2">navigate_next</md-icon>
            <span class="content2">Details</span>
            </div>
        </md-toolbar>
        <div class="order-detail-vue">
            <table class="order-detail-table">
                <tr>
                    <td class="td1" colspan="2"><h2>Product Batch Detail:</h2></td>
                </tr>
                <tr>
                    <td class="td1" colspan="2">Product Name:</td>
                    <td class="td2" colspan="2">{{singleBatch.product.name}} ({{singleBatch.product.id}})</td>
                </tr>
                <tr>
                    <td class="td1" colspan="2">Source Name:</td>
                    <td class="td2" colspan="2">{{singleBatch.source.full_name}}</td>
                </tr>
                <tr>
                    <td class="td1" colspan="2">Request Quantity:</td>
                    <td class="td2" colspan="2">{{singleBatch.order_quantity}} {{singleBatch.unit.name}}</td>
                </tr>
                <tr>
                    <td class="td1" colspan="2">Request Date:</td>
                    <td class="td2" colspan="2">{{singleBatch.created_at}}</td>
                </tr>
                <tr v-if="singleBatch.dispatched_quantity != null">
                    <td class="td1" colspan="2">Dispatched Quantity:</td>
                    <td class="td2" colspan="2">{{singleBatch.dispatched_quantity}} {{singleBatch.unit.name}}</td>
                </tr>
                <tr v-if="singleBatch.dispatched_at != null">
                    <td class="td1" colspan="2">Dispatched Date:</td>
                    <td class="td2" colspan="2">{{singleBatch.dispatched_at}}</td>
                </tr>
                <tr v-if="singleBatch.received_quantity != null">
                    <td class="td1" colspan="2">Received Quantity:</td>
                    <td class="td2" colspan="2">{{singleBatch.received_quantity}} {{singleBatch.unit.name}}</td>
                </tr>
                <tr v-if="singleBatch.received_at != null">
                    <td class="td1" colspan="2">Received Date:</td>
                    <td class="td2" colspan="2">{{singleBatch.received_at}}</td>
                </tr>
                <tr>
                    <td class="td1" colspan="2">Batch Status:</td>
                    <td class="td2" colspan="2">{{singleBatch.status}}</td>
                </tr>
                <tr>
                    <td class="td1" colspan="2">
                        <button class="gg-create-btn" @click="printQRCode">PRINT QR CODE</button>
                    </td>
                </tr>
            </table>

            <table class="order-detail-table-cost">
                <tr>
                    <td class="td1" colspan="2">
                        <img :src="mediaRootUrl + singleBatch.qrcode"  alt="QR Code of Product"/><br/>
                    </td>
                </tr>
                <tr>
                    <td class="td1">Batch Code:</td>
                    <td class="td2"><h2>{{singleBatch.id}}</h2></td>
                </tr>
            </table>
        </div><!-- // -->

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import printJS from 'print-js';

export default {
  name: 'BatchDetail',
  components: {
    //
  },
  data() {
    return {
      batch_id: this.$route.params.id,
      mediaRootUrl: process.env.VUE_APP_USER_PANEL,
    };
  },
  computed: {
    ...mapState(['batch_obj']),
    singleBatch() {
      let newbatchdata = this.batch_obj;
      if (newbatchdata) {
        newbatchdata = newbatchdata.filter((row) => {
          return row.id === this.batch_id;
        });
      }
      return newbatchdata[0];
    },
  },
  created() {
    this.getProductBatch();
  },
  methods: {
    ...mapActions(['getProductBatch']),
    printQRCode() {
      printJS({
        printable: this.mediaRootUrl + this.singleBatch.qrcode,
        type: 'image',
        header: 'Green Growth Batch QR Code: ' + this.batch_id
      });
    },
  },
};
</script>
