<template>
  <div class='customers'>
    <div class='contents'>
      <md-toolbar class='nav page-title'>
        <md-icon class='navbar-icon1'>people_outline</md-icon>
        <h1>Customers</h1>
      </md-toolbar>
      <Cards />
        <!-- <md-toolbar class='nav section-title'> -->
        <h2>Users List</h2>
      <!-- </md-toolbar> -->
      <UserTable class="customer-table" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Cards from '@/components/Cards.vue';
import UserTable from '@/components/UserTable.vue';

export default {
  name: 'Customers',
  components: {
    Cards,
    UserTable,
  },
};
</script>
<style lang='scss' scoped>

</style>
