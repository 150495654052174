<template>
  <div>
    <div class="product-card">
      <div class="product-image" id="product-details">
        <img src="@/assets/img/detailpic1.png" alt="Rice" />
        <div class="preview-product-icons">
          <a class="preview-icon" @click="openDialog = !openDialog" href="#">
            <i class="fas fa-pen"></i>
          </a>
          <!-- <a class="add-to-cart-icon" href="#">
            <i class="fas fa-share"></i>
          </a>-->
        </div>
      </div>
      <div class="description-card">
        <a class="product-description" href="#">
          <h1>Finger miler flour</h1>
        </a>
        <div class="product-quantity">
          <p>Rs 200</p>
          <span>Quantity: 50kg</span>
        </div>
        <div class="star-rating">
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
        <!-- <i class="far fa-check-circle"></i> -->
      </div>
    </div>
    <ModalProductEdit v-if="openDialog" />
  </div>
</template>

<script>
import ModalProductEdit from "@/components/ModalProductEdit.vue";

export default {
  name: "ProductCart",
  components: {
    ModalProductEdit,
  },
  data() {
    return {
      showPreviewIcons: false,
      openDialog: false,
    };
  },
};
</script>

<style>
</style>
