<template>
  <div class="sources">
    <div class="contents">
      <md-toolbar class="source-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-carrot"></i>
        </md-icon>
        <h1>Sources</h1>
      </md-toolbar>
      <!-- <div class="add-btn">
        <button class="gg-create-btn" @click="openDialog = !openDialog">+ New Source</button>
      </div> -->
      <!-- <md-field class="source-nav1"> -->
        <h2>Table of Sources</h2>
        <!-- <md-select class="option">
          <md-option value="0">Today</md-option>
          <md-option value="1">Monday</md-option>
        </md-select> -->
      <!-- </md-field>                               -->
      <TableSource />
    </div>
    <ModalSource v-if="openDialog" />
  </div>
</template>

<script>
// @ is an alias to /src
import TableSource from "@/components/TableSource.vue";
import ModalSource from "@/components/ModalSource.vue";

export default {
  name: "Sources",
  components: {
    TableSource,
    ModalSource,
  },
  data: () => ({
    openDialog: false
  })
};
</script>
<style lang='scss' scoped>
</style>
