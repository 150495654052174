<template>
  <div class="member-detail">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">people_outline</md-icon>
        <span class="content1">Members</span>
        <md-icon class="navbar-icon2">navigate_next</md-icon>
        <span class="content2">Source Member</span>
      </md-toolbar>
      <Cards />
      <OrderDetailCards />
      <TabTableDetail />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import OrderDetailCards from '@/components/OrderDetailCards.vue';
import TabTableDetail from '@/components/TabTableDetail.vue';
import Cards from '@/components/Cards.vue';

export default {
  name: 'MemberDetail',
  components: {
    Cards,
    OrderDetailCards,
    TabTableDetail,
  },
};
</script>
<style lang="scss" scoped>

</style>
