<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <form class="modal-form" @submit.prevent="addTask">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <div class="main-form">
          <h2 class="form-title">Add Task</h2>

          <label for="fname">Assigned To</label>
          <input type="text" class="fname" list="assignee" v-model="assignee_name" placeholder="Eg. Dipak Khadka" />
          <datalist id="assignee">
            <option>
              Ram
            </option>
            <option>
              Bhakta
            </option>
          </datalist>
          <span class="form-error" v-if="errors.assignee_name">{{errors.assignee_name}}</span>

          <label for="pname">Product Name</label>
          <input
            type="text"
            id="pname"
            name="pname"
            v-model="product_name"
            placeholder="Eg. Potato"
          />
          <span class="form-error" v-if="errors.product_name">{{errors.product_name}}</span>

          <label for="location">Location</label>
          <input
            type="text"
            id="location"
            name="location"
            v-model="location"
            placeholder="Eg. Bhaktapur"
          />
          <span class="form-error" v-if="errors.location">{{errors.location}}</span>

          <label for="quantity">Quantity</label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            v-model="quantity"
            placeholder="Eg. 250"
          />
          <span class="form-error" v-if="errors.quantity">{{errors.quantity}}</span>

          <label for="price">Price</label>
          <div class="input-wrap">
            <span class="default-input-value">Rs.</span>
            <input type="number" class="price" v-model="price" placeholder="Enter Price Here" />
          </div>
          <span class="form-error" v-if="errors.price">{{errors.price}}</span>

          <label for="category">Unit</label>
          <select class="category" v-model="single_unit">
            <option
              v-for="(unit_obj,index) in product_unit"
              :key="index"
              :value="unit_obj.id"
            >{{unit_obj.name}}</option>
          </select>
          <span class="form-error" v-if="errors.single_unit">{{errors.single_unit}}</span>

          <!--
          Add this if description is needed
          
          <label for="description">Task Description</label>
          <textarea placeholder="Write task details...">
          </textarea>
          -->

          <!-- <label for="tdate">Task Date</label>
          <input type="datetime-local" v-model="current_date" name="tdate" /> -->
          <label for="tdate">Due Date</label>
          <input type="datetime-local" v-model="due_date" name="tdate" />
          <span class="form-error" v-if="errors.due_date">{{errors.due_date}}</span>

          <button type="submit" class="gg-create-btn">Add Task</button>
        </div>
        <!-- <task-assign-form /> -->
      </form>
    </md-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      showDialog: true,
      assignee_name: "",
      product_name: "",
      location: "",
      quantity: 1,
      price: "",
      current_date: new Date().toISOString().slice(0, 16),
      due_date: "",
      single_unit: "",
      errors: [],
    };
  },
  computed: {
    ...mapState(["product_unit", "product_obj"]),
  },
  methods: {
    addTask() {
      this.errors = [];
      this.success = "";
      if (this.assignee_name == "") {
        this.errors["assignee_name"] = "Name of assignee is required.";
      } else if (this.product_name == "") {
        this.errors["product_name"] = "Name of product is required.";
      } else if (this.location == "") {
        this.errors["location"] = "Location is required.";
      } else if (this.quantity <= 0) {
        this.errors["quantity"] = "Quantity should be at least 1 or greater.";
      } else if ((this.single_unit = "")) {
        this.errors["single_unit"] = "Unit is required";
      } else if (this.price == "") {
        this.errors["price"] = "Price is required.";
      } else if (this.due_date == "") {
        this.errors["due_date"] = "Assign due date required.";
      } else {
        this.success = "Form submitted successfully.";
      }
    },
  },
};
</script>

<style lang="scss">
</style>