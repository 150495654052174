<template>
  <div class="gg-table source-tabtable">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>

    <div class="choose-date">
      <download-excel
        v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'"
        class="btn excel_export_btn"
        :fetch="fetchData"
        :fields="json_fields"
        :before-generate="startDownload"
        :before-finish="finishDownload"
        :name="order_list_file_name"
      >
        <md-icon class="navbar-icon1">get_app</md-icon> &nbsp; Export To Excel
      </download-excel>
      <div class="date" v-if="showDates">
        <div class="start-date">
          <span>Choose Start date</span>
          <input type="date" v-model="start_date" />
        </div>
        <div class="end-date">
          <span>Choose End date</span>
          <input type="date" v-model="end_date" />
        </div>
      </div>
      <button class="choose-button" @click="choose" v-if="chooseDate">Choose Date to export</button>
      <button class="choose-button" @click="clear" v-else>Clear</button>
    </div>
    <!-- download excel btn excel_export_btn -->
    <div>
      <md-table>
        <div class="table-top-nav">
          <md-tabs>
            <md-tab md-label="All" @click="tabIndex = 0"></md-tab>
            <md-tab md-label="Pending" @click="tabIndex = 1"></md-tab>
            <md-tab md-label="Confirmed" @click="tabIndex = 2"></md-tab>
            <md-tab md-label="Inititated" @click="tabIndex = 3"></md-tab>
            <md-tab md-label="Delivered" @click="tabIndex = 4"></md-tab>
            <md-tab md-label="Rejected" @click="tabIndex = 5"></md-tab>
            <md-tab md-label="Returned" @click="tabIndex = 6"></md-tab>
            <md-tab md-label="Cancelled" @click="tabIndex = 7"></md-tab>
          </md-tabs>
        </div>
      </md-table>
      <md-table
        class="gg-table table-order"
        v-model="filterTableData.slice(count_start, count_end)"
      >
        <md-table-row>
          <md-table-head>&nbsp;</md-table-head>
          <md-table-head>OrderBy</md-table-head>
          <md-table-head>OrderID</md-table-head>
          <md-table-head>OrderPrice</md-table-head>
          <md-table-head>PaymentType</md-table-head>
          <md-table-head>DeliveryTo</md-table-head>
          <md-table-head>Status</md-table-head>
          <md-table-head>Actions</md-table-head>
        </md-table-row>

        <md-table-row
          class="table-row"
          v-for="order in filterTableData.slice(count_start, count_end)"
          :key="order"
        >
          <md-table-cell>
            <input type="checkbox" :value="order.id" />
          </md-table-cell>
          <md-table-cell>
            <span>{{ order.author.full_name }}</span
            ><br />
            <span>{{ order.author.email }}</span
            ><br />
            <span>{{ order.author.phone_number }}</span>
          </md-table-cell>
          <md-table-cell>
            <router-link :to="{ name: 'order-detail', params: { id: order.id } }">{{
              order.id
            }}</router-link
            ><br />
            <span>({{ order.order_code }})</span><br />
            <br />
            <span>Date: {{ order.created_at.slice(0, 10) }}</span>
          </md-table-cell>
          <md-table-cell class="table-total-price">
            <strong
              >Rs.
              {{
                (order.order_price + order.tax_vat_price + order.delivery_charge).toFixed(2)
              }}/-</strong
            >
          </md-table-cell>
          <md-table-cell>
            <span>Cash On Delivery</span>
          </md-table-cell>
          <md-table-cell>
            <span v-if="order.delivery_address">
              <span v-if="order.delivery_address.first_name"
                >{{ order.delivery_address.first_name }}
                {{ order.delivery_address.middle_name }}
                {{ order.delivery_address.last_name }} ({{ order.delivery_address.phone }})<br />
              </span>
              <span v-if="order.delivery_address.street_address"
                >{{ order.delivery_address.street_address }},
              </span>
              <span v-if="order.delivery_address.city">{{ order.delivery_address.city }}, </span>
              <span v-if="order.delivery_address.state">{{ order.delivery_address.state }}</span>
            </span>
          </md-table-cell>
          <md-table-cell>
            <md-button class="md-raised">{{
              order.order_status == "delivery_initiated"
                ? "Initiated"
                : order.order_status == "accepted" && !needToAssign(order.id)
                ? "Assigned"
                : order.order_status
            }}</md-button>
          </md-table-cell>
          <md-table-cell md-label="Action" md-sort-by="action">
            <div class="gg-dropdown-btn">
              <md-menu md-size="big">
                <md-button class="dropdown-icon" md-menu-trigger>
                  <i class="fas fa-caret-down"></i>
                </md-button>
                <md-menu-content class="gg-dropdown-menu">
                  <md-menu-item
                    v-if="
                      (profile_obj.admin || profile_obj.group.name == 'Staff User') &&
                        order.order_status != 'delivered'
                    "
                    @click="showEditDialog(order.order_code)"
                    >Update</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      (profile_obj.admin || profile_obj.group.name == 'Staff User') &&
                        order.order_status == 'accepted' &&
                        needToAssign(order.id)
                    "
                    @click="openAssignDialog(order.order_code)"
                    >Assign</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      (order.order_status == 'pending' || order.order_status == 'accepted') &&
                        (profile_obj.admin || profile_obj.group.name == 'Staff User')
                    "
                    @click="showAddressDialog(order)"
                    >Modify Address</md-menu-item
                  >
                  <md-menu-item>
                    <router-link :to="{ name: 'order-detail', params: { id: order.id } }"
                      >View Detail</router-link
                    >
                  </md-menu-item>
                  <md-menu-item
                    v-if="profile_obj.admin && order.order_status == 'pending'"
                    @click="openDialogRemove(order.order_code)"
                    >Remove</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      !profile_obj.admin &&
                        (profile_obj.group.name == 'Web User' ||
                          profile_obj.group.name == 'Mobile User')
                    "
                    @click="showCancelDialog(order.order_code)"
                    >Cancel Order</md-menu-item
                  >
                </md-menu-content>
              </md-menu>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="filterTableData && filterTableData.length">
        <ul class="nav-pagination">
          <li>
            <router-link
              :to="{
                name: current_route,
                query: { page: current_page <= 1 ? 1 : current_page - 1 }
              }"
              >&laquo;</router-link
            >
          </li>

          <li
            v-for="(page, index) in Math.ceil(filterTableData.length / page_sequence)"
            :class="current_page == page ? 'active' : ''"
            :key="index"
          >
            <router-link :to="{ name: current_route, query: { page: page } }">{{
              page
            }}</router-link>
          </li>

          <li>
            <router-link
              :to="{
                name: current_route,
                query: {
                  page:
                    current_page >= Math.ceil(filterTableData.length / page_sequence)
                      ? Math.ceil(filterTableData.length / page_sequence)
                      : current_page + 1
                }
              }"
              >&raquo;</router-link
            >
          </li>
        </ul>
      </div>
      <div v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">
        <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDialogEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="editOrder">
              <h2 class="form-title">Update Order</h2>

              <label for="fullName">OrderID: {{ single_order.order_code }}</label>
              <label for="category"
                >Total Price: Rs.{{
                  (
                    single_order.order_price +
                    single_order.tax_vat_price +
                    single_order.delivery_charge
                  ).toFixed(2)
                }}/-</label
              >
              <label for="price">Order Status: {{ single_order.payment_status }}</label>
              <label for="status">Update Status *</label>
              <div class="select-options">
                <select class="status" v-model="order_status" @change="checkOnChange($event)">
                  <option selected value="">-- Select Order Status --</option>
                  <option
                    v-for="(status, index) in tableOrderStatus"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.title }}
                  </option>
                </select>
                <md-icon>expand_more</md-icon>
              </div>
              <div class="text-area">
                <textarea
                  name=""
                  id="text-area"
                  placeholder="Enter Rejection Message"
                  cols="30"
                  rows="5"
                  v-model="reject_reason"
                  v-show="reject_reason_show_hide"
                ></textarea>
              </div>
              <span class="form-error" v-if="errors.order_status">{{ errors.order_status }}</span>
              <span class="form-success" v-if="success"
                ><strong>{{ success }}</strong></span
              >
              <button type="submit" class="gg-create-btn">Save</button>
            </form>
          </div>
        </md-dialog>
      </div>
      <div v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">
        <md-dialog :md-active.sync="showDialogAddress" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDialogAddress = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="modifyAddress">
              <h2 class="form-title">Modify Delivery Address</h2>

              <label for="product">State Name *</label>
              <input
                type="text"
                v-model.trim="single_address.state"
                class="product"
                placeholder="Enter State Name"
              />
              <span v-if="errors.state_name" style="color: red">{{ errors.state_name }}</span>

              <label for="product">City Name *</label>
              <input
                type="text"
                v-model.trim="single_address.city"
                class="product"
                placeholder="Enter City Name"
              />
              <span v-if="errors.city_name" style="color: red">{{ errors.city_name }}</span>

              <label for="product">Street Address *</label>
              <input
                type="text"
                v-model.trim="single_address.street_address"
                class="product"
                placeholder="Enter Street Address"
              />
              <span v-if="errors.street_address" style="color: red">{{
                errors.street_address
              }}</span>

              <label for="product">Latitude Value *</label>
              <input
                type="text"
                v-model.trim="single_address.latitude"
                class="product"
                placeholder="Enter Latitude Value"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              />
              <span v-if="errors.latitude_value" style="color: red">{{
                errors.latitude_value
              }}</span>

              <label for="product">Longitude Value *</label>
              <input
                type="text"
                v-model.trim="single_address.longitude"
                class="product"
                placeholder="Enter Longitude Value"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              />
              <span v-if="errors.longitude_value" style="color: red">{{
                errors.longitude_value
              }}</span>

              <span class="form-success" v-if="success"
                ><strong>{{ success }}</strong></span
              >
              <button type="submit" class="gg-create-btn">Modify</button>
            </form>
          </div>
        </md-dialog>
      </div>
      <div>
        <md-dialog v-if="profile_obj.admin" :md-active.sync="showDialogRemove">
          <div v-if="delete_status == false">
            <div class="modal-alert">
              <div class="icon-div">
                <span class="material-icons remove-icon">highlight_off</span>
              </div>
              <md-dialog-title class="modal-title"
                >Are you sure want to remove this item</md-dialog-title
              >
              <md-dialog-actions>
                <md-button class="cancel-btn" @click="showDialogRemove = false">Cancel</md-button>
                <md-button class="remove-btn" @click="deleteOrder">Delete</md-button>
              </md-dialog-actions>
            </div>
          </div>
          <div v-else>
            <div class="modal-alert">
              <div class="icon-div">
                <span class="material-icons remove-icon">highlight_off</span>
              </div>
              <md-dialog-title class="modal-title">Order has been Deleted.</md-dialog-title>
              <md-dialog-actions>
                <md-button
                  class="cancel-btn"
                  @click="(showDialogRemove = false), (delete_status = false)"
                  >Cancel</md-button
                >
              </md-dialog-actions>
            </div>
          </div>
        </md-dialog>
      </div>

      <div v-if="profile_obj.admin || profile_obj.group.name == 'Staff User'">
        <md-dialog :md-active.sync="showDialogAssign" class="modal-main">
          <form class="modal-form" @submit.prevent="assignTask">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDialogAssign = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <div class="main-form">
              <h2 class="form-title">Assign Delivery Task</h2>

              <label for="assigned_to">Assigned To</label>
              <select class="assigned_to" v-model="assigned_to">
                <option value="">-- Choose Delivery Person --</option>
                <option
                  v-for="(delivery_user_item, index) in all_user_obj"
                  :key="index"
                  :value="delivery_user_item.id"
                  v-if="
                    delivery_user_item.group.name == 'Delivery User' && delivery_user_item.active
                  "
                >
                  {{ delivery_user_item.full_name }}
                </option>
              </select>
              <span class="form-error" v-if="errors.assigned_to">{{ errors.assigned_to }}</span>

              <label for="tdate">Due Date</label>
              <input type="datetime-local" v-model="due_date" name="tdate" />
              <span class="form-error" v-if="errors.due_date">{{ errors.due_date }}</span>

              <label for="message">Remarks to Delivery</label>
              <div class="input-wrap">
                <textarea
                  class="message"
                  placeholder="Enter Remark to Delivery Person"
                  v-model="message"
                  style="width: 100%; height: 125px"
                ></textarea>
              </div>
              <span class="form-error" v-if="errors.message">{{ errors.message }}</span>

              <!-- <label for="">Order ID : </label>

              <label for="">Product Name : </label>

              <label for="">Product Code : </label>

              <label for="code"> Product Batch </label>
              <input
                type="text"
                v-model="product_code"
                name="code"
                placeholder="Enter product batch"
              /> -->
              <span class="form-error"></span>

              <span v-if="success == 'assigned'" style="color: #4ba069; font-weight: bold"
                >Delivery Assigned Successfully</span
              >

              <button type="submit" class="gg-create-btn">Assign Task</button>
            </div>
          </form>
        </md-dialog>
      </div>
      <div
        v-if="
          !profile_obj.admin &&
            (profile_obj.group.name == 'Web User' || profile_obj.group.name == 'Mobile User')
        "
      >
        <md-dialog :md-active.sync="showCancelEdit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showCancelEdit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <form class="main-form" @submit.prevent="startCancelOrder">
              <h2 class="form-title">Cancel Order</h2>
              <p>Please select</p>
              <div class="select-options">
                <select
                  class="cancel_option"
                  v-model="cancel_option"
                  @change="changeCancelOrder($event)"
                >
                  <option selected value="">-- Select One * --</option>
                  <option
                    v-for="(status, index) in cancelOrderStatus"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.title }}
                  </option>
                </select>
                <md-icon>expand_more</md-icon>
                <span class="form-error" v-if="errors.cancel_option">{{
                  errors.cancel_option
                }}</span>
              </div>
              <div
                v-if="
                  single_order &&
                    single_order.order_product &&
                    single_order.order_product.length > 0
                "
                class="product-list"
                v-show="show_hide_some_product"
              >
                <p>&nbsp;</p>
                <span><strong>Choose Some Product for Cancel *</strong></span>
                <label v-for="(product_item, index) in single_order.order_product" :key="index">
                  <input type="checkbox" :value="product_item.id" v-model="checkedItems" />
                  <span>{{ product_item.product.name }}</span>
                </label>
                <span class="form-error" v-if="errors.product_item">{{ errors.product_item }}</span>
              </div>
              <div class="text-area">
                <textarea
                  name=""
                  id="text-area"
                  placeholder="Cancellation Message *"
                  cols="30"
                  rows="5"
                  v-model="cancel_message"
                ></textarea>
              </div>
              <span class="form-error" v-if="errors.cancel_message">{{
                errors.cancel_message
              }}</span>
              <span class="form-success" v-if="success"
                ><strong>{{ success }}</strong></span
              >
              <button type="submit" class="gg-create-btn">Submit</button>
            </form>
          </div>
        </md-dialog>
      </div>
    </div>
  </div>
</template>

<script>
// import VueHtml2pdf from "vue-html2pdf";
// import PdfContent from "./PdfContent";
import { clear } from "console";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState, mapActions } from "vuex";
export default {
  name: "TabContent",
  data() {
    return {
      showDates: false,
      chooseDate: true,
      isLoading: false,
      showDialogEdit: false,
      showCancelEdit: false,
      showDialogAssign: false,
      showDialogRemove: false,
      showDialogAddress: false,
      assigned_to: "",
      message: "",
      due_date: "",
      order_status: "",
      category: "",
      tabIndex: 0,
      activeTab: "",
      tableTabs: [
        {
          id: "all",
          title: "All"
        },
        {
          id: "pending",
          title: "Pending"
        },
        {
          id: "accepted",
          title: "Confirmed"
        },
        {
          id: "delivery_initiated",
          title: "Initiated"
        },
        {
          id: "delivered",
          title: "Delivered"
        },
        {
          id: "rejected",
          title: "Rejected"
        },
        {
          id: "returned",
          title: "Returned"
        },
        {
          id: "canceled",
          title: "Cancelled"
        }
      ],
      tableOrderStatus: [
        {
          id: "accepted",
          title: "Confirmed"
        },
        {
          id: "rejected",
          title: "Rejected"
        }
      ],
      cancelOrderStatus: [
        {
          id: "All",
          title: "Cancel All Product"
        },
        {
          id: "Partial",
          title: "Cancel Some Product"
        }
      ],
      delete_status: false,
      success: "",
      single_order: [],
      single_address: [],
      errors: [],
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      thisFileName: "green_grwoth_confirm_order_list",
      generatingPdf: false,
      pdfDownloaded: false,
      transition: [
        {
          id: 123,
          title: "Title one",
          description: "Description of Tite one"
        },
        {
          id: 234,
          title: "Title two",
          description: "Description of Tite two"
        },
        {
          id: 345,
          title: "Title three",
          description: "Description of Tite three"
        }
      ],
      json_fields: {
        "S.N.": "sn",
        Date: "date",
        "Customer Name": "delivery.full_name",
        "Contact Number": "delivery.phone_no",
        "Delivery Address": "delivery.address",
        "Order ID": "order_id",
        "Product Item": "product.name",
        "Product Quantity": "product.quantity",
        "Product Rate": "product.rate",
        "Product Total": "product.total",
        Due: "blank",
        Total: "blank",
        Remarks: "blank",
        Sign: "blank"
      },
      order_list_file_name: "greengrowth_order_list_" + new Date().toLocaleString() + ".xls",
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      selected: "",
      reject_reason: "N/a",
      reject_reason_show_hide: false,
      cancel_option: "",
      cancel_message: "",
      show_hide_some_product: false,
      checkedItems: [],
      product_code: "",
      start_date: "",
      end_date: ""
    };
  },
  components: {
    Loading
  },
  computed: {
    ...mapState([
      "profile_obj",
      "order_obj",
      "all_user_obj",
      "product_unit",
      "task_obj",
      "success_message",
      "error_message",
      "response_message"
    ]),
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: { page: 1 }
      });

      let table_data = this.order_obj;
      if (this.tabIndex == 1) {
        table_data = table_data.filter(row => {
          return row.order_status == "pending";
        });
      }
      if (this.tabIndex == 2) {
        table_data = table_data.filter(row => {
          return row.order_status == "accepted";
        });
      }
      if (this.tabIndex == 3) {
        table_data = table_data.filter(row => {
          return row.order_status == "delivery_initiated";
        });
      }
      if (this.tabIndex == 4) {
        table_data = table_data.filter(row => {
          return row.order_status == "delivered";
        });
      }
      if (this.tabIndex == 5) {
        table_data = table_data.filter(row => {
          return row.order_status == "rejected";
        });
      }
      if (this.tabIndex == 6) {
        table_data = table_data.filter(row => {
          return row.order_status == "returned";
        });
      }
      if (this.tabIndex == 7) {
        table_data = table_data.filter(row => {
          return row.order_status == "canceled";
        });
      }
      return table_data;
    }
  },
  created() {
    this.listProfile();
    this.listOrder();
    this.listUser();
    this.listUnits();
    this.listTask();
    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    }
  },
  methods: {
    ...mapActions(["listProfile", "listOrder", "listUser", "listUnits", "listTask"]),
    showEditDialog(order_code) {
      this.single_order = this.order_obj.find(evt => evt.order_code == order_code);
      this.order_status = this.single_order.order_status;
      this.showDialogEdit = true;
    },

    showCancelDialog(order_code) {
      this.single_order = this.order_obj.find(evt => evt.order_code == order_code);
      this.order_status = this.single_order.order_status;
      this.showCancelEdit = true;
    },
    checkOnChange(event) {
      this.selected = event.target.value;
      this.reject_reason_show_hide = this.selected == "rejected" ? true : false;
    },
    editOrder() {
      this.success = "";
      this.errors = [];
      if (this.order_status == "Select Order Status" || this.order_status == "") {
        this.errors["order_status"] = "Please select an order status";
      } else {
        //console.log("Message", this.reject_reason);
        this.$store
          .dispatch("changeOrderStatus", {
            order_status: this.order_status,
            remark: this.reject_reason,
            id: this.single_order.id
          })
          .then(() => {
            if (this.success_message == "success") {
              this.success = "Order Updated Successfully.";
              setTimeout(function() {
                location.reload();
              }, 1000);
            } else if (this.error_message == "errormessage") {
              alert(this.response_message);
            }
          });
      }
    },
    showAddressDialog(order_obj) {
      this.showDialogAddress = true;
      this.single_address = order_obj.delivery_address;
    },
    modifyAddress() {
      this.success = "";
      this.errors = [];
      if (this.single_address.state == "") {
        this.errors["state_name"] = "State name required";
      } else if (this.single_address.city == "") {
        this.errors["city_name"] = "City name required";
      } else if (this.single_address.street_address == "") {
        this.errors["street_address"] = "Street address required";
      } else if (this.single_address.latitude == "") {
        this.errors["latitude_value"] = "Latitude value required";
      } else if (this.single_address.longitude == "") {
        this.errors["longitude_value"] = "Longitude value required";
      } else {
        console.log("He he he");
        let formData = {
          state: this.single_address.state,
          city: this.single_address.city,
          street_address: this.single_address.street_address,
          latitude: this.single_address.latitude,
          longitude: this.single_address.longitude,
          id: this.single_address.id
        };
        this.$store.dispatch("modifyDeliveryAddress", formData).then(() => {
          if (this.success_message == "success") {
            this.success = "Address Modified Successfully.";
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
    openAssignDialog(order_code) {
      this.single_order = this.order_obj.find(evt => evt.order_code == order_code);
      this.showDialogAssign = true;
    },
    assignTask() {
      this.errors = [];
      this.success = "";
      if (this.assigned_to == "") {
        this.errors["assigned_to"] = "Assign To is required.";
      } else if (this.due_date == "") {
        this.errors["due_date"] = "Due date is required.";
      } else if (this.message == "") {
        this.errors["message"] = "Message required.";
      } else if (this.message.length < 10) {
        this.errors["message"] = "Message minimum 10 characters.";
      } else {
        let formData = new FormData();
        formData.append("assigned_to", this.assigned_to);
        formData.append("task_type", "order");
        formData.append("order", this.single_order.id);
        formData.append("message", this.message);
        formData.append("reply_message", "");
        formData.append("due_date", this.due_date);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0]+ ', ' + pair[1]);
        // }

        this.$store.dispatch("createNewTask", formData).then(() => {
          if (this.success_message == "success") {
            this.success = "assigned";
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
    needToAssign(order_id) {
      let taskdata = this.task_obj;

      taskdata = taskdata.filter(row => {
        return row.order.id == order_id && row.status != "rejected";
      });

      return taskdata.length > 0 ? false : true;
    },
    openDialogRemove(order_code) {
      this.single_order = this.order_obj.find(evt => evt.order_code == order_code);
      this.showDialogRemove = true;
      console.log(this.single_order.id);
    },
    deleteOrder() {
      this.success = "";
      this.$store.dispatch("deleteOrder", this.single_order.id).then(() => {
        if (this.success_message == "success") {
          this.success = "deleted";
          this.delete_status = true;
          setTimeout(function() {
            location.reload();
          }, 1000);
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    },
    changeCancelOrder(event) {
      this.cancel_option = event.target.value;
      this.show_hide_some_product = this.cancel_option == "Partial" ? true : false;
    },
    startCancelOrder() {
      this.errors = [];
      if (this.cancel_option == "") {
        this.errors["cancel_option"] = "Cancel option required.";
      } else if (this.cancel_option == "Partial" && this.checkedItems.length <= 0) {
        this.errors["product_item"] = "Cancel product required.";
      } else if (this.cancel_message == "") {
        this.errors["cancel_message"] = "Cancel message required.";
      } else if (this.cancel_message.length <= 9) {
        this.errors["cancel_message"] = "Cancel message at least 10 characters.";
      } else {
        let selectedProducts = [];
        if (this.cancel_option == "All") {
          this.single_order.order_product.forEach(item => {
            selectedProducts.push(item.id);
            //console.log(item.id)
          });
        } else {
          selectedProducts = this.checkedItems;
        }

        //console.log("Array: ", JSON.stringify(selectedProducts))

        let formData = new FormData();
        formData.append("order", this.single_order.id);
        formData.append("products", selectedProducts);
        formData.append("remark", this.cancel_message);
        formData.append("cancel_option", this.cancel_option);

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.$store.dispatch("cancelOrderByUser", formData).then(() => {
          if (this.success_message == "success") {
            this.success = "Order Cancelled Successfully.";
            setTimeout(function() {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
    createJson() {
      let finalJson = [];
      let table_data = this.order_obj;
      let final_data = [];

      table_data = table_data.filter(row => {
        return row.order_status == "accepted" || row.order_status == "delivered";
      });

      table_data.forEach(element => {
        if (this.start_date != this.end_date) {
          let start_date = new Date(this.start_date).toISOString();
          let end_date = new Date(this.end_date).toISOString();
          if (start_date < element.created_at && end_date > element.created_at) {
            final_data.push(element);
            // console.log(element);
          }
        } else {
          final_data.push(element);
        }
      });
      console.log(final_data);
      // let unique_date = [...new Set(created_at)];
      // console.log(unique_date);

      let serialNumber = 1;
      if (final_data && final_data.length > 0) {
        //order loop
        for (var i = 0; i < final_data.length; i++) {
          let singleOrder = final_data[i];
          let singleOrderProductList = singleOrder.order_product;
          // product loop
          for (var j = 0; j < singleOrderProductList.length; j++) {
            let singleProduct = singleOrderProductList[j];
            let jsonItem = {
              sn: serialNumber + ".",
              date: new Date(singleOrder.created_at).toLocaleDateString("en-US"),
              order_id: singleOrder.id,
              product: {
                name: singleProduct.product.name,
                quantity: singleProduct.quantity + " " + singleProduct.unit.name,
                rate: "Rs. " + singleProduct.price,
                total: "Rs. " + parseFloat(singleProduct.price) * singleProduct.quantity
              },
              delivery: {
                full_name: singleOrder.author.full_name,
                phone_no: singleOrder.author.phone_number,
                address:
                  singleOrder.delivery_address.street_address +
                  ", " +
                  singleOrder.delivery_address.city +
                  ", " +
                  singleOrder.delivery_address.state
              },
              blank: ""
            };
            console.log(jsonItem);
            finalJson.push(jsonItem);
            serialNumber++;
          } // end product for
          let jsonItem = {
            sn: "",
            order_id: "Order Total",
            product: {
              name: "",
              quantity: "",
              rate: "",
              total: "Rs. " + singleOrder.order_price
            },
            delivery: {
              full_name: "",
              phone_no: "",
              address: ""
            },
            blank: ""
          };
          finalJson.push(jsonItem);
        } // end order for
      }

      return finalJson;
    },
    async fetchData() {
      let json_data = await this.createJson();
      return json_data;
    },
    startDownload() {
      //alert('show loading');
      this.isLoading = true;
    },
    finishDownload() {
      this.isLoading = false;
    },
    generateReport() {
      console.log("I'm here ...");
    },
    choose() {
      this.showDates = true;
      this.chooseDate = false;
    },
    clear() {
      this.start_date = "";
      this.end_date = "";
      this.showDates = false;
      this.chooseDate = true;
    }
  }
};
</script>
<style type="text/css" scoped>
/** *** Pagination Menu Styling *** **/

.choose-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px;
}

.choose-button {
  border: 1px solid #ccc;
  background-color: #0f7513;
  color: #fff;
  padding: 10px 10px;
  margin: 10px 10px;
}

.choose-button:hover {
  background-color: #fff;
  border: 1px solid #0f7513;
  color: #000;
  cursor: pointer;
}
.choose-date .date {
  display: flex;
}

.choose-date .date .start-date {
  display: flex;
  flex-direction: column;
  position: relative;
}

.choose-date .date .start-date span {
  position: absolute;
  background: #fff;
  top: -7px;
  left: 13px;
  padding: 0px 5px;
}
.choose-date .date .end-date {
  display: flex;
  flex-direction: column;
  position: relative;
}
.choose-date .date .end-date span {
  position: absolute;
  background: #fff;
  top: -7px;
  left: 13px;
  padding: 0px 5px;
}
.choose-date .date input {
  margin: 0px 10px;
  font-size: 14px;
  border: 1px solid #c8dbcf;
  padding: 10px 10px;
}
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
.excel_export_btn {
  clear: both;
  /* width: 100%; */
  text-align: right;
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #0f7513;
  /* text-decoration: underline; */
  cursor: pointer;
}

textarea {
  height: 100px !important;
  width: 100%;
}
</style>
<style lang="scss">
.select-options {
  position: relative;
  select {
    appearance: none;
    width: 100%;
  }
  i {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
.text-area {
  width: 100%;
}
.product-list {
  display: grid;
  span {
    margin: 0px 10px;
  }
}
// .show{
//   display: block;
// }
</style>
