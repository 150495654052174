<template>
  <form
    class="main-form"
    @submit.prevent="productrequestForm"
    @change="onChange"
  >
    <h2 class="form-title">Request Product</h2>
    <label for="requestTo">Request To *</label>
    <input
      type="text"
      placeholder="Eg. Ram"
      list="requestTo"
      v-model="request_to"
    />
    <datalist
      id="requestTo"
      v-if="filterMemberData && filterMemberData.length > 0"
    >
      <option
        v-for="(member_obj, index) in filterMemberData"
        :key="index"
        :value="member_obj.id"
      >
        {{ member_obj.full_name }}
      </option>
    </datalist>
    <datalist id="requestTo" v-else>
      <option value="1">Greengrowth Admin</option>
    </datalist>
    <input type="hidden" v-model="changed_user_id" />
    <span class="form-error" v-if="errors.request_to">{{
      errors.request_to
    }}</span>

    <label for="category">Category *</label>
    <select class="category" v-model="category">
      <option value disabled>Select Category</option>
      <option
        v-for="(category_obj, index) in product_category"
        :key="index"
        :value="category_obj.id"
        v-if="category_obj.product_category.length > 0"
      >
        {{ category_obj.name }}
      </option>
    </select>
    <span class="form-error" v-if="errors.category">{{ errors.category }}</span>

    <label for="category">Product *</label>
    <select class="category" v-model="product">
      <option value disabled>Select Product</option>
      <option
        v-for="(product_obj, index) in productlist_obj.product_category"
        :key="index"
        :value="product_obj.id"
      >
        {{ product_obj.name }}
      </option>
    </select>
    <span class="form-error" v-if="errors.product">{{ errors.product }}</span>

    <label for="quantity">Quantity *</label>
    <input type="number" v-model="quantity" />
    <span class="form-error" v-if="errors.quantity">{{ errors.quantity }}</span>

    <label for="category">Unit *</label>
    <select class="category" v-model="unit">
      <option value disabled>Select Unit Value</option>
      <option
        v-for="(unit_item, index) in product_unit"
        :key="index"
        :value="unit_item.id"
      >
        {{ unit_item.name }}
      </option>
    </select>
    <span class="form-error" v-if="errors.unit">{{ errors.unit }}</span>

    <label>
      <div>Upload Product's Image</div>
      <div class="upload-file-area">
        <input
          type="file"
          ref="file"
          @change="onChangeFileUpload()"
          accept="image/jpeg, image/png"
        />
        <div class="drag-text">
          <div>
            <button class="choose-button">Choose File</button>
          </div>
          <div class="or">OR</div>
          <div>Drag and drop your file here</div>
        </div>
      </div>
      <span class="form-error" v-if="errors.file">{{ errors.file }}</span>
    </label>
    <div class="thumbnail">
      <span class="clear-image" v-if="file !== ''" @click="clearImage">
        <md-icon>cancel </md-icon>
      </span>
      <img :src="image_url" />
    </div>

    <label for="message">Message</label>
    <textarea v-model="message" rows="3" style="height: 100px;"></textarea>
    <span
      class="form-error"
      v-if="errors.message"
    >{{ errors.message}}</span>

    <label for="due_date">Due Date *</label>
    <input type="date" v-model="due_date" />
    <span class="form-error" v-if="errors.due_date">{{ errors.due_date }}</span>

    <span class="success" style="color: red" v-if="errors.submit_error">{{
      errors.submit_error
    }}</span>
    <span class="success" style="color: green" v-if="success"
      >Request Submitted Successfuly.</span
    >

    <button type="submit" class="gg-create-btn">Request</button>

    <input type="hidden" v-model="state_change_value" />
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  components: {},
  computed: {
    ...mapState([
      "all_user_obj",
      "product_category",
      "success_message",
      "error_message",
      "response_message",
      "profile_obj",
      "product_unit",
    ]),
    filterMemberData() {
      let table_data = this.all_user_obj;

      return table_data;
    },
  },

  watch: {
    category: function (val) {
      if (val != "") {
        this.productlist_obj = this.product_category.find(
          (evt) => evt.id == val
        );
      }
    },
  },

  created() {
    this.listCategories();
    this.listStaffUser();
    this.listUnits();
    this.listProfile();
  },
  data: () => ({
    request_to: "",
    request_to_id: "",
    category: "",
    product: "",
    productlist_obj: [],
    errors: [],
    quantity: 1,
    files: [],
    file: "",
    file_name: "",
    image_url: null,
    image: "",
    allMemberList: [],
    changed_user_id: 0,
    unit: null,
    message: "",
    due_date: "",
    success: false,
    state_change_value: "",
  }),

  methods: {
    ...mapActions(["listCategories", "listStaffUser", "listUnits", "listProfile"]),

    productrequestForm() {
      this.errors = [];
      if (this.request_to == "") {
        this.errors["request_to"] = "Name is required.";
      } else if (this.category == "") {
        this.errors["category"] = "Category Name required.";
      } else if (this.product == "") {
        this.errors["product"] = "Product required.";
      } else if (this.quantity < 1) {
        this.errors["quantity"] = "Quantity must be greater than 1.";
      } else if (!this.unit) {
        this.errors["unit"] = "Unit required.";
      } else if (this.file == "") {
        this.errors["file"] = "File Required.";
      } else if (this.message == "") {
        this.errors["message"] = "Message required.";
      } else if (this.due_date == "") {
        this.errors["due_date"] = "Due Date required.";
      } else {
        let formData = new FormData();
        formData.append("product", this.product);
        formData.append("unit", this.unit);
        formData.append("quantity", this.quantity);
        formData.append("image", this.file);
        formData.append("message", this.message);
        formData.append("request_by", this.profile_obj.id);
        formData.append("request_status", "Requested");
        formData.append("request_to", this.request_to);
        formData.append("due_date", this.due_date + "T00:00");

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.$store.dispatch("createProductRequest", formData).then(() => {
          if (this.success_message == "success") {
            this.state_change_value = Date.now();
            this.success = true;
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            this.state_change_value = Date.now();
            this.errors["submit_error"] = this.response_message;
          }
        });
      }
    },

    // For Image Upload
    selectFile() {
      // this.$refs.file.click()
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.image_url = URL.createObjectURL(this.file);
    },
    // createImage(file) {
    //   var image = new Image()
    //   var reader = new FileReader()

    //   reader.onload = (e) => {
    //     this.file = e.target.result;
    //     this.$emit('change', this.file)
    //   }
    //   reader.readAsDataURL(file)
    // },
    clearImage() {
      const input = this.$refs.file;
      input.type = "file";
      this.file = "";
      this.image_url = "";
    },
    onChange(image) {
      // console.log('New picture selected!')
      // if (image) {
      //   console.log('Picture loaded.')
      //   this.image = image;
      // } else {
      //   console.log('FileReader API not supported: use the <form>, Luke!')
      // }
    },
  },
};
</script>

<style lang="scss" >
</style>
