<template>
  <div class="inventry">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-carrot"></i>
        </md-icon>
        <h1>Products from Source & Outlet</h1>
      </md-toolbar>
      
       <div class="table-top-nav">
      <md-tabs>
        <md-tab id="tab-home" md-label="All" @click="tabIndex = 0"></md-tab>
        <md-tab id="tab-pages" md-label="Active" @click="tabIndex = 1"></md-tab>
        <md-tab id="tab-posts" md-label="Inactive" @click="tabIndex = 2"></md-tab>
      </md-tabs>
      <md-field class="table-category">
        <md-select
          v-if="product_category && product_category.length > 0"
          v-model="category"
          name="category"
          id="category"
          placeholder="All Category"
        >
          <md-option class="table-filter-option" selected>All Category</md-option>
          <md-option
            class="table-filter-option"
            v-for="(cats, index) in product_category"
            :key="index"
            :value="cats.id"
            >{{ cats.name }}</md-option
          >
        </md-select>
      </md-field>
    </div>

    <div class="product-table">
      <md-table
        v-model="filterTableData.slice(count_start, count_end)"
        md-sort="name"
        md-sort-order="asc"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S.N" md-sort-by="sn"
            >{{ index + 1 + (current_page - 1) * 10 }}.</md-table-cell
          >
          <md-table-cell md-label="Name" md-sort-by="name" class="table-name">
            {{ item.name }}
          </md-table-cell>
          <md-table-cell md-label="Price" md-sort-by="price">{{ item.price_out }}</md-table-cell>
          <md-table-cell md-label="Qty" md-sort-by="qty"
            >{{ item.total_quantity }} {{ item.unit.name }}</md-table-cell
          >
          <md-table-cell md-label="Add/Edit Unit" md-sort-by="addEditUnit"
            ><button class="gg-create-btn add-edit-button" @click="openDialogEditUnit(item.id)">
              Units
            </button></md-table-cell
          >
          <md-table-cell md-label="Category" md-sort-by="category">{{
            item.category.name
          }}</md-table-cell>
          <md-table-cell
            md-label="Availability"
            md-sort-by="availability"
            class="in-stock-indication"
            v-if="item.product_status == 'Available'"
            >In Stock</md-table-cell
          >
          <md-table-cell
            md-label="Availability"
            md-sort-by="availability"
            class="not-in-stock-indication"
            v-else
            >Not In Stock</md-table-cell
          >
          <md-table-cell md-label="Added on " md-sort-by="added">{{
            item.created_at
          }}</md-table-cell>
          <md-table-cell md-label="Status" md-sort-by="status">{{
            item.status ? "Publish" : "Unpublish"
          }}</md-table-cell>
          <md-table-cell md-label="Action" md-sort-by="action">
            <div class="gg-dropdown-btn">
              <md-menu md-size="big">
                <md-button class="dropdown-icon" md-menu-trigger>
                  <i class="fas fa-caret-down"></i>
                </md-button>
                <md-menu-content class="gg-dropdown-menu">
                  <md-menu-item @click="editProduct(item.id)">Edit</md-menu-item>
                  <md-menu-item @click="removesingleProduct(item.id)">Remove</md-menu-item>
                </md-menu-content>
              </md-menu>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="filterTableData && filterTableData.length">
        <ul class="nav-pagination">
          <li>
            <router-link
              :to="{
                name: current_route,
                query: { page: current_page <= 1 ? 1 : current_page - 1 }
              }"
              >&laquo;</router-link
            >
          </li>

          <li
            v-for="(page, index) in Math.ceil(filterTableData.length / page_sequence)"
            :class="current_page == page ? 'active' : ''"
            :key="index"
          >
            <router-link :to="{ name: current_route, query: { page: page } }">{{
              page
            }}</router-link>
          </li>

          <li>
            <router-link
              :to="{
                name: current_route,
                query: {
                  page:
                    current_page >= Math.ceil(filterTableData.length / page_sequence)
                      ? Math.ceil(filterTableData.length / page_sequence)
                      : current_page + 1
                }
              }"
              >&raquo;</router-link
            >
          </li>
        </ul>
      </div>
      
    </div>

    <div class="add-edit-unit-modal">
        <md-dialog :md-active.sync="showDialogEditUnit" class="modal-main">
          <div class="modal-form">
            <md-dialog-actions class="close-icon">
              <span class="material-icons error-icon" @click="showDialogEditUnit = false"
                >highlight_off</span
              >
            </md-dialog-actions>
            <div v-if="single_product.product_unit && single_product.product_unit.length > 0" class="product-unit-form product-unit-form-edit">
              <form class="order-detail-table-cost unit" @submit.prevent="">
                <h3>List units of '{{ single_product.name }}'</h3>
                <ul>
                  <li
                    v-for="(product_unit_obj, index) in single_product.product_unit"
                    :key="index"
                    class="edit-unit-list"
                  >
                    <span>{{ product_unit_obj.unit.name}}</span>
                    <span>Rs. {{ product_unit_obj.price }}</span>
                  </li>
                </ul>
              </form>
            </div>

          </div>
        </md-dialog>
      </div>
      
    </div>
    
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {
    //
  },
  data() {
    return {
      showDialogEditUnit: false,
      single_product: [],
      category: null,
      tabIndex: 0,
      tabIsActive: false,
      activeTab: "",
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
      boolean: true
    };
  },
  computed: {
    ...mapState([
      "product_obj",
      "product_category",
      "success_message",
      "error_message",
      "response_message"
    ]),
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: { page: 1 }
      });
      let table_data = this.product_obj;

      table_data = table_data.filter(row => {
        return row.author.group.id == 4 || row.author.group.id == 5
      });

      if (this.tabIndex == 1) {
        table_data = table_data.filter(row => {
          return row.product_status == "Available";
        });
      }
      if (this.tabIndex == 2) {
        table_data = table_data.filter(row => {
          return row.product_status == "Not Available";
        });
      }
      if (this.category) {
        table_data = table_data.filter(row => {
          return row.category.id == this.category;
        });
      }
      return table_data;
    }
  },
  created() {
    this.listProducts();
    this.listCategories();
    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    }
  },
  methods: {
    ...mapActions(["listProducts", "listCategories"]),
    openDialogEditUnit(product_id){
      this.single_product = this.product_obj.find(evt => evt.id == product_id);
      this.showDialogEditUnit = true
    },
  },
};
</script>

<style scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
</style>
