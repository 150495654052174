<template>
  <div class="deliveries">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>
    <div class="contents">
      <md-toolbar class="order-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-shopping-cart"></i>
        </md-icon>
        <span class="content1">Deliveries</span>
      </md-toolbar>
      <aside class="map-section">
        <div id="map"></div>
      </aside>
      
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Baato from "@klltech/baato-js-client";
import mapboxgl from "mapbox-gl";
import { mapState, mapActions } from "vuex";
import axios from 'axios'

export default {
  name: "DeliveriesMap",
  components: {
    Loading,
  },
  created() {
		this.taskid = this.$route.params.taskid
    this.listSingleTask(this.taskid)
    this.listProfile();
	},
  computed: {
		...mapState(["task_obj", "userinfo_obj"])
	},
  mounted: function () {
    this.isLoading = true
    axios.defaults.headers.common['authorization'] = 'JWT ' + localStorage.getItem("token")
    axios.get('tasks/'+this.$route.params.taskid, {})
      .then(response => {
        if(response.status == 200){
          this.isLoading = false
          console.log("I'm here :)")
          this.end_latitude = response.data.order.delivery_address.latitude
          this.end_longitude = response.data.order.delivery_address.longitude

              // Map Showing Now 


              let startLatLongString = this.start_latitude+','+this.start_longitude
              let endLatLongString = this.end_latitude+','+this.end_longitude

              console.log("Start", startLatLongString)
              console.log("End", endLatLongString)
              
              mapboxgl.accessToken =
                "pk.eyJ1IjoiaGlzdWJlZGlzdXNoaWwiLCJhIjoiY2tsYnlicXZyMW80ZTJwbnBmdTEyNDhoayJ9.gPdIT5tO9cI55mlBouyYYw";
              var map = new mapboxgl.Map({
                container: "map",
                style:
                  "https://api.baato.io/api/v1/styles/breeze?key=bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu", // Baato stylesheet location
                center: [85.35198723335493, 27.69126223283422], // starting position [lng, lat]
                zoom: 14, // starting zoom
              });

              map.on("load", function () {
                new Baato.Routing({
                  key: "bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu",
                })
                  .addPoints([
                    startLatLongString,endLatLongString
                  ])
                  .setVehicle("bike") // means of transporation
                  .getBest()
                  .setBaseUrl(`https://api.baato.io/api`) // Baato base URL
                  .doRequest()
                  .then((response) => {
                    response.forEach((item) => {
                      map.addSource("route", {
                        type: "geojson",
                        data: {
                          type: "Feature",
                          properties: {},
                          geometry: {
                            type: "LineString",
                            coordinates: item.geojson.coordinates,
                          },
                        },
                      });
                      map.addLayer({
                        id: "route",
                        type: "line",
                        source: "route",
                        layout: {
                          "line-join": "round",
                          "line-cap": "round",
                        },
                        paint: {
                          "line-color": "#008148",
                          "line-width": 8,
                        },
                      });
                    });
                  });
              });


          // this.end_latitude = "27.667328"
          // this.end_longitude = "85.346190"
        }
      });
  },
  methods: {
    ...mapActions(["listSingleTask", "listProfile"]),
  },
  data() {
    return {
      isLoading: false,
      day: "today",
      map_url: "",
      taskid: "",
      start_latitude: '27.689152',
			start_longitude: '85.337372',
			end_latitude: '27.706327',
			end_longitude: '85.325004',
			startLatLongString: '',
      endLatLongString: '',
      currentLocation: JSON.parse(localStorage.getItem("currentLocation")),
    };
  },
};
</script>
<style lang='scss' scoped>
</style>
