<template>
  <div class="request">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-share"></i>
        </md-icon>
        <h1>Requests</h1>
      </md-toolbar>
      <div class="add-btn">
        <button class="gg-create-btn" @click="openDialogRequest = !openDialogRequest">+ Make Request</button>
      </div>
      <TableRequest />
    </div>
    <ModalRequest v-if="openDialogRequest" />
  </div>
</template>
<script>
import TableRequest from "@/components/TableRequest.vue";
import ModalRequest from "@/components/ModalRequest.vue";

export default {
  name: 'RequestsPage',
  components: {
    TableRequest,
    ModalRequest,
  },
  data() {
    return {
      openDialogRequest: false,
    }
  }
};
</script>
