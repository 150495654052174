<template>
  <div class="orderdetail">
    
    <div class="contents">
      <md-toolbar class="order-nav">
        <div class="content-title-navigation">
          <md-icon class="navbar-icon1">local_mall</md-icon>
          <span class="content1">Orders</span>
          <md-icon class="navbar-icon2">navigate_next</md-icon>
          <span class="content2">Details</span>
        </div>
        
      </md-toolbar>
      <OrderDetailCards />
      
    </div>
    <ModalOrderEdit v-if="openDialog" />
    <ModalRemove v-if="openDialogRemove" />
  </div>
</template>

<script>
// @ is an alias to /src
import OrderDetailCards from "@/components/OrderDetailCards.vue";
import ModalOrderEdit from "@/components/ModalOrderEdit.vue";
import ModalRemove from "@/components/ModalRemove.vue";

export default {
  name: "OrderDetail",
  components: {
    OrderDetailCards,
    ModalOrderEdit,
    ModalRemove,
  },
    data: () => ({
    openDialog: false,
    openDialogRemove: false,
  }),
};
</script>
<style lang="scss" scoped>
</style>
