<template>
  <div class="profile">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-user"></i>
        </md-icon>
        <h2 class="content">My Profile</h2>
      </md-toolbar>
      <div class="card">
        <ProfileCard />
      </div>
    </div>
  </div>
</template>
<script>
import ProfileCard from '@/components/ProfileCard.vue';
export default {
  components: {
   ProfileCard,
  },
};

</script>
