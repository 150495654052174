<template>
  <div class="categories-table">
    <!-- < class='nav-title'> -->
    <span class="nav-title">List of Categories</span>
    <!-- </md-toolbar> -->
    <md-tabs class="md-accent" md-alignment="right">
      <md-tab id="tab-list" class="categories-md-tab" md-icon="list">
        <CategoriesTable />
      </md-tab>
      <md-tab id="tab-grid" md-icon="view_module">
        <CategoriesCards />
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
import CategoriesTable from '@/components/CategoriesTable.vue';
import CategoriesCards from '@/components/CategoriesCards.vue';

export default {
  components: {
    CategoriesTable,
    CategoriesCards,
  },
};
</script>

<style lang='scss' >

</style>
