<template>
  <div class='card'>
    <div class='card-body'>
    </div>
    <div class='card-img-bottom'>
      <chartjs-doughnut
        :bind='true'
        :datasets='datasets'
        :labels='labels'
        :option='option'
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {

  computed: {
    ...mapState(["doughnut_visualization"])
  },

  watch: {
    doughnut_visualization: function(){
      if(this.doughnut_visualization){
        this.datasets.push(this.doughnut_visualization);
      }
    },
  },

  created() {
    this.doughnutVisualization();
  },

  data() {
    return {
      datasets: [],
        // {
        //   data: [10, 20, 40,5],
        //   backgroundColor: ['#ffdb3b', '#228B22', '#185190', '#f36e60'],
        //   hoverBackgroundColor: ['#ffdb3b', '#228B22', '#185190', '#f36e60'],
        // },
      // ],
      labels: ['Total Order','Total Delivered','New Order','Total Rejected'],
      option: {},
    };
  },
  methods: {
    ...mapActions(["doughnutVisualization"]),
  }
};
</script>
<style lang="scss" scoped>
  .card {
    padding: 20px;
    height: max-content;
  }
</style>
