<template>
  <div class="gg-table purchase-table">
    <md-table v-model="filterTableData" md-sort="name" md-sort-order="asc" class="comment-table">
      
      <md-table-row slot="md-table-row" slot-scope="{ item }" class="table-head">
        <md-table-cell md-label="Customer" md-sort-by="author">
          <span>{{ item.order.author.full_name }}</span><br/>
          <span>{{ item.order.author.phone_number }}</span><br/>
          <span>{{ item.order.author.email }}</span>
        </md-table-cell>
        <md-table-cell md-label="Order ID" md-sort-by="orderid">
          <span>{{ item.order.id }}</span><br/>
          <span>Delivered On:</span><br/>{{ item.order.delivered_at }}
        </md-table-cell>
        <md-table-cell md-label="Product" md-sort-by="product.name">{{ item.product.name }}<br/>({{ item.product.id }})</md-table-cell>
        <md-table-cell md-label="Comments" class="comment-cell">{{ item.comment }}</md-table-cell>
        <md-table-cell md-label="Action" md-sort-by="action" v-if="!profile_obj.admin && (profile_obj.group.name=='Web User' || profile_obj.group.name=='Mobile User')">
          <div class="gg-dropdown-btn">
            <md-menu md-size="big">
              <md-button class="dropdown-icon" md-menu-trigger>
                <i class="fas fa-caret-down"></i>
              </md-button>
              <md-menu-content class="gg-dropdown-menu">
                <md-menu-item @click="openDialogEdit(item)">Edit</md-menu-item>
                <md-menu-item @click="openDialogRemove(item)">Remove</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div>
      <md-dialog :md-active.sync="showDialogEdit" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span class="material-icons error-icon" @click="showDialogEdit = false">highlight_off</span>
          </md-dialog-actions>
          <form class="main-form" @submit.prevent="editComment">
            <h2 class="form-title">Edit Comment</h2>

            <p v-if="single_comment.id">
              <span><strong>Order ID: </strong> {{ single_comment.order.id }}</span><br/>
              <span><strong>Product: </strong> {{ single_comment.product.name }}</span>
            </p>

            <label for="comment">Comment</label>
            <textarea class="comment" v-model="single_comment.comment"/>
            <span class="form-error" v-if="errors.comment">{{errors.comment}}</span>

            <span class="form-success" v-if="success">
              <md-icon class="md-size-2x">check_circle_outline</md-icon>
              {{success}}
            </span>
            <button type="submit" class="gg-create-btn">Save</button>
          </form>
        </div>
      </md-dialog>
    </div>
    <div>
      <md-dialog :md-active.sync="showDialogRemove">
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons remove-icon">highlight_off</span>
          </div>
          <md-dialog-title class="modal-title">Are you sure want to remove this item</md-dialog-title>
          <span class="form-success" v-if="success">
              <md-icon class="md-size-2x">check_circle_outline</md-icon>
              {{success}}
            </span>
          <md-dialog-actions>
            <md-button class="cancel-btn" @click="showDialogRemove = false">Cancel</md-button>
            <md-button class="remove-btn" @click="removeComment">Delete</md-button>
          </md-dialog-actions>
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalProductEdit from "./ModalProductEdit.vue";
import ModalRemove from "./ModalRemove.vue";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }
  return items;
};

export default {
  data(){
    return{
      showDialogEdit: false,
      showDialogRemove: false,
      single_comment: [],
      comment: "",
      errors: [],
      success: "",
      search: null,
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
    }
  },
  computed: {
    ...mapState(["comment_obj", "order_obj", "profile_obj", "product_new_obj", "delivery_obj", "success_message", "error_message", "response_message",]),
    filterTableData() {
      let table_data = this.comment_obj

      return table_data
    },
  },
  methods: {
    ...mapActions(["listComments", "listOrder", "listProductsNoUser", "listMyDeliveries", "listProfile"]),
    // getProductById(order_id, product_id){
    //   let thisOrder = this.comment_obj
    //   thisOrder = thisOrder.find(
    //     (evt) => evt.order.id == order_id
    //   )

    //   let thisProduct = thisOrder.order.order_product
    //   thisProduct = thisProduct.find(
    //     (evt) => evt.product == product_id
    //   )
    //   return thisProduct
    // },
    openDialogEdit(comment_obj){
      this.single_comment = comment_obj
      this.showDialogEdit = true
    },
    openDialogRemove(comment_obj){
      this.single_comment = comment_obj
      this.showDialogRemove = true
    },
    editComment() {
      this.errors = [];
      this.success = '';
      if (this.single_comment.comment == "") {
        this.errors["comment"] = "Comment is required.";
      } else if (this.single_comment.comment.length <= 10) {
        this.errors["comment"] = "Comment at least 10 characters."
      } else {
        
        this.$store.dispatch("updateComment", { "comment": this.single_comment.comment, "id": this.single_comment.id }).then(() => {
          if (this.success_message == "success") {
            this.success = "Comment updated successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
    removeComment(){
      this.$store.dispatch("removeComment", this.single_comment.id).then(() => {
          if (this.success_message == "success") {
            this.success = "Comment deleted successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
    },
  },
  created() {
    this.listComments()
    this.listOrder();
    this.listProductsNoUser();
    this.listMyDeliveries();
    this.listProfile();

    // Pagination
    this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
    this.count_start = (this.current_page-1)*this.page_sequence
    this.count_end = this.current_page*this.page_sequence
  },
  watch: {
    '$route.query.page'(){
      this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
      this.count_start = (this.current_page-1)*this.page_sequence
      this.count_end = this.current_page*this.page_sequence
    }
  },
};
</script>

<style scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination{list-style: none; float: left; width: 100%; padding: 10px;}
.nav-pagination li{float: left; margin: 0 5px;}
.nav-pagination li.active{}
.nav-pagination li a{padding: 3px 8px; background: #e6e6e6; border: 1px #3b844a solid; border-radius: 6px; color: #3b844a; text-decoration: none; font-size: 14px; transition-duration: 1s;}
.nav-pagination li a:hover{ background: #3b844a; color: #ffffff; transition-duration: 1s;}
.nav-pagination li.active a{background: #3b844a; color: #ffffff;}
</style>