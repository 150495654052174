<template>
  <div class="gg-table detail-table">
    <h2>Member Details</h2>
    <table class="member-detail-table" border="0" cellspacing="0" cellpadding="10">
      <tr v-if="getUserInfo.image">
        <th>Photo</th><td> : </td><td>
          <a :href="getUserInfo.image" target="_blank">
            <img :src="getUserInfo.image" width="" height="" alt="Green Growth" style="height: 100px;">
          </a>
        </td>
      </tr>
      <tr v-if="getUserInfo.username">
        <th>Username</th><td> : </td><td>{{ getUserInfo.username }}</td>
      </tr>
      <tr v-if="getUserInfo.full_name">
        <th>Full Name</th><td> : </td><td>{{ getUserInfo.full_name }}</td>
      </tr>
      <tr v-if="getUserInfo.phone_number">
        <th>Phone No.</th><td> : </td><td>{{ getUserInfo.phone_number }}</td>
      </tr>
      <tr v-if="getUserInfo.email">
        <th>Email</th><td> : </td><td>{{ getUserInfo.email }}</td>
      </tr>
      <tr v-if="getUserInfo.group.name">
        <th>In Group</th><td> : </td><td>{{ getUserInfo.group.name }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info">
        <th>DOB</th><td> : </td><td>{{ getUserInfo.user_info.dob }} AD</td>
      </tr>
      <tr v-if="getUserInfo.user_info && getUserInfo.user_info.nationality && getUserInfo.user_info.nationality.length > 0">
        <th>Nationality</th><td> : </td><td>{{ getUserInfo.user_info.nationality.name }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info">
        <th>Street Address</th><td> : </td><td>{{ getUserInfo.user_info.street_address }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info">
        <th>City</th><td> : </td><td>{{ getUserInfo.user_info.city }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info">
        <th>State</th><td> : </td><td>{{ getUserInfo.user_info.state }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info">
        <th>Latitude</th><td> : </td><td>{{ getUserInfo.user_info.latitude }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info">
        <th>Longitude</th><td> : </td><td>{{ getUserInfo.user_info.longitude }}</td>
      </tr>
      <tr v-if="getUserInfo.active">
        <th>Status</th><td> : </td><td>{{ getUserInfo.active ? 'Active' : 'Inactive' }}</td>
      </tr>
      <tr v-if="getUserInfo.active">
        <th>User Description</th><td> : </td><td>{{ getUserInfo.user_info.user_description }}</td>
      </tr>
    </table>

    <table class="member-detail-table">
      <tr v-if="getUserInfo.user_info && getUserInfo.user_info.company_name">
        <th>Company Name</th><td> : </td><td>{{ getUserInfo.user_info.company_name }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info && getUserInfo.user_info.company_address">
        <th>Company Address</th><td> : </td><td>{{ getUserInfo.user_info.company_address }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info && getUserInfo.user_info.company_email">
        <th>Company Email</th><td> : </td><td>{{ getUserInfo.user_info.company_email }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info && getUserInfo.user_info.company_phone">
        <th>Company Phone</th><td> : </td><td>{{ getUserInfo.user_info.company_phone }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info && getUserInfo.user_info.vat_pan_number">
        <th>VAT/PAN Number</th><td> : </td><td>{{ getUserInfo.user_info.vat_pan_number }}</td>
      </tr>
      <tr v-if="getUserInfo.user_info && getUserInfo.user_info.vat_pan_photo">
        <th>VAT/PAN Image</th><td> : </td><td>
        <a :href="getUserInfo.user_info.vat_pan_photo" target="_blank">
            <img :src="getUserInfo.user_info.vat_pan_photo" width="" height="" alt="Green Growth" style="height: 100px;">
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
//import DetailTable from '@/components/DetailTable.vue';
//listProfile
import { mapState, mapActions } from "vuex";
export default {
  name: 'TabContent',
  components: {
    //
  },
  data(){
    return{
      user_id: this.$route.params.id,
    }
  },
  computed: {
    ...mapState(["all_user_obj"]),
    getUserInfo(){
      let userList = []
      userList = this.all_user_obj.find((evt) => evt.id == this.user_id)
      return userList
    }
  },
  created() {
    this.listUser();
  },
  methods: {
    ...mapActions(["listUser"]),
  }
};
</script>
<style lang="scss" >
.member-detail-table tr th{ text-align: left; }
// #tab-detail{
//   background-color: #fff;
//   border-radius: 10px;
//   margin: 4rem 0;
//   max-width: 70vw;
//   padding: 2rem;
// }
</style>
