//contents are same that of Card.vue and only two cards are shown
<template>
  <div class="dashboard-cards">
    <md-card class="md-accent" md-with-hover id="card1">
      <md-card-header >
        <md-card-header-text>
          <div class="md-title1">Today's Order</div>
          <div class="md-subhead1">{{dashboard_count['today_order']}}</div>
        </md-card-header-text>
      </md-card-header>
    </md-card>
    <md-card class="md-accent" md-with-hover id="card2">
      <md-card-header >
        <md-card-header-text>
          <div class="md-title1">Today's Delivered</div>
          <div class="md-subhead1">{{dashboard_count['today_deliver']}}</div>
        </md-card-header-text>
      </md-card-header>
    </md-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'Cards',

    computed: {
      ...mapState(["dashboard_count"])
    },

    created() {
      this.dashBoardCount();
    },
    methods: {
      ...mapActions(["dashBoardCount"]),
    }
};
</script>

<style lang="scss" >

</style>
