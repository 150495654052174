<template>
  <div class="container-form">
    <div class="form">
      <div class="heading-items">
        <p class="heading">Register your account</p>
      </div>
      <form id="contactForm" class="custom-form">
        <div class="name">
          <div class="first-name form-input-wrap">
            <input
              id="first-name"
              v-model="first_name"
              placeholder="First Name"
              required
              tabindex="1"
              type="text"
            />
            <label for="name">First Name</label>
            <!-- <span  v-if="errors.first_name">{{errors.first_name}}</span> -->
            <!-- <span >First name</span> -->
          </div>
          <div class="middle-name form-input-wrap">
            <input
              id="middle-name"
              v-model="middle_name"
              placeholder="Middle name"
              tabindex="1"
              type="text"
            />
            <label for="name">Middle Name</label>
          </div>
          <div class="last-name form-input-wrap">
            <input
              id="last-name"
              v-model="last_name"
              placeholder="last name"
              required
              tabindex="1"
              type="text"
            />
            <label for="name">Last Name</label>
            <!-- <span  v-if="errors.last_name">{{errors.last_name}}</span> -->
            <!-- <span >Last Name</span> -->
          </div>

          <div class="user-name form-input-wrap">
            <input
              id="user-name"
              v-model="username"
              placeholder="username"
              required
              tabindex="1"
              type="text"
            />
            <label for="name">Username</label>
            <!-- <span  v-if="errors.username">{{errors.username}}</span> -->
            <!-- <span >Username</span> -->
          </div>
        </div>
        <div class="email-phone">
          <div class="email form-input-wrap">
            <input
              id="email"
              v-model="email"
              placeholder="example@domain.com"
              required
              type="email"
            />
            <label for="email">Email</label>
            <!-- <span  v-if="errors.email">{{errors.email}}</span> -->
            <!-- <span >Email</span> -->
          </div>
          <div class="phone form-input-wrap">
            <input
              id="phone"
              v-model="phone"
              placeholder="phone number"
              required
              type="text"
            />
            <label for="phone">Mobile phone</label>
            <!-- <span  v-if="errors.phone">{{errors.phone}}</span> -->
            <!-- <span >Mobile Phone</span> -->
          </div>
        </div>
        <div class="password">
          <div class="new-password form-input-wrap">
              <i :class="'fas '+password_eye" @click="showHidePassword"></i>
            <input
              placeholder="Create a password"
              :type="password_type"
              v-model="password"
              id="password"
              required
            />
            <label for="password">Create a password</label>
          
            <!-- <span  v-if="errors.password">{{errors.password}}</span> -->
            <!-- <span >Password</span> -->
          </div>
          <div class="re-enter-password form-input-wrap">
            <i :class="'fas '+conf_password_eye" @click="showHideConfPassword"></i>
            <input
              placeholder="Conform your password"
              :type="conf_password_type"
              v-model="confirm_password"
              id="repassword"
              required
            />
            <label for="repassword">Confirm your password</label>
          
            <!-- <span
              v-if="errors.confirm_password"
            > -->
            <!-- <span>
              Confirm Password
            </span> -->
          </div>
        </div>
        <div class="user-age" id="user-age">
          <input
            type="checkbox"
            id="agreement"
            v-model="agreed"
            value="checkbox"
          />
          <label for="check-box">I am over 13 years old</label>
          <!-- <span  v-if="errors.agreed">{{errors.agreed}}</span> -->
          <!-- <span >Over 13 years old</span> -->
        </div>
        <div class="agreement-field" id="agreement-field">
          <input
            type="checkbox"
            id="agreement"
            v-model="agreed1"
            value="checkbox"
          />
          <label for="checkbox">
            I agree to account
            <span>
              <a href>terms and privacy</a>
            </span>
          </label>
          <!-- <span  v-if="errors.agreed1">{{errors.agreed1}}</span> -->
          <!-- <span>Privacy</span> -->
          <br />
        </div>
        <div class="submit-button">
          <button type="button" class="button" @click="RegisterForm">
            Register now
          </button>
        </div>
      </form>
    </div>
    <router-link to="/" class="login-link">
      Already have an account? Login
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import province from "../js/districts.js";

export default {
  name: "SiteRegistration",
  methods: {
    showHidePassword() {
      //alert("Here")
      if (this.password_type == "password") {
        this.password_eye = "fa-eye";
        this.password_type = "text";
      } else {
        this.password_eye = "fa-eye-slash";
        this.password_type = "password";
      }
    },
    showHideConfPassword() {
      if (this.conf_password_type == "password") {
        this.conf_password_eye = "fa-eye";
        this.conf_password_type = "text";
      } else {
        this.conf_password_eye = "fa-eye-slash";
        this.conf_password_type = "password";
      }
    },
    RegisterForm() {
        // this.errors = [];
        // if (this.first_name == "") {
        //   this.errors["first_name"] = "First Name require";
        // } else if (this.last_name == "") {
        //   this.errors["last_name"] = "Last Name required.";
        // } else if (this.username == "") {
        //   this.errors["username"] = "Username required.";
        // } else if (this.email == "") {
        //   this.errors["email"] = "Email required.";
        // } else if (this.phone == "") {
        //   this.errors["phone"] = "Phone Number required.";
        // } else if (this.agreed == "") {
        //   this.errors["agreed"] = "This field required.";
        // } else if (this.agreed1 == "") {
        //   this.errors["agreed1"] = "This field required.";
        // } else {
        //   this.$store
        //     .dispatch("createUser", {
        //       first_name: this.first_name,
        //       middle_name: this.middle_name,
        //       last_name: this.last_name,
        //       username: this.username,
        //       email: this.email,
        //       password: this.password,
        //       confirm_password: this.confirm_password,
        //       phone_number: this.phone,
        //       group: 1
        //     })
        //     .then(() => {
        //       if (this.user_register_message == "success") {
        //         alert("Successfully Register.");
        //       } else if (this.error_message == "errormessage") {
        //         alert(this.response_message);
        //       }
        //     });
        // }
      }
  },

    computed: {
      ...mapState([
        "successmessage",
        "error_message",
        "message",
        "userregister_message",
        "response_message"
      ]),

      districts: function() {
        if (this.province_selected != "") {
          return this.provinces.find(
            province => province.name == this.province_selected
          ).districts;
        } else {
          return "";
        }
      }
    },
    data() {
      return {
        first_name: "",
        middle_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        country: "Nepal",
        zone: "",
        city_address: "",
        street_address: "",
        errors: [],
        condition: "",
        strongpasword: "",
        wrongpassword: "",
        passworderror: "",
        agreed: "",
        agreed1: "",
        district_selected: "",
        province_selected: "",
        provinces: province,
        errors: [],
        password_eye: 'fa-eye-slash',
        password_type: 'password',
        conf_password_eye: 'fa-eye-slash',
        conf_password_type: 'password',
      };
    },
};
</script>

<style lang="scss">
.new-password {
  i {
    position: relative;
    left: 157px;
    top: 16px;
    width: 20px;
    cursor: pointer;
  }
}
.re-enter-password {
  margin: 0px 40px;
  i {
    position: relative;
    left: 177px;
    top: 16px;
    width: 20px;
    margin: 0px 5px;
    cursor: pointer;
  }
}
</style>
