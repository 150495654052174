<template>
	<div class="contents">
		<h2>Request Detail</h2>
		<div class="request-detail" v-if="getSingleRequest">
			<h3>Request General :-</h3>
			<p><strong>Image: </strong> &nbsp; <a :href="getSingleRequest.image" target="_blank"><img :src="getSingleRequest.image" width="100" height="" alt="Green Growht" /></a></p>
			<p><strong>Request ID: </strong> &nbsp; {{ getSingleRequest.id }}</p>
			<p><strong>Quantity: </strong> &nbsp; {{ getSingleRequest.quantity }}</p>
			<p><strong>Due Date: </strong> &nbsp; {{ getSingleRequest.due_date }}</p>
			<p><strong>Message: </strong> &nbsp; {{ getSingleRequest.message }}</p>
			<p><strong>Status: </strong> &nbsp; {{ getSingleRequest.request_status }}</p>
			<br>

			<h3>Request By :-</h3>
			<p><strong>Name: </strong> &nbsp; {{ getSingleRequest.request_by.full_name }}</p>
			<p><strong>Phone: </strong> &nbsp; {{ getSingleRequest.request_by.phone_number }}</p>
			<p><strong>Email: </strong> &nbsp; {{ getSingleRequest.request_by.email }}</p>
			<br>

			<h3>Request To :-</h3>
			<p><strong>Name: </strong> &nbsp; {{ getSingleRequest.request_to.full_name }}</p>
			<p><strong>Phone: </strong> &nbsp; {{ getSingleRequest.request_to.phone_number }}</p>
			<p><strong>Email: </strong> &nbsp; {{ getSingleRequest.request_to.email }}</p>
			<br>

			<h3>Request Product :-</h3>
			<p><strong>Product ID: </strong> &nbsp; {{ getSingleRequest.product.id }}</p>
			<p><strong>Product Name: </strong> &nbsp; {{ getSingleRequest.product.name }}</p>
			<p><strong>Product Category: </strong> &nbsp; {{ getSingleRequest.product.category.name }}</p>
			<p><strong>Product Unit: </strong> &nbsp; {{ getSingleRequest.product.unit.name }}</p>
			<p><strong>Product Price: </strong> &nbsp; Rs.{{ getSingleRequest.product.price_out }}</p>
			<p><strong>Product Brand: </strong> &nbsp; {{ getSingleRequest.product.brand.name }}</p>
			
		</div>
		<router-link :to="{name: 'request'}" class="btn btn-primary"> &laquo; Go Back </router-link>
	</div>
</template>

<script type="text/javascript">
import { mapState, mapActions } from "vuex";
export default{
	name: 'request-detail',
	data(){
		return {
			request_id: this.$route.params.id,
		}
	},
	computed: {
		...mapState(["product_request"]),
		getSingleRequest(){
		  let requestObj = []
		  requestObj = this.product_request.find((evt) => evt.id == this.request_id)
		  return requestObj
		}
	},
	created() {
		this.listProductRequests();
	},
	methods: {
		...mapActions(["listProductRequests"]),
	}
}
</script>