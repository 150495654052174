<template>
  <div class="deliveries-table">
    <md-field class="order-nav1">
        <h2 class="content">Table of Deliveries</h2>
        <div class="deliveries-daily">
          <h3 class="label-opt">{{ getCountNewDelivery() }} deliveries for</h3>
          <div class="md-layout-item">
            <md-field class="select-options">
              <md-select v-model="day" name="day" id="day">
                <md-option value="all" class="table-filter-option">All Day</md-option>
                <!-- <md-option value="today">Today</md-option>
                <md-option value="tomorrow">Tomorrow</md-option>
                <md-option value="yesterday">Yesterday</md-option> -->
              </md-select>
            </md-field>
          </div>
        </div>
      </md-field>
    <div>
      <!-- <div class="table-tabs">
        <ul>
          <li v-for="(tab, index) in tableTabs" :key="index" @click="tabIndex = index">
            <router-link :to="'#' + tab.title" class="active-tabs">{{ tab.title }}</router-link>
          </li>
        </ul>
      </div> -->
      <div class="table-top-nav">
        <md-tabs>
          <md-tab md-label="All" @click="tabIndex = 0"></md-tab>
          <md-tab md-label="Pending" @click="tabIndex = 1"></md-tab>
          <md-tab md-label="Delivered" @click="tabIndex = 2"></md-tab>
          <md-tab md-label="Rejected" @click="tabIndex = 3"></md-tab>
        </md-tabs>
      </div>
      <md-table class="gg-table table-order" v-model="filterTableData.slice(count_start,count_end)">
        <md-table-row>
          <md-table-head>Name</md-table-head>
          <md-table-head>Order ID</md-table-head>
          <md-table-head>Total Price</md-table-head>
          <md-table-head>Location</md-table-head>
          <md-table-head>Delivery Date</md-table-head>
          <md-table-head>Status</md-table-head>
          <md-table-head class="last-column">Action</md-table-head>
        </md-table-row>
        <draggable v-model="draggedData" group="people" @start="drag=true" @end="drag=false">
          <transition-group type="transition" :name="'flip-list'">
            <md-table-row
              class="table-row list-group-item"
              v-for="delivery in filterTableData.slice(count_start,count_end)"
              :key="delivery"
              title="Drag row (up and down) to change the position of deliveries"
            >
              <md-table-cell>
                <router-link
                  :to="{
                    name: 'order-detail',
                    params: { id: delivery.task.order.id },
                  }"
                  >{{ delivery.task.order.author.full_name }} {{ delivery.id }}</router-link
                ><br />
                {{ delivery.task.order.author.email }},
                {{ delivery.task.order.author.phone_number }}
              </md-table-cell>
              <md-table-cell
                >{{ delivery.task.order.id }}<br />{{
                  delivery.task.order.order_product.length
                }}
                item(s)</md-table-cell
              >
              <md-table-cell
                ><span class="table-total-price"
                  ><strong
                    >Rs.
                    {{
                      delivery.task.order.order_price +
                      delivery.task.order.tax_vat_price +
                      delivery.task.order.delivery_charge
                    }}</strong
                  ></span
                ><br />(Cash On Delivery)</md-table-cell
              >
              <md-table-cell
                >{{ delivery.task.order.delivery_address.street_address }},
                {{ delivery.task.order.delivery_address.city }},
                {{ delivery.task.order.delivery_address.state }}</md-table-cell
              >
              <md-table-cell>{{ delivery.task.due_date }}</md-table-cell>
              <md-table-cell>{{ delivery.status == 'end' ? 'Complete' : capitalize(delivery.status) }}</md-table-cell>
              <md-table-cell class="last-column">
                <md-button
                  v-if="delivery.status == 'hold'"
                  class="md-raised"
                  @click="openStartDeliveryBox(delivery)"
                  >Start</md-button
                >
                <md-button
                  v-if="delivery.status == 'start'"
                  class="md-raised"
                  @click="openEndDeliveryBox(delivery)"
                  >Complete</md-button
                >
              </md-table-cell>
            </md-table-row>
          </transition-group>
        </draggable>
      </md-table>
    </div>
    
    <div v-if="filterTableData && filterTableData.length">
      <ul class="nav-pagination">
        <li><router-link :to="{name: current_route, query:{page: current_page <= 1 ? 1 : current_page-1}}">&laquo;</router-link></li>

        <li v-for="(page, index) in Math.ceil(filterTableData.length/page_sequence)" :class="current_page == page ? 'active' : ''" :key="index">
          <router-link :to="{name: current_route, query:{page: page}}">{{ page }}</router-link>
        </li>
        
        <li><router-link :to="{name: current_route, query:{page: current_page >= Math.ceil(filterTableData.length/page_sequence) ? Math.ceil(filterTableData.length/page_sequence) : current_page+1}}">&raquo;</router-link></li>
      </ul>
    </div>
    <md-dialog :md-active.sync="openStartDialogBox">
      <div class="modal-alert">
        <span class="form-success" v-if="success">
          <md-icon class="md-size-2x">check_circle_outline</md-icon>
          Start This Delivery Process
        </span>
        <md-dialog-title class="modal-title"
          >Are you sure to start the delivery process?</md-dialog-title
        >
        <span class="form-success" v-if="success"
          ><br /><strong>{{ success }}</strong></span
        >
        <md-dialog-actions>
          <md-button class="cancel-btn" @click="openStartDialogBox = false"
            >Cancel</md-button
          >
          <md-button class="remove-btn" @click="submitStartDelivery"
            >START</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="openEndDialogBox">
      <div class="modal-alert">
        <span class="form-success" v-if="success">
          <md-icon class="md-size-2x">check_circle_outline</md-icon>
          This Delivery is Completed
        </span>
        <md-dialog-title class="modal-title"
          >Are you sure this delivery process is completed now?</md-dialog-title
        >
        <span class="form-success" v-if="success"
          ><br /><strong>{{ success }}</strong></span
        >
        <md-dialog-actions>
          <md-button class="cancel-btn" @click="openEndDialogBox = false"
            >Cancel</md-button
          >
          <md-button class="remove-btn" @click="submitEndDelivery"
            >COMPLETE</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "TabContent",
  components: {
    draggable,
  },
  data() {
    return {
      day: "all",
      new_delivery_count: 0,
      showDialogEdit: false,
      showDialogAssign: false,
      showDialogRemove: false,
      openStartDialogBox: false,
      openEndDialogBox: false,
      full_name: "",
      country: "",
      street: "",
      phone_number: "",
      province_selected: "Select Province",
      district_selected: "Select District",
      product_name: "",
      quantity: "",
      order_status: "Select Order Status",
      provinces: [
        {
          name: "Province 1",
        },
        {
          name: "Province 2",
        },
        {
          name: "Province 3",
        },
        {
          name: "Province 4",
        },
        {
          name: "Province 5",
        },
        {
          name: "Province 6",
        },
        {
          name: "Province 7",
        },
      ],
      districts: ["Kavre", "Kathmandu", "Lalitpur", "Bhaktapur"],
      assignee_name: "",
      product_name: "",
      location: "",
      assign_quantity: "",
      price: "",
      category: "",
      units: [
        {
          name: "Kg",
        },
        {
          name: "Gm",
        },
        {
          name: "Cm",
        },
      ],
      tabIndex: 0,
      activeTab: "",
      tableTabs: [
        {
          title: "All",
        },
        {
          title: "Pending",
        },
        {
          title: "Delivered",
        },
        {
          title: "Rejected",
        },
      ],
      single_delivery: [],
      delete_status: false,
      success: "",
      errors: [],
      currentLocation: JSON.parse(localStorage.getItem("currentLocation")),
      isDragging: false,
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10,
    };
  },
  computed: {
    ...mapState([
      "delivery_obj",
      "success_message",
      "error_message",
      "response_message",
    ]),
    draggedData: {
      get() {
        return this.$store.state.filterTableData;
      },
      set(value) {
        this.$store.commit("updatememberlist", value);
        console.log(this.all_user_obj);
      },
    },
    filterTableData() {
      this.$router.replace({
        name: this.current_route,
        query: {page: 1}
      })

      let todayValue = this.getToday();
      console.log('Today: ',todayValue)

      let table_data = this.delivery_obj;

      // if (this.day == 'today') {
      //   table_data = table_data.filter((row) => {
      //     return row.due_date == todayValue
      //   });
      // }
      // if (this.day == 'tomorrow') {
      //   table_data = table_data.filter((row) => {
      //     return row.due_date > todayValue
      //   });
      // }
      // if (this.day == 'yesterday') {
      //   table_data = table_data.filter((row) => {
      //     return row.due_date < todayValue
      //   });
      // }

      if (this.tabIndex == 1) {
        table_data = table_data.filter((row) => {
          return row.status == 'hold'
        });
      }
      if (this.tabIndex == 2) {
        table_data = table_data.filter((row) => {
          return row.status == 'end'
        });
      }
      if (this.tabIndex == 3) {
        table_data = table_data.filter((row) => {
          return row.status == 'reject'
        });
      }



      return table_data;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
  },
  created() {
    this.listDeliveries();
    // Pagination
    this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
    this.count_start = (this.current_page-1)*this.page_sequence
    this.count_end = this.current_page*this.page_sequence
  },
  watch: {
    '$route.query.page'(){
      this.current_page = (this.$route.query.page && this.$route.query.page > 0) ? this.$route.query.page : 1
      this.count_start = (this.current_page-1)*this.page_sequence
      this.count_end = this.current_page*this.page_sequence
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  methods: {
    ...mapActions(["listDeliveries"]),
    editOrder() {
      this.success = "";
      this.errors = [];
      if (this.order_status === "Select Order Status") {
        this.errors["order_status"] = "Please select an order status";
      } else {
        this.success = "Changes saved successfully";
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    assignTask() {
      this.errors = [];
      this.success = "";
      if (this.assignee_name == "") {
        this.errors["assignee_name"] = "Name of assignee is required.";
      } else if (this.product_name == "") {
        this.errors["product_name"] = "Name of product is required.";
      } else if (this.location == "") {
        this.errors["location"] = "Location is required.";
      } else if (this.assign_quantity <= 0) {
        this.errors["assign_quantity"] =
          "Quantity should be at least 1 or greater.";
      } else if ((this.single_unit = "")) {
        this.errors["single_unit"] = "Unit is required";
      } else if (this.price == "") {
        this.errors["price"] = "Price is required.";
      } else if (this.due_date == "") {
        this.errors["due_date"] = "Assign due date required.";
      } else {
        this.success = "Form submitted successfully.";
      }
    },
    openStartDeliveryBox(deliveryObj) {
      // Start Delivery Process
      this.single_delivery = this.delivery_obj.find(
        (evt) => evt.id == deliveryObj.id
      );
      this.openStartDialogBox = true;
    },
    openEndDeliveryBox(deliveryObj) {
      // End Delivery Process
      this.single_delivery = this.delivery_obj.find(
        (evt) => evt.id == deliveryObj.id
      );
      this.openEndDialogBox = true;
    },
    submitStartDelivery() {
      this.success = "";

      this.$store.dispatch("updateDeliveryStatus", {"status":'start',"id":this.single_delivery.id}).then(() => {
        if (this.success_message == "success") {
          this.success = "Delivery Started Successfully.<br/>Please wait we are updating the location.";

            let deliveryData = {
              "task": this.single_delivery.task.id,
              "start_lat": this.currentLocation ? this.currentLocation.latitude : "27.700000762939453",
              "start_long": this.currentLocation ? this.currentLocation.longitude : "85.33329772949219",
              "end_lat": this.single_delivery.task.order.delivery_address.latitude,
              "end_long": this.single_delivery.task.order.delivery_address.longitude,
              "ordering": 1,
              "id": this.single_delivery.id,
            }
            this.success_message = "";
            console.log("DD", deliveryData)

            this.$store.dispatch("updateNewDelivery", deliveryData).then(() => {
              if (this.success_message == "success") {
                this.success = "New Delivery Created Successfully.";
                setTimeout(function () {
                  location.reload();
                }, 1000);
              } else if (this.error_message == "errormessage") {
                alert(this.response_message);
              }
            });
          
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });

    },
    submitEndDelivery() {
      this.success = "";

      this.$store.dispatch("updateDeliveryStatus", {"status":'end',"id":this.single_delivery.id}).then(() => {
        if (this.success_message == "success") {
          this.success = "Delivery Delivered Successfully.";
            setTimeout(function () {
              location.reload();
            }, 1000);
        } else if (this.error_message == "errormessage") {
          alert(this.response_message);
        }
      });
    },
    deleteOrder() {
      this.success = "";
      this.delete_status = true;
    },
    getToday() {
      var currentDate = new Date();
      var formatted_date = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      return formatted_date;
    },
    getCountNewDelivery(){
      var data = this.delivery_obj
      var datalength = data.filter((item) => item.status == 'hold');
      return datalength.length;
    },
    capitalize(s){
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  },
};
</script>
<style lang="scss" scoped>
/** *** Pagination Menu Styling *** **/
.nav-pagination{list-style: none; float: left; width: 100%; padding: 10px;}
.nav-pagination li{float: left; margin: 0 5px;}
.nav-pagination li.active{}
.nav-pagination li a{padding: 3px 8px; background: #e6e6e6; border: 1px #3b844a solid; border-radius: 6px; color: #3b844a; text-decoration: none; font-size: 14px; transition-duration: 1s;}
.nav-pagination li a:hover{ background: #3b844a; color: #ffffff; transition-duration: 1s;}
.nav-pagination li.active a{background: #3b844a; color: #ffffff;}
</style>
<style lang='scss'>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.deliveries-table {
  .md-table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px 200px;
  }
  .table-row {
    border-bottom: 1px solid #efefef;
  }
  .md-table-cell {
    border: none;
    height: max-content;
    padding: 20px 0;
  }
  .last-column {
    display: flex;
    justify-content: flex-end;
    .md-table-cell-container,
    .md-table-head-label {
      padding: 0;
    }
  }
}
.table-filter-option {
  .md-list-item-text {
    &:hover {
      background: #4ba069;
      color: white;
    }
  }
}
</style>