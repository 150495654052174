<template>
  <div class="inventry">
    <div class="contents">
      <md-toolbar class="nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-layer-group"></i>
        </md-icon>
        <h1 class="content">Inventory</h1>
      </md-toolbar>
      <CardsTwo />
      <!-- <div class="add-btn">
        <button class="gg-create-btn" @click="openDialogProduct = !openDialogProduct">+ Add Inventory</button>
      </div> -->
      <div class="search-box">
        <md-field md-clearable class="search">
          <md-icon><i class="fas fa-search"></i></md-icon>
          <md-input placeholder="Search to edit the product" v-model="search" />
        </md-field>
      </div>

      <div v-if="search_product && search_product.length > 0">
        <h1>Your Search Results...!!</h1>
        <div class="card">
          <div class="product-card" v-for="(search, index) in search_product" :key="index">
            <div class="product-image">
              <img :src="search.image" alt="" />
              <div class="preview-product-icons">
                <a
                  class="preview-icon"
                  @click="addInInventryData(search.id)"
                  href="javascript:void(0);"
                  title="Add in Inventory"
                  ><i class="fas fa-plus"></i
                ></a>

                <a
                  class="preview-icon"
                  @click="reduceInInventryData(search.id)"
                  href="javascript:void(0);"
                  title="Reduce in Inventory"
                  ><i class="fas fa-minus"></i
                ></a>
              </div>
            </div>
            <div class="description-card">
              <a class="product-description" href="#">
                <h1>{{ search.name }}</h1>
              </a>
              <div class="product-quantity">
                <p>
                  <span><strong>Price:</strong> Rs. {{ search.price_out }}/-</span><br />
                  <span><strong>SKU: </strong> {{ !search.sku ? "---" : search.sku }}</span>
                </p>
                <span
                  >Quantity: {{ search.total_quantity }} {{ search.unit.name }} (<a
                    href="javascript:void(0);"
                    style="font-size: 14px;"
                    @click="viewIntentryDetail(search.id)"
                    ><strong><u>In detail</u></strong></a
                  >)
                </span>
                <span>Product Status: {{ search.product_status }}</span>
              </div>
              <div class="star-rating">
                <span
                  class="fa fa-star one"
                  :class="search.average_rating >= 1 ? 'checked' : ''"
                ></span>
                <span
                  class="fa fa-star two"
                  :class="search.average_rating >= 2 ? 'checked' : ''"
                ></span>
                <span
                  class="fa fa-star three"
                  :class="search.average_rating >= 3 ? 'checked' : ''"
                ></span>
                <span
                  class="fa fa-star four"
                  :class="search.average_rating >= 4 ? 'checked' : ''"
                ></span>
                <span
                  class="fa fa-star five"
                  :class="search.average_rating >= 5 ? 'checked' : ''"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="profile_obj.admin || profile_obj.staff">
        <div
          class="nav section-title"
          v-for="(category_obj, index) in product_category"
          :key="index"
          v-if="category_obj.product_category.length > 0"
        >
          <h2>{{ category_obj.name }}</h2>
          <div class="card">
            <div v-for="(product_obj, index) in category_obj.product_category" :key="index">
              <div class="product-card">
                <div class="product-image" id="product-details">
                  <img :src="product_obj.image" />
                  <div class="preview-product-icons">
                    <a
                      class="preview-icon"
                      @click="addInInventryData(product_obj.id)"
                      href="javascript:void(0);"
                      title="Add in Inventory"
                      ><i class="fas fa-plus"></i
                    ></a>

                    <a
                      class="preview-icon"
                      @click="reduceInInventryData(product_obj.id)"
                      href="javascript:void(0);"
                      title="Reduce in Inventory"
                      ><i class="fas fa-minus"></i
                    ></a>
                  </div>
                </div>
                <div class="description-card">
                  <a class="product-description" href="#">
                    <h1>{{ product_obj.name }}</h1>
                  </a>
                  <div class="product-quantity">
                    <p>
                      <span><strong>Price:</strong> Rs. {{ product_obj.price_out }}/-</span><br />
                      <span
                        ><strong>SKU: </strong>
                        {{ !product_obj.sku ? "---" : product_obj.sku }}</span
                      >
                    </p>
                    <span
                      >Quantity: {{ product_obj.total_quantity }} {{ product_obj.unit.name }} (<a
                        href="javascript:void(0);"
                        style="font-size: 14px;"
                        @click="viewIntentryDetail(product_obj.id)"
                        ><strong><u>In detail</u></strong></a
                      >)
                    </span>
                    <span>Product Status: {{ product_obj.product_status }}</span>
                  </div>
                  <div class="star-rating">
                    <span
                      class="fa fa-star one"
                      :class="product_obj.average_rating >= 1 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star two"
                      :class="product_obj.average_rating >= 2 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star three"
                      :class="product_obj.average_rating >= 3 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star four"
                      :class="product_obj.average_rating >= 4 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star five"
                      :class="product_obj.average_rating >= 5 ? 'checked' : ''"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="nav section-title"
          v-for="(category_obj, index) in product_category"
          :key="index"
          v-if="category_obj.product_category.length > 0"
        >
          <h2>{{ category_obj.name }}</h2>
          <div class="card">
            <div
              v-for="(product_obj, index) in category_obj.product_category"
              :key="index"
              v-if="product_obj.author.id == profile_obj.id"
            >
              <div class="product-card">
                <div class="product-image" id="product-details">
                  <img :src="product_obj.image" />
                  <div class="preview-product-icons">
                    <a
                      class="preview-icon"
                      @click="addInInventryData(product_obj.id)"
                      href="javascript:void(0);"
                      title="Add in Inventory"
                      ><i class="fas fa-plus"></i
                    ></a>

                    <a
                      class="preview-icon"
                      @click="reduceInInventryData(product_obj.id)"
                      href="javascript:void(0);"
                      title="Reduce in Inventory"
                      ><i class="fas fa-minus"></i
                    ></a>
                  </div>
                </div>
                <div class="description-card">
                  <a class="product-description" href="#">
                    <h1>{{ product_obj.name }}</h1>
                  </a>
                  <div class="product-quantity">
                    <p>
                      <span><strong>Price:</strong> Rs. {{ product_obj.price_out }}/-</span><br />
                      <span
                        ><strong>SKU: </strong>
                        {{ !product_obj.sku ? "---" : product_obj.sku }}</span
                      >
                    </p>
                    <span
                      >Quantity: {{ product_obj.total_quantity }} {{ product_obj.unit.name }} (<a
                        href="javascript:void(0);"
                        style="font-size: 14px;"
                        @click="viewIntentryDetail(product_obj.id)"
                        ><strong><u>In detail</u></strong></a
                      >)
                    </span>
                    <span>Product Status: {{ product_obj.product_status }}</span>
                  </div>
                  <div class="star-rating">
                    <span
                      class="fa fa-star one"
                      :class="product_obj.average_rating >= 1 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star two"
                      :class="product_obj.average_rating >= 2 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star three"
                      :class="product_obj.average_rating >= 3 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star four"
                      :class="product_obj.average_rating >= 4 ? 'checked' : ''"
                    ></span>
                    <span
                      class="fa fa-star five"
                      :class="product_obj.average_rating >= 5 ? 'checked' : ''"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- Add in Inventnry Modal Box -->
      <md-dialog :md-active.sync="openDialog" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span class="material-icons error-icon" @click="openDialog = false">highlight_off</span>
          </md-dialog-actions>

          <div class="main-form">
            <h2 class="form-title"><i class="fas fa-plus"></i> Add In Inventroy</h2>

            <label for="product-id">Added Quantity</label>
            <input
              type="number"
              v-model="addform.quantity"
              name="product-id"
              placeholder="Add Quantity in Stock"
            />
            <span class="text-danger float-right" style="color:red" v-if="errors.quantity">{{
              errors.quantity
            }}</span>

            <label for="category">Unit</label>
            <select class="category" v-model="addform.unit">
              <option v-for="(unit_obj, index) in product_unit" :key="index" :value="unit_obj.id">{{
                unit_obj.name
              }}</option>
            </select>
            <span class="text-danger float-right" style="color:red" v-if="errors.unit">{{
              errors.unit
            }}</span>

            <label for="price">Price In</label>
            <input type="text" v-model="addform.price_in" name="price" placeholder="200" required />
            <span class="text-danger float-right" style="color:red" v-if="errors.price_in">{{
              errors.price_in
            }}</span>

            <label for="price">Price Out</label>
            <input
              type="text"
              v-model="addform.price_out"
              name="price"
              placeholder="200"
              required
            />
            <span class="text-danger float-right" style="color:red" v-if="errors.price_out">{{
              errors.price_out
            }}</span>

            <span style="color:red" v-if="error_message.length > 0"
              >Inventroy Value Required..<br
            /></span>
            <span style="color:green" v-if="success_message.length > 0"
              >Inventory Added Successfully.<br
            /></span>

            <input
              type="submit"
              value="Add In Inventory "
              class="gg-create-btn"
              @click="submitAddInInventry(single_product.id)"
            />
          </div>
        </div>
      </md-dialog>

      <!-- Reduce in Inventnry Modal Box -->
      <md-dialog :md-active.sync="openReduceDialog" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span class="material-icons error-icon" @click="openReduceDialog = false"
              >highlight_off</span
            >
          </md-dialog-actions>

          <div class="main-form">
            <h2 class="form-title"><i class="fas fa-minus"></i> Reduce In Inventory</h2>

            <label for="product-id">Reduce Quantity</label>
            <input
              type="number"
              v-model="reduceForm.quantity"
              name="product-id"
              placeholder="Reducue Quantity in Stock"
            />
            <span class="text-danger float-right" style="color:red" v-if="errors.quantity">{{
              errors.quantity
            }}</span>

            <label for="category">Unit</label>
            <select class="category" v-model="reduceForm.unit">
              <option v-for="(unit_obj, index) in product_unit" :key="index" :value="unit_obj.id">{{
                unit_obj.name
              }}</option>
            </select>
            <span class="text-danger float-right" style="color:red" v-if="errors.unit">{{
              errors.unit
            }}</span>

            <span style="color:red" v-if="error_message.length > 0"
              >Inventory Value Required.<br
            /></span>
            <span style="color:green" v-if="success_message.length > 0"
              >Inventory Managed Successfully.<br
            /></span>

            <input
              type="submit"
              value="Reduce In Inventory "
              class="gg-create-btn"
              @click="submitReduceInInventry(single_product.id)"
            />
          </div>
        </div>
      </md-dialog>

      <!-- List Product wise inventory Modal Box -->
      <md-dialog :md-active.sync="productInventryModal" class="modal-main">
        <div class="modal-form">
          <md-dialog-actions class="close-icon">
            <span class="material-icons error-icon" @click="productInventryModal = false"
              >highlight_off</span
            >
          </md-dialog-actions>

          <div class="main-form">
            <h2 class="form-title">Product Inventory Detail</h2>
            <div v-if="producut_inventry_detail">
              <p>
                <a :href="producut_inventry_detail.image" target="_blank"
                  ><img
                    :src="producut_inventry_detail.image"
                    width="100"
                    height=""
                    :alt="producut_inventry_detail.name"
                /></a>
              </p>
              <p><span>Name: </span> {{ producut_inventry_detail.name }}</p>
              <p>
                <span>Quantity: </span> {{ producut_inventry_detail.total_quantity }}
                {{ producut_inventry_detail.unit.name }}
              </p>
              <p><span>Category: </span> {{ producut_inventry_detail.category.name }}</p>
              <p><span>SKU (Stock Keeping Unit): </span> {{ producut_inventry_detail.sku }}</p>
              <p>
                <span>Product Status: </span>
                {{
                  producut_inventry_detail.product_status == "Available"
                    ? "In Stock"
                    : "Out of stock"
                }}
              </p>
              <p><span>Added By: </span> {{ producut_inventry_detail.author.full_name }}</p>

              <div
                v-if="
                  producut_inventry_detail.product_inventry &&
                    producut_inventry_detail.product_inventry.length > 0
                "
              >
                <table border="1" cellspacing="0" cellpadding="5">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Type</th>
                      <th>Price In</th>
                      <th>Price Out</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(single_inventry, index) in producut_inventry_detail.product_inventry"
                      :key="index"
                    >
                      <td>{{ index + 1 }}.</td>
                      <td>{{ single_inventry.quantity }}</td>
                      <td>{{ single_inventry.unit ? single_inventry.unit.name : "" }}</td>
                      <td>
                        {{
                          single_inventry.entry_type.charAt(0).toUpperCase() +
                            single_inventry.entry_type.slice(1)
                        }}
                        (<span
                          v-if="
                            single_inventry.entry_type == 'new' ||
                              single_inventry.entry_type == 'return'
                          "
                          >+</span
                        ><span v-else>-</span>)
                      </td>
                      <td>Rs.{{ single_inventry.price_in }}/-</td>
                      <td>Rs.{{ single_inventry.price_out }}/-</td>
                      <td>{{ single_inventry.status ? "Accepted" : "Pending" }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Remain</td>
                      <td colspan="6">
                        {{ producut_inventry_detail.total_quantity }}
                        {{ producut_inventry_detail.unit.name }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div v-else>
                <p>
                  <strong><em>Sorry! No stock added yet.</em></strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </md-dialog>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ProductCard from "@/components/ProductCard.vue";
import CardsTwo from "@/components/CardsTwo.vue";
import ModalProductEdit from "@/components/ModalProductEdit.vue";
import ModalProduct from "@/components/ModalProduct.vue";
import axios from "axios";

export default {
  components: {
    ProductCard,
    ModalProductEdit,
    ModalProduct,
    CardsTwo
  },
  computed: {
    ...mapState([
      "profile_obj",
      "product_new_obj",
      "product_category",
      "product_unit",
      "success_message",
      "error_message",
      "response_message"
    ]),

    search_product() {
      let table_data = this.product_category;
      let searchString = this.search;
      // console.log(table_data);
      if (searchString) {
        let table_data_new = [];
        let table_data_new_obj = [];
        searchString = searchString.toLowerCase();
        table_data.forEach(element => {
          table_data_new.push(element.product_category);
        });
        table_data_new.forEach(items => {
          items = items.filter(row => {
            if (row.name.toLowerCase().indexOf(searchString) > -1) {
              table_data_new_obj.push(row);
            }
          });
        });
        console.log(table_data_new_obj);
        return table_data_new_obj;
      }
    }
  },

  created() {
    this.listProfile();
    this.listProductsNoUser();
    this.listCategories();
    this.listUnits();
  },
  data: () => ({
    openDialog: false,
    openReduceDialog: false,
    openDialogProduct: false,
    productInventryModal: false,
    single_product: [],
    single_category: "",
    producut_inventry_detail: "",
    single_unit: "",
    addform: {
      unit: "",
      quantity: "",
      price_in: "",
      price_out: ""
    },
    reduceForm: {
      unit: "",
      quantity: ""
    },
    errors: [],
    search: ""
  }),
  methods: {
    ...mapActions(["listProfile", "listProductsNoUser", "listCategories", "listUnits"]),
    addInInventryData(product_id) {
      this.single_product = this.product_new_obj.find(evt => evt.id == product_id);
      this.addform.unit = this.single_product.unit.id;
      this.single_category = this.single_product.category.id;
      this.single_unit = this.single_product.unit.id;
      this.openDialog = !this.openDialog;
    },
    reduceInInventryData(product_id) {
      this.single_product = this.product_new_obj.find(evt => evt.id == product_id);
      this.reduceForm.unit = this.single_product.unit.id;
      this.openReduceDialog = !this.openReduceDialog;
    },
    viewIntentryDetail(product_id) {
      axios.defaults.headers.common["authorization"] = "JWT " + this.$store.state.token;
      axios.get(`productinventry/${product_id}`).then(response => {
        if (response.status == 200) {
          this.producut_inventry_detail = response.data;
        }
      });

      this.productInventryModal = !this.productInventryModal;
    },
    submitAddInInventry() {
      this.errors = [];
      if (this.addform.quantity == "") {
        this.errors["quantity"] = "Quantity required.";
      } else if (this.addform.unit == "") {
        this.errors["unit"] = "Unit required.";
      } else if (this.addform.price_in == "") {
        this.errors["price_in"] = "Price-in required.";
      } else if (this.addform.price_out == "") {
        this.errors["price_out"] = "Price-out required.";
      } else {
        let formData = new FormData();
        formData.append("product", this.single_product.id);
        formData.append("unit", this.addform.unit);
        formData.append("quantity", this.addform.quantity);
        formData.append("price_in", this.addform.price_in);
        formData.append("price_out", this.addform.price_out);
        formData.append("entry_type", "new");
        formData.append("ip_address", "127.0.0.1");
        formData.append("status", "active");
        this.$store.dispatch("createInventory", formData).then(() => {
          if (this.success_message == "success") {
            setTimeout(function() {
              location.reload();
            }, 1500);
          } else if (this.error_message == "errormessage") {
          }
        });
      }
    },
    submitReduceInInventry() {
      this.errors = [];
      if (this.reduceForm.quantity == "") {
        this.errors["quantity"] = "Quantity required.";
      } else if (this.reduceForm.unit == "") {
        this.errors["unit"] = "Unit required.";
      } else {
        let formData = new FormData();
        formData.append("product", this.single_product.id);
        formData.append("unit", this.reduceForm.unit);
        formData.append("quantity", this.reduceForm.quantity);
        formData.append("price_in", this.single_product.price_in);
        formData.append("price_out", this.single_product.price_out);
        formData.append("entry_type", "defected");
        formData.append("ip_address", "127.0.0.1");
        formData.append("status", "active");
        this.$store.dispatch("createInventory", formData).then(() => {
          if (this.success_message == "success") {
            setTimeout(function() {
              location.reload();
            }, 1500);
          } else if (this.error_message == "errormessage") {
          }
        });
      }
    }
  }
};
</script>

<style type="text/css" scoped>
.search-box {
  display: flex;
  justify-content: flex-end;
}
.search {
  border: 1px solid rgb(156, 154, 154);
  padding: 10px 10px;
  width: 300px;
}
.main-form .gg-create-btn {
  width: 150px;
}
.product-card .description-card .product-quantity {
  font-size: 16px;
}
</style>
